import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BUY_SELL_CHART_VALUE, INDICATORS_TYPES } from 'shared-modules/constants';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { useInstrumentShortName } from 'shared-modules/hooks';
import { widget } from 'shared-modules/thirdPartyLibrary/TradingView/charting_library';
import { chartType } from 'shared-modules/services/builder';
import { useEffectSetChildTabIndex } from '../../services/hooks';
import { Spin } from '../Spin';
import datafeed from '../Chart/datafeed';
import styles from './techPriceChart.module.scss';
import { overrides } from '../Chart/Chart';

const indicatorParams = {
  [INDICATORS_TYPES.MOVING_AVERAGE.VALUE]: [
    ['', 5, 'close', 0, 'SMA', 5],
    ['', 20, 'close', 0, 'SMA', 20],
  ],
  [INDICATORS_TYPES.RELATIVE_STRENGTH_INDEX.VALUE]: [[14, 'sma', 14]],
  [INDICATORS_TYPES.MACD.VALUE]: [[12, 26, 'close', 9]],
  [INDICATORS_TYPES.BOLLINGER_BANDS.VALUE]: [[20, 2]],
  [INDICATORS_TYPES.DMI.VALUE]: [[14, 14]],
  [INDICATORS_TYPES.STOCHASTICS.VALUE]: [[9, 3, 3]],
  [INDICATORS_TYPES.MOMENTUM.VALUE]: [[10, 'close']],
  [INDICATORS_TYPES.ICHIMOKU_CLOUD.VALUE]: [[9, 26, 52, 26]],
};

const TechPriceChart = ({
  selectedCurrencyPairId,
  selectedSide,
  resolution,
  serviceId,
  instrumentOptions,
  instrumentList,
  indicator1,
  indicator2,
  isMobile,
}) => {
  const [loading, setLoading] = useState(true);

  const tvWidgetRef = useRef({});
  const sideRef = useRef({});
  const chartContainerRef = useEffectSetChildTabIndex('-1');

  const shortName = useInstrumentShortName(selectedCurrencyPairId);
  const symbol = useMemo(() => `${shortName}(${BUY_SELL_CHART_VALUE[selectedSide]})`, [shortName, selectedSide]);

  useEffect(() => {
    if (
      ALL_SERVICES.some((service) => !instrumentOptions[service].length) ||
      !selectedCurrencyPairId ||
      tvWidgetRef.current.headerReady
    ) {
      return;
    }

    const widgetOptions = {
      symbol,
      datafeed: datafeed({ instrumentOptions, isMobile, serviceId, instrumentList }),
      interval: resolution,
      container: chartContainerRef.current,
      library_path: '/charting_library/',
      custom_css_url: isMobile ? 'mobileCustomStyle2.css' : 'customStyle.css',
      timezone: 'Asia/Tokyo',
      locale: 'ja',
      disabled_features: [
        'display_market_status',
        'items_favoriting',
        'header_screenshot',
        'header_saveload',
        'header_undo_redo',
        'header_fullscreen_button',
        'header_compare',
        'timeframes_toolbar',
        'study_dialog_search_control',
        'create_volume_indicator_by_default',
        'header_widget',
        'left_toolbar',
        'header_symbol_search',
        'header_settings',
        'symbol_search_hot_key',
        'study_buttons_in_legend',
        'show_hide_button_in_legend',
        'format_button_in_legend',
        'delete_button_in_legend',
        'context_menus',
        'insert_indicator_dialog_shortcut',
        'property_pages',
      ],
      autosize: true,
      theme: 'Dark',
      overrides,
      settings_overrides: overrides,
    };
    tvWidgetRef.current = new widget(widgetOptions); // eslint-disable-line

    tvWidgetRef.current.onChartReady(() => {
      setLoading(false);
      sideRef.current = selectedSide;
    });
  }, [
    chartContainerRef,
    instrumentOptions,
    selectedCurrencyPairId,
    instrumentList,
    serviceId,
    isMobile,
    selectedSide,
    symbol,
    resolution,
  ]);

  useEffect(() => {
    return () => {
      if (tvWidgetRef.current.remove) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = {};
      }
    };
  }, []);

  const indicator1Ref = useRef(indicator1);
  const indicator2Ref = useRef(indicator2);
  const [indicatorsDeletionTime, setIndicatorsDeletionTime] = useState(null);

  useEffect(() => {
    indicator1Ref.current = indicator1;
  }, [indicator1]);

  useEffect(() => {
    indicator2Ref.current = indicator2;
  }, [indicator2]);

  const renderIndicators = useCallback(() => {
    if (indicator1Ref.current) {
      indicatorParams[indicator1Ref.current].forEach((config) => {
        tvWidgetRef.current.activeChart().createStudy(indicator1Ref.current, false, false, config);
      });
    }
    if (indicator2Ref.current) {
      indicatorParams[indicator2Ref.current].forEach((config) => {
        tvWidgetRef.current.activeChart().createStudy(indicator2Ref.current, false, false, config);
      });
    }
  }, []);

  useEffect(() => {
    if (indicatorsDeletionTime) {
      renderIndicators();
    }
  }, [indicatorsDeletionTime, renderIndicators]);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    tvWidgetRef.current.onChartReady(() => {
      tvWidgetRef.current.chart().setChartType(chartType);
      tvWidgetRef.current.activeChart().removeAllStudies();
      setIndicatorsDeletionTime(Date.now());
    });
  }, []);

  // selectedSideのエフェクト
  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady || !Number.isFinite(selectedSide)) {
      return;
    }

    tvWidgetRef.current.onChartReady(() => {
      tvWidgetRef.current.setSymbol(symbol, resolution, () => {
        tvWidgetRef.current.activeChart().removeAllStudies();
        setIndicatorsDeletionTime(Date.now());
      });
    });
  }, [selectedSide, symbol, resolution]);

  // indicator1とindicator2のエフェクト
  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }

    tvWidgetRef.current.onChartReady(() => {
      tvWidgetRef.current.activeChart().removeAllStudies();
      setIndicatorsDeletionTime(Date.now());
    });
  }, [indicator1, indicator2, selectedSide, resolution]);

  return (
    <div className={classNames(styles.wrapper, { [styles.loading]: loading })}>
      {loading && <Spin className={styles.loader} />}
      <div className={classNames(styles.chartAreaWrapper, { [styles.displayNone]: loading })}>
        <div className={styles.chartContainer} ref={chartContainerRef} />
      </div>
    </div>
  );
};

TechPriceChart.propTypes = {
  selectedCurrencyPairId: PropTypes.string.isRequired,
  selectedSide: PropTypes.number,
  resolution: PropTypes.string.isRequired,
  serviceId: PropTypes.string.isRequired,
  instrumentOptions: PropTypes.shape(
    ALL_SERVICES.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      {},
    ),
  ).isRequired,
  instrumentList: PropTypes.shape({}).isRequired,
  indicator1: PropTypes.string,
  indicator2: PropTypes.string,
  isMobile: PropTypes.bool,
};
TechPriceChart.defaultProps = {
  selectedSide: null,
  indicator1: null,
  indicator2: null,
  isMobile: false,
};

export default memo(TechPriceChart);
