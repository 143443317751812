import { memo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FX } from 'shared-modules/constants';
import { useSelectedShareItem } from 'shared-modules/hooks';
import { useOpenLink } from '../../../../../hooks';
import { Button, Spin } from '../../../../../components';
import SelectionImage from '../../../../../components/SelectionImage';
import styles from './header.module.scss';

export const Header = memo(
  ({ strategyGroup, hasLogic, externalLinkUrl, externalLinkName, loadingSelections, loadingDetail, onClick }) => {
    const openLink = useOpenLink(externalLinkUrl, externalLinkName);
    const validExternalLink = Boolean(externalLinkUrl && externalLinkName);
    const displayedOnceRef = useRef(validExternalLink);

    const isSelectedShareItem = useSelectedShareItem();

    useEffect(() => {
      if (validExternalLink) {
        displayedOnceRef.current = true;
      }
    }, [validExternalLink]);

    if (loadingSelections) {
      displayedOnceRef.current = false;
      return <Spin className={styles.loader} />;
    }
    const { image, serviceId, name, description, comment, labId } = strategyGroup;
    const isShare = labId != null;
    const disabled = strategyGroup == null || loadingDetail || !validExternalLink;

    return (
      <div className={styles.container}>
        <SelectionImage
          className={styles.image}
          width="32px"
          image={image}
          hasFrame={serviceId !== FX}
          isShare={isShare}
          serviceId={serviceId}
        />
        <div className={styles.content}>
          <div className={styles.name}>{name}</div>
          <div className={styles.description}>{isShare ? comment : description}</div>
        </div>
        <div className={styles.buttonArea}>
          {(hasLogic || (loadingDetail && displayedOnceRef.current)) && !isSelectedShareItem && (
            <Button
              className={styles.linkButton}
              onClick={onClick}
              disabled={strategyGroup == null || loadingDetail}
              secondary
            >
              <div className={styles.linkCaption}>この戦略の解説</div>
            </Button>
          )}
          {(validExternalLink || (loadingDetail && displayedOnceRef.current)) && (
            <Button
              className={styles.linkButton}
              onClick={openLink}
              disabled={disabled}
              loading={loadingDetail}
              secondary
            >
              <div className={styles.linkCaption}>
                {externalLinkName}
                <i className={classNames('material-icons', styles.linkIcon, { [styles.disabledLinkIcon]: disabled })}>
                  open_in_new
                </i>
              </div>
            </Button>
          )}
        </div>
      </div>
    );
  },
);

Header.propTypes = {
  strategyGroup: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    serviceId: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    comment: PropTypes.string,
    labId: PropTypes.number,
  }),
  externalLinkUrl: PropTypes.string,
  externalLinkName: PropTypes.string,
  loadingSelections: PropTypes.bool,
  loadingDetail: PropTypes.bool,
  hasLogic: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Header.defaultProps = {
  strategyGroup: undefined,
  externalLinkUrl: undefined,
  externalLinkName: undefined,
  loadingSelections: false,
  loadingDetail: false,
  hasLogic: false,
};
