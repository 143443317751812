import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHANNEL_BROWSER } from 'shared-modules/constants';
import { TAB_INDIVIDUAL_NOTICE, TEXT_NO_MESSAGES } from 'shared-modules/constants/message';
import { getMessagesRequest } from 'shared-modules/redux/actions/messageActions';
import { Spin } from '../../../components';
import { Card } from '../Card';
import styles from './contents.module.scss';

export const Contents = memo(() => {
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.message.currentTab);
  const loading = useSelector((state) => state.message.loading);
  const messages = useSelector((state) => state.message.messages);

  useEffect(() => {
    const isIndividual = currentTab === TAB_INDIVIDUAL_NOTICE;
    dispatch(getMessagesRequest({ isIndividual, channel: CHANNEL_BROWSER }));
  }, [dispatch, currentTab]);

  if (loading) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }

  if (!messages?.length) {
    return <div className={styles.noMessage}>{TEXT_NO_MESSAGES}</div>;
  }

  return (
    <div className={styles.container}>
      {messages.map((message) => (
        <Card key={message.messageId} message={message} />
      ))}
    </div>
  );
});
