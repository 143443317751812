import { call, put, select } from 'redux-saga/effects';
import {
  getMessagesRequestStartLoading,
  getMessagesRequestStopLoading,
  getMessagesSuccess,
} from '../../actions/messageActions';
import { getMessages } from '../../../api/messageApi';
import { errorHandler } from '../errorSaga';
import { TAB_INDIVIDUAL_NOTICE } from '../../../constants/message';
import { sorted } from './common';

export function* getMessagesRequestHandler({ payload }) {
  try {
    const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
    if (!isAuthenticated) {
      // 未ログイン時はスキップ
      return;
    }
    const { isIndividual, channel } = payload;
    yield put(getMessagesRequestStartLoading());
    const { data: messages } = yield call(getMessages, { isIndividual, channel });
    const currentTab = yield select((state) => state.message.currentTab);
    if (isIndividual === (currentTab === TAB_INDIVIDUAL_NOTICE)) {
      yield put(getMessagesSuccess({ messages: sorted(messages) }));
    }
  } catch (error) {
    yield call(errorHandler, { error });
  } finally {
    yield put(getMessagesRequestStopLoading());
  }
}
