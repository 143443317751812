import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CHANNEL_BROWSER } from 'shared-modules/constants';
import { LABEL_INDIVIDUAL_NOTICE, LABEL_NEWS, TAB_INDIVIDUAL_NOTICE, TAB_NEWS } from 'shared-modules/constants/message';
import {
  checkInitialDepositRequest,
  clearMessages,
  getUnreadCountRequest,
  setCurrentTab,
  startAutoRefreshMessage,
  stopAutoRefreshMessage,
} from 'shared-modules/redux/actions/messageActions';
import { Tabs } from '../../components';
import { Contents } from './Contents';
import styles from './message.module.scss';

const TAB_ITEM = (
  <div className={styles.tabItem}>
    <Contents />
  </div>
);

const TAB_ITEMS = [
  {
    id: TAB_NEWS,
    label: LABEL_NEWS,
    children: TAB_ITEM,
  },
  {
    id: TAB_INDIVIDUAL_NOTICE,
    label: LABEL_INDIVIDUAL_NOTICE,
    children: TAB_ITEM,
  },
];

export const Message = memo(() => {
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.message.currentTab);

  const handleChange = useCallback(
    (activeKey) => {
      dispatch(setCurrentTab({ currentTab: activeKey }));
      dispatch(getUnreadCountRequest({ channel: CHANNEL_BROWSER }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(startAutoRefreshMessage());
    dispatch(getUnreadCountRequest({ channel: CHANNEL_BROWSER }));
    dispatch(checkInitialDepositRequest());
    return () => {
      dispatch(stopAutoRefreshMessage());
      dispatch(clearMessages());
    };
  }, [dispatch]);

  return <Tabs items={TAB_ITEMS} activeKey={currentTab} onChange={handleChange} />;
});
