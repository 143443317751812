/* eslint-disable-next-line import/no-unresolved */
import { useMemo } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector } from 'react-redux';
import Decimal from 'decimal.js';
import {
  DECIMAL_ZERO,
  calculateAssumptionPl,
  calculateRiskAssessment,
  groupByInstrumentKey,
  makeInstrumentInfoMap,
  toRiskAssessmentRate,
} from '../utils';
import { useAccountInfo } from './useAccountInfo';
import { useCalculatingChartData } from './useCalculatingChartData';
import { DEFAULT_QUANTITY_UNIT_CONV_FACTOR } from '../constants';

const reduceAssumptionPl = (totalPl, pl) => {
  const [totalMinusPl, totalPlusPl] = totalPl;
  const [minusPl, plusPl] = pl;
  return [totalMinusPl.add(minusPl), totalPlusPl.add(plusPl)];
};

// TODO: matsuzaki100983 内部で推奨証拠金を算出しているが、呼び出し元で推奨証拠金を計算している場合はpropsで渡すようにしたほうが良い。
// 全ての呼び出し元で推奨証拠金を取得できているか確認したらuseCalculatingChartDataの呼び出しを削除する。
export const useRiskAssessment = (strategyList, defaultSets) => {
  const accountInfo = useAccountInfo();
  const rates = useSelector((state) => state.currencies.rates);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const { chartStats } = useCalculatingChartData({ strategyList, defaultSets });
  const marginRecommended = useMemo(
    () => (chartStats?.marginRecommended ? new Decimal(chartStats.marginRecommended) : 0),
    [chartStats?.marginRecommended],
  );

  return useMemo(() => {
    if (strategyList == null || strategyList.length === 0) {
      // strategyListの中身がない場合は算出不可
      return null;
    }
    if (strategyList.some(({ sourceType }) => sourceType === 'tech')) {
      // technical builder の自動売買グループが含まれている場合は算出不可
      return null;
    }
    if (!marginRecommended) {
      // marginRecommendedがない、または0の場合は算出不可
      return null;
    }
    const groupedByInstrumentKey = groupByInstrumentKey(strategyList);
    const instrumentInfoMap = makeInstrumentInfoMap({ groupedByInstrumentKey, instrumentList, rates, accountInfo });
    if (Object.keys(groupedByInstrumentKey).length !== Object.keys(instrumentInfoMap).length) {
      // 計算に必要なパラメータが不足している
      return null;
    }
    const set = new Decimal(defaultSets);
    const assumptionPl = Object.keys(groupedByInstrumentKey)
      .map((key) => {
        const strategies = groupedByInstrumentKey[key];
        return strategies
          .flatMap((strategy) => {
            const { strategyDetail, strategySets } = strategy;
            const { itemList } = strategyDetail;
            return itemList.map(({ instrumentId, quantity, amount, tp, profitMargin, side, buySell, ...restItem }) => ({
              ...restItem,
              quantity: new Decimal(quantity ?? amount).mul(
                instrumentList?.[instrumentId]?.quantityUnitConvFactor ?? DEFAULT_QUANTITY_UNIT_CONV_FACTOR,
              ),
              tp: tp ?? profitMargin,
              side: side ?? buySell,
              set: set.mul(strategySets),
            }));
          })
          .map((order) => calculateAssumptionPl(order, instrumentInfoMap[key]))
          .reduce(reduceAssumptionPl, [DECIMAL_ZERO, DECIMAL_ZERO]);
      })
      .map(([minusPl, plusPl]) => Decimal.min(minusPl, plusPl))
      .reduce((total, pl) => total.add(pl), DECIMAL_ZERO);
    const value = calculateRiskAssessment(marginRecommended, assumptionPl);
    const rate = toRiskAssessmentRate(value);
    return rate;
  }, [strategyList, accountInfo, instrumentList, rates, defaultSets, marginRecommended]);
};
