import { createAdditionalParams, invokeApi, get, post } from './index';

export const getSelectionDetailData = async ({
  selectionId,
  selectionVersion,
  tradesFlg = false,
  chartsFlg = true,
  termId,
}) => {
  const additionalParams = await createAdditionalParams();
  let requestQuery = `?tradesFlg=${tradesFlg}&chartsFlg=${chartsFlg}`;
  if (termId) {
    requestQuery += `&simulationTermId=${termId}`;
  }

  return invokeApi({}, `selections/${selectionId}/versions/${selectionVersion}${requestQuery}`, get, additionalParams);
};

export const getStrategySimulation = async ({ requestBody, termId }) => {
  const additionalParams = await createAdditionalParams();
  const requestQuery = `?simulationTermId=${termId}`;

  return invokeApi({}, `strategies/simulation${requestQuery}`, post, additionalParams, requestBody);
};

export const getTechBuilderSimulation = async ({ requestBody }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `technical_builders/simulation`, post, additionalParams, requestBody);
};

export const addStrategy = async ({ strategySets, target, requestBody }) => {
  const additionalParams = await createAdditionalParams();
  const requestQuery = `strategySets=${strategySets}&target=${target}`;

  return invokeApi({}, `strategies?${requestQuery}`, post, additionalParams, requestBody);
};

export const addTechnicalBuilder = async ({ strategySets, target, requestBody }) => {
  const additionalParams = await createAdditionalParams();
  const requestQuery = `target=${target}&sets=${strategySets}`;

  return invokeApi({}, `technical_builders?${requestQuery}`, post, additionalParams, requestBody);
};

export const addCartChartMakeStrategy = async ({ strategySets, requestBody }) => {
  const additionalParams = await createAdditionalParams();
  const requestQuery = `strategySets=${strategySets}`;

  return invokeApi({}, `cart/chart_make?${requestQuery}`, post, additionalParams, requestBody);
};
