import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ScrollableItems } from '../ScrollableItems';
import { TabItem } from './TabItem';
import styles from './tabs.module.scss';

export const Tabs = memo(
  ({
    type,
    tabMinWidth,
    scrollWidth,
    defaultActiveKey,
    activeKey,
    disabled,
    items,
    toolbar,
    className: childrenClassName,
    tabsClassName,
    containerClassName,
    onChange,
    onReady,
    onResize,
  }) => {
    const containerRef = useRef(null);
    const [element, setElement] = useState(null);
    const isLine = type === 'line';
    const activeItem = (items || []).find(({ id }) => id === activeKey ?? defaultActiveKey) || {};

    const handleActive = useCallback((activeElement) => {
      setElement(activeElement);
    }, []);

    const tabItems = useMemo(
      () =>
        (items || []).map(
          ({ label, value, className, id, disabled: disabledItem, isVisuallyDisabled, children, badge }) => (
            <div key={id} className={classNames(styles.tabWrapper, { [styles.cardTabWrapper]: !isLine })}>
              <TabItem
                isLine={isLine}
                tabMinWidth={tabMinWidth}
                activeKey={activeKey ?? defaultActiveKey}
                label={label}
                value={value}
                badge={badge}
                id={id}
                className={className}
                disabled={disabled || disabledItem}
                isVisuallyDisabled={isVisuallyDisabled}
                onChange={onChange}
                onActive={handleActive}
              >
                {children}
              </TabItem>
            </div>
          ),
        ),
      [isLine, tabMinWidth, defaultActiveKey, activeKey, disabled, items, onChange, handleActive],
    );

    const activeLine = useMemo(() => {
      if (!isLine) {
        return null;
      }
      const { offsetLeft, clientWidth } = element || {};
      const style = { left: offsetLeft ?? 0, width: clientWidth ?? 0 };
      return <div className={styles.activeLine} style={style} />;
    }, [isLine, element]);

    useEffect(() => {
      const container = containerRef.current;
      if (onReady) {
        onReady(container.getBoundingClientRect());
      }
    }, [onReady]);

    useEffect(() => {
      if (!window.ResizeObserver || !onResize) {
        return () => {};
      }
      const observer = new window.ResizeObserver((entries) => {
        const [target] = entries;
        onResize(target?.contentRect ?? {});
      });
      const container = containerRef.current;
      observer.observe(container);
      return () => {
        observer.unobserve(container);
      };
    }, [onResize]);

    return (
      <div ref={containerRef} className={classNames(styles.container, containerClassName)}>
        <div className={classNames(styles.tabs, tabsClassName)}>
          <div className={styles.tabsLayout}>
            <ScrollableItems
              items={tabItems}
              className={classNames({ [styles.card]: !isLine })}
              containerClassName={classNames({ [styles.lineContainer]: isLine })}
              scrollWidth={scrollWidth}
              disabledScroll
            />
            {!isLine && toolbar}
          </div>
          {activeLine}
          <div className={classNames(styles.border, { [styles.lineBorder]: isLine })} />
        </div>
        <div className={classNames(styles.componentWrapper, childrenClassName)}>{activeItem.children}</div>
      </div>
    );
  },
);

Tabs.propTypes = {
  type: PropTypes.string,
  tabMinWidth: PropTypes.number,
  scrollWidth: PropTypes.number,
  defaultActiveKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      className: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      disabled: PropTypes.bool,
      isVisuallyDisabled: PropTypes.bool,
      children: PropTypes.element,
      badge: PropTypes.string,
    }),
  ),
  toolbar: PropTypes.node,
  className: PropTypes.string,
  tabsClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  onChange: PropTypes.func,
  onReady: PropTypes.func,
  onResize: PropTypes.func,
};

Tabs.defaultProps = {
  type: 'card',
  tabMinWidth: undefined,
  scrollWidth: undefined,
  defaultActiveKey: undefined,
  activeKey: undefined,
  disabled: false,
  items: undefined,
  toolbar: undefined,
  className: undefined,
  tabsClassName: undefined,
  containerClassName: undefined,
  onChange: undefined,
  onReady: undefined,
  onResize: undefined,
};
