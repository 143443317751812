import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { toHalfwidthKana } from 'japanese-string-utils';
import {
  CHART_MAKE_STEP4,
  CHART_MAKE_STEP4_TITLE,
  CHART_MAKE_SELECTBOX_DISABLED_LOGIC,
  CHART_MAKE_LOGIC_ID,
  MODAL_SIZES,
  FX,
  CFD,
} from 'shared-modules/constants';
import { formatNumberToDisplayedString, getCircleNum } from 'shared-modules/services';
import { useBuilderPricePrecision } from 'shared-modules/services/hooks/builder';
import { BUILDER_PAGES } from 'shared-modules/constants/builder';
import useChartMakeInfo from 'shared-modules/services/hooks/chartMakeLogic';
import { resetBuilderOrdersTables } from 'shared-modules/redux/actions/builderActions';
import {
  changeBuilderActivePage,
  openChartMakeAddToCartModal,
  openChartMakeRunNowConfirmationModal,
} from '../../../redux/actions';
import { store } from '../../../redux/store';

import ChartMakeOrderSettingTable from '../components/ChartMakeOrderSettingTable';
import CustomCheckbox from '../../../components/CustomCheckbox';
import CustomSelect from '../../../components/CustomSelect';
import CustomTooltip from '../../../components/CustomTooltip';

import styles from './chartMakeSummaryPage.module.scss';
import Modal from '../../../components/Modal';
import SummaryChart from './SummaryChart';
import { Button, InputNumber } from '../../../components';

const ChartMakeSummaryPage = () => {
  const dispatch = useDispatch();

  const serviceId = useSelector((state) => state.auth.serviceId);

  const isFX = useMemo(() => serviceId === FX, [serviceId]);
  const isCFD = useMemo(() => serviceId === CFD, [serviceId]);
  const activeCurrency = useSelector((state) => state.builder.activeCurrency);
  const { quantityUnitConvFactor } = useSelector((state) => state.settings.instrumentList[activeCurrency]);
  const { sketchData, selectRecommend: selectRecommendId } = useSelector((state) => state.webBuilder);
  const { pricePrecision } = useBuilderPricePrecision();

  const selectLogicName = useMemo(() => CHART_MAKE_LOGIC_ID[selectRecommendId], [selectRecommendId]);
  const isSelectBoxDisable = CHART_MAKE_SELECTBOX_DISABLED_LOGIC.includes(selectLogicName);
  const [isOpenAlertModal, setOpenAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const {
    changeTarget,
    priceRange,
    rangeWidth,
    itemsCount,
    quantity,
    profitMargin,
    lossCutWidthCheck,
    lossCutWidth,
    fundIndication,
    requiredMargin,
    reCalc,
    orderSettings,
    deleteOrderSetting,
    buttonController,
    errorsArray,
    rangeAreaAllPrice,
    recalcErrorMessage,
    setRecalcErrorMessage,
  } = useChartMakeInfo({ store, sketchData, selectLogicName });

  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const currencyName = isFX ? activeCurrency : toHalfwidthKana(instrumentList[activeCurrency]?.shortName);

  const openPreviousPage = useCallback(() => {
    dispatch(changeBuilderActivePage({ activePage: BUILDER_PAGES.CHART_MAKE_DRAW_PAGE.ID }));
    dispatch(resetBuilderOrdersTables());
  }, [dispatch]);

  const onAddToCartClick = useCallback(() => {
    dispatch(
      openChartMakeAddToCartModal({
        fundIndication: fundIndication.value,
        requiredMargin: requiredMargin.value,
        sets: 1,
        callback: () => {},
        selectionId: null,
        selectionVersion: null,
      }),
    );
  }, [dispatch, fundIndication.value, requiredMargin.value]);

  const onRunNowClick = useCallback(() => {
    dispatch(
      openChartMakeRunNowConfirmationModal({
        fundIndication: fundIndication.value,
        requiredMargin: requiredMargin.value,
        sets: 1,
        callback: () => {},
      }),
    );
  }, [dispatch, fundIndication, requiredMargin]);

  const displayFundIndication = useMemo(
    () => formatNumberToDisplayedString({ value: fundIndication.value, withoutPlus: true }),
    [fundIndication.value],
  );

  const displayRequiredMargin = useMemo(
    () => formatNumberToDisplayedString({ value: requiredMargin.value, withoutPlus: true }),
    [requiredMargin.value],
  );

  const hasCurrentChangeMessage = '注文設定が変更されています。\n注文設定再作成ボタンを押下して下さい。';
  useEffect(() => {
    if (recalcErrorMessage) {
      setAlertMessage(recalcErrorMessage);
      setOpenAlertModal(true);
    }
  }, [recalcErrorMessage]);

  const [refreshKey, changeChartKey] = useState(Date.now());

  const refreshChart = useCallback(() => {
    changeChartKey(Date.now());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', refreshChart);
    return () => {
      window.removeEventListener('resize', refreshChart);
    };
  }, [refreshChart]);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          <span>{CHART_MAKE_STEP4}</span>
          <span>{CHART_MAKE_STEP4_TITLE}</span>
        </div>
      </div>

      <div className={styles.sideMenuWrapper}>
        <div className={styles.sideMenuTitle}>ロジック設定</div>
        <div className={styles.sideMenuContent}>
          {!isSelectBoxDisable && (
            <div className="mb-3">
              <div className={styles.sideMenuItem}>
                <div>{changeTarget.label}</div>
                <CustomSelect
                  options={changeTarget.options}
                  selectItemId={changeTarget.get}
                  onChange={changeTarget.set}
                  isDisabled={
                    buttonController.isInitValidation ||
                    (buttonController.isReCalcDisabled && buttonController.isAddCartAndRunNowDisabled)
                  }
                  isLighter
                />
              </div>
            </div>
          )}
          <div className={styles.sideMenuItem}>
            <div className="d-flex">
              <div className="me-1">{priceRange.label}</div>
              <CustomTooltip message={priceRange.infoMessage} className={styles.infoWrapper}>
                <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
              </CustomTooltip>
            </div>
            <InputNumber
              step={priceRange.step}
              value={priceRange.get}
              onChange={priceRange.set}
              disabled={priceRange.isDisabled}
              name={priceRange.name}
              errorMessages={errorsArray}
              validateFunction={priceRange.validate}
              withErrorTooltip
              isLighter
            />
          </div>
          <div className={styles.sideMenuItem}>
            <div className="d-flex">
              <div className="me-1">{rangeWidth.label}</div>
              <CustomTooltip message={rangeWidth.infoMessage} className={styles.infoWrapper}>
                <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
              </CustomTooltip>
            </div>
            <InputNumber
              step={rangeWidth.step}
              value={rangeWidth.get}
              onChange={rangeWidth.set}
              disabled={rangeWidth.isDisabled}
              name={rangeWidth.name}
              errorMessages={errorsArray}
              validateFunction={rangeWidth.validate}
              withErrorTooltip
              isLighter
            />
          </div>
          <div className={styles.sideMenuItem}>
            <div className="d-flex">
              <div className="me-1">{itemsCount.label}</div>
              {itemsCount.infoMessage && (
                <CustomTooltip message={itemsCount.infoMessage} className={styles.infoWrapper}>
                  <i className={classNames('material-icons-outlined', styles.infoIcon)}>info</i>
                </CustomTooltip>
              )}
            </div>
            <InputNumber
              step={itemsCount.step}
              value={itemsCount.get}
              onChange={itemsCount.set}
              name={itemsCount.name}
              errorMessages={errorsArray}
              validateFunction={itemsCount.validate}
              withErrorTooltip
              isLighter
            />
          </div>
          <div className={styles.sideMenuItem}>
            <div>
              {quantity.label}
              {quantity.readOnlyAmountSettings ? getCircleNum(1) : ''}
              {`(${quantity.quantityUnit})`}
              {isCFD && <div>1Lot={quantityUnitConvFactor}</div>}
            </div>
            <InputNumber
              step={quantity.step}
              value={quantity.get}
              onChange={quantity.set}
              name={quantity.name}
              errorMessages={errorsArray}
              validateFunction={quantity.validate}
              withErrorTooltip
              isLighter
            />
          </div>
          {quantity?.readOnlyAmountSettings?.map((as, index) => (
            <div className={styles.sideMenuItem} key={`${quantity.label + (index + 2)}`}>
              <div>
                {quantity.label}
                {getCircleNum(index + 2)}
                {`(${quantity.quantityUnit})`}
              </div>
              <InputNumber value={as} disabled isLighter />
            </div>
          ))}

          {profitMargin.get.map(
            (tp, index) =>
              index < itemsCount.get && (
                <div className={styles.sideMenuItem} key={`${profitMargin.name + (index + 1)}`}>
                  <div>{`${profitMargin.label(profitMargin.get.length > 1 ? getCircleNum(index + 1) : '')}`}</div>
                  <InputNumber
                    step={profitMargin.step}
                    value={tp}
                    onChange={(newVal) => profitMargin.set(newVal, index)}
                    name={`${profitMargin.name}${index}`}
                    errorMessages={errorsArray}
                    validateFunction={(newVal) => profitMargin.validate(newVal, index)}
                    withErrorTooltip
                    isLighter
                  />
                </div>
              ),
          )}

          <div className={styles.sideMenuItem}>
            <CustomCheckbox
              label={lossCutWidth.label}
              isChecked={lossCutWidthCheck.get}
              onChange={lossCutWidthCheck.set}
              isDisabled={false}
            />
            <InputNumber
              step={lossCutWidth.step}
              value={lossCutWidth.get}
              onChange={lossCutWidth.set}
              disabled={lossCutWidth.isDisabled}
              name={lossCutWidth.name}
              min={lossCutWidth.min}
              errorMessages={errorsArray}
              validateFunction={lossCutWidth.validate}
              withErrorTooltip
              validateNegativeValues
              isLighter
            />
          </div>
        </div>
        <div className={styles.sideMenuBottom}>
          <div style={{ paddingLeft: 8 }}>
            <Button
              onClick={() => {
                reCalc();
                refreshChart();
              }}
              disabled={buttonController.isReCalcDisabled}
            >
              注文設定再作成
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.chartWrapper}>
        <div className={styles.marginInfoRow}>
          {displayFundIndication && (
            <div className={styles.recommendedMargin}>
              {fundIndication.label}
              <div className={styles.detailsValue}>{displayFundIndication}</div>
              <CustomTooltip
                message={fundIndication.tooltipMessage}
                className={styles.infoIconWrapper}
                placement="bottom"
              >
                <i className="material-icons-outlined">info</i>
              </CustomTooltip>
            </div>
          )}
          <div className={styles.recommendedMargin}>
            {requiredMargin.label}
            <div className={styles.detailsValue}>{displayRequiredMargin}</div>
            <CustomTooltip
              message={requiredMargin.tooltipMessage}
              className={styles.infoIconWrapper}
              placement="bottom"
            >
              <i className="material-icons-outlined">info</i>
            </CustomTooltip>
          </div>
        </div>
        <SummaryChart
          key={refreshKey}
          instrumentName={currencyName}
          historyPoints={sketchData}
          ranges={rangeAreaAllPrice}
          precision={pricePrecision}
        />
      </div>

      <div className={styles.tableWrapper}>
        <ChartMakeOrderSettingTable
          key={refreshKey}
          orderSettingsData={orderSettings}
          instrumentId={activeCurrency}
          deleteHandler={deleteOrderSetting}
        />
      </div>

      <div className={styles.buttonGroupWrapper}>
        <Button className={styles.buttonInnerStyle} onClick={openPreviousPage} width={168} secondary>
          戻る
        </Button>
        <Button
          className={styles.buttonInnerStyle}
          onClick={() => {
            if (buttonController.isAddCartAndRunNowDisabled) {
              setAlertMessage(hasCurrentChangeMessage);
              setOpenAlertModal(true);
              return;
            }
            onAddToCartClick();
          }}
          disabled={buttonController.isInitValidation}
          width={168}
          secondary
        >
          カートに追加
        </Button>
        <Button
          className={styles.buttonInnerStyle}
          onClick={() => {
            if (buttonController.isAddCartAndRunNowDisabled) {
              setAlertMessage(hasCurrentChangeMessage);
              setOpenAlertModal(true);
              return;
            }
            onRunNowClick();
          }}
          disabled={buttonController.isInitValidation}
          width={168}
        >
          今すぐ稼働
        </Button>
        <AlertModal
          isOpen={isOpenAlertModal}
          message={alertMessage}
          closeModal={() => {
            setOpenAlertModal(false);
            setRecalcErrorMessage(null);
          }}
        />
      </div>
    </div>
  );
};
const AlertModal = ({ isOpen, closeModal, message }) => {
  return (
    <Modal isOpen={isOpen} title="" closeModal={closeModal} size={MODAL_SIZES.WIDTH_400} isOverlap>
      <div className={styles.alertModalBody}>
        <div className={styles.message}>{message}</div>
        <div className={styles.buttonRow}>
          <Button
            width={140}
            onClick={() => {
              closeModal();
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};
AlertModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
export default memo(ChartMakeSummaryPage);
