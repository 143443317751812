import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { BUY_SELL_MAIN, BUY_SELL_CHART_VALUE, CHART_TYPES_MAIN, M_SUFFIX } from 'shared-modules/constants';
import {
  KEY_FOR_DEFAULT_CHART_RESOLUTION,
  KEY_FOR_CHART_INDICATORS,
  KEY_FOR_DISPLAY_POSITIONS,
  KEY_FOR_DISPLAY_CLOSED_ORDERS,
  KEY_FOR_DISPLAY_NEW_ORDERS,
} from 'shared-modules/constants/chart';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { makeMaintenanceQueryString } from 'shared-modules/utils';
import { useAccountInfo, useInstrumentShortName } from 'shared-modules/hooks';
import Logger from 'shared-modules/services/Logger';
import styleConstants from 'shared-modules/styles/_constants.scss';
// todo: move to common function
import { PRICE_CHART_COLORS, orderLineColor, getColorBySide } from 'shared-modules/services/builder';
import { widget } from 'shared-modules/thirdPartyLibrary/TradingView/charting_library';
import { saveDefaultValuesFromLocalStorage, getDefaultValuesFromLocalStorage } from 'shared-modules/services';
import { useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import { MANUAL_TRADE_CHART, POPUP_MESSAGES } from '../../constants';
import {
  changeChartResolution,
  toggleDisplayPositionsFlag,
  toggleDisplayNewOrdersFlag,
  toggleDisplayClosedOrdersFlag,
  changeChartSide,
} from '../../redux/actions';
import { useAddSubWindow } from '../../hooks';
import indicators from './indicators';
import datafeed from './datafeed';
import styles from './chart.module.scss';

export const drawingAccess = {
  type: 'white',
  tools: [
    { name: '十字' },
    { name: '矢印' },
    { name: 'トレンドライン' },
    { name: '水平線' },
    { name: '垂直線' },
    { name: '平行チャネル' },
    { name: 'フィボナッチ・リトレースメント' },
  ],
};

export const overrides = {
  'paneProperties.background': '#171717',
  'paneProperties.backgroundType': 'solid',
  'mainSeriesProperties.priceLineColor': PRICE_CHART_COLORS.GREEN,
  'mainSeriesProperties.lineStyle.color': styleConstants.WEB_WHITE,
  'mainSeriesProperties.candleStyle.upColor': styleConstants.WEB_PINKISH_ORANGE,
  'mainSeriesProperties.candleStyle.downColor': styleConstants.WEB_DARK_SKY_BLUE,
  'mainSeriesProperties.candleStyle.drawWick': true,
  'mainSeriesProperties.candleStyle.borderUpColor': styleConstants.WEB_PINKISH_ORANGE,
  'mainSeriesProperties.candleStyle.borderDownColor': styleConstants.WEB_DARK_SKY_BLUE,
  'mainSeriesProperties.candleStyle.wickUpColor': styleConstants.WEB_PINKISH_ORANGE,
  'mainSeriesProperties.candleStyle.wickDownColor': styleConstants.WEB_DARK_SKY_BLUE,
  'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false,
  'mainSeriesProperties.haStyle.upColor': styleConstants.WEB_PINKISH_ORANGE,
  'mainSeriesProperties.haStyle.downColor': styleConstants.WEB_DARK_SKY_BLUE,
  'mainSeriesProperties.haStyle.borderUpColor': styleConstants.WEB_PINKISH_ORANGE,
  'mainSeriesProperties.haStyle.borderDownColor': styleConstants.WEB_DARK_SKY_BLUE,
  'mainSeriesProperties.haStyle.wickUpColor': styleConstants.WEB_PINKISH_ORANGE,
  'mainSeriesProperties.haStyle.wickDownColor': styleConstants.WEB_DARK_SKY_BLUE,
  'linetoolfibretracement.level2.coeff': 0.236,
  'linetoolfibretracement.level3.coeff': 0.382,
  'linetoolfibretracement.level4.coeff': 0.5,
  'linetoolfibretracement.level5.coeff': 0.618,
  'linetoolfibretracement.level6.coeff': 0.764,
  'linetoolfibretracement.level7.coeff': 1,
  'linetoolfibretracement.level8.coeff': 1.5,
  'linetoolfibretracement.level9.coeff': 2,
  'linetoolfibretracement.level10.visible': false,
  'linetoolfibretracement.level11.visible': false,
  'scalesProperties.showSeriesLastValue': true,
  'mainSeriesProperties.showPriceLine': true,
  'mainSeriesProperties.priceAxisProperties.alignLabels': false,
  'paneProperties.legendProperties.showSeriesOHLC': true,
  'paneProperties.legendProperties.showBarChange': true,
};

export const studiesAccess = {
  type: 'white',
  tools: [
    { name: 'Moving Average' },
    { name: 'Moving Average Exponential' },
    { name: 'Envelopes' },
    { name: 'Bollinger Bands' },
    { name: 'Ichimoku Cloud' },
    { name: 'MACD' },
    { name: 'Momentum' },
    { name: 'Relative Strength Index' },
    { name: 'Stochastic' },
    { name: 'Directional Movement' },
    { name: 'Commodity Channel Index' },
    { name: 'Sessions' },
    { name: 'RCI' },
    { name: '移動平均乖離率' },
  ],
};

export const studiesOverrides = {
  'moving average.length': 25,
  'moving average exponential.length': 25,
  'envelopes.length': 25,
  'envelopes.plots background.visible': false,
  'bollinger bands.length': 25,
  'bollinger bands.mult': 2,
  'bollinger bands.plots background.visible': false,
  'ichimoku cloud.plots background.visible': false,
  'ichimoku cloud.leading span b:input': 52,
  'rsi.hlines background.visible': false,
  'stochastic.smooth': 3,
  'stochastic.k': 9,
  'stochastic.d': 3,
  'stochastic.hlines background.visible': false,
  'commodity channel index.length': 14,
  'cci.hlines background.visible': false,
};

const createAskBidToggle = (title) => <div className="askbid-toggle">{title}</div>;

const addButton = (tvWidgetRef, buttonRef, innerHTML, display, onClick) => () => {
  const button = tvWidgetRef.current?.createButton && tvWidgetRef.current.createButton();
  if (!button) return;
  buttonRef.current = button; // eslint-disable-line
  button.classList.add('button');
  if (!display) {
    button.classList.add('button-unselected');
  }
  button.addEventListener('click', onClick);
  button.innerHTML = innerHTML;
};

const createOrderDataSelector = (serviceId, isClose, side) => (state) => {
  const ordersData = state.orders[serviceId];

  if (ordersData) {
    if (Number(side)) {
      if (isClose) {
        return ordersData.filter((order) => order.isClose && Number(order.side) !== side);
      }
      return ordersData.filter((order) => !order.isClose && Number(order.side) === side);
    }
    return ordersData.filter((order) => order.isClose === isClose);
  }

  return ordersData;
};

export const getLineStyleBySide = (side) => (side === BUY_SELL_MAIN.BUY.ID ? 1 : 2);

export const removeAllLines = (linesRef) => {
  Object.values(linesRef.current).forEach((line) => line.remove());
  linesRef.current = {}; // eslint-disable-line
};

const setButtonSelected = (button, selected) => {
  if (button && button.classList) {
    if (!selected) {
      button.classList.add('button-unselected');
    } else {
      button.classList.remove('button-unselected');
    }
  }
};

const Chart = ({
  isFullScreen,
  selectedInstrumentId,
  resolution: resolutionOuter,
  selectedSide,
  chartType,
  isMobile,
  serviceId,
  disableHeaderWidget,
  withoutIndicators,
  forceChartType,
  withPositions,
  onlyManualPositions,
  onlyApGroupPositions,
  isMobileAuthenticated,
}) => {
  const dispatch = useDispatch();
  const addSubWindow = useAddSubWindow();
  const underDisposalRef = useRef(true);
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const tvWidgetRef = useRef({});
  const closedOrderLinesRef = useRef({});
  const newOrderLinesRef = useRef({});
  const positionLinesRef = useRef({});
  const [displayPositionsCounter, setDisplayPositionsCounter] = useState(0);
  const [displayClosedOrdersCounter, setDisplayClosedOrdersCounter] = useState(0);
  const [displayNewOrdersCounter, setDisplayNewOrdersCounter] = useState(0);

  const resolutionInner = useSelector((state) => state.chart.resolution);
  const resolution = useMemo(() => resolutionOuter || resolutionInner, [resolutionOuter, resolutionInner]);

  const isManualChart = selectedInstrumentId.includes(M_SUFFIX);
  const instrumentOptions = useApOrManualInstrumentOptions(isManualChart);
  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const chartSide = useSelector((state) => state.manualTrade.chartSide);

  const accountInfo = useAccountInfo();

  const fullScreenPopupRef = useRef({});
  const onChartExpandClick = useCallback(() => {
    const instrumentId = selectedInstrumentId;
    // see symbol
    const side = onlyManualPositions == null ? chartSide : selectedSide;
    const maintenanceQueryString = makeMaintenanceQueryString(accountInfo);
    let url = `/${MANUAL_TRADE_CHART}?selectedInstrumentId=${instrumentId}&&serviceId=${serviceId}&&${maintenanceQueryString}`; // eslint-disable-line
    if (side != null) {
      url += `&&selectedSide=${side}`;
    }
    if (onlyApGroupPositions) url += `&&onlyApGroupPositions=${onlyApGroupPositions}`;
    if (onlyManualPositions) url += `&&onlyManualPositions=${onlyManualPositions}`;

    const params = 'status=no,location=0,toolbar=no,menubar=no,width=800,height=600,left=150,top=150';
    fullScreenPopupRef.current = window.open(encodeURI(url), 'manual-trade-chart', params);
    addSubWindow(fullScreenPopupRef.current);
  }, [
    onlyApGroupPositions,
    onlyManualPositions,
    selectedInstrumentId,
    selectedSide,
    serviceId,
    accountInfo,
    chartSide,
    addSubWindow,
  ]);

  const toggleChartSide = useCallback(
    (value) => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ side: value }));
      }
      dispatch(changeChartSide({ id: value }));
    },
    [dispatch],
  );

  const handleDropdownItemSelect = useCallback(
    (title, value) => {
      dropdownRef.current.applyOptions({
        title,
      });
      toggleChartSide(value);
    },
    [toggleChartSide],
  );

  const createDropdownItem = useCallback(
    (title, value) => ({
      title,
      onSelect: () => {
        handleDropdownItemSelect(title, value);
      },
    }),
    [handleDropdownItemSelect],
  );

  const dropdownOptionsRef = useRef(null);
  const dropdownOptions = useMemo(
    () => ({
      title: createAskBidToggle(Number(chartSide) ? 'ASK' : 'BID'),
      items: [createDropdownItem(createAskBidToggle('BID'), 0), createDropdownItem(createAskBidToggle('ASK'), 1)],
    }),
    [createDropdownItem, chartSide],
  );

  useEffect(() => {
    toggleChartSide(selectedSide);
  }, [selectedSide, toggleChartSide]);

  const buttonClosedOrdersWidget = useRef({});
  const buttonNewOrdersWidget = useRef({});
  const buttonPositionsWidget = useRef({});

  const displayPositions = useSelector((state) => state.chart.displayPositions);
  const toggleDisplayPositions = useCallback(() => {
    if (isMobileAuthenticated !== false) {
      dispatch(toggleDisplayPositionsFlag());
    } else {
      setDisplayPositionsCounter((oldValue) => oldValue + 1);
    }
  }, [dispatch, isMobileAuthenticated]);
  const prevToggleDisplayPositionsRef = useRef(toggleDisplayPositions);
  useEffect(() => {
    const positionsButton = buttonPositionsWidget.current;
    // チャート作成時に発生し得る不整合解消のため下記 if 文が必要
    if (toggleDisplayPositions !== prevToggleDisplayPositionsRef.current) {
      positionsButton?.removeEventListener?.('click', prevToggleDisplayPositionsRef.current);
      prevToggleDisplayPositionsRef.current = toggleDisplayPositions;
    }
    positionsButton?.addEventListener?.('click', toggleDisplayPositions);
    return () => {
      positionsButton?.removeEventListener?.('click', toggleDisplayPositions);
    };
  }, [toggleDisplayPositions]);
  useEffect(() => {
    if (isMobileAuthenticated !== false) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ togglePositions: displayPositions }));
      }
    }
    saveDefaultValuesFromLocalStorage({
      key: KEY_FOR_DISPLAY_POSITIONS,
      value: displayPositions,
    });
  }, [displayPositions, isMobileAuthenticated]);
  useEffect(() => {
    if (displayPositionsCounter > 0 && isMobileAuthenticated === false) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ togglePositions: displayPositions }));
      }
    }
  }, [displayPositionsCounter, displayPositions, isMobileAuthenticated]);

  const closedOrdersData = useSelector(createOrderDataSelector(serviceId, true, onlyManualPositions), shallowEqual);
  const displayClosedOrders = useSelector((state) => state.chart.displayClosedOrders);
  const toggleDisplayClosedOrders = useCallback(() => {
    if (isMobileAuthenticated !== false) {
      dispatch(toggleDisplayClosedOrdersFlag());
    } else {
      setDisplayClosedOrdersCounter((oldValue) => oldValue + 1);
    }
  }, [dispatch, isMobileAuthenticated]);
  const prevToggleDisplayClosedOrdersRef = useRef(toggleDisplayClosedOrders);
  useEffect(() => {
    const closedOrdersButton = buttonClosedOrdersWidget.current;
    // チャート作成時に発生し得る不整合解消のため下記 if 文が必要
    if (toggleDisplayClosedOrders !== prevToggleDisplayClosedOrdersRef.current) {
      closedOrdersButton?.removeEventListener?.('click', prevToggleDisplayClosedOrdersRef.current);
      prevToggleDisplayClosedOrdersRef.current = toggleDisplayClosedOrders;
    }
    closedOrdersButton?.addEventListener?.('click', toggleDisplayClosedOrders);
    return () => {
      closedOrdersButton?.removeEventListener?.('click', toggleDisplayClosedOrders);
    };
  }, [toggleDisplayClosedOrders]);
  useEffect(() => {
    if (isMobileAuthenticated !== false) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ toggleClosedOrders: displayClosedOrders }));
      }
    }
    saveDefaultValuesFromLocalStorage({
      key: KEY_FOR_DISPLAY_CLOSED_ORDERS,
      value: displayClosedOrders,
    });
  }, [displayClosedOrders, isMobileAuthenticated]);
  useEffect(() => {
    if (displayClosedOrdersCounter > 0 && isMobileAuthenticated === false) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ toggleClosedOrders: displayClosedOrders }));
      }
    }
  }, [displayClosedOrdersCounter, displayClosedOrders, isMobileAuthenticated]);

  const newOrdersData = useSelector(createOrderDataSelector(serviceId, false, onlyManualPositions), shallowEqual);
  const displayNewOrders = useSelector((state) => state.chart.displayNewOrders);
  const toggleDisplayNewOrders = useCallback(() => {
    if (isMobileAuthenticated !== false) {
      dispatch(toggleDisplayNewOrdersFlag());
    } else {
      setDisplayNewOrdersCounter((oldValue) => oldValue + 1);
    }
  }, [dispatch, isMobileAuthenticated]);
  const prevToggleDisplayNewOrdersRef = useRef(toggleDisplayNewOrders);
  useEffect(() => {
    const newOrdersButton = buttonNewOrdersWidget.current;
    if (toggleDisplayNewOrders !== prevToggleDisplayNewOrdersRef.current) {
      newOrdersButton?.removeEventListener?.('click', prevToggleDisplayNewOrdersRef.current);
      prevToggleDisplayNewOrdersRef.current = toggleDisplayNewOrders;
    }
    newOrdersButton?.addEventListener?.('click', toggleDisplayNewOrders);
    return () => {
      newOrdersButton?.removeEventListener?.('click', toggleDisplayNewOrders);
    };
  }, [toggleDisplayNewOrders]);
  useEffect(() => {
    if (isMobileAuthenticated !== false) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ toggleNewOrders: displayNewOrders }));
      }
    }
    saveDefaultValuesFromLocalStorage({
      key: KEY_FOR_DISPLAY_NEW_ORDERS,
      value: displayNewOrders,
    });
  }, [displayNewOrders, isMobileAuthenticated]);
  useEffect(() => {
    if (displayNewOrdersCounter > 0 && isMobileAuthenticated === false) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ toggleNewOrders: displayNewOrders }));
      }
    }
  }, [displayNewOrdersCounter, displayNewOrders, isMobileAuthenticated]);

  const positions = useSelector((state) => state.currencies.positions[serviceId]);
  const positionsBySelectedInstrumentId = useMemo(
    () =>
      positions?.filter((position) => {
        const isSelectedInstrument = position.instrumentId === selectedInstrumentId;

        if (onlyManualPositions) {
          return isSelectedInstrument && Number(position.side) === onlyManualPositions && !position.apGroupId;
        }

        if (onlyApGroupPositions) {
          return isSelectedInstrument && position.apGroupId === onlyApGroupPositions;
        }

        return isSelectedInstrument;
      }),
    [positions, selectedInstrumentId, onlyManualPositions, onlyApGroupPositions],
  );

  const [finishedOnChartReady, setFinishedOnChartReady] = useState(false);
  const finishedOnChartReadyRef = useRef(finishedOnChartReady);
  const prevFinishedOnChartReadyRef = useRef(finishedOnChartReady);
  const [changingSymbol, setChangingSymbol] = useState(false);
  const changingSymbolRef = useRef(changingSymbol);
  const [changingTradeMethod, setChangingTradeMethod] = useState(false);
  const changingTradeMethodRef = useRef(changingTradeMethod);
  const prevChangingSymbolRef = useRef(changingSymbol);
  const prevSymbolRef = useRef(null);
  const prevTradeMethodRef = useRef(null);
  const [changingResolution, setChangingResolution] = useState(false);
  const changingResolutionRef = useRef(changingResolution);
  const prevChangingResolutionRef = useRef(changingResolution);
  const prevResolutionRef = useRef(null);

  const shortName = useInstrumentShortName(selectedInstrumentId);

  const symbol = useMemo(() => {
    if (onlyManualPositions) {
      return `${shortName}(${BUY_SELL_CHART_VALUE[selectedSide]})`;
    }
    return `${shortName}(${BUY_SELL_CHART_VALUE[chartSide]})`;
  }, [onlyManualPositions, shortName, chartSide, selectedSide]);

  const removeAllChartObjects = useCallback(() => {
    removeAllLines(newOrderLinesRef);
    removeAllLines(closedOrderLinesRef);
    removeAllLines(positionLinesRef);
  }, []);

  const renderLines = useCallback(() => {
    if (changingSymbolRef.current || changingSymbol) {
      // symbol 変更中の場合は symbol 変更後に renderLines が再度呼び出されるためスキップ
      return;
    }
    if (changingResolutionRef.current || changingResolution) {
      // resolution 変更中の場合は resolution 変更後に renderLines が再度呼び出されるためスキップ
      return;
    }
    removeAllChartObjects();
    if (!withPositions) {
      return;
    }
    try {
      if (displayPositions) {
        positionsBySelectedInstrumentId.forEach((i) => {
          const profitLine = positionLinesRef.current[i.positionId];
          if (!profitLine) {
            positionLinesRef.current[i.positionId] = tvWidgetRef.current
              .activeChart()
              .createPositionLine()
              .setText('')
              .setQuantity('')
              .setPrice(i.tradePrice)
              .setLineColor(orderLineColor(Number(i.side)))
              .setLineStyle(2)
              .setLineLength(1);
          } else {
            positionLinesRef.current[i.positionId].setPrice(i.tradePrice);
          }
        });

        // removing old positions existing in positionLinesRef.current
        const positionsIdOnly = positionsBySelectedInstrumentId.map((position) => position.positionId);
        const removedPositions = Object.keys(positionLinesRef.current).filter(
          (drawnPositionId) => !positionsIdOnly.includes(drawnPositionId),
        );
        removedPositions.forEach((removedId) => {
          positionLinesRef.current[removedId].remove();
          delete positionLinesRef.current[removedId];
        });
      }

      if (displayNewOrders) {
        newOrdersData.forEach((i) => {
          const profitLine = newOrderLinesRef.current[i.orderId];
          if (!profitLine) {
            newOrderLinesRef.current[i.orderId] = tvWidgetRef.current
              .activeChart()
              .createOrderLine()
              .setText('')
              .setQuantity('')
              .setLineColor(getColorBySide(Number(i.side)))
              .setLineStyle(getLineStyleBySide(Number(i.side)))
              .setPrice(i.price);
          } else {
            newOrderLinesRef.current[i.orderId].setPrice(i.price);
          }
        });
        const ordersIdOnly = newOrdersData.map((order) => order.orderId);
        const removedOrders = Object.keys(newOrderLinesRef.current).filter(
          (drawnId) => !ordersIdOnly.includes(drawnId),
        );
        removedOrders.forEach((removedId) => {
          newOrderLinesRef.current[removedId].remove();
          delete newOrderLinesRef.current[removedId];
        });
      }

      if (displayClosedOrders) {
        closedOrdersData.forEach((i) => {
          const profitLine = closedOrderLinesRef.current[i.orderId];
          if (!profitLine) {
            closedOrderLinesRef.current[i.orderId] = tvWidgetRef.current
              .activeChart()
              .createOrderLine()
              .setText('')
              .setQuantity('')
              .setLineColor(PRICE_CHART_COLORS.YELLOW)
              .setLineStyle(getLineStyleBySide(Number(i.side)))
              .setPrice(i.price);
          } else {
            closedOrderLinesRef.current[i.orderId].setPrice(i.price);
          }
        });
        const ordersIdOnly = closedOrdersData.map((order) => order.orderId);
        const removedOrders = Object.keys(closedOrderLinesRef.current).filter(
          (drawnId) => !ordersIdOnly.includes(drawnId),
        );
        removedOrders.forEach((removedId) => {
          closedOrderLinesRef.current[removedId].remove();
          delete closedOrderLinesRef.current[removedId];
        });
      }
    } catch (error) {
      Logger.error(error);
    }
  }, [
    displayPositions,
    withPositions,
    positionsBySelectedInstrumentId,
    displayNewOrders,
    newOrdersData,
    displayClosedOrders,
    closedOrdersData,
    changingSymbol,
    changingResolution,
    removeAllChartObjects,
  ]);

  // チャートオブジェクトに関連するプロパティの変更をトリガーに描画を試みる
  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    if (finishedOnChartReadyRef.current) {
      renderLines();
    } else {
      tvWidgetRef.current.onChartReady(() => renderLines());
    }
  }, [renderLines]);

  // チャート準備完了時に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (finishedOnChartReady !== prevFinishedOnChartReadyRef.current) {
      prevFinishedOnChartReadyRef.current = finishedOnChartReady;
      if (finishedOnChartReady) {
        renderLines();
      }
    }
  }, [finishedOnChartReady, renderLines]);

  // シンボル変更後に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (changingSymbol !== prevChangingSymbolRef.current) {
      prevChangingSymbolRef.current = changingSymbol;
      if (!changingSymbol) {
        renderLines();
      }
    }
  }, [changingSymbol, renderLines]);

  // 足種変更後に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (changingResolution !== prevChangingResolutionRef.current) {
      prevChangingResolutionRef.current = changingResolution;
      if (!changingResolution) {
        renderLines();
      }
    }
  }, [changingResolution, renderLines]);

  useEffect(() => {
    if (isManualChart !== prevTradeMethodRef.current) {
      changingTradeMethodRef.current = true;
      setChangingTradeMethod(true);
    }
  }, [isManualChart]);

  const otherWindowChartOpenButtonWidget = useRef(null);

  useEffect(() => {
    if (
      ALL_SERVICES.some((service) => !instrumentOptions[service].length) ||
      !selectedInstrumentId ||
      !serviceId ||
      (tvWidgetRef.current.headerReady && !changingTradeMethod)
    ) {
      return;
    }

    const isMobilePortfolio = isMobile && (onlyManualPositions || onlyApGroupPositions);
    const isMobileApGroupPositionsPortfolio = isMobile && onlyApGroupPositions;
    const widgetOptions = {
      symbol,
      datafeed: datafeed({
        instrumentOptions,
        isMobile,
        serviceId,
        instrumentList,
      }),
      interval: resolution,
      container: containerRef.current,
      library_path: '/charting_library/',
      custom_css_url: isMobile ? 'mobileCustomStyle2.css' : 'customStyle.css',
      timezone: 'Asia/Tokyo',
      locale: 'ja',
      disabled_features: [
        'display_market_status',
        isFullScreen && 'header_settings',
        'header_screenshot',
        'header_saveload',
        'header_undo_redo',
        'header_fullscreen_button',
        'header_compare',
        isMobile && 'header_chart_type',
        'timeframes_toolbar',
        'study_dialog_search_control',
        'create_volume_indicator_by_default',
        'header_symbol_search',
        'symbol_search_hot_key',
        disableHeaderWidget && 'header_widget',
        (isMobilePortfolio || disableHeaderWidget) && 'left_toolbar',
        isMobile && 'property_pages',
        isMobile && 'context_menus',
        'items_favoriting',
      ],
      enabled_features: [(!isFullScreen || disableHeaderWidget) && 'hide_left_toolbar_by_default', 'chart_zoom'],
      autosize: true,
      theme: 'Dark',
      favorites: {
        intervals: ['1H', '4H', '8H', '1D', '1W', '1M'],
      },
      studies_access: studiesAccess,
      custom_indicators_getter(PineJS) {
        return Promise.resolve(indicators(PineJS));
      },
      drawings_access: drawingAccess,
      studies_overrides: studiesOverrides,
      overrides,
      settings_overrides: overrides,
    };
    underDisposalRef.current = true;
    finishedOnChartReadyRef.current = false;
    setFinishedOnChartReady(false);
    prevTradeMethodRef.current = isManualChart;
    setChangingTradeMethod(false);
    tvWidgetRef.current = new widget(widgetOptions); // eslint-disable-line

    if (!isMobile && !isFullScreen && !disableHeaderWidget) {
      tvWidgetRef.current.headerReady().then(() => {
        const button = tvWidgetRef.current?.createButton && tvWidgetRef.current.createButton({ align: 'right' });
        if (!button) return;
        otherWindowChartOpenButtonWidget.current = button;
        button.classList.add('button');
        button.setAttribute('title', '別画面で開く');
        button.addEventListener('click', onChartExpandClick);
        button.innerHTML = `<svg
        xmlns="http://www.w3.org/2000/svg"
        fill="#868993"
        height="22"
        width="22"
        viewBox="0 0 24 24"
        >
          <path d="M0 0h24v24H0V0z" fill="none"/>
          <path d='M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6zM3
            9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6zm6 12l-2.3-2.3
            2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6z'
          />
        </svg>`;
      });
    }

    if (!tvWidgetRef.current?.onChartReady) return;
    tvWidgetRef.current.onChartReady(() => {
      underDisposalRef.current = false;
      finishedOnChartReadyRef.current = true;
      // これをトリガーとして chart object の描画が行われる
      setFinishedOnChartReady(true);
    });
    tvWidgetRef.current.onChartReady(() => {
      if (isMobileApGroupPositionsPortfolio || (!isMobile && !onlyManualPositions && !disableHeaderWidget)) {
        tvWidgetRef.current.headerReady().then(() => {
          const addDropDown = async () => {
            dropdownRef.current = await tvWidgetRef.current.createDropdown(dropdownOptionsRef.current);
          };

          addDropDown();
        });
      }

      if (withPositions) {
        tvWidgetRef.current
          .headerReady()
          .then(addButton(tvWidgetRef, buttonPositionsWidget, '建玉表示', displayPositions, toggleDisplayPositions));

        tvWidgetRef.current
          .headerReady()
          .then(addButton(tvWidgetRef, buttonNewOrdersWidget, '新規表示', displayNewOrders, toggleDisplayNewOrders));
        tvWidgetRef.current
          .headerReady()
          .then(
            addButton(
              tvWidgetRef,
              buttonClosedOrdersWidget,
              '決済表示',
              displayClosedOrders,
              toggleDisplayClosedOrders,
            ),
          );
      }

      if (!withoutIndicators) {
        const savedIndicators = JSON.parse(
          getDefaultValuesFromLocalStorage({
            key: KEY_FOR_CHART_INDICATORS,
            defaultValue: null,
          }),
        );
        if (savedIndicators) {
          tvWidgetRef.current.activeChart().applyStudyTemplate(savedIndicators);
        }

        const updateIndicatorsSettings = () => {
          // 念の為、タスクを末尾に積んでおく
          setTimeout(() => {
            // containerRef.current が null の場合は、chart の実体が既に破棄されているということなのでスキップ
            if (underDisposalRef.current || containerRef.current == null) {
              return;
            }
            try {
              // この if はおそらく不要だが念の為残しておく
              if (!tvWidgetRef.current?.activeChart) {
                return;
              }
              const newIndicators = tvWidgetRef.current.activeChart().createStudyTemplate({});
              if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ indicators: newIndicators }));
              }
              saveDefaultValuesFromLocalStorage({
                key: KEY_FOR_CHART_INDICATORS,
                value: JSON.stringify(newIndicators),
              });
            } catch (e) {
              Logger.error(e);
            }
          });
        };
        tvWidgetRef.current.subscribe('study', updateIndicatorsSettings);
        tvWidgetRef.current.subscribe('study_event', updateIndicatorsSettings);
        tvWidgetRef.current.subscribe('study_properties_changed', updateIndicatorsSettings);
      }

      tvWidgetRef.current.subscribe('time_interval', (e) => {
        const newResolution = e.label;
        // ここが resolution 変更の起点となる
        if (newResolution !== prevResolutionRef.current) {
          changingResolutionRef.current = true;
          setChangingResolution(true);
        }
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify({ resolution: newResolution }));
        }
        dispatch(changeChartResolution({ resolution: newResolution }));
        saveDefaultValuesFromLocalStorage({
          key: KEY_FOR_DEFAULT_CHART_RESOLUTION,
          value: newResolution,
        });
      });
    });
  }, [
    withoutIndicators,
    isFullScreen,
    isMobile,
    onChartExpandClick,
    instrumentOptions,
    selectedInstrumentId,
    instrumentList,
    selectedSide,
    resolution,
    dispatch,
    serviceId,
    disableHeaderWidget,
    withPositions,
    displayPositions,
    toggleDisplayPositions,
    displayClosedOrders,
    toggleDisplayClosedOrders,
    displayNewOrders,
    toggleDisplayNewOrders,
    onlyApGroupPositions,
    onlyManualPositions,
    chartSide,
    toggleChartSide,
    symbol,
    removeAllChartObjects,
    isManualChart,
    changingTradeMethod,
  ]);

  useEffect(() => {
    return () => {
      if (tvWidgetRef.current && tvWidgetRef.current.remove) {
        underDisposalRef.current = true; // この時点では遅すぎるが念の為
        tvWidgetRef.current.remove();
        tvWidgetRef.current = {};
      }
    };
  }, []);

  useEffect(() => {
    if (!isMobile && !isFullScreen && !disableHeaderWidget) {
      tvWidgetRef.current.headerReady().then(() => {
        const button = otherWindowChartOpenButtonWidget.current;
        if (button) {
          button.removeEventListener('click', onChartExpandClick);
          button.addEventListener('click', onChartExpandClick);
        }
      });
    }
  }, [isMobile, isFullScreen, disableHeaderWidget, onChartExpandClick]);

  useEffect(() => {
    dropdownRef.current?.applyOptions(dropdownOptions);
    dropdownOptionsRef.current = dropdownOptions;
  }, [dropdownOptions, selectedSide]);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    const changeChartProps = () => {
      if (isMobile || forceChartType) {
        tvWidgetRef.current.chart().setChartType(chartType);
      }
      const callbacks = [];
      if (symbol !== prevSymbolRef.current) {
        changingSymbolRef.current = true;
        setChangingSymbol(true); // symbol 変更開始
        callbacks.push(() => {
          prevSymbolRef.current = symbol;
          changingSymbolRef.current = false;
          // これをトリガーとして chart object の描画が行われる
          setChangingSymbol(false); // symbol 変更終了
        });
      }
      if (resolution !== prevResolutionRef.current) {
        callbacks.push(() => {
          prevResolutionRef.current = resolution;
          changingResolutionRef.current = false;
          // これをトリガーとして chart object の描画が行われる
          setChangingResolution(false); // resolution 変更終了
        });
      }
      tvWidgetRef.current.setSymbol(symbol, resolution, () => callbacks.forEach((cb) => cb()));
    };
    if (finishedOnChartReadyRef.current) {
      changeChartProps();
    } else {
      tvWidgetRef.current.onChartReady(changeChartProps);
    }
  }, [resolution, chartType, isMobile, forceChartType, symbol]);

  const fullScreenPopupEventListener = useCallback(
    (e) => {
      if (isMobile || !e.isTrusted || !e.source || e.source.name !== MANUAL_TRADE_CHART) {
        return;
      }
      switch (e.data.message) {
        case POPUP_MESSAGES.GET_CHART_TYPE_REQUEST: {
          if (fullScreenPopupRef.current && fullScreenPopupRef.current.postMessage) {
            fullScreenPopupRef.current.postMessage(
              {
                message: POPUP_MESSAGES.GET_CHART_TYPE_SUCCESS,
                payload: {
                  selectedInstrumentId,
                  chartSide,
                  serviceId,
                },
              },
              '*',
            );
          }
          break;
        }
        default: {
          // empty
        }
      }
    },
    [selectedInstrumentId, chartSide, serviceId, isMobile],
  );

  useEffect(() => {
    const handlePageHide = () => {
      underDisposalRef.current = true;
    };
    window.addEventListener('pagehide', handlePageHide);
    return () => {
      window.removeEventListener('pagehide', handlePageHide);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('message', fullScreenPopupEventListener);
    return () => {
      window.removeEventListener('message', fullScreenPopupEventListener);
    };
  }, [fullScreenPopupEventListener]);

  useEffect(() => {
    if (!withPositions) {
      return;
    }
    setButtonSelected(buttonClosedOrdersWidget.current, displayClosedOrders);
    setButtonSelected(buttonNewOrdersWidget.current, displayNewOrders);
    setButtonSelected(buttonPositionsWidget.current, displayPositions);
  }, [withPositions, displayClosedOrders, displayNewOrders, displayPositions]);

  if (isMobile) {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i += 1) {
      if (metas[i].getAttribute('name') === 'viewport') {
        metas[i].content = 'initial-scale=1.0, maximum-scale=1.0';
      }
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartAreaWrapper}>
        <div ref={containerRef} className={styles.chartContainer}>
          Chart
        </div>
      </div>
    </div>
  );
};

Chart.propTypes = {
  isFullScreen: PropTypes.bool,
  selectedInstrumentId: PropTypes.string.isRequired,
  selectedSide: PropTypes.number,
  resolution: PropTypes.string,
  chartType: PropTypes.number,
  isMobile: PropTypes.bool,
  serviceId: PropTypes.oneOf(ALL_SERVICES).isRequired,
  disableHeaderWidget: PropTypes.bool,
  withoutIndicators: PropTypes.bool,
  forceChartType: PropTypes.bool,
  withPositions: PropTypes.bool,
  onlyManualPositions: PropTypes.number,
  onlyApGroupPositions: PropTypes.string,
  isMobileAuthenticated: PropTypes.bool,
};

Chart.defaultProps = {
  isFullScreen: false,
  selectedSide: BUY_SELL_MAIN.SELL.CHART_ID,
  resolution: '',
  chartType: CHART_TYPES_MAIN.CANDLESTICK.ID,
  isMobile: false,
  disableHeaderWidget: false,
  withoutIndicators: false,
  forceChartType: false,
  withPositions: false,
  onlyManualPositions: null,
  onlyApGroupPositions: null,
  isMobileAuthenticated: undefined,
};

export default memo(Chart);
