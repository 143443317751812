export const CHANGE_EXCHANGE_TYPE = 'BUILDER/CHANGE_EXCHANGE_TYPE';
export const CHANGE_ORDER_TYPE = 'BUILDER/CHANGE_ORDER_TYPE';
export const CHANGE_ACTIVE_CURRENCY = 'BUILDER/CHANGE_ACTIVE_CURRENCY';
export const CHANGE_SINGLE_ORDER_OPTION = 'BUILDER/CHANGE_SINGLE_ORDER_OPTION';
export const CHANGE_MULTI_ORDER_OPTION = 'BUILDER/CHANGE_MULTI_ORDER_OPTION';
export const CHANGE_TECH_ORDER_OPTION = 'BUILDER/CHANGE_TECH_ORDER_OPTION';
export const CHANGE_ORDER_EDIT_STATUS = 'BUILDER/CHANGE_ORDER_EDIT_STATUS';
export const CHANGE_SINGLE_ORDER_VALIDATION_ERRORS = 'BUILDER/CHANGE_SINGLE_ORDER_VALIDATION_ERRORS';
export const CHANGE_MULTI_ORDER_VALIDATION_ERRORS = 'BUILDER/CHANGE_MULTI_ORDER_VALIDATION_ERRORS';
export const CHANGE_SELECTED_TERM_ID = 'BUILDER/CHANGE_SELECTED_TERM_ID';

export const RESET_ORDER_TYPE = 'BUILDER/RESET_ORDER_TYPE';
export const RESET_ACTIVE_CURRENCY = 'BUILDER/RESET_ACTIVE_CURRENCY';
export const RESET_EXCHANGE_TYPE = 'BUILDER/RESET_EXCHANGE_TYPE';
export const RESET_OPTIONS = 'BUILDER/RESET_OPTIONS';
export const RESET_SINGLE_ORDER_OPTIONS = 'BUILDER/RESET_SINGLE_ORDER_OPTIONS';
export const RESET_MULTI_ORDER_OPTIONS = 'BUILDER/RESET_MULTI_ORDER_OPTIONS';
export const RESET_TECH_ORDER_OPTIONS = 'BUILDER/RESET_TECH_ORDER_OPTIONS';
export const RESET_ORDERS_TABLES = 'BUILDER/RESET_ORDERS_TABLES';
export const RESET_CHART_DATA = 'BUILDER/RESET_CHART_DATA';
export const RESET_SINGLE_ORDER_VALIDATION_ERRORS = 'BUILDER/RESET_SINGLE_ORDER_VALIDATION_ERRORS';
export const RESET_MULTI_ORDER_VALIDATION_ERRORS = 'BUILDER/RESET_MULTI_ORDER_VALIDATION_ERRORS';

export const ADD_ORDER_SETTINGS_LIST = 'BUILDER/ADD_ORDER_SETTINGS_LIST';
export const ADD_ORDER_SETTINGS = 'BUILDER/ADD_ORDER_SETTINGS';
export const ADD_LOGIC_GROUP = 'BUILDER/LOGIC_GROUP';
export const ADD_TECH_LOGIC_GROUP = 'BUILDER/ADD_TECH_LOGIC_GROUP';

export const DELETE_ORDER_SETTINGS_BY_ID = 'BUILDER/DELETE_ORDER_SETTINGS_BY_ID';
export const DELETE_CHART_MAKE_ORDER_SETTINGS_BY_ID = 'BUILD/DELETE_CHART_MAKE_ORDER_SETTINGS_BY_ID';
export const DELETE_LOGIC_GROUP_BY_ID = 'BUILDER/DELETE_LOGIC_GROUP_BY_ID';

export const GET_CHART_DATA_REQUEST = 'BUILDER/GET_CHART_DATA_REQUEST';
export const GET_CHART_DATA_REQUEST_START_LOADING = 'BUILDER/GET_CHART_DATA_REQUEST_START_LOADING';
export const GET_CHART_DATA_REQUEST_END_LOADING = 'BUILDER/GET_CHART_DATA_REQUEST_END_LOADING';
export const GET_CHART_DATA_SUCCESS = 'BUILDER/GET_CHART_DATA_SUCCESS';
export const GET_CLOSE_RATE_DATA_SUCCESS = 'BUILDER/GET_CLOSE_RATE_DATA_SUCCESS';

export const GET_SIMULATION_DATA_REQUEST = 'BUILDER/GET_SIMULATION_DATA_REQUEST';
export const GET_SIMULATION_DATA_REQUEST_START_LOADING = 'BUILDER/GET_SIMULATION_DATA_REQUEST_START_LOADING';
export const GET_SIMULATION_DATA_REQUEST_END_LOADING = 'BUILDER/GET_SIMULATION_DATA_REQUEST_END_LOADING';
export const GET_SIMULATION_DATA_SUCCESS = 'BUILDER/GET_SIMULATION_DATA_SUCCESS';
export const RESET_SIMULATION_DATA = 'BUILDER/RESET_SIMULATION_DATA';
export const GET_TECH_SIMULATION_DATA_REQUEST = 'BUILDER/GET_TECH_SIMULATION_DATA_REQUEST';

export const ADD_TO_CART_REQUEST = 'BUILDER/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_REQUEST_START_LOADING = 'BUILDER/ADD_TO_CART_REQUEST_START_LOADING';
export const ADD_TO_CART_REQUEST_END_LOADING = 'BUILDER/ADD_TO_CART_REQUEST_END_LOADING';
export const ADD_TO_CART_TECH_REQUEST = 'BUILDER/ADD_TO_CART_TECH_REQUEST';

export const ADD_TO_PORTFOLIO_REQUEST = 'BUILDER/ADD_TO_PORTFOLIO_REQUEST';
export const ADD_TO_PORTFOLIO_REQUEST_START_LOADING = 'BUILDER/ADD_TO_PORTFOLIO_REQUEST_START_LOADING';
export const ADD_TO_PORTFOLIO_REQUEST_END_LOADING = 'BUILDER/ADD_TO_PORTFOLIO_REQUEST_END_LOADING';
export const ADD_TO_PORTFOLIO_TECH_REQUEST = 'BUILDER/ADD_TO_PORTFOLIO_TECH_REQUEST';

export const HIDE_PROFITLOSS_CHART = 'BUILDER/HIDE_PROFITLOSS_CHART';
export const SHOW_PROFITLOSS_CHART = 'BUILDER/SHOW_PROFITLOSS_CHART';
