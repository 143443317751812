import React, { memo, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { MODAL_SIZES, BUY_SELL_VALUE, TRADE_METHOD_VALUE, FX } from 'shared-modules/constants';
import { getOrdersRequest } from 'shared-modules/redux/orders';
import { useServiceId } from 'shared-modules/hooks';
import Modal from '../../../../components/Modal';
import PortfolioManualTradeDetailCard from '../PortfolioManualTradeDetailCard';
import PortfolioManualTradeDetailChart from '../PortfolioManualTradeDetailChart';
import PortfolioManualTradeDetailTable from '../PortfolioManualTradeDetailTable';
import styles from './portfolioManualTradeDetail.module.scss';

const PortfolioManualTradeDetail = ({ isOpen, closeModal, data }) => {
  const { type, currency, buySell, shortName } = data || {};
  const serviceId = useServiceId(currency);
  const title = useMemo(
    () => `${type} (${BUY_SELL_VALUE[buySell]}) ${serviceId === FX ? currency : shortName}`,
    [type, buySell, currency, shortName, serviceId],
  );
  const positionsData = useSelector((state) => state.currencies.positions[serviceId]);
  const isLoading = useSelector((state) => state.portfolio.selectedPositionsMetaInfo.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    const payload = { serviceId, params: { instrumentId: currency, tradeMethod: '1' } };
    dispatch(getOrdersRequest(payload));
  }, [dispatch, currency, buySell, serviceId]);

  useEffect(() => {
    if (
      isOpen &&
      !isLoading &&
      !positionsData.some(
        ({ instrumentId, side, tradeMethod }) =>
          instrumentId === currency && side === buySell && type === TRADE_METHOD_VALUE[tradeMethod],
      )
    ) {
      closeModal();
    }
  }, [positionsData, isOpen, isLoading, currency, buySell, type, closeModal]);
  return (
    <Modal size={MODAL_SIZES.WIDTH_1024} isOpen={isOpen} closeModal={closeModal} title={title} isOverlayModal>
      <div className={styles.wrapper}>
        <PortfolioManualTradeDetailCard data={data} />
        <PortfolioManualTradeDetailChart selectedInstrumentId={currency} buySell={buySell} serviceId={serviceId} />
        <PortfolioManualTradeDetailTable type={type} currency={currency} buySell={buySell} />
      </div>
    </Modal>
  );
};
PortfolioManualTradeDetail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    type: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    buySell: PropTypes.string,
  }),
};
PortfolioManualTradeDetail.defaultProps = {
  data: {},
};

export default memo(PortfolioManualTradeDetail);
