const compareMessageIdDesc = (a, b) => {
  const aMessageId = a?.messageId ?? 0;
  const bMessageId = b?.messageId ?? 0;
  if (aMessageId === bMessageId) {
    return 0;
  }
  if (aMessageId < bMessageId) {
    return 1;
  }
  return -1;
};

const compareStartTimeDesc = (a, b) => {
  const aStartTime = a?.startTime ?? '0000-00-00T00:00:00';
  const bStartTime = b?.startTime ?? '0000-00-00T00:00:00';
  if (aStartTime === bStartTime) {
    return 0;
  }
  if (aStartTime < bStartTime) {
    return 1;
  }
  return -1;
};

const compare = (a, b) => {
  const compResult = compareStartTimeDesc(a, b);
  if (compResult === 0) {
    return compareMessageIdDesc(a, b);
  }
  return compResult;
};

export const sorted = (messages) => {
  if (!messages) {
    return messages;
  }
  messages.sort(compare);
  return messages;
};
