import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BUY_SELL_CHART_VALUE, BUY_SELL_MAIN, CHART_TYPES_MAIN, M_SUFFIX } from 'shared-modules/constants';
import {
  KEY_FOR_CHART_INDICATORS,
  KEY_FOR_DEFAULT_CHART_RESOLUTION,
  STANDARD_FONT_SIZE,
} from 'shared-modules/constants/chart';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import { useInstrumentShortName } from 'shared-modules/hooks';
import { getDefaultValuesFromLocalStorage, saveDefaultValuesFromLocalStorage } from 'shared-modules/services';
import { widget } from 'shared-modules/thirdPartyLibrary/TradingView/charting_library';
import { v4 as uuid } from 'uuid';
import { useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import { changeChartResolution, changeChartSide } from '../../redux/actions';
import { overrides as defaultOverrides, studiesAccess, studiesOverrides } from '../Chart/Chart';
import styles from '../Chart/chart.module.scss';
import datafeed from '../Chart/datafeed';
import indicators from '../Chart/indicators';
import { Spin } from '../Spin';

const saveIndicatorDelay = 300;

function indicatorCall(tempTvWidgetRef) {
  const savedIndicators = JSON.parse(
    getDefaultValuesFromLocalStorage({ key: KEY_FOR_CHART_INDICATORS, defaultValue: null }),
  );
  if (savedIndicators) {
    tempTvWidgetRef.current.activeChart().applyStudyTemplate(savedIndicators);
  }

  const updateIndicatorsSettings = () =>
    setTimeout(() => {
      if (!tempTvWidgetRef.current?.activeChart) return;
      const newIndicators = tempTvWidgetRef.current.activeChart().createStudyTemplate({});
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ indicators: newIndicators }));
      }
      saveDefaultValuesFromLocalStorage({ key: KEY_FOR_CHART_INDICATORS, value: JSON.stringify(newIndicators) });
    }, saveIndicatorDelay);

  tempTvWidgetRef.current.subscribe('study', updateIndicatorsSettings);
  tempTvWidgetRef.current.subscribe('study_event', updateIndicatorsSettings);
  tempTvWidgetRef.current.subscribe('study_properties_changed', updateIndicatorsSettings);
}

const CommonChart = ({
  callOnChartReadyCb,
  callOnChartReadyInitialCb,
  chartType,
  disableHeaderWidget,
  disableLeftToolbar,
  disableOHLCInfo,
  isMobile,
  resolution: resolutionOuter,
  selectedInstrumentId,
  selectedSide,
  serviceId,
  withoutIndicators,
  renderChartObject,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const dropdownRef = useRef(null);

  const tvWidgetRef = useRef({});
  const containerId = useMemo(() => uuid(), []);

  const resolutionInner = useSelector((state) => state.chart.resolution);

  const resolution = useMemo(() => resolutionOuter || resolutionInner, [resolutionOuter, resolutionInner]);

  const isManual = selectedInstrumentId.includes(M_SUFFIX);
  const instrumentOptions = useApOrManualInstrumentOptions(isManual);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const chartSide = useSelector((state) => state.manualTrade.chartSide);

  const [finishedOnChartReady, setFinishedOnChartReady] = useState(false);
  const finishedOnChartReadyRef = useRef(finishedOnChartReady);
  const prevFinishedOnChartReadyRef = useRef(finishedOnChartReady);
  const [changingSymbol, setChangingSymbol] = useState(false);
  const changingSymbolRef = useRef(changingSymbol);
  const prevChangingSymbolRef = useRef(changingSymbol);
  const prevSymbolRef = useRef(null);

  const isMobileShareChart = isMobile && disableHeaderWidget;

  const shortName = useInstrumentShortName(selectedInstrumentId);

  const symbol = useMemo(() => {
    if (isMobileShareChart) {
      return `${shortName}(${BUY_SELL_CHART_VALUE[selectedSide]})`;
    }
    return `${shortName}(${BUY_SELL_CHART_VALUE[chartSide]})`;
  }, [isMobileShareChart, shortName, chartSide, selectedSide]);
  const createAskBidToggle = (title) => <div className="askbid-toggle">{title}</div>;

  // チャートオブジェクトに関連するプロパティの変更をトリガーに描画を試みる
  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    if (finishedOnChartReadyRef.current) {
      renderChartObject?.(tvWidgetRef.current, finishedOnChartReadyRef.current, changingSymbolRef.current);
    } else {
      tvWidgetRef.current.onChartReady(() => {
        renderChartObject?.(tvWidgetRef.current, finishedOnChartReadyRef.current, changingSymbolRef.current);
      });
    }
  }, [renderChartObject]);

  // チャート準備完了時に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (finishedOnChartReady !== prevFinishedOnChartReadyRef.current) {
      prevFinishedOnChartReadyRef.current = finishedOnChartReady;
      if (finishedOnChartReady) {
        renderChartObject?.(tvWidgetRef.current, finishedOnChartReadyRef.current, changingSymbolRef.current);
      }
    }
  }, [finishedOnChartReady, renderChartObject]);

  // シンボル変更後に最新状態のチャートオブジェクトを描画する
  useEffect(() => {
    if (changingSymbol !== prevChangingSymbolRef.current) {
      prevChangingSymbolRef.current = changingSymbol;
      if (!changingSymbol) {
        renderChartObject?.(tvWidgetRef.current, finishedOnChartReadyRef.current, changingSymbolRef.current);
      }
    }
  }, [changingSymbol, renderChartObject]);

  const toggleChartSide = useCallback(
    (value) => {
      dispatch(changeChartSide({ id: value }));
    },
    [dispatch],
  );

  const handleDropdownItemSelect = useCallback(
    (title, value) => {
      dropdownRef.current.applyOptions({
        title,
      });
      toggleChartSide(value);
    },
    [toggleChartSide],
  );
  const createDropdownItem = useCallback(
    (title, value) => ({
      title,
      onSelect: () => {
        handleDropdownItemSelect(title, value);
      },
    }),
    [handleDropdownItemSelect],
  );
  const dropdownOptionsRef = useRef(null);
  const dropdownOptions = useMemo(
    () => ({
      title: createAskBidToggle(Number(selectedSide) ? 'ASK' : 'BID'),
      items: [createDropdownItem(createAskBidToggle('BID'), 0), createDropdownItem(createAskBidToggle('ASK'), 1)],
    }),
    [createDropdownItem, selectedSide],
  );
  useEffect(() => {
    toggleChartSide(selectedSide);
  }, [selectedSide, toggleChartSide]);

  useEffect(() => {
    dropdownRef.current?.applyOptions(dropdownOptions);
    dropdownOptionsRef.current = dropdownOptions;
  }, [dropdownOptions]);

  useEffect(() => {
    if (
      ALL_SERVICES.some((service) => !instrumentOptions[service].length) ||
      !selectedInstrumentId ||
      !serviceId ||
      tvWidgetRef.current.headerReady
    ) {
      return;
    }

    const overrides = {
      ...defaultOverrides,
      'paneProperties.legendProperties.showSeriesOHLC': !disableOHLCInfo,
      'paneProperties.legendProperties.showBarChange': !disableOHLCInfo,
      'scalesProperties.fontSize': isMobile ? 8 : STANDARD_FONT_SIZE,
    };
    const widgetOptions = {
      symbol,
      datafeed: datafeed({ instrumentOptions, isMobile, serviceId, instrumentList }),
      interval: resolution,
      container: containerId,
      library_path: '/charting_library/',
      custom_css_url: isMobile ? 'mobileCustomStyle2.css' : 'customStyle.css',
      timezone: 'Asia/Tokyo',
      locale: 'ja',
      disabled_features: [
        'display_market_status',
        'header_settings',
        'header_screenshot',
        'header_saveload',
        'header_undo_redo',
        'header_fullscreen_button',
        'header_compare',
        isMobile && 'header_chart_type',
        'timeframes_toolbar',
        'study_dialog_search_control',
        'create_volume_indicator_by_default',
        'header_symbol_search',
        'symbol_search_hot_key',
        disableHeaderWidget && 'header_widget',
        disableLeftToolbar && 'left_toolbar',
        isMobile && 'property_pages',
        isMobile && 'context_menus',
        'items_favoriting',
      ],
      enabled_features: [disableLeftToolbar && 'hide_left_toolbar_by_default', 'chart_zoom'],
      autosize: true,
      theme: 'Dark',
      favorites: {
        intervals: ['1H', '4H', '1D', '1W', '1M'],
      },
      studies_access: studiesAccess,
      custom_indicators_getter(PineJS) {
        return Promise.resolve(indicators(PineJS));
      },
      drawings_access: {
        type: 'white',
        tools: [
          { name: '十字' },
          { name: '矢印' },
          { name: 'トレンドライン' },
          { name: '水平線' },
          { name: '垂直線' },
          { name: '平行チャネル' },
          { name: 'フィボナッチ・リトレースメント' },
        ],
      },
      studies_overrides: studiesOverrides,
      overrides,
      settings_overrides: overrides,
    };
    finishedOnChartReadyRef.current = false;
    setFinishedOnChartReady(false);
    tvWidgetRef.current = new widget(widgetOptions); // eslint-disable-line
    tvWidgetRef.current.onChartReady(() => {
      if (!isMobileShareChart) {
        tvWidgetRef.current.headerReady().then(() => {
          const addDropDown = async () => {
            dropdownRef.current = await tvWidgetRef.current.createDropdown(dropdownOptionsRef.current);
          };
          addDropDown();
        });
      }
    });

    if (!tvWidgetRef.current?.onChartReady) return;
    tvWidgetRef.current.onChartReady(() => {
      finishedOnChartReadyRef.current = true;
      setFinishedOnChartReady(true);
    });
    tvWidgetRef.current.onChartReady(() => {
      callOnChartReadyInitialCb(tvWidgetRef.current);

      if (!withoutIndicators && !isMobile) {
        indicatorCall(tvWidgetRef);
      }

      tvWidgetRef.current.subscribe('time_interval', (e) => {
        const newResolution = e.label;
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify({ resolution: newResolution }));
        }
        dispatch(changeChartResolution({ resolution: newResolution }));
        saveDefaultValuesFromLocalStorage({ key: KEY_FOR_DEFAULT_CHART_RESOLUTION, value: newResolution });
      });

      setLoading(false);
    });
  }, [
    location.pathname,
    containerId,
    isMobile,
    instrumentOptions,
    selectedInstrumentId,
    instrumentList,
    selectedSide,
    resolution,
    dispatch,
    serviceId,
    disableHeaderWidget,
    disableLeftToolbar,
    disableOHLCInfo,
    chartSide,
    callOnChartReadyInitialCb,
    withoutIndicators,
    symbol,
    isMobileShareChart,
  ]);

  useEffect(() => {
    return () => {
      if (tvWidgetRef.current && tvWidgetRef.current.remove) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = {};
      }
    };
  }, []);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    const changeChartProps = () => {
      tvWidgetRef.current.chart().setChartType(chartType);
      let callback;
      if (symbol !== prevSymbolRef.current) {
        changingSymbolRef.current = true;
        setChangingSymbol(true); // symbol 変更開始
        callback = () => {
          prevSymbolRef.current = symbol;
          changingSymbolRef.current = false;
          // これをトリガーとして chart object の描画が行われる
          setChangingSymbol(false); // symbol 変更終了
        };
      }
      // ******************** 注意 ********************
      // 現状、props で渡される resolution が固定値である為問題が発生していないが、
      // props で渡される resolution が固定値でなくなった場合、 components/Chart/Chart.js 相当の対応が必要
      tvWidgetRef.current.setSymbol(symbol, resolution, callback);
    };
    if (finishedOnChartReadyRef.current) {
      changeChartProps();
    } else {
      tvWidgetRef.current.onChartReady(changeChartProps);
    }
  }, [resolution, chartType, symbol]);

  useEffect(() => {
    if (!tvWidgetRef.current.onChartReady) {
      return;
    }
    tvWidgetRef.current.onChartReady(() => {
      try {
        callOnChartReadyCb(tvWidgetRef.current, chartSide);
      } catch (error) {
        // do nothing
      }
    });
  }, [callOnChartReadyCb, chartSide]);

  if (isMobile) {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i += 1) {
      if (metas[i].getAttribute('name') === 'viewport') {
        metas[i].content = 'initial-scale=1.0, maximum-scale=1.0';
      }
    }
  }

  return (
    <div className={classNames(styles.wrapper, { [styles.loading]: loading })}>
      {loading && <Spin className={styles.loader} />}
      <div className={classNames(styles.chartAreaWrapper, { [styles.displayNone]: loading })}>
        <div id={containerId} className={styles.chartContainer}>
          Chart
        </div>
      </div>
    </div>
  );
};

CommonChart.propTypes = {
  callOnChartReadyCb: PropTypes.func,
  callOnChartReadyInitialCb: PropTypes.func,
  chartType: PropTypes.number,
  disableHeaderWidget: PropTypes.bool,
  disableLeftToolbar: PropTypes.bool,
  disableOHLCInfo: PropTypes.bool,
  isMobile: PropTypes.bool,
  resolution: PropTypes.string,
  selectedInstrumentId: PropTypes.string.isRequired,
  selectedSide: PropTypes.number,
  serviceId: PropTypes.oneOf(ALL_SERVICES).isRequired,
  withoutIndicators: PropTypes.bool,
  renderChartObject: PropTypes.func,
};

CommonChart.defaultProps = {
  callOnChartReadyCb: () => {},
  callOnChartReadyInitialCb: () => {},
  chartType: CHART_TYPES_MAIN.CANDLESTICK.ID,
  disableHeaderWidget: false,
  disableLeftToolbar: false,
  disableOHLCInfo: false,
  isMobile: false,
  resolution: '',
  selectedSide: BUY_SELL_MAIN.SELL.CHART_ID,
  withoutIndicators: false,
  renderChartObject: undefined,
};

export default memo(CommonChart);
