import { call, put, select } from 'redux-saga/effects';
import { getUnreadCount } from '../../../api/messageApi';
import { getUnreadCountSuccess } from '../../actions/messageActions';
import { errorHandler } from '../errorSaga';

export function* getUnreadCountRequestHandler({ payload }) {
  try {
    const isAuthenticated = yield select((state) => state.auth.isAuthenticated);
    if (!isAuthenticated) {
      // 未ログイン時はスキップ
      return;
    }
    const { channel } = payload;
    const { data: unreadCount } = yield call(getUnreadCount, { channel });
    yield put(getUnreadCountSuccess({ unreadCount }));
  } catch (error) {
    // TODO 暗黙の更新で画面側にエラーを通知してしまって良いのかどうか
    yield call(errorHandler, { error });
  }
}
