/* eslint-disable react/prop-types */
import Decimal from 'decimal.js';
import {
  BUY_SELL_MAIN,
  CFD,
  ETF,
  FX,
  MIXED,
  NEW_SETTLEMENT_VALUE,
  SPECIAL,
  TRADE_METHOD_FILTER_INITIAL_STATES,
  TRADE_METHOD_VALUE,
} from 'shared-modules/constants';
import {
  MAPPING_TABLE_COLUMN_NAMES_MAIN,
  OPTIONS_ORDERS_TABLE_ACTIVE_ORDERS_FILTERING,
  SORT_ASCENDING,
  SORT_DESCENDING,
} from 'shared-modules/constants/manualTrade';
import { uniq } from 'lodash';
import {
  createFlooredAccessor,
  formatDateTimeWithoutDivider,
  formatNumberToDisplayedString,
  roundTo3DigitsAfterDot,
} from 'shared-modules/services';
import { TECH_BASE_PRICE_LITERAL } from 'shared-modules/constants/builder';
import { useSelector } from 'react-redux';
import { useRemovedDuplicateInstrumentOptions } from 'shared-modules/hooks/symbol';
import HighlightValue from '../components/HighlightValue';
import NumberTotal from '../components/TotalItem/NumberTotal';
import { store } from '../redux/store';
import ValueWithPrecision from '../components/ValueWithPrecision';
import CurrentPriceCellValue from '../components/CurrentPriceCellValue';
import InstrumentPrecisionTableCell from '../components/InstrumentPrecisionTableCell';
import UnrealizedProfitLossCellValue from '../components/UnrealizedProfitLossCellValue';
import TotalPlCellValue from '../components/TotalPlCellValue';
import BuySellItem from '../components/BuySellItem';
import CounterValueCell from '../appWrappers/ModalContainer/components/CounterValueCell';
import { CustomTooltip, PopControl, ShortName } from '../components';
import PipsSpecialTableCell from '../components/PipsSpecialTableCell/PipsSpecialTableCell';
import CounterValue from '../appWrappers/ModalContainer/components/CounterValueCell/CounterValue';
import {
  TradeInfoTotalPositionsPl,
  TradeInfoTotalPl,
  TechEntryPriceTableCell,
  TechCounterPriceTableCell,
} from '../components/TableComponents';

const Cell = ({ cell: { value } }) => value || '-';

export const orderNameWithToolTips = (value) => (value ? <CustomTooltip message={value}>{value}</CustomTooltip> : '-');

export const instrumentIdHeader = ({ tableMetaInfo, filterHandler, sortingHandler, manualSortBy, column }) => {
  const state = store.getState();
  const { instrumentId: filtered } = tableMetaInfo;
  const { serviceId } = state.auth;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const instrumentOptions = useRemovedDuplicateInstrumentOptions(serviceId);
  const filterOptions = {
    type: 'radio',
    options: [
      { label: 'すべて', value: null, selected: !filtered },
      {
        label: instrumentOptions.map((instrumentsOption) => ({
          ...instrumentsOption,
          selected: filtered === instrumentsOption.value,
        })),
        value: 'select',
        selected: Boolean(filtered),
      },
    ],
  };

  const { toggleSortBy, isSorted, isSortedDesc } = column;
  const sortOptions = [
    {
      label: '昇順',
      value: SORT_ASCENDING,
      selected: isSorted && !isSortedDesc,
    },
    {
      label: '降順',
      value: SORT_DESCENDING,
      selected: isSorted && isSortedDesc,
    },
  ];

  const applyCallback = ({ filterSelected, sortSelected }) => {
    filterHandler({ key: 'instrumentId', value: filterSelected });

    if (sortSelected !== undefined) {
      if (manualSortBy) {
        sortingHandler({ id: 'instrumentId', desc: sortSelected === SORT_DESCENDING });
      }
      toggleSortBy(sortSelected === SORT_DESCENDING, false);
    }
  };

  const applyFilteringViewCondition = () => {
    return filterOptions.options[1].selected;
  };

  return (
    <PopControl
      header={MAPPING_TABLE_COLUMN_NAMES_MAIN.INSTRUMENT_ID.LABEL}
      filterOption={filterOptions}
      sortOptions={sortOptions}
      applyCallback={applyCallback}
      applyFilteringViewCondition={applyFilteringViewCondition}
    />
  );
};

const tradeMethodsHeader =
  ({ serviceId }) =>
  ({ tableMetaInfo, filterHandler }) => {
    const { tradeMethods } = tableMetaInfo;
    const filterOptions = {
      type: 'checkbox',
      options: TRADE_METHOD_FILTER_INITIAL_STATES[serviceId].map((method) => {
        if (tradeMethods?.length > 0) {
          return {
            label: method.label,
            value: method.id[0],
            selected: tradeMethods.includes(method.id[0]),
          };
        }
        return {
          label: method.label,
          value: method.id[0],
          selected: true,
        };
      }),
    };

    const applyCallback = ({ filterSelected }) => {
      filterHandler({ key: 'tradeMethods', value: filterSelected });
    };

    const applyFilteringViewCondition = () => {
      return filterOptions.options.filter((item) => item.selected).length !== filterOptions.options.length;
    };

    return (
      <PopControl
        header={MAPPING_TABLE_COLUMN_NAMES_MAIN.TRADE_METHOD.LABEL}
        filterOption={filterOptions}
        applyCallback={applyCallback}
        applyFilteringViewCondition={applyFilteringViewCondition}
      />
    );
  };

const isCloseHeader = ({ tableMetaInfo, filterHandler }) => {
  const { isClose } = tableMetaInfo;
  const filterOptions = {
    type: 'checkbox',
    options: [
      { label: '新規', value: 'false', selected: isClose === null || isClose === false },
      { label: '決済', value: 'true', selected: isClose === null || isClose === true },
    ],
  };

  const applyCallback = ({ filterSelected }) => {
    if (filterSelected.length === 1) {
      filterHandler({ key: 'isClose', value: filterSelected[0] === 'true' });
      return;
    }
    filterHandler({ key: 'isClose', value: null });
  };

  const applyFilteringViewCondition = () => {
    return filterOptions.options.filter((item) => item.selected).length !== filterOptions.options.length;
  };

  return (
    <PopControl
      header={
        <span>
          新規
          <br />
          決済
        </span>
      }
      filterOption={filterOptions}
      applyCallback={applyCallback}
      applyFilteringViewCondition={applyFilteringViewCondition}
    />
  );
};

export const sideHeader = ({ tableMetaInfo, filterHandler }) => {
  const { side } = tableMetaInfo;
  const filterOptions = {
    type: 'checkbox',
    options: [
      { label: '買い', value: '3', selected: !side || side === '3' },
      { label: '売り', value: '1', selected: !side || side === '1' },
    ],
  };

  const applyCallback = ({ filterSelected }) => {
    filterHandler({ key: 'side', value: filterSelected.length === 1 ? filterSelected[0] : null });
  };

  const applyFilteringViewCondition = () => {
    return filterOptions.options.filter((item) => item.selected).length !== filterOptions.options.length;
  };

  return (
    <PopControl
      header="売買"
      filterOption={filterOptions}
      applyCallback={applyCallback}
      applyFilteringViewCondition={applyFilteringViewCondition}
    />
  );
};

export const orderStatusHeader =
  ({ innerCallback }) =>
  ({ tableMetaInfo, filterHandler, sortingHandler, manualSortBy, column }) => {
    const { status } = tableMetaInfo;
    const selectedId = status
      ? OPTIONS_ORDERS_TABLE_ACTIVE_ORDERS_FILTERING[1].id
      : OPTIONS_ORDERS_TABLE_ACTIVE_ORDERS_FILTERING[0].id;
    const filterOptions = {
      type: 'radio',
      options: OPTIONS_ORDERS_TABLE_ACTIVE_ORDERS_FILTERING.map((option) => ({
        ...option,
        value: option.id,
        selected: selectedId === option.id,
      })),
    };

    const { toggleSortBy, isSorted, isSortedDesc } = column;
    const sortOptions = [
      {
        label: '昇順',
        value: SORT_ASCENDING,
        selected: isSorted && !isSortedDesc,
      },
      {
        label: '降順',
        value: SORT_DESCENDING,
        selected: isSorted && isSortedDesc,
      },
    ];
    const applyCallback = ({ filterSelected, sortSelected }) => {
      innerCallback(filterSelected);
      filterHandler({ key: MAPPING_TABLE_COLUMN_NAMES_MAIN.STATUS.ID, value: Boolean(Number(filterSelected)) });

      if (sortSelected !== undefined) {
        if (manualSortBy) {
          sortingHandler({
            id: MAPPING_TABLE_COLUMN_NAMES_MAIN.STATUS.ID,
            desc: sortSelected === SORT_DESCENDING,
          });
        }
        toggleSortBy(sortSelected === SORT_DESCENDING, false);
      }
    };

    const applyFilteringViewCondition = () => {
      return filterOptions.options[1].selected;
    };

    return (
      <PopControl
        header={MAPPING_TABLE_COLUMN_NAMES_MAIN.STATUS.LABEL}
        filterOption={filterOptions}
        sortOptions={sortOptions}
        applyCallback={applyCallback}
        applyFilteringViewCondition={applyFilteringViewCondition}
      />
    );
  };

const commonSortHeader =
  ({ label }) =>
  ({ sortingHandler, manualSortBy, column, setSortBy }) => {
    const { id, toggleSortBy, isSorted, isSortedDesc } = column;
    const sortOptions = [
      {
        label: '昇順',
        value: SORT_ASCENDING,
        selected: isSorted && !isSortedDesc,
      },
      {
        label: '降順',
        value: SORT_DESCENDING,
        selected: isSorted && isSortedDesc,
      },
    ];

    const applyCallback = ({ sortSelected }) => {
      const desc = sortSelected === SORT_DESCENDING;
      if (manualSortBy) {
        setSortBy([{ id, desc }]);
        sortingHandler({ id, desc });
      } else {
        toggleSortBy(desc, false);
      }
    };

    return <PopControl header={label} sortOptions={sortOptions} applyCallback={applyCallback} />;
  };

export const commonDateSortHeader =
  ({ label }) =>
  ({ sortingHandler, manualSortBy, column, setSortBy }) => {
    const { id, toggleSortBy, isSorted, isSortedDesc } = column;
    const sortOptions = [
      {
        label: '昇順',
        value: SORT_ASCENDING,
        selected: isSorted && !isSortedDesc,
      },
      {
        label: '降順',
        value: SORT_DESCENDING,
        selected: isSorted && isSortedDesc,
      },
    ];

    const applyCallback = ({ sortSelected }) => {
      const desc = sortSelected === SORT_DESCENDING;
      if (manualSortBy) {
        setSortBy([{ id, desc }]);
        sortingHandler({ id, desc });
      } else {
        toggleSortBy(desc, false);
      }
    };

    return <PopControl header={label} sortOptions={sortOptions} applyCallback={applyCallback} />;
  };

export const quantityFooter = ({ data }) => {
  if (data.length === 0) return '-';
  const buyTotal = data
    .filter((item) => item.side === String(BUY_SELL_MAIN.BUY.ID))
    .reduce((sum, item) => Decimal.add(sum, item.quantity).toNumber(), 0);
  const sellTotal = data
    .filter((item) => item.side === String(BUY_SELL_MAIN.SELL.ID))
    .reduce((sum, item) => Decimal.add(sum, item.quantity).toNumber(), 0);
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <NumberTotal label="買 計" value={buyTotal} />
      <NumberTotal label="売 計" value={sellTotal} />
    </div>
  );
};

export const priceAvgFooter = ({ data, column }) => {
  if (data.length === 0 || uniq(data.map(({ instrumentId }) => instrumentId)).length > 1) return '-';
  if (data.every((item) => !item[column.id])) return '-';

  const priceAvg = Decimal.div(
    data.reduce((sum, item) => Decimal.add(sum, item[column.id] ?? 0).toNumber(), 0),
    data.length,
  ).toNumber();
  return (
    <NumberTotal label="平均" value={<ValueWithPrecision instrumentId={data[0].instrumentId} value={priceAvg} />} />
  );
};

const sumFooter =
  ({ accessor }) =>
  ({ data }) => {
    if (data.length === 0) return '-';
    const total = data.reduce((sum, item) => Decimal.add(sum, item[accessor] ?? 0).toNumber(), 0);
    return <NumberTotal label="計" value={<HighlightValue value={total} isToFormat />} />;
  };

const sumPlFooter =
  () =>
  ({ data }) => {
    const tutorialMode = useSelector((state) => state.tutorial.tutorialMode);

    if (tutorialMode) {
      const sumPl = data.reduce(
        (acc, item) => {
          acc.value = Number(Decimal.add(acc.value, Math.abs(item.pl)));
          return acc;
        },
        { value: 0 },
      );

      return <NumberTotal label="計" value={<HighlightValue value={sumPl.value} isToFormat />} />;
    }

    const positionsUnrealizedProfitLoss = useSelector(
      (state) => state.currencies.positionsUnrealizedProfitLoss[data[0]?.instrumentId],
    );
    const id = data.map((e) => e.positionId);
    const filteredData = Object.fromEntries(
      Object.entries(positionsUnrealizedProfitLoss ?? {}).filter(([key]) => id.includes(key)),
    );

    const totalUnrealizedProfitLoss = Object.values(filteredData).reduce(
      (total, item) => total + item.unrealizedProfitLoss,
      0,
    );
    if (data.length === 0) return '-';
    return <NumberTotal label="計" value={<HighlightValue value={totalUnrealizedProfitLoss} isToFormat />} />;
  };

const serverSumFooter =
  ({ summarizedField }) =>
  ({ tableMetaInfo }) => {
    const isSummarizedComm = summarizedField === 'summarizedComm';
    if (isSummarizedComm) {
      return tableMetaInfo[summarizedField] ? (
        <NumberTotal
          label="計"
          value={formatNumberToDisplayedString({
            value: tableMetaInfo[summarizedField],
            withoutPlus: true,
            withYenIcon: false,
          })}
        />
      ) : (
        '-'
      );
    }
    return <NumberTotal label="計" value={<HighlightValue value={tableMetaInfo[summarizedField]} isToFormat />} />;
  };

const serverEachSideSumFooter =
  () =>
  ({ tableMetaInfo }) => {
    const buyValue = tableMetaInfo.summarizedBuyQuantity;
    const sellValue = tableMetaInfo.summarizedSellQuantity;

    if (!buyValue && !sellValue) return '-';

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <NumberTotal label="買 計" value={buyValue} />
        <NumberTotal label="売 計" value={sellValue} />
      </div>
    );
  };

const executionTimeFilterHeader = ({ filterHandler, sortingHandler, manualSortBy, column }) => {
  const { toggleSortBy, isSorted, isSortedDesc } = column;
  const sortOptions = [
    {
      label: '昇順',
      value: SORT_ASCENDING,
      selected: isSorted && !isSortedDesc,
    },
    {
      label: '降順',
      value: SORT_DESCENDING,
      selected: isSorted && isSortedDesc,
    },
  ];

  const applyCallback = ({ filterSelected, sortSelected }) => {
    filterHandler({ key: 'execTime', value: filterSelected });
    if (manualSortBy) {
      sortingHandler({ id: 'execTime', desc: sortSelected === SORT_DESCENDING });
    }
    toggleSortBy(sortSelected === SORT_DESCENDING, false);
  };

  return (
    <PopControl header="約定日時" sortOptions={sortOptions} applyCallback={applyCallback} applyFilteringViewCondition />
  );
};

export const getTradeMethodHeader = (serviceId, isManual) => {
  if (isManual) {
    // string
    return MAPPING_TABLE_COLUMN_NAMES_MAIN.TRADE_METHOD.LABEL;
  }
  // function
  return tradeMethodsHeader({ serviceId });
};

export const getPositionsTableTemplate = (serviceId, isManual) => {
  const POSITIONS_TABLE_FX_TEMPLATE = [
    {
      Header: instrumentIdHeader,
      minWidth: 160,
      accessor: 'instrumentId',
    },
    {
      Header: getTradeMethodHeader(serviceId, isManual),
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)] || '-',
      disableSortBy: true,
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
      disableSortBy: true,
    },
    {
      Header: commonSortHeader({ label: '数量(万)' }),
      accessor: 'quantity',
      isNumber: true,
      Footer: quantityFooter,
    },
    {
      Header: commonSortHeader({ label: '注文中数量(万)' }),
      accessor: 'settlingQuantity',
      minWidth: 120,
      isNumber: true,
    },
    {
      Header: commonSortHeader({ label: '取引価格' }),
      accessor: 'tradePrice',
      Cell: InstrumentPrecisionTableCell,
      isNumber: true,
    },
    {
      Header: '現在価格',
      accessor: 'currentPrice',
      Cell: ({
        row: {
          original: { instrumentId, side },
        },
      }) => <CurrentPriceCellValue instrumentId={instrumentId} side={side} />,
      isNumber: true,
      disableSortBy: true,
    },
    {
      id: 'pl',
      Header: commonSortHeader({ label: '評価損益(円)' }),
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({
        row: {
          original: { instrumentId, positionId },
        },
      }) => <UnrealizedProfitLossCellValue instrumentId={instrumentId} positionId={positionId} isToFormat />,
      Footer: () => <TradeInfoTotalPositionsPl isManual={isManual} />,
    },
    {
      id: 'unrealizedSwapPl',
      Header: commonSortHeader({ label: '未実現スワップ(円)' }),
      accessor: createFlooredAccessor('unrealizedSwapPl'),
      minWidth: 150,
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      Footer: sumFooter({ accessor: 'unrealizedSwapPl' }),
    },
    {
      id: 'totalPl',
      Header: (
        <span>
          評価損益(円)
          <br />
          (スワップ含む)
        </span>
      ),
      accessor: createFlooredAccessor('totalPl'),
      Cell: ({
        row: {
          original: { instrumentId, positionId, unrealizedSwapPl },
        },
      }) => (
        <TotalPlCellValue
          instrumentId={instrumentId}
          positionId={positionId}
          unrealizedSwapPl={unrealizedSwapPl}
          isToFormat
        />
      ),
      isNumber: true,
      minWidth: 120,
      disableSortBy: true,
      Footer: () => <TradeInfoTotalPl isManual={isManual} />,
    },
    {
      Header: commonDateSortHeader({ label: '約定日時' }),
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
    {
      Header: '注文名',
      accessor: 'apName',
      isOrderName: true,
      Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
      disableSortBy: true,
    },
  ];

  const POSITIONS_TABLE_ETF_TEMPLATE = [
    {
      Header: instrumentIdHeader,
      minWidth: 160,
      accessor: 'instrumentId',
      Cell: ({ cell: { value } }) => <ShortName instrumentId={value} />,
    },
    {
      Header: getTradeMethodHeader(serviceId, isManual),
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)] || '-',
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
    },
    {
      Header: commonSortHeader({ label: '数量(口)' }),
      accessor: 'quantity',
      isNumber: true,
      Footer: quantityFooter,
    },
    {
      Header: commonSortHeader({ label: '注文中数量(口)' }),
      accessor: 'settlingQuantity',
      minWidth: 120,
      isNumber: true,
    },
    {
      Header: commonSortHeader({ label: '取引価格' }),
      accessor: 'tradePrice',
      Cell: InstrumentPrecisionTableCell,
      isNumber: true,
    },
    {
      Header: '現在価格',
      accessor: 'currentPrice',
      Cell: ({
        row: {
          original: { instrumentId, side },
        },
      }) => <CurrentPriceCellValue instrumentId={instrumentId} side={side} />,
      isNumber: true,
      disableSortBy: true,
    },
    {
      id: 'pl',
      Header: commonSortHeader({ label: '評価損益(円)' }),
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({
        row: {
          original: { instrumentId, positionId },
        },
      }) => <UnrealizedProfitLossCellValue instrumentId={instrumentId} positionId={positionId} isToFormat />,
      Footer: () => <TradeInfoTotalPositionsPl isManual={isManual} />,
    },
    {
      Header: commonSortHeader({ label: '未実現金利(円)' }),
      accessor: 'unrealizedSwapPl',
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      minWidth: 120,
      disableSortBy: true,
      Footer: sumFooter({ accessor: 'unrealizedSwapPl' }),
    },
    {
      id: 'totalPl',
      Header: (
        <span style={{ textAlign: 'left' }}>
          評価損益(円)
          <br />
          (未実現金利含む)
        </span>
      ),
      accessor: createFlooredAccessor('totalPl'),
      Cell: ({
        row: {
          original: { instrumentId, positionId, unrealizedSwapPl },
        },
      }) => (
        <TotalPlCellValue
          instrumentId={instrumentId}
          positionId={positionId}
          unrealizedSwapPl={unrealizedSwapPl}
          isToFormat
        />
      ),
      isNumber: true,
      minWidth: 120,
      disableSortBy: true,
      Footer: () => <TradeInfoTotalPl isManual={isManual} />,
    },
    {
      id: 'dividend',
      Header: commonSortHeader({ label: '分配相当額(円)' }),
      minWidth: 150,
      accessor: createFlooredAccessor('dividend'),
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      Footer: sumFooter({ accessor: 'dividend' }),
    },
    {
      Header: commonDateSortHeader({ label: '約定日時' }),
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
    {
      Header: '注文名',
      accessor: 'apName',
      isOrderName: true,
      Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
      disableSortBy: true,
    },
  ];

  const POSITIONS_TABLE_CFD_TEMPLATE = [
    {
      Header: instrumentIdHeader,
      minWidth: 160,
      accessor: 'instrumentId',
      Cell: ({ cell: { value } }) => <ShortName instrumentId={value} />,
    },
    {
      Header: getTradeMethodHeader(serviceId, isManual),
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)] || '-',
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
    },
    {
      Header: commonSortHeader({ label: '数量(Lot)' }),
      accessor: 'quantity',
      isNumber: true,
      Footer: quantityFooter,
    },
    {
      Header: commonSortHeader({ label: '注文中数量(Lot)' }),
      accessor: 'settlingQuantity',
      minWidth: 120,
      isNumber: true,
    },
    {
      Header: commonSortHeader({ label: '取引価格' }),
      accessor: 'tradePrice',
      Cell: InstrumentPrecisionTableCell,
      isNumber: true,
    },
    {
      Header: '現在価格',
      accessor: 'currentPrice',
      Cell: ({
        row: {
          original: { instrumentId, side },
        },
      }) => <CurrentPriceCellValue instrumentId={instrumentId} side={side} />,
      isNumber: true,
      disableSortBy: true,
    },
    {
      id: 'pl',
      Header: commonSortHeader({ label: '評価損益(円)' }),
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({
        row: {
          original: { instrumentId, positionId },
        },
      }) => <UnrealizedProfitLossCellValue instrumentId={instrumentId} positionId={positionId} isToFormat />,
      Footer: () => <TradeInfoTotalPositionsPl isManual={isManual} />,
    },
    {
      Header: commonSortHeader({
        label: (
          <span style={{ textAlign: 'left' }}>
            未実現金利・
            <br />
            配当相当額(円)
          </span>
        ),
      }),
      accessor: 'unrealizedSwapPl',
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      minWidth: 120,
      disableSortBy: true,
      Footer: sumFooter({ accessor: 'unrealizedSwapPl' }),
    },
    {
      id: 'totalPl',
      Header: (
        <span style={{ textAlign: 'left' }}>
          評価損益(円)
          <br />
          (未実現金利・配当相当額含む)
        </span>
      ),
      accessor: createFlooredAccessor('totalPl'),
      Cell: ({
        row: {
          original: { instrumentId, positionId, unrealizedSwapPl },
        },
      }) => (
        <TotalPlCellValue
          instrumentId={instrumentId}
          positionId={positionId}
          unrealizedSwapPl={unrealizedSwapPl}
          isToFormat
        />
      ),
      isNumber: true,
      minWidth: 120,
      disableSortBy: true,
      Footer: () => <TradeInfoTotalPl isManual={isManual} />,
    },
    {
      Header: commonDateSortHeader({ label: '約定日時' }),
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
    {
      Header: '注文名',
      accessor: 'apName',
      isOrderName: true,
      Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
      disableSortBy: true,
    },
  ];
  if (serviceId === FX) {
    return POSITIONS_TABLE_FX_TEMPLATE;
  }
  if (serviceId === ETF) {
    return POSITIONS_TABLE_ETF_TEMPLATE;
  }
  if (serviceId === CFD) {
    return POSITIONS_TABLE_CFD_TEMPLATE;
  }
  return [];
};

export const getModalPositionsTableTemplate = (serviceId) => {
  const MODAL_POSITIONS_TABLE_FX_TEMPLATE = [
    {
      Header: '銘柄',
      accessor: 'instrumentId',
    },
    {
      Header: '種類',
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)] || '-',
      disableSortBy: true,
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
      disableSortBy: true,
    },
    {
      Header: commonSortHeader({ label: '数量(万)' }),
      accessor: 'quantity',
      isNumber: true,
      Footer: quantityFooter,
    },
    {
      Header: commonSortHeader({ label: '注文中数量(万)' }),
      accessor: 'settlingQuantity',
      isNumber: true,
    },
    {
      Header: commonSortHeader({ label: '取引価格' }),
      accessor: 'tradePrice',
      Cell: InstrumentPrecisionTableCell,
      isNumber: true,
    },
    {
      Header: '現在価格',
      accessor: 'currentPrice',
      Cell: ({
        row: {
          original: { instrumentId, side },
        },
        // for tutorial prop
        cell: { value },
      }) => <CurrentPriceCellValue instrumentId={instrumentId} side={side} value={value} />,
      isNumber: true,
      disableSortBy: true,
    },
    {
      id: 'pl',
      Header: commonSortHeader({ label: '評価損益(円)' }),
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({
        row: {
          original: { instrumentId, positionId },
        },
        // for tutorial prop
        cell: { value },
      }) => (
        <UnrealizedProfitLossCellValue instrumentId={instrumentId} positionId={positionId} value={value} isToFormat />
      ),
      Footer: sumPlFooter(),
    },
    {
      id: 'unrealizedSwapPl',
      Header: commonSortHeader({ label: '未実現スワップ(円)' }),
      accessor: createFlooredAccessor('unrealizedSwapPl'),
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      Footer: sumFooter({ accessor: 'unrealizedSwapPl' }),
    },
    {
      Header: commonDateSortHeader({ label: '約定日時' }),
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
    {
      Header: '注文名',
      accessor: 'apName',
      isOrderName: true,
      Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
      disableSortBy: true,
    },
  ];

  const MODAL_POSITIONS_TABLE_ETF_TEMPLATE = [
    {
      Header: '銘柄',
      accessor: 'instrumentId',
      Cell: ({ cell: { value } }) => <ShortName instrumentId={value} />,
    },
    {
      Header: '種類',
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)] || '-',
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
    },
    {
      Header: commonSortHeader({ label: '数量(口)' }),
      accessor: 'quantity',
      isNumber: true,
      Footer: quantityFooter,
    },
    {
      Header: commonSortHeader({ label: '注文中数量(口)' }),
      accessor: 'settlingQuantity',
      isNumber: true,
    },
    {
      Header: commonSortHeader({ label: '取引価格' }),
      accessor: 'tradePrice',
      Cell: InstrumentPrecisionTableCell,
      isNumber: true,
    },
    {
      Header: '現在価格',
      accessor: 'currentPrice',
      Cell: ({
        row: {
          original: { instrumentId, side },
        },
      }) => <CurrentPriceCellValue instrumentId={instrumentId} side={side} />,
      isNumber: true,
      disableSortBy: true,
    },
    {
      id: 'pl',
      Header: commonSortHeader({ label: '評価損益(円)' }),
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({
        row: {
          original: { instrumentId, positionId },
        },
      }) => <UnrealizedProfitLossCellValue instrumentId={instrumentId} positionId={positionId} isToFormat />,
      Footer: sumPlFooter(),
    },
    {
      Header: commonSortHeader({ label: '未実現金利(円)' }),
      accessor: 'unrealizedSwapPl',
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      minWidth: 95,
      disableSortBy: true,
      Footer: sumFooter({ accessor: 'unrealizedSwapPl' }),
    },
    {
      id: 'dividend',
      Header: commonSortHeader({ label: '分配相当額(円)' }),
      accessor: createFlooredAccessor('dividend'),
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      Footer: sumFooter({ accessor: 'dividend' }),
    },
    {
      Header: commonDateSortHeader({ label: '約定日時' }),
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
    {
      Header: '注文名',
      accessor: 'apName',
      isOrderName: true,
      Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
      disableSortBy: true,
    },
  ];

  const MODAL_POSITIONS_TABLE_CFD_TEMPLATE = [
    {
      Header: '銘柄',
      accessor: 'instrumentId',
      Cell: ({ cell: { value } }) => <ShortName instrumentId={value} />,
    },
    {
      Header: '種類',
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)] || '-',
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
    },
    {
      Header: commonSortHeader({ label: '数量(Lot)' }),
      accessor: 'quantity',
      isNumber: true,
      Footer: quantityFooter,
    },
    {
      Header: commonSortHeader({ label: '注文中数量(Lot)' }),
      accessor: 'settlingQuantity',
      isNumber: true,
    },
    {
      Header: commonSortHeader({ label: '取引価格' }),
      accessor: 'tradePrice',
      Cell: InstrumentPrecisionTableCell,
      isNumber: true,
    },
    {
      Header: '現在価格',
      accessor: 'currentPrice',
      Cell: ({
        row: {
          original: { instrumentId, side },
        },
      }) => <CurrentPriceCellValue instrumentId={instrumentId} side={side} />,
      isNumber: true,
      disableSortBy: true,
    },
    {
      id: 'pl',
      Header: commonSortHeader({ label: '評価損益(円)' }),
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({
        row: {
          original: { instrumentId, positionId },
        },
      }) => <UnrealizedProfitLossCellValue instrumentId={instrumentId} positionId={positionId} isToFormat />,
      Footer: sumPlFooter(),
    },
    {
      Header: commonSortHeader({
        label: (
          <span style={{ textAlign: 'left' }}>
            未実現金利・
            <br />
            配当相当額(円)
          </span>
        ),
      }),
      accessor: 'unrealizedSwapPl',
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      minWidth: 95,
      disableSortBy: true,
      Footer: sumFooter({ accessor: 'unrealizedSwapPl' }),
    },
    {
      Header: commonDateSortHeader({ label: '約定日時' }),
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
    {
      Header: '注文名',
      accessor: 'apName',
      isOrderName: true,
      Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
      disableSortBy: true,
    },
  ];
  if (serviceId === FX) {
    return MODAL_POSITIONS_TABLE_FX_TEMPLATE;
  }
  if (serviceId === ETF) {
    return MODAL_POSITIONS_TABLE_ETF_TEMPLATE;
  }
  if (serviceId === CFD) {
    return MODAL_POSITIONS_TABLE_CFD_TEMPLATE;
  }
  return [];
};

export const getExecutionsTableTemplate = (serviceId, isManual) => {
  const EXECUTIONS_TABLE_FX_TEMPLATE = [
    {
      Header: instrumentIdHeader,
      accessor: 'instrumentId',
    },
    {
      Header: isCloseHeader,
      accessor: 'isClose',
      Cell: ({ cell: { value } }) => NEW_SETTLEMENT_VALUE[Number(value)],
    },
    {
      Header: getTradeMethodHeader(serviceId, isManual),
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)],
      disableSortBy: true,
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
    },
    {
      Header: '数量(万)',
      accessor: 'quantity',
      isNumber: true,
      disableSortBy: true,
      Footer: serverEachSideSumFooter(),
    },
    {
      Header: '取引価格',
      accessor: 'execPrice',
      isNumber: true,
      disableSortBy: true,
      Cell: InstrumentPrecisionTableCell,
    },
    {
      id: 'pl',
      Header: '実現損益(円)',
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedPl' }),
    },
    {
      id: 'swapPl',
      Header: '累計スワップ(円)',
      accessor: createFlooredAccessor('swapPl'),
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedSwap' }),
    },
    {
      Header: executionTimeFilterHeader,
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
  ];

  const EXECUTIONS_TABLE_ETF_TEMPLATE = [
    {
      Header: instrumentIdHeader,
      accessor: 'instrumentId',
      Cell: ({ cell: { value } }) => <ShortName instrumentId={value} />,
    },
    {
      Header: isCloseHeader,
      accessor: 'isClose',
      Cell: ({ cell: { value } }) => NEW_SETTLEMENT_VALUE[Number(value)],
    },
    {
      Header: getTradeMethodHeader(serviceId, isManual),
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)],
      disableSortBy: true,
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
    },
    {
      Header: '数量(口)',
      accessor: 'quantity',
      isNumber: true,
      disableSortBy: true,
      Footer: serverEachSideSumFooter(),
    },
    {
      Header: '取引価格',
      accessor: 'execPrice',
      isNumber: true,
      disableSortBy: true,
      Cell: InstrumentPrecisionTableCell,
    },
    {
      id: 'pl',
      Header: '実現損益(円)',
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedPl' }),
    },
    {
      id: 'dividend',
      Header: '分配相当額(円)',
      accessor: createFlooredAccessor('dividend'),
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedDividend' }),
    },
    {
      Header: '金利等(円)',
      accessor: 'swapPl',
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedSwap' }),
    },
    {
      Header: executionTimeFilterHeader,
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
  ];

  const EXECUTIONS_TABLE_CFD_TEMPLATE = [
    {
      Header: instrumentIdHeader,
      accessor: 'instrumentId',
      Cell: ({ cell: { value } }) => <ShortName instrumentId={value} />,
    },
    {
      Header: isCloseHeader,
      accessor: 'isClose',
      Cell: ({ cell: { value } }) => NEW_SETTLEMENT_VALUE[Number(value)],
    },
    {
      Header: getTradeMethodHeader(serviceId, isManual),
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)],
      disableSortBy: true,
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
    },
    {
      Header: '数量(Lot)',
      accessor: 'quantity',
      isNumber: true,
      disableSortBy: true,
      Footer: serverEachSideSumFooter(),
    },
    {
      Header: '取引価格',
      accessor: 'execPrice',
      isNumber: true,
      disableSortBy: true,
      Cell: InstrumentPrecisionTableCell,
    },
    {
      id: 'pl',
      Header: '実現損益(円)',
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedPl' }),
    },
    {
      Header: '金利・配当相当額(円)',
      accessor: 'swapPl',
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedSwap' }),
    },
    {
      Header: executionTimeFilterHeader,
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
  ];
  if (serviceId === FX) {
    return EXECUTIONS_TABLE_FX_TEMPLATE;
  }
  if (serviceId === ETF) {
    return EXECUTIONS_TABLE_ETF_TEMPLATE;
  }
  if (serviceId === CFD) {
    return EXECUTIONS_TABLE_CFD_TEMPLATE;
  }
  return [];
};

export const getModalExecutionsTableTemplate = (serviceId) => {
  const MODAL_EXECUTIONS_TABLE_FX_TEMPLATE = [
    {
      Header: '銘柄',
      accessor: 'instrumentId',
    },
    {
      Header: isCloseHeader,
      accessor: 'isClose',
      Cell: ({ cell: { value } }) => NEW_SETTLEMENT_VALUE[Number(value)],
    },
    {
      Header: MAPPING_TABLE_COLUMN_NAMES_MAIN.TRADE_METHOD.LABEL,
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)],
      disableSortBy: true,
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
    },
    {
      Header: '数量(万)',
      accessor: 'quantity',
      isNumber: true,
      disableSortBy: true,
      Footer: serverEachSideSumFooter(),
    },
    {
      Header: '取引価格',
      accessor: 'execPrice',
      isNumber: true,
      disableSortBy: true,
    },
    {
      id: 'pl',
      Header: '実現損益(円)',
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedPl' }),
    },
    {
      id: 'swapPl',
      Header: '累計スワップ(円)',
      accessor: createFlooredAccessor('swapPl'),
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedSwap' }),
    },
    {
      Header: executionTimeFilterHeader,
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
  ];

  const MODAL_EXECUTIONS_TABLE_ETF_TEMPLATE = [
    {
      Header: '銘柄',
      accessor: 'instrumentId',
      Cell: ({ cell: { value } }) => <ShortName instrumentId={value} />,
    },
    {
      Header: isCloseHeader,
      accessor: 'isClose',
      Cell: ({ cell: { value } }) => NEW_SETTLEMENT_VALUE[Number(value)],
    },
    {
      Header: '種類',
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)],
      disableSortBy: true,
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
    },
    {
      Header: '数量(口)',
      accessor: 'quantity',
      isNumber: true,
      disableSortBy: true,
      Footer: serverEachSideSumFooter(),
    },
    {
      Header: '取引価格',
      accessor: 'execPrice',
      isNumber: true,
      disableSortBy: true,
      Cell: InstrumentPrecisionTableCell,
    },
    {
      id: 'pl',
      Header: '実現損益(円)',
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedPl' }),
    },
    {
      id: 'dividend',
      Header: '分配相当額(円)',
      accessor: createFlooredAccessor('dividend'),
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedDividend' }),
    },
    {
      Header: '金利等(円)',
      accessor: 'swapPl',
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedSwap' }),
    },
    {
      Header: executionTimeFilterHeader,
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
  ];

  const MODAL_EXECUTIONS_TABLE_CFD_TEMPLATE = [
    {
      Header: '銘柄',
      accessor: 'instrumentId',
      Cell: ({ cell: { value } }) => <ShortName instrumentId={value} />,
    },
    {
      Header: isCloseHeader,
      accessor: 'isClose',
      Cell: ({ cell: { value } }) => NEW_SETTLEMENT_VALUE[Number(value)],
    },
    {
      Header: '種類',
      accessor: 'tradeMethod',
      Cell: ({ cell: { value } }) => TRADE_METHOD_VALUE[Number(value)],
      disableSortBy: true,
    },
    {
      Header: sideHeader,
      accessor: 'side',
      Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
    },
    {
      Header: '数量(Lot)',
      accessor: 'quantity',
      isNumber: true,
      disableSortBy: true,
      Footer: serverEachSideSumFooter(),
    },
    {
      Header: '取引価格',
      accessor: 'execPrice',
      isNumber: true,
      disableSortBy: true,
      Cell: InstrumentPrecisionTableCell,
    },
    {
      id: 'pl',
      Header: '実現損益(円)',
      accessor: createFlooredAccessor('pl'),
      isNumber: true,
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedPl' }),
    },
    {
      Header: '金利・配当相当額(円)',
      accessor: 'swapPl',
      Cell: ({ cell: { value } }) => (value ? <HighlightValue value={value} isToFormat /> : '-'),
      isNumber: true,
      disableSortBy: true,
      Footer: serverSumFooter({ summarizedField: 'summarizedSwap' }),
    },
    {
      Header: executionTimeFilterHeader,
      accessor: 'execTime',
      Cell: ({ cell: { value } }) => formatDateTimeWithoutDivider(value) || '-',
      isNumber: true,
    },
  ];

  if (serviceId === FX) {
    return MODAL_EXECUTIONS_TABLE_FX_TEMPLATE;
  }
  if (serviceId === ETF) {
    return MODAL_EXECUTIONS_TABLE_ETF_TEMPLATE;
  }
  if (serviceId === CFD) {
    return MODAL_EXECUTIONS_TABLE_CFD_TEMPLATE;
  }
  return [];
};

export const getSettingsTableTemplate = (serviceId = FX) => {
  let handlePips;

  switch (serviceId) {
    case FX: {
      handlePips = ({ cell: { value } }) => value || '-';
      break;
    }
    case ETF: {
      handlePips = InstrumentPrecisionTableCell;
      break;
    }
    // TODO CFD 暫定で ETF のコピー
    case CFD: {
      handlePips = InstrumentPrecisionTableCell;
      break;
    }
    case SPECIAL: {
      handlePips = PipsSpecialTableCell;
      break;
    }
    case MIXED: {
      handlePips = PipsSpecialTableCell;
      break;
    }
    default: {
      handlePips = ({ cell: { value } }) => value || '-';
    }
  }

  return [
    { Header: '銘柄', accessor: 'instrumentDisplayName', Cell },
    {
      Header: '注文名',
      accessor: 'name',
      Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
    },
    {
      Header: '売買',
      accessor: 'side',
      maxWidth: 60,
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value } }) => (value ? <BuySellItem type={Number(value)} /> : '-'),
    },
    {
      Header: '数量(万)',
      accessor: 'quantity',
      Cell: ({
        row: {
          original: { quantity, strategySets },
        },
      }) => (quantity && strategySets ? roundTo3DigitsAfterDot(quantity * strategySets) : '-'),
      isNumber: true,
    },
    {
      Header: 'エントリー 価格1',
      accessor: 'entryPrice1',
      isNumber: true,
      Cell: InstrumentPrecisionTableCell,
    },
    {
      Header: 'エントリー 価格2',
      accessor: 'entryPrice2',
      isNumber: true,
      Cell: InstrumentPrecisionTableCell,
    },
    {
      Header: '利確幅(pips)',
      accessor: 'tp',
      isNumber: true,
      Cell: handlePips,
    },
    {
      Header: '損切幅(pips)',
      accessor: 'sl',
      isNumber: true,
      Cell: handlePips,
    },
    {
      Header: 'フォロー値',
      accessor: 'follow',
      isNumber: true,
      Cell: handlePips,
    },
    {
      Header: 'カウンター値(価格指定)',
      accessor: 'counter',
      isNumber: true,
      disableSortBy: true,
      // eslint-disable-next-line react/prop-types
      Cell: CounterValueCell,
      minWidth: 90,
    },
  ];
};

export const getSettingsTableColumns = () => {
  return [
    { label: '銘柄', path: 'instrumentDisplayName' },
    {
      label: '注文名',
      path: 'name',
      content: (item) => orderNameWithToolTips(item.name),
    },
    {
      label: '売買',
      path: 'side',
      content: ({ side }) => (side ? <BuySellItem type={Number(side)} /> : '-'),
    },
    {
      label: '数量(万/Lot/口)',
      path: 'quantity',
      content: ({ quantity, strategySets }) =>
        quantity && strategySets ? roundTo3DigitsAfterDot(quantity * strategySets) : '-',
    },
    {
      label: 'エントリー 価格1/2',
      path: 'entryPrice1',
      content: ({ entryPrice1, entryPrice2, instrumentId }) => (
        <>
          <ValueWithPrecision
            value={Number.isFinite(Number(entryPrice1)) && entryPrice1 !== null ? Number(entryPrice1) : null}
            instrumentId={instrumentId}
          />{' '}
          /{' '}
          <ValueWithPrecision
            value={Number.isFinite(Number(entryPrice2)) && entryPrice2 !== null ? Number(entryPrice2) : null}
            instrumentId={instrumentId}
          />
        </>
      ),
    },
    {
      label: '利確幅(pips)',
      path: 'tp',
      content: ({ tp: value, instrumentId, serviceId }) => {
        if (serviceId === FX) {
          return value || '-';
        }

        return (
          <ValueWithPrecision
            value={Number.isFinite(Number(value)) && value !== null ? Number(value) : null}
            instrumentId={instrumentId}
          />
        );
      },
    },
    {
      label: '損切幅(pips)',
      path: 'sl',
      content: ({ sl: value, instrumentId, serviceId }) => {
        if (serviceId === FX) {
          return value || '-';
        }

        return (
          <ValueWithPrecision
            value={Number.isFinite(Number(value)) && value !== null ? Number(value) : null}
            instrumentId={instrumentId}
          />
        );
      },
    },
    {
      label: 'フォロー値',
      path: 'follow',
      content: ({ follow: value, instrumentId, serviceId }) => {
        if (serviceId === FX) {
          return value || '-';
        }

        return (
          <ValueWithPrecision
            value={Number.isFinite(Number(value)) && value !== null ? Number(value) : null}
            instrumentId={instrumentId}
          />
        );
      },
    },
    {
      label: 'カウンター値(価格指定)',
      path: 'counter',
      // eslint-disable-next-line
      content: (item) => <CounterValue {...item} />,
    },
  ];
};

export const getCartTableTemplate = () => {
  return [
    { Header: '銘柄', accessor: 'instrumentDisplayName', Cell },
    {
      Header: '注文名',
      accessor: 'name',
      Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
    },
    {
      Header: '売買',
      accessor: 'side',
      maxWidth: 60,
      // eslint-disable-next-line react/prop-types
      Cell: ({ cell: { value } }) => (value ? <BuySellItem type={Number(value)} /> : '-'),
    },
    {
      Header: '数量(万)',
      accessor: 'quantity',
      Cell: ({
        row: {
          original: { quantity, strategySets },
        },
      }) => (quantity && strategySets ? roundTo3DigitsAfterDot(quantity * strategySets) : '-'),
      isNumber: true,
    },
    {
      Header: 'エントリー 価格1',
      accessor: 'entryPrice1',
      isNumber: true,
      Cell: ({ cell }) => {
        if (cell.value?.includes(TECH_BASE_PRICE_LITERAL)) {
          return <TechEntryPriceTableCell cell={cell} />;
        }
        return <InstrumentPrecisionTableCell cell={cell} />;
      },
    },
    {
      Header: 'エントリー 価格2',
      accessor: 'entryPrice2',
      isNumber: true,
      Cell: ({ cell }) => {
        return <InstrumentPrecisionTableCell cell={cell} />;
      },
    },
    {
      Header: '利確幅(pips)',
      accessor: 'tp',
      isNumber: true,
      Cell: PipsSpecialTableCell,
    },
    {
      Header: '損切幅(pips)',
      accessor: 'sl',
      isNumber: true,
      Cell: PipsSpecialTableCell,
    },
    {
      Header: 'フォロー値',
      accessor: 'follow',
      isNumber: true,
      Cell: PipsSpecialTableCell,
    },
    {
      Header: 'カウンター値(価格指定)',
      accessor: 'counter',
      isNumber: true,
      disableSortBy: true,
      Cell: ({ cell }) => {
        if (cell.row.original.counterPrice?.includes(TECH_BASE_PRICE_LITERAL)) {
          return (
            <TechCounterPriceTableCell
              counterPrice={cell.row.original?.counterPrice}
              instrumentId={cell.row.original?.instrumentId}
            />
          );
        }
        return <CounterValueCell cell={cell} />;
      },
      minWidth: 90,
    },
  ];
};
