import {
  GET_NOTIFICATION_LIST_SUCCESS,
  OPEN_ADVERTISEMENT_SUCCESS,
  CLOSE_ADVERTISEMENT,
} from '../actionConstants/advertisementConstants';

const emptyActiveAdvertisementInfo = {
  body: null,
  popupUrl: null,
};

export const initialState = {
  isLoading: true,
  advertisementsList: [],
  isOpen: false,
  activeAdvertisementInfo: emptyActiveAdvertisementInfo,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NOTIFICATION_LIST_SUCCESS: {
      const { advertisementsList } = payload;
      return {
        ...state,
        advertisementsList,
        isLoading: false,
      };
    }
    case OPEN_ADVERTISEMENT_SUCCESS: {
      const { body, popupUrl } = payload;
      return {
        ...state,
        isOpen: true,
        activeAdvertisementInfo: {
          body,
          popupUrl,
        },
      };
    }
    case CLOSE_ADVERTISEMENT: {
      return {
        ...state,
        isOpen: false,
        activeAdvertisementInfo: emptyActiveAdvertisementInfo,
      };
    }
    default: {
      return state;
    }
  }
};
