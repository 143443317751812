import { TAB_NEWS } from '../../constants/message';
import {
  CHECK_INITIAL_DEPOSIT_SUCCESS,
  CLEAR_MESSAGES,
  GET_MESSAGES_REQUEST_START_LOADING,
  GET_MESSAGES_REQUEST_STOP_LOADING,
  GET_MESSAGES_SUCCESS,
  GET_RANGE_OUTS_SUCCESS,
  GET_UNREAD_COUNT_SUCCESS,
  REFRESH_MESSAGES_SUCCESS,
  SET_CURRENT_TAB,
  START_AUTO_REFRESH_MESSAGE,
  STOP_AUTO_REFRESH_MESSAGE,
} from '../actionConstants/messageConstants';

export const initialState = () => ({
  unreadCount: 0,
  messages: [],
  loading: false,
  autoRefresh: false,
  currentTab: TAB_NEWS,
  initialDepositState: {},
  rangeOutState: [],
});

export default (state = initialState(), action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        unreadCount: payload.unreadCount,
      };
    case GET_MESSAGES_REQUEST_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_MESSAGES_REQUEST_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case GET_MESSAGES_SUCCESS:
    case REFRESH_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: payload.messages,
      };
    case START_AUTO_REFRESH_MESSAGE:
      return {
        ...state,
        autoRefresh: true,
      };
    case STOP_AUTO_REFRESH_MESSAGE:
      return {
        ...state,
        autoRefresh: false,
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        messages: [],
      };
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: payload.currentTab,
      };
    case CHECK_INITIAL_DEPOSIT_SUCCESS:
      return {
        ...state,
        initialDepositState: payload.initialDepositState,
      };
    case GET_RANGE_OUTS_SUCCESS:
      return {
        ...state,
        rangeOutState: payload.rangeOutState,
      };
    default:
      return state;
  }
};
