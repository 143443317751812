import {
  CFD,
  ETF,
  EXPIRATION_TYPE_MAIN,
  FX,
  LEARN_TRIAUTO_MENU,
  LEARN_TRIAUTO_STATUS,
  ORDER_TYPES_MAIN,
} from '../../constants';
import {
  GET_INSTRUMENTS_LIST_START_LOADING,
  GET_INSTRUMENTS_LIST_STOP_LOADING,
  GET_INSTRUMENTS_LIST_SUCCESS,
  UPDATE_DISPLAYING_CURRENCY_PAIR,
  GET_ACCOUNT_INFO_START_LOADING,
  GET_ACCOUNT_INFO_STOP_LOADING,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_SETTINGS_START_LOADING,
  GET_SETTINGS_STOP_LOADING,
  GET_SETTINGS_SUCCESS,
  TOGGLE_USER_SETTING_CROSS_ORDER,
  GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_SUCCESS,
  GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_SUCCESS,
  GET_ORDER_SETTINGS_SUCCESS,
  GET_ORDER_SETTINGS_START_LOADING,
  GET_ORDER_SETTINGS_STOP_LOADING,
  UPDATE_ORDER_SETTINGS_SUCCESS,
  UPDATE_ORDER_SETTINGS_START_LOADING,
  UPDATE_ORDER_SETTINGS_STOP_LOADING,
  ACCEPT_AGREEMENT_INFO_REQUEST_START_LOADING,
  ACCEPT_AGREEMENT_INFO_REQUEST_END_LOADING,
  GET_DISPLAYING_CURRENCY_PAIR_SUCCESS,
  CHANGE_MARKET_STATUS,
  GET_USER_MAIL_SETTINGS_SUCCESS,
  UPDATE_USER_MAIL_SETTINGS_SUCCESS,
  UPDATE_DISCLAIMER_FLG,
  GET_LOGIN_METHOD_SUCCESS,
  UPDATE_SELECTION_MAINTENANCE_STATUS,
  GET_USER_MAIL_SETTINGS_START_LOADING,
  GET_USER_MAIL_SETTINGS_STOP_LOADING,
  UPDATE_SERVICE_MAINTENANCE_STATUS,
  CHANGE_LOSSCUT_STATUS,
  GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST_SUCCESS,
  UPDATE_USER_LEARN_TRIAUTO_STATUS,
  UPDATE_IS_FIRST_LOGIN,
} from '../actionConstants/settingsConstants';

const initialStateForAppType = {
  skipNewOrderConfirmation: false,
  skipCloseOrderConfirmation: false,
  isCrossOrder: true,
  orderSettings: {
    orderType: ORDER_TYPES_MAIN.MARKET_ORDER.ID,
    quantity: 0,
    expirationType: EXPIRATION_TYPE_MAIN.DAY.ID,
  },
  displayedInstruments: [],
  isMaintenance: false,
  labForbiddenInstruments: '',
};

export const initialState = {
  instrumentList: {},
  instrumentListIsLoading: false,
  accountInfo: {
    [FX]: {
      marginGroupId: '',
      disclaimerReadFlg: true,
      disclaimerReadFlgMobile: null,
      losscutStatus: 0,
      status: 0,
      canTradeType: 0,
    },
    [ETF]: null,
    [CFD]: null,
  },
  maintenanceInfo: {
    [FX]: {
      isMaintenance: false,
    },
    [ETF]: {
      isMaintenance: false,
    },
    [CFD]: {
      isMaintenance: false,
    },
  },
  accountInfoIsLoading: false,
  acceptAgreementInfoIsLoading: false,
  loginMethod: null,
  selectionMaintenanceStatus: 0,
  [FX]: initialStateForAppType,
  [ETF]: initialStateForAppType,
  [CFD]: initialStateForAppType,
  settingsIsLoading: false,
  loadingUpdateOrderSettings: false,
  loadingGetOrderSettings: false,
  isFirstLogin: false,
  mailSettings: {
    isLoading: false,
    addresses: {
      [FX]: {
        primaryMailAddress: '',
        secondaryMailAddress: '',
      },
      [ETF]: {
        primaryMailAddress: '',
        secondaryMailAddress: '',
      },
      [CFD]: {
        primaryMailAddress: '',
        secondaryMailAddress: '',
      },
    },
    mailActions: {
      [FX]: [],
      [ETF]: [],
      [CFD]: [],
    },
  },
  learnTriautoStatus: {
    beginnerReadStatusList: {
      beginner1: '1',
      beginner2: '1',
      beginner3: '1',
    },
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INSTRUMENTS_LIST_START_LOADING: {
      return {
        ...state,
        instrumentListIsLoading: true,
      };
    }
    case GET_INSTRUMENTS_LIST_STOP_LOADING: {
      return {
        ...state,
        instrumentListIsLoading: false,
      };
    }
    case GET_INSTRUMENTS_LIST_SUCCESS: {
      const { instrumentList } = payload;
      return {
        ...state,
        instrumentList,
      };
    }
    case GET_ACCOUNT_INFO_START_LOADING: {
      return {
        ...state,
        accountInfoIsLoading: true,
      };
    }
    case GET_ACCOUNT_INFO_STOP_LOADING: {
      return {
        ...state,
        accountInfoIsLoading: false,
      };
    }
    case GET_ACCOUNT_INFO_SUCCESS: {
      return {
        ...state,
        accountInfo: {
          [FX]: {
            ...state.accountInfo[FX],
            ...payload[FX],
            disclaimerReadFlgMobile: payload[FX]?.disclaimerReadFlg ?? true,
          },
          [ETF]: payload[ETF] ? { ...payload[ETF], disclaimerReadFlgMobile: payload[ETF].disclaimerReadFlg } : null,
          [CFD]: payload[CFD] ? { ...payload[CFD], disclaimerReadFlgMobile: payload[CFD].disclaimerReadFlg } : null,
        },
      };
    }
    case ACCEPT_AGREEMENT_INFO_REQUEST_START_LOADING: {
      return {
        ...state,
        acceptAgreementInfoIsLoading: true,
      };
    }
    case ACCEPT_AGREEMENT_INFO_REQUEST_END_LOADING: {
      return {
        ...state,
        acceptAgreementInfoIsLoading: false,
      };
    }
    case GET_LOGIN_METHOD_SUCCESS: {
      const { loginMethod } = payload;
      return {
        ...state,
        loginMethod,
      };
    }
    case GET_SETTINGS_START_LOADING: {
      return {
        ...state,
        settingsIsLoading: true,
      };
    }
    case GET_SETTINGS_STOP_LOADING: {
      return {
        ...state,
        settingsIsLoading: false,
      };
    }
    case GET_SETTINGS_SUCCESS: {
      const { loginMethod, selectionMaintenanceStatus, isFirstLogin } = payload;
      return {
        ...state,
        loginMethod,
        selectionMaintenanceStatus,
        [FX]: payload[FX],
        [ETF]: payload[ETF],
        [CFD]: payload[CFD],
        maintenanceInfo: {
          ...state.maintenanceInfo,
          [FX]: {
            isMaintenance: payload[FX]?.isMaintenance,
          },
          [ETF]: {
            isMaintenance: payload[ETF]?.isMaintenance,
          },
          [CFD]: {
            isMaintenance: payload[CFD]?.isMaintenance,
          },
        },
        isFirstLogin,
      };
    }
    case UPDATE_DISPLAYING_CURRENCY_PAIR: {
      const { array, serviceId } = payload;
      return {
        ...state,
        [serviceId]: {
          ...state[serviceId],
          displayedInstruments: array,
        },
      };
    }
    case UPDATE_DISCLAIMER_FLG: {
      return {
        ...state,
        accountInfo: {
          [FX]: {
            ...state.accountInfo[FX],
            disclaimerReadFlg: true,
          },
          [ETF]: {
            ...state.accountInfo[ETF],
            disclaimerReadFlg: true,
          },
          [CFD]: {
            ...state.accountInfo[CFD],
            disclaimerReadFlg: true,
          },
        },
      };
    }
    case UPDATE_IS_FIRST_LOGIN: {
      return {
        ...state,
        isFirstLogin: false,
      };
    }
    case GET_DISPLAYING_CURRENCY_PAIR_SUCCESS: {
      const { displayedInstruments, serviceId } = payload;
      return {
        ...state,
        [serviceId]: {
          ...state[serviceId],
          displayedInstruments,
        },
      };
    }
    case TOGGLE_USER_SETTING_CROSS_ORDER: {
      const { value, serviceId } = payload;
      return {
        ...state,
        [serviceId]: {
          ...state[serviceId],
          isCrossOrder: value,
        },
      };
    }
    case GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_SUCCESS: {
      const { value, serviceId } = payload;
      return {
        ...state,
        [serviceId]: {
          ...state[serviceId],
          skipNewOrderConfirmation: value,
        },
      };
    }
    case GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_SUCCESS: {
      const { value, serviceId } = payload;
      return {
        ...state,
        [serviceId]: {
          ...state[serviceId],
          skipCloseOrderConfirmation: value,
        },
      };
    }
    case GET_ORDER_SETTINGS_START_LOADING: {
      return {
        ...state,
        loadingGetOrderSettings: true,
      };
    }
    case GET_ORDER_SETTINGS_STOP_LOADING: {
      return {
        ...state,
        loadingGetOrderSettings: false,
      };
    }
    case GET_ORDER_SETTINGS_SUCCESS:
    case UPDATE_ORDER_SETTINGS_SUCCESS: {
      const { orderType, quantity, expirationType, serviceId } = payload;

      return {
        ...state,
        [serviceId]: {
          ...state[serviceId],
          orderSettings: {
            orderType,
            quantity,
            expirationType,
          },
        },
      };
    }
    case UPDATE_ORDER_SETTINGS_START_LOADING: {
      return {
        ...state,
        loadingUpdateOrderSettings: true,
      };
    }
    case UPDATE_ORDER_SETTINGS_STOP_LOADING: {
      return {
        ...state,
        loadingUpdateOrderSettings: false,
      };
    }
    case CHANGE_MARKET_STATUS: {
      const { status, serviceId, instrumentId } = payload;

      // TODO CFD ETF と同じ扱いで問題ないか要確認
      if (serviceId === ETF || serviceId === CFD) {
        return {
          ...state,
          instrumentList: {
            ...state.instrumentList,
            [instrumentId]: {
              ...state.instrumentList[instrumentId],
              marketStatus: status,
            },
          },
        };
      }

      return {
        ...state,
        instrumentList: Object.entries(state.instrumentList).reduce((acc, [innerInstrumentId, instrumentObject]) => {
          if (instrumentObject.serviceId === FX) {
            acc[innerInstrumentId] = {
              ...instrumentObject,
              marketStatus: status,
            };
          } else {
            acc[innerInstrumentId] = instrumentObject;
          }
          return acc;
        }, {}),
      };
    }
    case GET_USER_MAIL_SETTINGS_START_LOADING: {
      return {
        ...state,
        mailSettings: {
          ...state.mailSettings,
          isLoading: true,
        },
      };
    }
    case GET_USER_MAIL_SETTINGS_STOP_LOADING: {
      return {
        ...state,
        mailSettings: {
          ...state.mailSettings,
          isLoading: false,
        },
      };
    }
    case GET_USER_MAIL_SETTINGS_SUCCESS: {
      const { primaryMailAddress, secondaryMailAddress, mailActions, serviceId } = payload;

      return {
        ...state,
        mailSettings: {
          ...state.mailSettings,
          addresses: {
            ...state.mailSettings.addresses,
            [serviceId]: {
              primaryMailAddress,
              secondaryMailAddress,
            },
          },
          mailActions: {
            ...state.mailSettings.mailActions,
            [serviceId]: mailActions,
          },
        },
      };
    }
    case UPDATE_USER_MAIL_SETTINGS_SUCCESS: {
      const { primaryMailAddress, secondaryMailAddress, mailActions, serviceId } = payload;

      return {
        ...state,
        mailSettings: {
          ...state.mailSettings,
          addresses: {
            ...state.mailSettings.addresses,
            [serviceId]: {
              primaryMailAddress,
              secondaryMailAddress,
            },
          },
          mailActions: {
            ...state.mailSettings.mailActions,
            [serviceId]: mailActions,
          },
        },
      };
    }
    case UPDATE_SELECTION_MAINTENANCE_STATUS: {
      const { status } = payload;

      return {
        ...state,
        selectionMaintenanceStatus: status,
      };
    }
    case UPDATE_SERVICE_MAINTENANCE_STATUS: {
      const { serviceId, isMaintenance } = payload;

      return {
        ...state,
        [serviceId]: {
          ...state[serviceId],
          isMaintenance,
        },
        maintenanceInfo: {
          ...state.maintenanceInfo,
          [serviceId]: {
            isMaintenance,
          },
        },
      };
    }
    case CHANGE_LOSSCUT_STATUS: {
      const { status, serviceId } = payload;

      if (!state.accountInfo[serviceId]) {
        return state;
      }

      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          [serviceId]: {
            ...state.accountInfo[serviceId],
            losscutStatus: status,
          },
        },
      };
    }
    case GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST_SUCCESS: {
      const { value } = payload;
      return {
        ...state,
        learnTriautoStatus: value,
      };
    }
    case UPDATE_USER_LEARN_TRIAUTO_STATUS: {
      const { key } = payload;
      const readStatusList = LEARN_TRIAUTO_MENU.BEGINNER.NAME;
      const targetState = state.learnTriautoStatus[readStatusList];
      if (!targetState || targetState[key] == null) {
        return state;
      }
      targetState[key] = LEARN_TRIAUTO_STATUS.READ_STATUS.READ;
      return {
        ...state,
        learnTriautoStatus: {
          ...state.learnTriautoStatus,
          [readStatusList]: {
            ...state.learnTriautoStatus[readStatusList],
            [key]: targetState[key],
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};
