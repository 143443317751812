import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { COLORS } from 'shared-modules/constants';
import { resetData } from 'shared-modules/redux/cash';
import { CASH_PAGES, CASH_PAGE_TITLE } from 'shared-modules/constants/cash';

import { useCashPageTransition } from '../../../../../services/hooks';
import { push } from '../../../../../redux/actions';
import CustomButton from '../../../../../components/CustomButton';
import styles from './quickDepositSuccess.module.scss';
import { HOME } from '../../../../../constants';

const QuickDepositSuccess = () => {
  const dispatch = useDispatch();
  const pageTransition = useCashPageTransition();

  useEffect(() => {
    pageTransition(CASH_PAGES.CASH_QUICK_DEPOSIT_SUCCESS.ID);
  }, [pageTransition]);

  const handleRedirectToPortfolio = useCallback(() => {
    dispatch(resetData());
    setTimeout(() => {
      dispatch(push(`/${HOME}`));
    }, 300);
  }, [dispatch]);

  return (
    <div className={styles.pageWrapper}>
      <span className={styles.pageTitle}>{CASH_PAGE_TITLE.QUICK_DEPOSIT}</span>

      <div className={styles.description}>即時入金が完了しました。取引画面にて、口座状況をご確認ください。</div>

      <div className={styles.buttonRow}>
        <CustomButton color={COLORS.GREY} className={styles.button} onClick={handleRedirectToPortfolio} width={270}>
          ホームに戻る
        </CustomButton>
      </div>
    </div>
  );
};

export default memo(QuickDepositSuccess);
