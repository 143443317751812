export const UPDATE_DISPLAYING_CURRENCY_PAIR = 'SETTINGS/UPDATE_DISPLAYING_CURRENCY_PAIR';

export const GET_DISPLAYING_CURRENCY_PAIR_REQUEST = 'SETTINGS/GET_USER_RATE_PANEL_REQUEST';
export const GET_DISPLAYING_CURRENCY_PAIR_SUCCESS = 'SETTINGS/GET_USER_RATE_PANEL_SUCCESS';

export const GET_INSTRUMENTS_LIST_START_LOADING = 'SETTINGS/GET_INSTRUMENTS_LIST_START_LOADING';
export const GET_INSTRUMENTS_LIST_STOP_LOADING = 'SETTINGS/GET_INSTRUMENTS_LIST_STOP_LOADING';
export const GET_INSTRUMENTS_LIST_REQUEST = 'SETTINGS/GET_INSTRUMENTS_LIST_REQUEST';
export const GET_INSTRUMENTS_LIST_SUCCESS = 'SETTINGS/GET_INSTRUMENTS_LIST_SUCCESS';

export const GET_ACCOUNT_INFO_START_LOADING = 'SETTINGS/GET_ACCOUNT_INFO_START_LOADING';
export const GET_ACCOUNT_INFO_STOP_LOADING = 'SETTINGS/GET_ACCOUNT_INFO_STOP_LOADING';
export const GET_ACCOUNT_INFO_REQUEST = 'SETTINGS/GET_ACCOUNT_INFO_REQUEST';
export const GET_ACCOUNT_INFO_SUCCESS = 'SETTINGS/GET_ACCOUNT_INFO_SUCCESS';

export const GET_SETTINGS_START_LOADING = 'SETTINGS/GET_SETTINGS_START_LOADING';
export const GET_SETTINGS_STOP_LOADING = 'SETTINGS/GET_SETTINGS_STOP_LOADING';
export const GET_SETTINGS_REQUEST = 'SETTINGS/GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'SETTINGS/GET_SETTINGS_SUCCESS';

export const GET_USER_MAIL_SETTINGS_START_LOADING = 'SETTINGS/GET_USER_MAIL_SETTINGS_START_LOADING';
export const GET_USER_MAIL_SETTINGS_STOP_LOADING = 'SETTINGS/GET_USER_MAIL_SETTINGS_STOP_LOADING';
export const GET_USER_MAIL_SETTINGS_REQUEST = 'SETTINGS/GET_USER_MAIL_SETTINGS_REQUEST';
export const GET_USER_MAIL_SETTINGS_SUCCESS = 'SETTINGS/GET_USER_MAIL_SETTINGS_SUCCESS';

export const GET_ORDER_SETTINGS_REQUEST = 'SETTINGS/GET_ORDER_SETTINGS_REQUEST';
export const GET_ORDER_SETTINGS_SUCCESS = 'SETTINGS/GET_ORDER_SETTINGS_SUCCESS';
export const GET_ORDER_SETTINGS_START_LOADING = 'SETTINGS/GET_ORDER_SETTINGS_START_LOADING';
export const GET_ORDER_SETTINGS_STOP_LOADING = 'SETTINGS/GET_ORDER_SETTINGS_STOP_LOADING';

export const GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST =
  'SETTINGS/GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST';
export const GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_SUCCESS =
  'SETTINGS/GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_SUCCESS';
export const TOGGLE_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST =
  'SETTINGS/TOGGLE_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST';
export const TOGGLE_USER_SETTING_CROSS_ORDER = 'SETTINGS/TOGGLE_USER_SETTING_CROSS_ORDER';
export const GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST =
  'SETTINGS/GET_USER_SETTING_CLOSER_ORDER_SKIP_CONFIRMATION_REQUEST';
export const GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_SUCCESS =
  'SETTINGS/GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_SUCCESS';
export const TOGGLE_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST =
  'SETTINGS/TOGGLE_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST';

export const UPDATE_USER_MAIL_SETTINGS_REQUEST = 'SETTINGS/UPDATE_USER_MAIL_SETTINGS_REQUEST';
export const UPDATE_USER_MAIL_SETTINGS_SUCCESS = 'SETTINGS/UPDATE_USER_MAIL_SETTINGS_SUCCESS';
export const UPDATE_ORDER_SETTINGS_REQUEST = 'SETTINGS/UPDATE_ORDER_SETTINGS_REQUEST';
export const UPDATE_ORDER_SETTINGS_SUCCESS = 'SETTINGS/UPDATE_ORDER_SETTINGS_SUCCESS';
export const UPDATE_ORDER_SETTINGS_START_LOADING = 'SETTINGS/UPDATE_ORDER_SETTINGS_START_LOADING';
export const UPDATE_ORDER_SETTINGS_STOP_LOADING = 'SETTINGS/UPDATE_ORDER_SETTINGS_STOP_LOADING';
export const ACCEPT_AGREEMENT_INFO_REQUEST = 'SETTINGS/ACCEPT_AGREEMENT_INFO_REQUEST';
export const ACCEPT_AGREEMENT_INFO_REQUEST_START_LOADING = 'SETTINGS/ACCEPT_AGREEMENT_INFO_REQUEST_START_LOADING';
export const ACCEPT_AGREEMENT_INFO_REQUEST_END_LOADING = 'SETTINGS/ACCEPT_AGREEMENT_INFO_REQUEST_END_LOADING';

export const CHANGE_MARKET_STATUS = 'SETTINGS/CHANGE_MARKET_STATUS';

export const GET_LOGIN_METHOD_SUCCESS = 'SETTINGS/GET_LOGIN_METHOD_SUCCESS';

export const UPDATE_SELECTION_MAINTENANCE_STATUS = 'SETTINGS/UPDATE_SELECTION_MAINTENANCE_STATUS';
export const UPDATE_SERVICE_MAINTENANCE_STATUS = 'SETTINGS/UPDATE_SERVICE_MAINTENANCE_STATUS';
export const UPDATE_DISCLAIMER_FLG = 'SETTINGS/UPDATE_DISCLAIMER_FLG';
export const UPDATE_IS_FIRST_LOGIN = 'SETTINGS/UPDATE_IS_FIRST_LOGIN';

export const LOSSCUT_STATUS_REQUEST = 'SETTINGS/LOSSCUT_STATUS_REQUEST';
export const CHANGE_LOSSCUT_STATUS = 'SETTINGS/CHANGE_LOSSCUT_STATUS';

export const GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST =
  'SETTING/GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST';
export const GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST_SUCCESS =
  'SETTINGS/GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST_SUCCESS';
export const UPDATE_USER_LEARN_TRIAUTO_STATUS = 'SETTINGS/UPDATE_USER_LEARN_TRIAUTO_STATUS';
export const UPDATE_USER_LEARN_TRIAUTO_STATUS_REQUEST = 'SETTING/UPDATE_USER_LEARN_TRIAUTO_STATUS_REQUEST';
