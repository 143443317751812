import { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isArray, isEqual } from 'shared-modules/utils';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './dropdown.module.scss';

const INITIAL_FOCUS_INDEX = -1;
const MENU_ITEM_HEIGHT = 42;
const MAX_MENU_HEIGHT = MENU_ITEM_HEIGHT * 8;
const MENU_ITEM_STYLE = { height: MENU_ITEM_HEIGHT };

export const Dropdown = ({ selected, items, placeholder, minWidth, offsetTop, iconName, onChange, labelClassName }) => {
  const containerRef = useRef(null);
  const labelRef = useRef(null);
  const menuRef = useRef(null);
  const [style, setStyle] = useState({});
  const itemCountRef = useRef(items.length);
  const focusIndexRef = useRef(INITIAL_FOCUS_INDEX);
  const [open, setOpen] = useState(false);
  const found = items.find((item) => isEqual(item.value, selected));

  const calculateStyles = useCallback(() => {
    const pos = labelRef.current.getBoundingClientRect();
    const menuHeight = Math.min(MENU_ITEM_HEIGHT * itemCountRef.current, MAX_MENU_HEIGHT);
    let top = pos.height + 6;
    let maxHeight = document.body.clientHeight - pos.top - top;
    const upperEffectiveHeight = pos.top - (offsetTop ?? 0);
    if (maxHeight < menuHeight && upperEffectiveHeight >= menuHeight) {
      // 下部への全表示は無理だが上部には可能な場合
      top = -menuHeight;
      maxHeight = menuHeight;
    } else if (maxHeight < MENU_ITEM_HEIGHT) {
      if (upperEffectiveHeight >= MENU_ITEM_HEIGHT) {
        // 上部に1項目分以上の表示領域がある場合
        top = -upperEffectiveHeight;
        maxHeight = upperEffectiveHeight;
      } else {
        // 上部にも下部にも1項目分表示できない場合は祖先要素のスクロールバー表示もやむなし
        maxHeight = MENU_ITEM_HEIGHT * 2;
      }
    }
    setStyle({ top: pos.top + top, left: pos.left, maxHeight: Math.min(maxHeight, MAX_MENU_HEIGHT) });
  }, [offsetTop]);

  const handleClick = useCallback(() => {
    calculateStyles();
    setOpen((oldValue) => !oldValue);
  }, [calculateStyles]);

  const handleKeyDown = useKeyDownHandler(handleClick);

  const handleChange = useCallback(
    (item) => {
      onChange(item.value);
      setOpen(false);
    },
    [onChange],
  );

  const handleKeyDownChange = useCallback(
    (event, item) => {
      const { key } = event;
      if (key === 'Enter' || key === ' ') {
        event.preventDefault();
        handleChange(item);
      }
    },
    [handleChange],
  );

  const handleKeyDownMenu = useCallback((event) => {
    if (!menuRef.current) {
      return;
    }
    const { key } = event;
    const isArrowDown = key === 'ArrowDown';
    const isArrowUp = key === 'ArrowUp';
    if (isArrowDown || isArrowUp) {
      const nextIndex = focusIndexRef.current + (isArrowDown ? 1 : -1);
      const { childNodes } = menuRef.current;
      if (nextIndex >= childNodes.length) {
        focusIndexRef.current = childNodes.length - 1;
      } else if (nextIndex < 0) {
        focusIndexRef.current = 0;
      } else {
        focusIndexRef.current = nextIndex;
      }
      childNodes[focusIndexRef.current].focus();
      event.preventDefault();
    } else if (key === 'Enter' || key === 'Escape') {
      setOpen(false);
      event.preventDefault();
    }
  }, []);

  const menu = useMemo(() => {
    if (!open) {
      return null;
    }
    return createPortal(
      <div className={styles.menuWrapper} style={style}>
        <ul ref={menuRef} className={styles.menu} role="menu" tabIndex={0} onKeyDown={handleKeyDownMenu}>
          {items.map((item) => {
            const { value } = item;
            const key = isArray(value) ? `[${value.join(',')}]` : value;
            return (
              <li
                key={key}
                className={styles.menuItem}
                style={MENU_ITEM_STYLE}
                role="menuitem"
                tabIndex={0}
                onClick={() => {
                  handleChange(item);
                }}
                onKeyDown={(event) => {
                  handleKeyDownChange(event, item);
                }}
              >
                <span className={styles.menuItemLabel}>{item.label}</span>
                {isEqual(value, selected) ? (
                  <i className={classNames(styles.icon, 'material-icons')}>check</i>
                ) : (
                  <div className={styles.filler} />
                )}
              </li>
            );
          })}
        </ul>
      </div>,
      document.body,
    );
  }, [open, selected, items, style, handleChange, handleKeyDownChange, handleKeyDownMenu]);

  const handleClickOutside = useCallback((e) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      containerRef.current &&
      !containerRef.current.contains(e.target)
    ) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  useEffect(() => {
    if (open) {
      focusIndexRef.current = INITIAL_FOCUS_INDEX;
      menuRef.current?.focus?.();
    } else {
      labelRef.current?.focus?.();
    }
  }, [open]);

  useEffect(() => {
    itemCountRef.current = items.length;
  }, [items.length]);

  useEffect(() => {
    document.addEventListener('wheel', calculateStyles);
    return () => {
      document.removeEventListener('wheel', calculateStyles);
    };
  }, [calculateStyles]);

  return (
    <div ref={containerRef} className={styles.container}>
      <div
        ref={labelRef}
        className={classNames(styles.label, labelClassName)}
        style={minWidth == null ? {} : { minWidth }}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        {iconName && (
          <i tabIndex={-1} className={classNames('material-icons', styles.icon)}>
            {iconName}
          </i>
        )}
        {found?.label ?? placeholder}
      </div>
      {menu}
    </div>
  );
};

Dropdown.propTypes = {
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      ]).isRequired,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  minWidth: PropTypes.number,
  offsetTop: PropTypes.number,
  iconName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
};

Dropdown.defaultProps = {
  selected: undefined,
  placeholder: '選択なし',
  minWidth: undefined,
  offsetTop: undefined,
  iconName: undefined,
  labelClassName: '',
};
