import moment from 'moment-timezone';
import Bugsnag from '@bugsnag/js';
import { BUY_SELL_MAIN, CFD, CHART_RESOLUTION_MAIN, CHART_RESOLUTION_VALUES, ETF, FX } from 'shared-modules/constants';
import { BUGSNAG_WEB_API_KEY, DEBUG } from 'shared-modules/config';
import { getChartData } from 'shared-modules/api/chartApi';
import { store } from '../../redux/store';

const MS_IN_SECONDS = 1000;
const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const START_OF_DAY_RESOLUTION = [
  CHART_RESOLUTION_MAIN.DAYS_1.TV_ID,
  CHART_RESOLUTION_MAIN.WEEKS_1.TV_ID,
  CHART_RESOLUTION_MAIN.MONTH_1.TV_ID,
];
const CONFIGURATION_DATA = {
  supported_resolutions: ['1', '5', '30', '60', '240', '480', '1D', '1W', '1M'],
  exchanges: [],
  symbols_types: [],
};
const EXCHANGE_INFO_NY = Object.freeze({
  timezone: 'America/New_York',
  session: '1700-1700:23456',
});
const EXCHANGE_INFO_TSE = Object.freeze({
  timezone: 'America/New_York',
  session: '1700-2500:12345',
});
const EXCHANGE_INFO_BATS = Object.freeze({
  timezone: 'America/New_York',
  session: '0900-1700:23456',
});

const lastBarsCache = new Map();
const channelToSubscription = new Map();

const getAllSymbols = ({ instrumentOptions, serviceId, instrumentList }) => {
  const result = [];
  if (serviceId === FX) {
    instrumentOptions[FX].forEach((i) => {
      result.push(
        {
          symbol: `${i.value}(${BUY_SELL_MAIN.SELL.KEY})`,
          full_name: `${i.value}(${BUY_SELL_MAIN.SELL.KEY})`,
          id: `${i.value}(${BUY_SELL_MAIN.SELL.KEY})`,
          description: ``,
          exchange: '',
          type: FX,
          ...EXCHANGE_INFO_NY,
        },
        {
          symbol: `${i.value}(${BUY_SELL_MAIN.BUY.KEY})`,
          full_name: `${i.value}(${BUY_SELL_MAIN.BUY.KEY})`,
          id: `${i.value}(${BUY_SELL_MAIN.BUY.KEY})`,
          description: ``,
          exchange: '',
          type: FX,
          ...EXCHANGE_INFO_NY,
        },
      );
    });
  } else if (serviceId === ETF) {
    instrumentOptions[ETF].forEach((i) => {
      const exchangeInfo = i.value.endsWith('JPY') ? EXCHANGE_INFO_TSE : EXCHANGE_INFO_BATS;
      result.push(
        {
          symbol: `${instrumentList[i.value].shortName}(${BUY_SELL_MAIN.SELL.KEY})`,
          full_name: `${instrumentList[i.value].shortName}(${BUY_SELL_MAIN.SELL.KEY})`,
          id: `${i.value}(${BUY_SELL_MAIN.SELL.KEY})`,
          description: ``,
          exchange: '',
          type: ETF,
          ...exchangeInfo,
        },
        {
          symbol: `${instrumentList[i.value].shortName}(${BUY_SELL_MAIN.BUY.KEY})`,
          full_name: `${instrumentList[i.value].shortName}(${BUY_SELL_MAIN.BUY.KEY})`,
          id: `${i.value}(${BUY_SELL_MAIN.BUY.KEY})`,
          description: ``,
          exchange: '',
          type: ETF,
          ...exchangeInfo,
        },
      );
    });
  } else if (serviceId === CFD) {
    instrumentOptions[CFD].forEach((i) => {
      const exchangeInfo = EXCHANGE_INFO_NY;
      result.push(
        {
          symbol: `${instrumentList[i.value].shortName}(${BUY_SELL_MAIN.SELL.KEY})`,
          full_name: `${instrumentList[i.value].shortName}(${BUY_SELL_MAIN.SELL.KEY})`,
          id: `${i.value}(${BUY_SELL_MAIN.SELL.KEY})`,
          description: ``,
          exchange: '',
          type: CFD,
          ...exchangeInfo,
        },
        {
          symbol: `${instrumentList[i.value].shortName}(${BUY_SELL_MAIN.BUY.KEY})`,
          full_name: `${instrumentList[i.value].shortName}(${BUY_SELL_MAIN.BUY.KEY})`,
          id: `${i.value}(${BUY_SELL_MAIN.BUY.KEY})`,
          description: ``,
          exchange: '',
          type: CFD,
          ...exchangeInfo,
        },
      );
    });
  }

  return result;
};

const fetchData = async ({ symbolInfo, resolution, from, to, serviceId, isMobile, onHistoryCallback }) => {
  try {
    const [instrumentId, rightPart] = symbolInfo.id.split('(');
    const askBid =
      rightPart.slice(0, -1) === BUY_SELL_MAIN.SELL.KEY ? BUY_SELL_MAIN.SELL.CHART_ID : BUY_SELL_MAIN.BUY.CHART_ID;

    const startTime = moment.tz(from * MS_IN_SECONDS, 'Asia/Tokyo').format(DATE_FORMAT);
    const endTime = moment.tz(to * MS_IN_SECONDS, 'Asia/Tokyo').format(DATE_FORMAT);

    // eslint-disable-next-line
    const data = await getChartData({
      instrumentId,
      resolution: CHART_RESOLUTION_VALUES[resolution].ID,
      askBid,
      startTime,
      endTime,
      isMobile,
      serviceId,
    });

    return data?.data || [];
  } catch (error) {
    if (BUGSNAG_WEB_API_KEY) {
      Bugsnag.notify(error);
    }
    if (DEBUG) {
      console.log('chart fetch data error', error); // eslint-disable-line
    }

    onHistoryCallback([], { noData: false });

    return [];
  }
};

function getNextBarTime(barTime, resolution) {
  return moment(barTime)
    .add(CHART_RESOLUTION_VALUES[resolution].INCREASE_AMOUNT, CHART_RESOLUTION_VALUES[resolution].INCREASE_UNIT)
    .valueOf();
}

/* eslint-disable max-len */
// https://github.com/tradingview/charting_library/wiki/JS-Api#getbarssymbolinfo-resolution-from-to-onhistorycallback-onerrorcallback-firstdatarequest
// day, week and month bars should be in utc, another bars should be in 'Asia/Tokyo' timezone
// symbolInfo.timezone should be 'Etc/UTC'. it's not uses for getBars, but uses for subscribeBars
/* eslint-enable max-len */
function getBarTime(timeString, resolution) {
  if (START_OF_DAY_RESOLUTION.includes(resolution)) {
    return moment.utc(timeString, DATE_FORMAT).valueOf();
  }
  return moment.tz(timeString, DATE_FORMAT, 'Asia/Tokyo').valueOf();
}

const listener = () => {
  try {
    if (!channelToSubscription.size) {
      return;
    }

    // eslint-disable-next-line
    for (const key of channelToSubscription.keys()) {
      const subscriptionItem = channelToSubscription.get(key);
      if (subscriptionItem === undefined) {
        return;
      }

      const { lastBar } = subscriptionItem;
      if (!lastBar) {
        return;
      }

      const [instrumentId, direction, resolution] = key.split('~');

      if (
        !store.getState().currencies.rates[instrumentId] ||
        !store.getState().currencies.rates[instrumentId].bidHigh
      ) {
        return;
      }

      const { [direction]: newPrice, time: currentTimeSting } = store.getState().currencies.rates[instrumentId];
      const currentTime = getBarTime(currentTimeSting, resolution);

      const nextBarTime = getNextBarTime(lastBar.time, resolution);

      let bar;

      if (lastBar.time <= currentTime && currentTime < nextBarTime) {
        bar = {
          ...lastBar,
          high: Math.max(lastBar.high, newPrice),
          low: Math.min(lastBar.low, newPrice),
          close: newPrice,
          time: lastBar.time,
        };
      } else if (nextBarTime <= currentTime && currentTime < getNextBarTime(nextBarTime, resolution)) {
        bar = {
          time: nextBarTime,
          open: newPrice,
          high: newPrice,
          low: newPrice,
          close: newPrice,
        };
      } else {
        bar = lastBar;
      }

      subscriptionItem.lastBar = bar;

      const copiedLastBar = { ...subscriptionItem.lastBar };
      // console.log('realtime lastBarTime:', moment(copiedLastBar.time).format(DATE_FORMAT)); // eslint-disable-line
      subscriptionItem.handlers.forEach((handler) => handler.callback(copiedLastBar));
    }
  } catch (error) {
    if (BUGSNAG_WEB_API_KEY) {
      Bugsnag.notify(error);
    }
    if (DEBUG) {
      console.log('chart listener error', error); // eslint-disable-line
    }
  }
};

const storeSubscribe = ({ symbolInfo, resolution, onRealtimeCallback, subscribeUID, lastBar }) => {
  const [instrumentId, directionTemp] = symbolInfo.id.split('(');
  const channelString = `${instrumentId}~${directionTemp.slice(0, -1)}~${resolution}`;

  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };

  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    // already subscribed to the channel, use the existing subscription
    subscriptionItem.handlers.push(handler);
    return;
  }
  subscriptionItem = {
    subscribeUID,
    lastBar,
    handlers: [handler],
  };

  channelToSubscription.set(channelString, subscriptionItem);
};

const storeUnsubscribe = (subscriberUID) => {
  // eslint-disable-next-line
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex((handler) => handler.id === subscriberUID);

    if (handlerIndex !== -1) {
      // remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
};

store.subscribe(listener);

export default ({ instrumentOptions, isMobile, serviceId, instrumentList }) => ({
  onReady: (callback) => {
    setTimeout(() => callback(CONFIGURATION_DATA));
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    const symbols = getAllSymbols({ instrumentOptions, serviceId, instrumentList });

    const newSymbols = symbols.filter((symbol) => {
      const isExchangeValid = exchange === '' || symbol.exchange === exchange;
      const isFullSymbolContainsInput = symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
      return isExchangeValid && isFullSymbolContainsInput;
    });

    setTimeout(() => onResultReadyCallback(newSymbols));
  },
  resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    const symbols = getAllSymbols({ instrumentOptions, serviceId, instrumentList });

    const symbolItem = symbols.find(({ symbol }) => symbol === symbolName);
    if (!symbolItem) {
      onResolveErrorCallback('cannot resolve symbol');
      return;
    }

    const [instrumentId] = symbolItem.id.split('(');
    const precision =
      instrumentList[instrumentId].pricePrecision < 1 ? Math.round(1 / instrumentList[instrumentId].pricePrecision) : 1;

    const symbolInfo = {
      name: symbolItem.full_name,
      symbol: symbolItem.symbol,
      id: symbolItem.id,
      description: symbolItem.description,
      type: symbolItem.type,
      session: symbolItem.session,
      timezone: symbolItem.timezone,
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: precision,
      has_intraday: true,
      visible_plots_set: 'ohlc',
      has_empty_bars: false,
      has_weekly_and_monthly: true,
      supported_resolutions: CONFIGURATION_DATA.supported_resolutions,
      volume_precision: 2,
      data_status: 'streaming',
    };

    setTimeout(() => onSymbolResolvedCallback(symbolInfo));
  },
  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback) => {
    const { from, to, firstDataRequest } = periodParams;
    // 2000年以前のデータは存在しないので打ち切る
    const noData = moment.tz(to * MS_IN_SECONDS, 'Asia/Tokyo').year() <= 1999;
    if (noData) {
      onHistoryCallback([], { noData });
      return;
    }

    const data = await fetchData({
      symbolInfo,
      resolution,
      from,
      to,
      serviceId,
      isMobile,
      onHistoryCallback,
    });

    if (!data.length) {
      // 次を検索
      onHistoryCallback([], { noData: false });
      return;
    }

    const bars = data.reduce((acc, bar) => {
      acc.push({
        time: getBarTime(bar.startTime, resolution),
        low: bar.low,
        high: bar.high,
        open: bar.open,
        close: bar.close,
      });
      return acc;
    }, []);

    if (firstDataRequest) {
      lastBarsCache.set(`${symbolInfo.symbol}~${resolution}`, {
        ...bars[bars.length - 1],
      });
    }

    onHistoryCallback(bars, { noData: false });
  },
  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID) => {
    storeSubscribe({
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      lastBar: lastBarsCache.get(`${symbolInfo.symbol}~${resolution}`),
    });
  },
  unsubscribeBars: (subscriberUID) => {
    storeUnsubscribe(subscriberUID);
  },
});
