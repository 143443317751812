export const TOP = 'top';
export const MIDDLE = 'middle';
export const BOTTOM = 'bottom';
export const LEFT = 'left';
export const CENTER = 'center';
export const RIGHT = 'right';

export const STEP1_TITLE = `トライオートをご利用いただきありがとうございます`;
export const STEP1_MESSAGE = `トライオートの自動売買は、ルールを選び、ルールがトレードを繰り返し、定期的にルールの見直しを行って運用を続けていきます。`;

export const STEP2_TITLE = `簡単な自動売買の始め方を説明します`;
export const STEP2_MESSAGE = `本チュートリアルでは「セレクト」より、選んで稼働するだけで簡単に取引をはじめる方法を説明します。`;

export const STEP3_TITLE = `「セレクト」を開始します`;
export const STEP3_MESSAGE = `メニューからセレクトをクリックしてください。`;

export const STEP4_TITLE = `自動売買ルールの一覧が表示されます`;
export const STEP4_MESSAGE = `一覧には様々なルールが存在しており、その中から使いたいルールを選びます。
今回は3つの通貨ペアを組み合わせることで分散効果を利用し、リスクを抑えながら収益を狙う「三大陸」を例に見ていきましょう。`;

export const STEP5_TITLE = `「ロジック設定」で、注文が設定されている価格帯を確認します`;
export const STEP5_MESSAGE = `現在値が色のついた範囲内にあれば現在値と設定値が合っているルールだと判断できます。
買い注文：ピンク
売り注文：水色`;

export const STEP6_TITLE = `「注文設定」で、各注文の設定情報を確認します`;
export const STEP6_MESSAGE = `各注文の売買方向や取引数量、エントリー価格といった設定内容はチャート下の注文設定から確認できます。`;

export const STEP7_TITLE = `「シミュレーション」で、運用に必要な資金目安となる推奨証拠金を確認します`;
export const STEP7_MESSAGE = `推奨証拠金は、過去の相場変動リスクを加味した運用資金の目安です。`;

export const STEP8_TITLE = `シミュレーションでは、過去のレートに沿ったシミュレーション結果も確認できます`;
export const STEP8_MESSAGE = `損益グラフが右肩上がりでリスク評価が高いと、リスクをおさえてコツコツ収益を積み重ねているルールと考えられます。`;

export const STEP9_TITLE = `「今すぐ稼働」をクリックして自動売買をはじめてみましょう`;
export const STEP9_MESSAGE = `本チュートリアルでは、実際に稼働することはございませんのでご安心ください。`;

export const STEP10_TITLE = `「セット数」を変更すると推奨証拠金も増減します`;
export const STEP10_MESSAGE = `ルールに含まれる注文の取引数量が変更されます。
セット数の変更と連動して、推奨証拠金額も増減されます。`;

export const STEP11_TITLE = `「バランスメーター」で、ルール稼働後の口座状況の評価を確認します`;
export const STEP11_MESSAGE = `バランスメーターは、発注した注文がすべて約定した場合の口座状況を表しています。
評価は「資金効率が低い」「バランスが良い」「資金効率が高い」「余力が少ない」の4段階があります。`;

export const STEP12_TITLE = `「バランスメーター」を参考にセット数・資金を調整します`;
export const STEP12_MESSAGE = `「バランスが良い」「資金効率が高い」となるよう意識しましょう。
「余力が少ない」と表示されている場合は、証拠金に対して注文数量が多い状態と考えられます。
稼働の際はメッセージを参考にルールのセット数を調整するか、ご資金を調整いただく事をおすすめしております。`;

export const STEP13_TITLE = `「稼働開始」ボタンをクリックします`;
export const STEP13_MESSAGE = `自動売買を稼働させてみましょう。`;

export const STEP14_TITLE = `稼働したルールを確認します`;
export const STEP14_MESSAGE = `「ホーム画面を開く」をクリックして稼働したルールを確認しましょう。`;

export const STEP15_TITLE = `ホームでは稼働したルールの建玉数量や評価損益、実現損益等が確認できます`;
export const STEP15_MESSAGE = `クリックすると、ルールの詳細画面が表示されます。`;

export const STEP16_TITLE = `チュートリアルは以上となります`;
export const STEP16_MESSAGE = `セレクトよりルールを選んでみましょう`;
