import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Decimal from 'decimal.js';
import classNames from 'classnames';
import { BUY_SELL_CHART_ID_BY_ID, RECOMMENDED_MARGIN_LABEL } from 'shared-modules/constants';
import { useCalculatingSimulationChartData } from 'shared-modules/services/hooks';
import {
  useGetTechBuilderOrderRequestData,
  useGetBuilderServiceId,
  useBuilderMarginRequirement,
} from 'shared-modules/services/hooks/builder';
import { BUILDER_ORDER_CONFIGURATION_OPTIONS } from 'shared-modules/constants/builder';
import { numberExists } from 'shared-modules/utils';
import { useApOrManualInstrumentOptions } from 'shared-modules/hooks/symbol';
import { changeSelectedTermIdBuilder, getTechBuilderSimulationDataRequest } from '../../../../redux/actions';
import {
  TechPriceChart,
  ProfitLossChart,
  SimulationInfo,
  Spin,
  TermSelect,
  LabelWithHelp,
} from '../../../../components';
import { AssetLabel } from '../../../Builder/components/ConfigurationPage/SimulatingChart/AssetLabel';
import styles from './techChart.module.scss';
import { openTechBuilderWarningInfo } from '../../../../redux/actions/modalActions';

export const TechChart = memo(() => {
  const dispatch = useDispatch();

  const activeCurrency = useSelector((state) => state.builder.activeCurrency);
  const allowOrderEdit = useSelector((state) => state.builder.allowOrderEdit);

  const selectionTermOptions = useSelector((state) => state.constants.selectionTermOptions);

  const defaultSelectedTermId = useSelector((state) => state.constants.defaultSelectionTermId);

  const getTechOrderRequestData = useGetTechBuilderOrderRequestData();

  const [termId, changeTermId] = useState(defaultSelectedTermId);
  const [isLoading, changeIsLoading] = useState(false);

  useEffect(() => {
    if (allowOrderEdit) {
      changeTermId(defaultSelectedTermId);
      dispatch(changeSelectedTermIdBuilder({ termId: defaultSelectedTermId }));
      changeIsLoading(false);
    }
  }, [dispatch, allowOrderEdit, defaultSelectedTermId]);

  const handleChangeTermId = useCallback(
    (newTermId) => {
      changeTermId(newTermId);
      dispatch(changeSelectedTermIdBuilder({ termId: newTermId }));
      changeIsLoading(true);

      const orderRequestData = getTechOrderRequestData();
      dispatch(
        getTechBuilderSimulationDataRequest({
          orderRequestData,
          callback: () => changeIsLoading(false),
          termId: newTermId,
        }),
      );
    },
    [dispatch, getTechOrderRequestData],
  );
  const simulationStat = useSelector((state) => state.builder.simulationData[termId]?.simulationStat);
  const { realizedPl, unrealizedPl, marginRecommended } = simulationStat ?? {};

  const rates = useSelector((state) => state.currencies.rates[activeCurrency]);
  const marginRequired = useBuilderMarginRequirement(rates, true);
  const totalPl = useMemo(
    () =>
      numberExists(realizedPl) && numberExists(unrealizedPl) ? new Decimal(realizedPl).add(unrealizedPl).toNumber() : 0,
    [realizedPl, unrealizedPl],
  );

  const chartTitle = useMemo(() => (allowOrderEdit ? 'プライスチャート' : '損益シミュレーション'), [allowOrderEdit]);
  const simulationData = useSelector((state) => state.builder.simulationData[termId]);
  const chartData = useCalculatingSimulationChartData({
    strategyList: [simulationData ?? {}],
  });
  const logicGroupsList = useSelector((state) => state.builder.logicGroupsList);
  const itemsCount = logicGroupsList.reduce((prev, curr) => prev + Number(curr.itemsCount), 0);

  const instrumentOptions = useApOrManualInstrumentOptions();
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const serviceId = useGetBuilderServiceId();

  const selectedSellBuyId = useSelector(
    (state) => state.builder.multiOrder[BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.SELECTED_SELL_BUY_ID],
  );

  const indicator1 = useSelector(
    (state) => state.builder.techOrder[BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.INDICATOR1],
  );
  const indicator2 = useSelector(
    (state) => state.builder.techOrder[BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.INDICATOR2],
  );
  const barType = useSelector((state) => state.builder.techOrder[BUILDER_ORDER_CONFIGURATION_OPTIONS.TECH.BAR_TYPE]);

  const onWarningButtonClick = useCallback(() => {
    dispatch(openTechBuilderWarningInfo());
  }, [dispatch]);

  const toolBox = useMemo(() => {
    if (allowOrderEdit) {
      return <div />;
    }
    return (
      <TermSelect
        className={styles.termSelect}
        titleClassName={styles.termSelectTitle}
        title="シミュレーション期間"
        options={selectionTermOptions}
        termId={termId}
        onChange={handleChangeTermId}
      />
    );
  }, [allowOrderEdit, selectionTermOptions, termId, handleChangeTermId]);

  return (
    <div className={styles.simulatingChart}>
      <div className={styles.header}>
        <div className={styles.title}>{chartTitle}</div>
        <AssetLabel />
        {!allowOrderEdit && (
          <div className="ms-auto me-3">
            <LabelWithHelp label="注意事項" onClick={onWarningButtonClick} />
          </div>
        )}
      </div>
      {toolBox}
      <div className={styles.content}>
        <div className={classNames(styles.chartWrapper, { [styles.existsInfo]: !allowOrderEdit && !isLoading })}>
          {allowOrderEdit && (
            <TechPriceChart
              selectedCurrencyPairId={activeCurrency}
              selectedSide={BUY_SELL_CHART_ID_BY_ID[selectedSellBuyId]}
              resolution={barType}
              serviceId={serviceId}
              instrumentOptions={instrumentOptions}
              instrumentList={instrumentList}
              indicator1={indicator1}
              indicator2={indicator2}
            />
          )}
          {!allowOrderEdit && !isLoading && <ProfitLossChart data={chartData} useDefaultYAxisWidth />}
        </div>
        {!allowOrderEdit && !isLoading && (
          <div className={styles.infoWrapper}>
            <SimulationInfo
              serviceId={serviceId}
              totalPl={totalPl}
              marginRecommended={marginRecommended}
              marginRequired={marginRequired}
              count={itemsCount}
              marginRecommendedLabel={RECOMMENDED_MARGIN_LABEL}
              riskAssessment={0}
            />
          </div>
        )}
        {!allowOrderEdit && isLoading && (
          <div className={styles.loaderWrapper}>
            <Spin className={styles.isLoading} />
          </div>
        )}
      </div>
    </div>
  );
});
