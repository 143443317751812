import { memo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { MODAL_SIZES, RECOMMENDED_MARGIN_LABEL, RISK_ASSESSMENT_LABEL, STYLES } from 'shared-modules/constants';
import { MARGINS, PERIODS, RISK_ASSESSMENTS, TITLES } from 'shared-modules/constants/select';
import { useFilterCondition } from 'shared-modules/hooks/select';
import { useKeyDownHandler } from '../../../../services/hooks';
import { Button, CustomCheckbox, Modal } from '../../../../components';
import styles from './filterCondition.module.scss';

const Item = memo(({ title, items, className, stateKey, state, onChange }) => {
  const handleChange = useCallback(
    (checked, label) => {
      const found = (items || []).find(({ value }) => value === label);
      if (found == null) {
        return;
      }
      onChange(checked, stateKey, found);
    },
    [items, stateKey, onChange],
  );
  return (
    <div className={styles.item}>
      <div className={styles.title}>{title}</div>
      <div className={styles.checkboxes}>
        {(items || []).map(({ id, value }) => {
          const isChecked = state[stateKey]?.[id] ?? false;
          return (
            <div key={id} className={className}>
              <CustomCheckbox isChecked={isChecked} onChange={handleChange} label={value} />
            </div>
          );
        })}
      </div>
    </div>
  );
});

Item.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      assets: PropTypes.shape({}),
      margins: PropTypes.shape({}),
    }),
  ).isRequired,
  className: PropTypes.string,
  stateKey: PropTypes.string.isRequired,
  state: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

Item.defaultProps = {
  className: undefined,
};

const FilterConditionModal = memo(({ visible, filterCondition, selectedAll, assets, onClose, onOk, onChange }) => {
  const handleChangeAssets = useCallback(
    (checked, key, { value }) => {
      onChange(checked, key, { id: value.toLowerCase(), value });
    },
    [onChange],
  );

  return (
    <Modal isOpen={visible} closeModal={onClose} title="絞込" size={MODAL_SIZES.WIDTH_512}>
      <div className={styles.content}>
        {selectedAll && (
          <Item
            title="アセット"
            items={assets}
            stateKey="assets"
            state={filterCondition}
            onChange={handleChangeAssets}
          />
        )}
        <Item
          className={styles.flex}
          title={RECOMMENDED_MARGIN_LABEL}
          items={MARGINS}
          stateKey="margins"
          state={filterCondition}
          onChange={onChange}
        />
        <Item
          className={styles.flex}
          title="運用スタイル"
          items={STYLES}
          stateKey="styles"
          state={filterCondition}
          onChange={onChange}
        />
        <Item
          className={styles.flex}
          title="運用期間"
          items={PERIODS}
          stateKey="periods"
          state={filterCondition}
          onChange={onChange}
        />
        <Item title="称号" items={TITLES} stateKey="titles" state={filterCondition} onChange={onChange} />
        <Item
          className={styles.flex}
          title={RISK_ASSESSMENT_LABEL}
          items={RISK_ASSESSMENTS}
          stateKey="riskAssessments"
          state={filterCondition}
          onChange={onChange}
        />
      </div>
      <div className={styles.footer}>
        <Button onClick={onOk}>OK</Button>
      </div>
    </Modal>
  );
});

FilterConditionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  filterCondition: PropTypes.shape({}).isRequired,
  selectedAll: PropTypes.bool.isRequired,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      assets: PropTypes.shape({}),
      margins: PropTypes.shape({}),
    }),
  ).isRequired,
  onOk: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const FilterCondition = memo(({ tabs }) => {
  const { visible, nothing, filterCondition, selectedAll, assets, handleOpen, handleClose, handleOk, handleChange } =
    useFilterCondition(tabs);
  const handleKeyDownOpen = useKeyDownHandler(handleOpen);
  return (
    <>
      <div className={styles.filter} role="button" tabIndex={0} onClick={handleOpen} onKeyDown={handleKeyDownOpen}>
        <i className={classNames('material-icons-outlined', styles.icon, { [styles.filtered]: !nothing })}>
          filter_alt
        </i>
        <div className={classNames({ [styles.filtered]: !nothing })}>{nothing ? '絞込なし' : '絞込あり'}</div>
      </div>
      {visible && (
        <FilterConditionModal
          visible={visible}
          filterCondition={filterCondition}
          selectedAll={selectedAll}
          assets={assets}
          onOk={handleOk}
          onClose={handleClose}
          onChange={handleChange}
        />
      )}
    </>
  );
});

FilterCondition.propTypes = {
  tabs: PropTypes.shape({
    get: PropTypes.number,
    set: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
