import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { reorderForDragAndDrop } from 'shared-modules/services';
import { useManualTradeChangeActiveCurrency } from 'shared-modules/services/hooks';
import { useAccountInfo } from 'shared-modules/hooks';
import {
  getServiceId,
  isYenConversionInstrument,
  makeAccountExistQueryString,
  makeMaintenanceQueryString,
} from 'shared-modules/utils';
import { FX } from 'shared-modules/constants';
import { openManualTradeCurrencySettings, updateDisplayingTradeCurrencyPair } from '../../../../redux/actions';
import { CURRENCY_PAIRS, MANUAL_TRADE_CURRENCY_PAIRS_POPUP_PARAMS, POPUP_MESSAGES } from '../../../../constants';
import { Spin } from '../../../../components';
import IconButton from '../../../../components/IconButton';
import SettingsIcon from '../../../../components/SettingsIcon';
import { StrictModeDroppable } from '../../../CurrencyPairPage/StrictModeDroppable';
import { CurrencyPairItems } from '../CurrencyPairItems';
import styles from './currencyPair.module.scss';

const CurrencyPair = () => {
  const isLoading = useSelector((state) => state.currencies.ratesIsLoading);

  const dispatch = useDispatch();
  const currencyPairsPopup = useRef({});

  const serviceId = useSelector((state) => state.auth.serviceId);
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const displayedInstruments = useSelector((state) => state.settings[serviceId].displayedInstruments);
  const accountInfo = useAccountInfo();
  const isFX = serviceId === FX;

  const changeActiveCurrency = useManualTradeChangeActiveCurrency();

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      if (result.destination.index === result.source.index) {
        return;
      }
      dispatch(
        updateDisplayingTradeCurrencyPair({
          array: reorderForDragAndDrop(displayedInstruments, result.source.index, result.destination.index),
          serviceId,
        }),
      );
    },
    [displayedInstruments, dispatch, serviceId],
  );
  useEffect(() => {
    if (currencyPairsPopup.current && currencyPairsPopup.current?.postMessage) {
      currencyPairsPopup.current.postMessage(
        {
          message: POPUP_MESSAGES.GET_DISPLAYED_CURRENCY_LIST_SUCCESS,
          payload: { displayedInstruments, serviceId },
        },
        '*',
      );
    }
  }, [displayedInstruments, serviceId]);

  const handleOpenCurrencyPairSettings = useCallback(() => {
    dispatch(openManualTradeCurrencySettings());
  }, [dispatch]);

  const handleOpenCurrencyPairPopup = useCallback(() => {
    const maintenanceQueryString = makeMaintenanceQueryString(accountInfo);
    const accountExistQueryString = makeAccountExistQueryString(accountInfo);
    const url = `/${CURRENCY_PAIRS}?${maintenanceQueryString}&&${accountExistQueryString}`;
    currencyPairsPopup.current = window.open(encodeURI(url), CURRENCY_PAIRS, MANUAL_TRADE_CURRENCY_PAIRS_POPUP_PARAMS);
  }, [accountInfo]);

  const currencyPairPopupEventListener = useCallback(
    (e) => {
      // checking it's correct window;
      if (!e.isTrusted || !e.source || e.source.name !== CURRENCY_PAIRS) {
        return;
      }
      switch (e.data.message) {
        case POPUP_MESSAGES.GET_DISPLAYED_CURRENCY_LIST_REQUEST: {
          currencyPairsPopup.current.postMessage(
            {
              message: POPUP_MESSAGES.GET_DISPLAYED_CURRENCY_LIST_SUCCESS,
              payload: { displayedInstruments, serviceId },
            },
            '*',
          );
          break;
        }
        case POPUP_MESSAGES.CHANGE_DISPLAYED_CURRENCY: {
          changeActiveCurrency(e.data.payload);
          break;
        }
        case POPUP_MESSAGES.GET_DISPLAYED_CURRENCY_LIST_SUCCESS: {
          if (JSON.stringify(displayedInstruments) !== JSON.stringify(e.data.payload.displayedInstruments)) {
            dispatch(
              updateDisplayingTradeCurrencyPair({
                array: e.data.payload.displayedInstruments,
                serviceId: e.data.payload.serviceId,
              }),
            );
          }
          break;
        }
        default: {
          // empty
        }
      }
    },
    [currencyPairsPopup, displayedInstruments, dispatch, serviceId, changeActiveCurrency],
  );

  const sendMessageClosePopup = useCallback(() => {
    if (currencyPairsPopup.current && currencyPairsPopup.current?.postMessage) {
      currencyPairsPopup.current.postMessage({ message: POPUP_MESSAGES.CLOSE_POPUP }, '*');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', currencyPairPopupEventListener);
    window.addEventListener('beforeunload', sendMessageClosePopup);
    return () => {
      window.removeEventListener('message', currencyPairPopupEventListener);
      window.removeEventListener('beforeunload', sendMessageClosePopup);
    };
  }, [sendMessageClosePopup, currencyPairPopupEventListener]);
  useEffect(() => {
    return () => {
      sendMessageClosePopup();
    };
  }, [sendMessageClosePopup]);

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Spin className={styles.loader} />
      ) : (
        <>
          <div className={styles.headerRow}>
            <div className={styles.headerContainer}>
              <IconButton iconName="zoom_out_map" onClick={handleOpenCurrencyPairPopup} />
              <div className={styles.header}>銘柄</div>
            </div>
            <SettingsIcon onClick={handleOpenCurrencyPairSettings} />
          </div>
          <div className={styles.tableHeaderRow}>
            <div className={styles.tableTitle}>BID</div>
            <div className={styles.tableTitle}>SPREAD</div>
            <div className={classNames(styles.tableTitle, styles.askTitle)}>ASK</div>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="currencies-area">
              {(provided) => (
                <div
                  className={styles.listContainer}
                  ref={provided.innerRef}
                  {...provided.droppableProps} // eslint-disable-line
                >
                  {displayedInstruments.reduce((acc, item, index) => {
                    if (item.displayed) {
                      acc.push(
                        <Draggable key={item.instrumentId} draggableId={item.instrumentId} index={index}>
                          {(draggableProvided) => {
                            // 円転用銘柄かどうかをチェック
                            const isYenConversion = isYenConversionInstrument(item.instrumentId, serviceId);
                            const CurrencyPairItem = CurrencyPairItems[getServiceId(item.instrumentId, instrumentList)];
                            if (CurrencyPairItem) {
                              return (
                                <CurrencyPairItem
                                  instrumentId={
                                    isYenConversion && !isFX ? `${serviceId}.${item.instrumentId}` : item.instrumentId
                                  }
                                  draggableProps={draggableProvided}
                                  innerRef={draggableProvided.innerRef}
                                  changeActiveCurrency={changeActiveCurrency} // disabled の場合は呼び出されない
                                  disabled={isYenConversion}
                                />
                              );
                            }
                            return null;
                          }}
                        </Draggable>,
                      );
                    }

                    return acc;
                  }, [])}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </>
      )}
    </div>
  );
};

export default memo(CurrencyPair);
