// inner or server errors
export const INNER_ERROR_VALIDATION_ERRORS = 'VALIDATION_ERRORS';
export const AWS_SIGNED_IN_USER_SESSION_INVALID = 'AWS_SIGNED_IN_USER_SESSION_INVALID';
export const CHECK_USER_AUTHENTICATED_ERROR = 'CHECK_USER_AUTHENTICATED_ERROR';

// errors for displaying on the client
export const DISPLAYING_ERROR_ID_TOO_SHORT = 'ログインIDは半角数字8桁でご入力ください。';
export const DISPLAYING_ERROR_PASSWORD_TOO_SHORT = 'パスワードは半角英数8桁以上でご入力ください。';
export const DISPLAYING_ERROR_PASSWORD_TOO_SHORT_MOBILE = 'パスワードは6文字以上20文字以下でご入力ください。';
export const DISPLAYING_ERROR_LOGIN = '取引ログインIDまたはパスワードが違います。';
export const DISPLAYING_ERROR_IMS = `ただいまメールアドレス及びMyページログインIDによるログインはご利用いただけません。\n取引用ID・パスワードを入力してください。`;
export const DISPLAYING_ERROR_DEFAULT = `サーバエラーが発生しました。

お手数ですが画面を更新いただくか再度ログインをお願いします。`;
export const DISPLAYING_ERROR_DEFAULT_MOBILE = `サーバエラーが発生しました。お手数ですが、再度ログインをお願いします。`;

export const SESSION_EXPIRED_ERROR_MESSAGE = `ログインセッションの有効期限が切れました。

お手数ですが再度ログインをお願いします。`;

export const NEW_SESSION_WITH_THIS_CREDENTIAL_ERROR_MESSAGE = `他の端末でログインがありました。

ログアウトします。`;

export const CONNECTION_TIMEOUT_ERROR_MESSAGE =
  '処理に失敗した可能性があります。お手数ですが画面を更新しご確認の上、しばらくたってから再度お試しください。';

export const IMS_200A_ERROR_MESSAGE = 'ログインIDまたはパスワードが間違っています。';
export const IMS_200B_ERROR_MESSAGE = 'メールアドレスまたはパスワードが違います。';
export const IMS_201_ERROR_MESSAGE = `パスワード失敗回数が上限を超えています。
Myページの「パスワードロック解除画面」よりパスワードを初期化してください。`;
export const IMS_202_ERROR_MESSAGE =
  '入力されたパスワードは初期パスワードです。Myページにログインして「初期パスワード変更画面」よりパスワードを変更してください。';
export const IMS_203_ERROR_MESSAGE = 'ご入力いただいたメールアドレスではログインできません。';
export const IMS_800_ERROR_MESSAGE =
  'システムメンテナンス中のため、一時的にご利用いただけません。詳細についてMyページよりご確認ください。';
export const IMS_XXX_ERROR_MESSAGE = `Myページへログインできませんでした。
しばらくしてから再度実行してください。`;

export const IMS_200A = 'IMS-200a';
export const IMS_200B = 'IMS-200b';
export const IMS_201 = 'IMS-201';
export const IMS_202 = 'IMS-202';
export const IMS_203 = 'IMS-203';
export const IMS_800 = 'IMS-800';

export const IMS_REG = /IMS-\d{3}\w?/;

export const MAINTENANCE_ERROR_MESSAGE_WEB = `現在はメンテナンス実施中です。
メンテナンス時間は{linkStart}こちら{linkEnd}をご参照ください。`;

export const MAINTENANCE_ERROR_MESSAGE_MOBILE = `現在はメンテナンス実施中です。
メンテナンス実施日時等についてはMyページよりご確認ください。`;

export const MAINTENANCE_LOGIN_ERROR_MESSAGE = `現在メンテナンス中のため、ログインができません。
メンテナンス時間は{linkStart}こちら{linkEnd}をご参照ください。`;

export const MAINTENANCE_MODAL_TITLE = 'システムメンテナンス中';
export const MAINTENANCE_MODAL_BUTTON = '閉じる';

export const ETF_NOT_EXIST_ERROR_MESSAGE_WEB = `トライオートETFをご利用いただくためには口座開設が必要です。
Myページより追加口座開設をお願いいたします。

Myページは{linkStart}こちら{linkEnd}`;

export const ETF_NOT_EXIST_TITLE = 'トライオート ETF未開設';

const CFD_NOT_EXIST_ERROR_MESSAGE_WEB = `トライオートCFDをご利用いただくためには口座開設が必要です。
Myページより追加口座開設をお願いいたします。

Myページは{linkStart}こちら{linkEnd}`;

const CFD_NOT_EXIST_TITLE = 'トライオート CFD未開設';

const FX_NOT_EXIST_ERROR_MESSAGE_WEB = `トライオートFXをご利用いただくためには口座開設が必要です。
Myページより追加口座開設をお願いいたします。

Myページは{linkStart}こちら{linkEnd}`;

const FX_NOT_EXIST_TITLE = 'トライオート FX未開設';
export const NOT_EXIST_BUTTON = '閉じる';

export const NOT_EXIST_ERROR_MESSAGES_WEB = Object.freeze({
  fx: FX_NOT_EXIST_ERROR_MESSAGE_WEB,
  etf: ETF_NOT_EXIST_ERROR_MESSAGE_WEB,
  cfd: CFD_NOT_EXIST_ERROR_MESSAGE_WEB,
});

export const NOT_EXIST_TITLES = Object.freeze({
  fx: FX_NOT_EXIST_TITLE,
  etf: ETF_NOT_EXIST_TITLE,
  cfd: CFD_NOT_EXIST_TITLE,
});

export const AP_GROUP_DELETE_MESSAGES = {
  apGroupIsActive: '自動売買が全て停止されていないためグループを削除できません。',
  apGroupHasOpenPositions: '自動売買に建玉があるためグループを削除できません。',
  apGroupHasOpenOrders: '自動売買に新規注文があるためグループを削除できません。',
  apGroupMultipleError: `自動売買が全て停止されていないためグループを削除できません。
	自動売買に建玉があるためグループを削除できません。
	自動売買に新規注文があるためグループを削除できません。`,
};

export const TECH_LOGIC_DELETE_MESSAGES = {
  techIsActive: 'テクニカルロジックが稼働中のためロジックを削除できません。',
  techHasOpenPositions: 'テクニカルロジックに紐づく建玉があるためロジックを削除できません。',
  techHasOpenOrders: 'テクニカルロジックに紐づく自動売買注文があるためロジックを削除できません。',
};

export const BUILDER_DISABLED = 'ビルダー機能はメンテナンス中です。';

export const POSITIONS_ARE_STILL_DELETING = '建玉の決済が完了していないため、再度時間をおいてからお試しください。';

export const LOSSCUT_MODAL_TITLE = '通知';
export const LOSSCUT_MODAL_MESSAGE = `ただいまロスカット処理中です。この処理には数分かかる場合があります。\n完了し操作可能となるまでお待ちください。`;
export const LOSSCUT_MODAL_BUTTON = '閉じる';

export const SWAP_TRANSFER_POSITIONS_VALIDATION_MESSAGE =
  '建玉が100件を超えています。建玉を100件以内にしてから振替処理を実施してください。';

export const REPLACEMENT_TEXT_REG = /{link(?:Start|End)}/;
