/* eslint-disable-next-line import/no-unresolved */
import { createElement, useMemo } from 'react';
import { PERIOD, STYLE } from '../constants';
import { isDoingWell, isEfficient } from '../utils';

const makeConvertMap = (def) =>
  Object.keys(def).reduce((obj, key) => ({ ...obj, [def[key].value]: def[key].label }), {});

const styleConvertMap = makeConvertMap(STYLE);

const periodConvertMap = makeConvertMap(PERIOD);

export const useFeatureTags = (FeatureTag, { styleValue, periodValue, riskReturn, recentRoi }) => {
  return useMemo(() => {
    const tags = [];
    if (styleValue != null && periodValue !== '') {
      const styleLabel = styleConvertMap[styleValue];
      if (styleLabel) {
        tags.push(createElement(FeatureTag, { key: 0, backgroundColor: '#85ac39', label: styleLabel }));
      }
    }
    if (periodValue != null && periodValue !== '') {
      const periodLabel = periodConvertMap[periodValue];
      if (periodLabel) {
        tags.push(createElement(FeatureTag, { key: 1, backgroundColor: '#ac5d39', label: periodLabel }));
      }
    }
    if (isEfficient(riskReturn)) {
      tags.push(createElement(FeatureTag, { key: 2, backgroundColor: '#ac3946', label: '効率良' }));
    }
    if (isDoingWell(recentRoi)) {
      tags.push(createElement(FeatureTag, { key: 3, backgroundColor: '#395cac', label: '好調' }));
    }
    return tags;
  }, [FeatureTag, styleValue, periodValue, riskReturn, recentRoi]);
};
