import { all, put, call, takeEvery } from 'redux-saga/effects';
import {
  ORDER_TYPES_NAMES,
  CHANGE_ORDER_FORM,
  CLOSE_ORDER_FORM,
  ORDER_TYPE_INPUT_NAME_BY_BACKEND_FIELD,
  CHANGE_ORDER_TYPE_INPUT_NAME_BY_BACKEND_FIELD,
  CLOSE_ORDER_TYPE_INPUT_NAME_BY_BACKEND_FIELD,
} from '../../constants/manualTrade';
import {
  NEW_SESSION_WITH_THIS_CREDENTIAL_CODE,
  SESSION_EXPIRED_CODE,
  DEFAULT_ERROR_CODE,
  CONNECTION_TIMEOUT_CODE,
} from '../../constants/apiConstant';
import {
  CONNECTION_TIMEOUT_ERROR_MESSAGE,
  DISPLAYING_ERROR_DEFAULT,
  DISPLAYING_ERROR_DEFAULT_MOBILE,
  NEW_SESSION_WITH_THIS_CREDENTIAL_ERROR_MESSAGE,
  SESSION_EXPIRED_ERROR_MESSAGE,
} from '../../constants/errorMesages';
import { UPDATE_VALIDATION_ERRORS } from '../actionConstants/errorConstants';
import { SEND_NOTIFICATION_ERROR } from '../actionConstants/notificationConstants';
import { DEBUG } from '../../config';
import { checkIsWebApp } from '../../services';
import { openErrorInfoModal, sendErrorToBugsnag, updateValidationError } from '../actions/errorActions';
import {
  changeCreateOrderValidationErrors,
  updateChangeOrderValidationErrors,
  updateCloseOrderValidationErrors,
} from '../actions/manualTradeActions';
import { clearAutoSelectFilterCondition } from '../actions/autoSelectActions';

// eslint-disable-next-line
export function* errorHandler({ error, isInitialRequest = false, form = '', buttonText, linkURL }) {
  try {
    const errorResponse = error.response;
    if (!errorResponse) {
      // open error modal if don't receive any response from server,
      // for example back-end for REST API is disabled, but socket API is working
      const message = checkIsWebApp() ? DISPLAYING_ERROR_DEFAULT : DISPLAYING_ERROR_DEFAULT_MOBILE;
      yield put(openErrorInfoModal({ message, title: 'エラー', withRefreshButton: true, buttonText, linkURL }));

      yield put(sendErrorToBugsnag({ error }));

      if (DEBUG) {
        console.error(error); // eslint-disable-line
      }

      return;
    }

    const {
      data: { message, title = 'エラー', errorFieldList },
      status,
    } = errorResponse;

    switch (status) {
      // if server return MAINTENANCE_REDIRECT_RESPONSE_CODE, should be redirected to maintenance page
      // case MAINTENANCE_REDIRECT_RESPONSE_CODE: {
      //   yield put(handleRedirect({ url: MAINTENANCE_PAGE_URL, isInner: false }));
      //   break;
      // }
      case NEW_SESSION_WITH_THIS_CREDENTIAL_CODE: {
        // forceLogout: true だが、モーダル操作が完了しないこともありえるので一旦ここでクリアしておく
        yield put(clearAutoSelectFilterCondition());
        yield put(
          openErrorInfoModal({
            message: NEW_SESSION_WITH_THIS_CREDENTIAL_ERROR_MESSAGE,
            title,
            forceLogout: true,
            buttonText,
            linkURL,
          }),
        );
        break;
      }
      case SESSION_EXPIRED_CODE: {
        // forceLogout: true だが、モーダル操作が完了しないこともありえるので一旦ここでクリアしておく
        yield put(clearAutoSelectFilterCondition());
        yield put(
          openErrorInfoModal({ message: SESSION_EXPIRED_ERROR_MESSAGE, title, forceLogout: true, buttonText, linkURL }),
        );
        break;
      }
      case CONNECTION_TIMEOUT_CODE: {
        yield put(
          openErrorInfoModal({
            message: CONNECTION_TIMEOUT_ERROR_MESSAGE,
            title,
            withRefreshButton: isInitialRequest,
            buttonText,
            linkURL,
          }),
        );
        break;
      }
      default: {
        if (isInitialRequest) {
          const initialErrorMessage = checkIsWebApp() ? DISPLAYING_ERROR_DEFAULT : DISPLAYING_ERROR_DEFAULT_MOBILE;

          yield put(
            openErrorInfoModal({ message: initialErrorMessage, title, withRefreshButton: true, buttonText, linkURL }),
          );
          return;
        }

        yield put(openErrorInfoModal({ message, title, buttonText, linkURL }));

        if (errorFieldList?.length) {
          yield put(updateValidationError({ errorsList: errorFieldList, form }));
        }
      }
    }
  } catch (e) {
    console.log('error errorHandler: ', e); // eslint-disable-line
  }
}

function* updateValidationErrorHandler(action) {
  try {
    const {
      payload: { errorsList, form },
    } = action;

    if (ORDER_TYPES_NAMES.includes(form)) {
      yield all(
        errorsList.reduce((errorsActions, { field, reason }) => {
          const names = ORDER_TYPE_INPUT_NAME_BY_BACKEND_FIELD[form][field];
          if (names?.length) {
            names.forEach((name) => {
              errorsActions.push(
                put(
                  changeCreateOrderValidationErrors({
                    errorMessage: reason,
                    hasValidationError: true,
                    orderType: form,
                    inputName: name,
                  }),
                ),
              );
            });
          }
          return errorsActions;
        }, []),
      );
    } else if (form === CHANGE_ORDER_FORM) {
      yield all(
        errorsList.map(({ field, reason }) =>
          put(
            updateChangeOrderValidationErrors({
              inputName: CHANGE_ORDER_TYPE_INPUT_NAME_BY_BACKEND_FIELD[field],
              errorMessage: reason,
              hasValidationError: true,
            }),
          ),
        ),
      );
    } else if (form === CLOSE_ORDER_FORM) {
      yield all(
        errorsList.map(({ field, reason }) =>
          put(
            updateCloseOrderValidationErrors({
              inputName: CLOSE_ORDER_TYPE_INPUT_NAME_BY_BACKEND_FIELD[field],
              errorMessage: reason,
              hasValidationError: true,
            }),
          ),
        ),
      );
    }
  } catch (e) {
    console.log('error updateValidationErrorHandler: ', e); // eslint-disable-line
  }
}

function* convertActionToError(action) {
  try {
    const {
      payload: { message, title, status = DEFAULT_ERROR_CODE, buttonText, linkURL },
    } = action;

    const error = {
      response: { data: { message, title }, status },
    };

    yield call(errorHandler, { error, buttonText, linkURL });
  } catch (e) {
    // empty
    console.log('error convertActionToError: ', e); // eslint-disable-line
  }
}

export default function* errorsSagaHandler() {
  yield takeEvery(UPDATE_VALIDATION_ERRORS, updateValidationErrorHandler);
  yield takeEvery(SEND_NOTIFICATION_ERROR, convertActionToError);
}
