import { FX } from '../../constants';
import {
  UPDATE_DISPLAYING_CURRENCY_PAIR,
  GET_DISPLAYING_CURRENCY_PAIR_REQUEST,
  GET_DISPLAYING_CURRENCY_PAIR_SUCCESS,
  GET_INSTRUMENTS_LIST_START_LOADING,
  GET_INSTRUMENTS_LIST_STOP_LOADING,
  GET_INSTRUMENTS_LIST_REQUEST,
  GET_INSTRUMENTS_LIST_SUCCESS,
  GET_ACCOUNT_INFO_START_LOADING,
  GET_ACCOUNT_INFO_STOP_LOADING,
  GET_ACCOUNT_INFO_REQUEST,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_SETTINGS_START_LOADING,
  GET_SETTINGS_STOP_LOADING,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST,
  GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_SUCCESS,
  TOGGLE_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST,
  TOGGLE_USER_SETTING_CROSS_ORDER,
  GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST,
  GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_SUCCESS,
  TOGGLE_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST,
  UPDATE_ORDER_SETTINGS_REQUEST,
  UPDATE_ORDER_SETTINGS_SUCCESS,
  UPDATE_ORDER_SETTINGS_START_LOADING,
  UPDATE_ORDER_SETTINGS_STOP_LOADING,
  ACCEPT_AGREEMENT_INFO_REQUEST,
  ACCEPT_AGREEMENT_INFO_REQUEST_START_LOADING,
  ACCEPT_AGREEMENT_INFO_REQUEST_END_LOADING,
  CHANGE_MARKET_STATUS,
  GET_USER_MAIL_SETTINGS_REQUEST,
  GET_USER_MAIL_SETTINGS_SUCCESS,
  UPDATE_USER_MAIL_SETTINGS_REQUEST,
  UPDATE_USER_MAIL_SETTINGS_SUCCESS,
  GET_ORDER_SETTINGS_REQUEST,
  GET_ORDER_SETTINGS_SUCCESS,
  GET_ORDER_SETTINGS_START_LOADING,
  GET_ORDER_SETTINGS_STOP_LOADING,
  GET_LOGIN_METHOD_SUCCESS,
  GET_USER_MAIL_SETTINGS_START_LOADING,
  GET_USER_MAIL_SETTINGS_STOP_LOADING,
  UPDATE_SELECTION_MAINTENANCE_STATUS,
  UPDATE_SERVICE_MAINTENANCE_STATUS,
  CHANGE_LOSSCUT_STATUS,
  LOSSCUT_STATUS_REQUEST,
  GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST,
  GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST_SUCCESS,
  UPDATE_USER_LEARN_TRIAUTO_STATUS,
  UPDATE_USER_LEARN_TRIAUTO_STATUS_REQUEST,
  UPDATE_IS_FIRST_LOGIN,
  UPDATE_DISCLAIMER_FLG,
} from '../actionConstants/settingsConstants';

export const updateDisplayingTradeCurrencyPair = ({ array, serviceId }) => ({
  type: UPDATE_DISPLAYING_CURRENCY_PAIR,
  payload: { array, serviceId },
});

export const getDisplayingCurrencyPairRequest = () => ({ type: GET_DISPLAYING_CURRENCY_PAIR_REQUEST });
export const getDisplayingCurrencyPairSuccess = ({ displayedInstruments, serviceId }) => ({
  type: GET_DISPLAYING_CURRENCY_PAIR_SUCCESS,
  payload: { displayedInstruments, serviceId },
});

export const getInstrumentListStartLoading = () => ({ type: GET_INSTRUMENTS_LIST_START_LOADING });
export const getInstrumentListStopLoading = () => ({ type: GET_INSTRUMENTS_LIST_STOP_LOADING });
export const getInstrumentListRequest = ({ isPublic = false, isRefetch = false } = {}) => ({
  type: GET_INSTRUMENTS_LIST_REQUEST,
  payload: { isPublic, isRefetch },
});
export const getInstrumentListSuccess = ({ instrumentList }) => ({
  type: GET_INSTRUMENTS_LIST_SUCCESS,
  payload: { instrumentList },
});

export const getAccountInfoStartLoading = () => ({ type: GET_ACCOUNT_INFO_START_LOADING });
export const getAccountInfoStopLoading = () => ({ type: GET_ACCOUNT_INFO_STOP_LOADING });
export const getAccountInfoRequest = ({ isRefetch = false } = {}) => ({
  type: GET_ACCOUNT_INFO_REQUEST,
  payload: { isRefetch },
});
export const getAccountInfoSuccess = ({ fx, etf, cfd }) => ({
  type: GET_ACCOUNT_INFO_SUCCESS,
  payload: { fx, etf, cfd },
});

export const getSettingsStartLoading = () => ({ type: GET_SETTINGS_START_LOADING });
export const getSettingsStopLoading = () => ({ type: GET_SETTINGS_STOP_LOADING });
export const getSettingsRequest = ({ isPublic = false, isRefetch = false } = {}) => ({
  type: GET_SETTINGS_REQUEST,
  payload: { isPublic, isRefetch },
});
export const getSettingsSuccess = ({ loginMethod, selectionMaintenanceStatus, isFirstLogin, fx, etf, cfd }) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: { loginMethod, selectionMaintenanceStatus, isFirstLogin, fx, etf, cfd },
});

export const getOrderSettingsRequest = () => ({ type: GET_ORDER_SETTINGS_REQUEST });
export const getOrderSettingsSuccess = ({ orderType, quantity, expirationType, serviceId }) => ({
  type: GET_ORDER_SETTINGS_SUCCESS,
  payload: { orderType, quantity, expirationType, serviceId },
});
export const getOrderSettingsStartLoading = () => ({ type: GET_ORDER_SETTINGS_START_LOADING });
export const getOrderSettingsStopLoading = () => ({ type: GET_ORDER_SETTINGS_STOP_LOADING });

export const getUserMailSettingsStartLoading = () => ({ type: GET_USER_MAIL_SETTINGS_START_LOADING });
export const getUserMailSettingsStopLoading = () => ({ type: GET_USER_MAIL_SETTINGS_STOP_LOADING });
export const getUserMailSettingsRequest = ({ serviceId }) => ({
  type: GET_USER_MAIL_SETTINGS_REQUEST,
  payload: { serviceId },
});
export const getUserMailSettingsSuccess = ({ primaryMailAddress, secondaryMailAddress, mailActions, serviceId }) => ({
  type: GET_USER_MAIL_SETTINGS_SUCCESS,
  payload: { primaryMailAddress, secondaryMailAddress, mailActions, serviceId },
});

export const getUserSettingNewOrderSkipConfirmationRequest = () => ({
  type: GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST,
});
export const getUserSettingNewOrderSkipConfirmationSuccess = ({ value, serviceId }) => ({
  type: GET_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_SUCCESS,
  payload: { value, serviceId },
});
export const toggleUserSettingNewOrderSkipConfirmationRequest = () => ({
  type: TOGGLE_USER_SETTING_NEW_ORDER_SKIP_CONFIRMATION_REQUEST,
});
export const toggleUserSettingCrossOrder = ({ value, serviceId }) => ({
  type: TOGGLE_USER_SETTING_CROSS_ORDER,
  payload: { value, serviceId },
});
export const getUserSettingCloseOrderSkipConfirmationRequest = () => ({
  type: GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST,
});
export const getUserSettingCloseOrderSkipConfirmationSuccess = ({ value, serviceId }) => ({
  type: GET_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_SUCCESS,
  payload: { value, serviceId },
});
export const toggleUserSettingCloseOrderSkipConfirmationRequest = () => ({
  type: TOGGLE_USER_SETTING_CLOSE_ORDER_SKIP_CONFIRMATION_REQUEST,
});

// todo: remove default serviceId value after WEB email settings will be updated for ETF
export const updateUserMailSettingsRequest = ({
  primaryMailAddress,
  secondaryMailAddress,
  mailActions,
  oldMailActions,
  callback,
  serviceId = FX,
}) => ({
  type: UPDATE_USER_MAIL_SETTINGS_REQUEST,
  payload: { primaryMailAddress, secondaryMailAddress, mailActions, oldMailActions, callback, serviceId },
});
export const updateUserMailSettingsSuccess = ({
  primaryMailAddress,
  secondaryMailAddress,
  mailActions,
  serviceId,
}) => ({
  type: UPDATE_USER_MAIL_SETTINGS_SUCCESS,
  payload: { primaryMailAddress, secondaryMailAddress, mailActions, serviceId },
});

export const updateOrderSettingsRequest = ({ orderType, quantity, expirationType, callback }) => ({
  type: UPDATE_ORDER_SETTINGS_REQUEST,
  payload: { orderType, quantity, expirationType, callback },
});
export const updateOrderSettingsSuccess = ({ orderType, quantity, expirationType, serviceId }) => ({
  type: UPDATE_ORDER_SETTINGS_SUCCESS,
  payload: { orderType, quantity, expirationType, serviceId },
});
export const updateOrderSettingsStartLoading = () => ({ type: UPDATE_ORDER_SETTINGS_START_LOADING });
export const updateOrderSettingsStopLoading = () => ({ type: UPDATE_ORDER_SETTINGS_STOP_LOADING });
export const acceptAgreementInfoRequest = ({ callback }) => ({
  type: ACCEPT_AGREEMENT_INFO_REQUEST,
  payload: { callback },
});
export const acceptAgreementInfoRequestStartLoading = () => ({ type: ACCEPT_AGREEMENT_INFO_REQUEST_START_LOADING });
export const acceptAgreementInfoRequestEndLoading = () => ({ type: ACCEPT_AGREEMENT_INFO_REQUEST_END_LOADING });

export const changeMarketStatus = ({ status, serviceId, instrumentId }) => ({
  type: CHANGE_MARKET_STATUS,
  payload: { status, serviceId, instrumentId },
});

export const getLoginMethodSuccess = ({ loginMethod }) => ({
  type: GET_LOGIN_METHOD_SUCCESS,
  payload: { loginMethod },
});

export const updateSelectionMaintenanceStatus = ({ status }) => ({
  type: UPDATE_SELECTION_MAINTENANCE_STATUS,
  payload: { status },
});

export const updateServiceMaintenanceStatus = ({ serviceId, isMaintenance }) => ({
  type: UPDATE_SERVICE_MAINTENANCE_STATUS,
  payload: { serviceId, isMaintenance },
});

export const updateDisclaimerFlg = () => ({
  type: UPDATE_DISCLAIMER_FLG,
});

export const updateIsFirstLogin = () => ({
  type: UPDATE_IS_FIRST_LOGIN,
});

export const losscutStatusRequest = ({ serviceId, callback }) => ({
  type: LOSSCUT_STATUS_REQUEST,
  payload: { serviceId, callback },
});

export const changeLosscutStatus = ({ status, serviceId }) => ({
  type: CHANGE_LOSSCUT_STATUS,
  payload: { status, serviceId },
});

export const getLearnTriautoConfirmationRequest = () => ({ type: GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST });

export const getUserSettingLearnTriautoConfirmationRequestSuccess = ({ value }) => ({
  type: GET_USER_SETTING_LEARN_TRIAUTO_CONFIRMATION_REQUEST_SUCCESS,
  payload: { value },
});

export const updateUserLearnTriautoStatus = ({ key }) => ({
  type: UPDATE_USER_LEARN_TRIAUTO_STATUS,
  payload: { key },
});

export const updateUserLearnTriautoStatusRequest = () => ({ type: UPDATE_USER_LEARN_TRIAUTO_STATUS_REQUEST });
