import { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { HOW_TO_READ_CARD, HOW_TO_READ_CARD_BOLD_LINES } from 'shared-modules/constants/select';
import { MODAL_SIZES } from 'shared-modules/constants';
import { Modal } from '../../../../components';
import styles from './cardDescription.module.scss';

export const CardDescription = memo(() => {
  const [visible, setVisible] = useState(false);
  const handleOpen = useCallback(() => {
    setVisible(true);
  }, []);
  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);
  return (
    <div className={styles.container}>
      <div>セレクトの見方</div>
      <div className={styles.iconButton} role="button" tabIndex={0} onClick={handleOpen} aria-hidden>
        <i className={classNames('material-icons-outlined', styles.icon)}>help_outline</i>
      </div>
      {visible && (
        <Modal
          isOpen={visible}
          closeModal={handleClose}
          title="表示について"
          size={MODAL_SIZES.WIDTH_960}
          titleStyle={styles.title}
        >
          <div className={styles.content}>
            {HOW_TO_READ_CARD.map((line, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classNames({
                  [styles.boldLine]: HOW_TO_READ_CARD_BOLD_LINES.includes(line),
                })}
              >
                {line}
                <br />
              </span>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
});
