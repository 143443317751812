import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  COLORS,
  DEFAULT_LAB_SELECTED_TERM_ID,
  LAB_SIMULATION_EMPTY_MESSAGE,
  LAB_STATUS_MAP,
  MODAL_SIZES,
} from 'shared-modules/constants';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import {
  SHARE_CANCEL_ACTION,
  SHARE_CANCEL_CONFIRMATION,
  SHARE_CANCEL_TITLE,
  SHARE_STOP_ACTION,
  SHARE_STOP_CONFIRMATION,
  SHARE_STOP_TITLE,
} from 'shared-modules/constants/builder';
import {
  cancelLabRequest,
  getAllLabsRequest,
  getLabDetailsRequest,
  labCancelingSelector,
  labSimulationSelector,
  resetForm,
} from 'shared-modules/redux/labs';
import { getJapaneseFormatDate } from 'shared-modules/services';
import { useCalculatingSimulationChartData } from 'shared-modules/services/hooks';
import { useMyLabDetails, useStrategyListForRiskAssessment } from 'shared-modules/hooks/share';
import { useRiskAssessment } from 'shared-modules/hooks';
import TVChart from '../../../../../components/TVChart';
import styles from './strategies.module.scss';
import { Strategy } from './Strategy';
import { Welcome } from './Welcome';
import Card from '../../../../../appWrappers/ModalContainer/components/LabDetailModal/components/Card/Card';
import { getLabImageSrc } from '../../../../../services';
import CustomButton from '../../../../../components/CustomButton';
import CustomModal from '../../../../../components/Modal/index';
import { Tables } from '../../../../../appWrappers/ModalContainer/components/LabDetailModal/components/Tables';
import Demo from '../../../../../appWrappers/ModalContainer/components/LabDetailModal/components/Demo/index';
import { ItemButtons, ProfitLossChart, Spin, Tabs, Tag } from '../../../../../components';
import { CHART_MARGIN } from '../../../../AutoSelect/components/SimulationChart/CommonChart';
import Nav from '../../../../../components/common/Nav';
import { openAutoSelectWarningInfo } from '../../../../../redux/actions';

const defaultTerm = { value: DEFAULT_LAB_SELECTED_TERM_ID, label: '稼働日' };

const ConfirmModal = ({ isOpen, title, message, closeModal, okHandler }) => {
  return (
    <CustomModal isOpen={isOpen} title={title} closeModal={closeModal} size={MODAL_SIZES.WIDTH_400} isOverlap>
      <div className={styles.confirmModalBody}>
        <div className={styles.message}>{message}</div>
        <div className={styles.buttonRow}>
          <CustomButton
            width={168}
            color={COLORS.LIGHT_GREY}
            className={classNames(styles.buttonMargin, styles.cancelButton)}
            onClick={closeModal}
          >
            戻る
          </CustomButton>
          <CustomButton width={168} color={COLORS.GREEN} onClick={okHandler} className={styles.cancelButton}>
            OK
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  okHandler: PropTypes.func.isRequired,
};

const TAB_PRICE_CHART = 'tvChart';
const TAB_SIMULATION_CHART = 'simulationChart';

export const Strategies = memo(() => {
  const dispatch = useDispatch();
  const [key, setKey] = useState('tvChart');
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const myLabs = useSelector((state) => state.labs.myLabs);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const selectedLab = useMemo(
    () => (selectedServiceId ? myLabs[selectedServiceId][0] : {}),
    [myLabs, selectedServiceId],
  );
  const {
    id,
    name,
    image,
    status,
    instanceCount,
    defaultSets,
    termStart,
    style: styleValue,
    technicalTerm,
  } = useMemo(() => selectedLab, [selectedLab]);
  const statusStr = useMemo(() => LAB_STATUS_MAP[String(status)], [status]);
  const isPublished = useMemo(() => statusStr === 'シェア中', [statusStr]);
  const modalTitle = useMemo(() => (isPublished ? SHARE_STOP_TITLE : SHARE_CANCEL_TITLE), [isPublished]);
  const buttonText = useMemo(() => (isPublished ? SHARE_STOP_ACTION : SHARE_CANCEL_ACTION), [isPublished]);
  const alertText = useMemo(() => (isPublished ? SHARE_STOP_CONFIRMATION : SHARE_CANCEL_CONFIRMATION), [isPublished]);

  const { result: simulationData, loading: isLoading = false } = useSelector(labSimulationSelector);
  const labCanceling = useSelector(labCancelingSelector);
  const [isOpenStopLabConfirmModal, setOpenStopLabConfirmModal] = useState(false);

  const [termId, changeTermId] = useState(DEFAULT_LAB_SELECTED_TERM_ID);
  const selectionTermOptionsRaw = useSelector((state) => state.constants.selectionTermOptions);
  const selectionTermOptions = useMemo(() => {
    return [defaultTerm, ...selectionTermOptionsRaw];
  }, [selectionTermOptionsRaw]);

  const [selectedStrategyId, setSelectedStrategyId] = useState(simulationData?.strategyList?.[0]?.id);
  const simulationStats = useMemo(() => simulationData?.simulationStats ?? {}, [simulationData?.simulationStats]);
  const selectedStrategy = useMemo(
    () => simulationData?.strategyList?.find((s) => s.id === selectedStrategyId),
    [selectedStrategyId, simulationData?.strategyList],
  );
  const { instrumentId: selectedInstrumentId, itemList } = useMemo(() => selectedStrategy ?? {}, [selectedStrategy]);
  const orders = useMemo(
    () => itemList?.map((i) => ({ orderId: `${i.strategyId}-${i.itemId}`, price: i.entryPrice1, side: i.side })),
    [itemList],
  );
  const strategyListForRA = useStrategyListForRiskAssessment(simulationData);
  const riskAssessment = useRiskAssessment(strategyListForRA, defaultSets);

  const calculationDataSources = useSelector((state) => state.labs.myLabDetails);
  useMyLabDetails();

  const stopHandler = useCallback(() => {
    dispatch(
      cancelLabRequest({
        labId: id,
        callback: () => {
          setOpenStopLabConfirmModal(false);
          dispatch(getAllLabsRequest());
          dispatch(resetForm());
        },
      }),
    );
  }, [dispatch, id]);

  const chartData = useCalculatingSimulationChartData({
    strategyList: [simulationData ?? {}],
  }).map((o) => {
    const multiplied = { ...o };
    multiplied.pl *= defaultSets;
    multiplied.realizedPl *= defaultSets;
    multiplied.sum *= defaultSets;
    multiplied.upl *= defaultSets;
    return multiplied;
  });

  // シェアは単一サービス
  const serviceId = simulationData?.strategyList
    ?.map((strategy) => instrumentList[strategy.instrumentId]?.serviceId)
    ?.find((sid) => sid);

  const itemsCount = useMemo(
    () =>
      simulationData?.strategyList?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.itemList.length,
        0,
      ),
    [simulationData],
  );

  const selectedTableTypeId = key === 'tvChart' ? 1 : 0;

  useEffect(() => {
    dispatch(resetForm());
  }, [dispatch]);

  useEffect(() => {
    setSelectedStrategyId(simulationData?.strategyList?.[0]?.id);
  }, [simulationData]);

  useEffect(() => {
    if (id) dispatch(getLabDetailsRequest({ labId: id, termId }));
  }, [dispatch, id, termId]);

  useEffect(() => {
    if (!id) {
      setOpenStopLabConfirmModal(false);
    }
  }, [id]);

  const handleOpenWarning = useCallback(
    () =>
      dispatch(
        openAutoSelectWarningInfo({
          termEndDate: getJapaneseFormatDate(simulationStats.termEnd),
          isLabPreview: false,
        }),
      ),
    [dispatch, simulationStats.termEnd],
  );

  const stopButton = (
    <button
      type="button"
      className="btn btn-outline-danger"
      style={{ fontWeight: 600, fontSize: 14, border: 0 }}
      onClick={() => {
        setOpenStopLabConfirmModal(true);
      }}
      disabled={labCanceling}
    >
      {buttonText}
    </button>
  );

  const options = useMemo(() => {
    if (_.isEmpty(simulationData?.strategyList)) {
      return [];
    }
    return simulationData?.strategyList.map(({ id: strategyId, name: value }) => ({
      id: strategyId,
      value,
    }));
  }, [simulationData?.strategyList]);

  const handleChange = useCallback(
    (strategyId) => {
      const strategy = simulationData?.strategyList?.find((e) => e.id === strategyId);
      if (strategy) {
        setSelectedStrategyId(strategyId);
      }
    },
    [simulationData?.strategyList],
  );

  const tabItems = useMemo(() => {
    return [
      {
        id: TAB_PRICE_CHART,
        label: 'ロジック設定',
        children: (
          <div style={{ height: 340 }}>
            <div style={{ marginTop: 5, marginBottom: 5 }}>
              <ItemButtons options={options} activeId={selectedStrategyId} onChange={handleChange} />
            </div>

            {selectedInstrumentId && <TVChart selectedInstrumentId={selectedInstrumentId} orders={orders} />}
          </div>
        ),
      },
      {
        id: TAB_SIMULATION_CHART,
        label: 'シミュレーション',
        children: simulationData?.simulationChartList?.length ? (
          <>
            <div className="d-flex align-items-center my-2">
              <div className="me-3">シミュレーション期間</div>
              <Nav
                items={selectionTermOptions}
                onItemSelect={(item) => changeTermId(item.value)}
                selectedItem={selectionTermOptions.find((item) => item.value === termId)}
              />
              <CustomButton color={COLORS.TRANSPARENT} onClick={handleOpenWarning} className="ms-auto me-1">
                注意事項
              </CustomButton>
            </div>
            <div style={{ height: 320 }}>
              {!isLoading ? (
                <ProfitLossChart chartMargin={CHART_MARGIN} data={chartData} useDefaultYAxisWidth />
              ) : (
                <>
                  <div className={styles.loaderContainer}>
                    <Spin />
                  </div>
                  <ProfitLossChart chartMargin={CHART_MARGIN} data={chartData} useDefaultYAxisWidth />
                </>
              )}
            </div>
          </>
        ) : (
          <div className={styles.blankWrapper}>{LAB_SIMULATION_EMPTY_MESSAGE}</div>
        ),
      },
    ];
  }, [
    options,
    selectedStrategyId,
    handleChange,
    handleOpenWarning,
    selectedInstrumentId,
    orders,
    chartData,
    selectionTermOptions,
    termId,
    simulationData?.simulationChartList?.length,
    isLoading,
  ]);

  const Table = Tables[serviceId];
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {ALL_SERVICES.map((service) => {
          const [myLab] = myLabs[service];
          const hasShare = !_.isEmpty(myLab);
          const title = hasShare ? LAB_STATUS_MAP[myLab.status] : 'シェアなし';
          const [calculationDataSource] = calculationDataSources?.[service] ?? [];
          return (
            <div key={service} className={styles.cardContainer}>
              <div className={styles.cardHeader}>
                <div className={styles.tag}>
                  <Tag text={service} />
                </div>
                <div>{title}</div>
              </div>
              {hasShare ? (
                <Strategy
                  lab={myLab}
                  serviceId={service}
                  onClick={() => setSelectedServiceId(service)}
                  hasSimulationData={myLab?.simulationChartList?.length > 0}
                  simulationData={calculationDataSource}
                />
              ) : (
                <Welcome serviceId={service} />
              )}
            </div>
          );
        })}
      </div>
      <Modal
        contentClassName="popup"
        show={Boolean(selectedServiceId)}
        onHide={() => setSelectedServiceId(null)}
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName={classNames(styles.wrapper)}
      >
        <Modal.Header className="popup__header" closeButton closeVariant="white">
          <Modal.Title>
            <div className={styles.nameWrapper}>
              <div className={styles.name}>
                タイトル：
                {name}
              </div>
              {statusStr && (
                <div className={styles.statusBadgeWrapper}>
                  <div
                    className={classNames(styles.statusBadge, {
                      [styles.isGreen]: statusStr === 'シェア中' || statusStr === 'シェア審査中',
                    })}
                  >
                    {statusStr}
                  </div>
                </div>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="popup__body">
          <div className="row flex-nowrap mb-1">
            <div className="col-3" style={{ paddingTop: 15 }}>
              <Card
                closeModal={() => {}}
                isLoading={false}
                ButtonElements={stopButton}
                imageUrl={getLabImageSrc(image)}
                simulationStats={simulationStats}
                createTime={termStart}
                itemsCount={itemsCount}
                instanceCount={instanceCount}
                technicalStyle={styleValue}
                technicalTerm={technicalTerm}
                isPost={false}
                riskAssessment={riskAssessment}
                simulationData={calculationDataSources ? calculationDataSources?.[selectedServiceId]?.[0] : null}
              />
            </div>
            <div className="col-9" style={{ height: 420 }}>
              {selectedInstrumentId && (
                <Tabs containerClassName={styles.tabs} items={tabItems} activeKey={key} onChange={setKey} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col" style={{ height: 300, width: 1200 }}>
              <div className={classNames('text-start', styles.tableTitle)}>
                {key === 'tvChart' ? '注文設定' : '直近の約定'}
              </div>

              {Table && (
                <Table
                  isLoading={isLoading}
                  simulationData={simulationData}
                  isPost={false}
                  defaultSets={defaultSets}
                  selectedTableTypeId={selectedTableTypeId}
                />
              )}
            </div>
          </div>
          <Demo termEndDate={simulationStats?.termEnd ?? new Date().toISOString()} isPreview={false} />
        </Modal.Body>
      </Modal>
      <ConfirmModal
        isOpen={isOpenStopLabConfirmModal}
        title={modalTitle}
        message={alertText}
        closeModal={() => {
          setOpenStopLabConfirmModal(false);
        }}
        okHandler={stopHandler}
      />
    </div>
  );
});
