import { get, invokeApi, createAdditionalParams } from './index';
import { QUICK_DEPOSIT_FAILED_URL, QUICK_DEPOSIT_SUCCESS_URL } from '../config';

export const getUserSSOMyPage = async () => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/user_sso_mypage`, get, additionalParams);
};

export const getUserSSOPayment = async ({ serviceId }) => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/user_sso_payment?serviceId=${serviceId}`, get, additionalParams);
};

export const getUserSSOMoneyHatch = async () => {
  const additionalParams = await createAdditionalParams();

  return invokeApi({}, `settings/user_sso_moneyhatch`, get, additionalParams);
};

export const getUserSSOQuickDeposit = async ({ serviceId, bankCode, amount, redirectKey }) => {
  const additionalParams = await createAdditionalParams();

  let requestQuery = `?bankCode=${bankCode}&amount=${amount}&redirectKey=${redirectKey}`;
  requestQuery += `&failUrl=${QUICK_DEPOSIT_FAILED_URL}&successUrl=${QUICK_DEPOSIT_SUCCESS_URL}`;

  return invokeApi({}, `${serviceId}/cashflow/bank/hash${requestQuery}`, get, additionalParams);
};
