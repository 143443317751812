/* eslint-disable-next-line import/no-unresolved */
import { useCallback } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useSelector } from 'react-redux';
import { FX } from '../constants';

export const useGetInstrumentDisplayName = () => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  return useCallback(
    (instrumentId, serviceId) => (serviceId === FX ? instrumentId : instrumentList?.[instrumentId]?.shortName ?? '-'),
    [instrumentList],
  );
};
