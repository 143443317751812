import React, { memo, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MAINTENANCE_INFOPAGE_URL } from 'shared-modules/config';
import { SYSTEM_MAINTENANCE_STATUS } from 'shared-modules/constants/apiConstant';
import { MAINTENANCE_ERROR_MESSAGE_WEB } from 'shared-modules/constants/errorMesages';
import ReactTooltip from 'react-tooltip';
import {
  HOME,
  AUTO_SELECT,
  BUILDER,
  CART,
  CASH,
  MANUAL_TRADE,
  MEDIA,
  MESSAGE,
  TRADE_INFO,
} from '../../../../constants';
import { tutorialNavBarAutoSelect } from '../../../../constants/tutorial/classNames';
import { sendNotificationError } from '../../../../redux/actions';
import { UnreadMark } from '../../../../components';
import styles from './navBar.module.scss';

const NavBar = ({ onClick, isExpanded, cartItemsCount }) => {
  const selectionMaintenanceStatus = useSelector((state) => state.settings.selectionMaintenanceStatus);
  const isAutoSelectMaintenance = selectionMaintenanceStatus === SYSTEM_MAINTENANCE_STATUS;
  const unreadCount = useSelector((state) => state.message.unreadCount);

  const dispatch = useDispatch();
  const openMaintenanceModal = () => {
    dispatch(sendNotificationError({ message: MAINTENANCE_ERROR_MESSAGE_WEB, linkURL: MAINTENANCE_INFOPAGE_URL }));
  };

  const NavArray = useMemo(
    () => [
      {
        id: 0,
        to: `/${HOME}`,
        exact: true,
        icon: 'home',
        isIconOutlined: true,
        label: 'ホーム',
        expandedLabel: 'ホーム',
      },
      {
        id: 1,
        className: tutorialNavBarAutoSelect,
        to: `/${AUTO_SELECT}`,
        exact: false,
        icon: 'view_agenda',
        isIconOutlined: true,
        label: 'セレクト',
        expandedLabel: 'セレクト',
        isMaintenance: isAutoSelectMaintenance,
      },
      {
        id: 2,
        to: `/${BUILDER}`,
        exact: false,
        icon: 'handyman',
        isIconOutlined: true,
        label: 'ビルダー',
        expandedLabel: 'ビルダー',
      },
      {
        id: 3,
        to: `/${MANUAL_TRADE}`,
        exact: false,
        icon: 'compare_arrows',
        label: 'トレード',
        expandedLabel: 'トレード',
      },
      {
        id: 4,
        to: `/${TRADE_INFO}`,
        exact: false,
        icon: 'view_list',
        isIconOutlined: true,
        label: '照会',
        expandedLabel: '照会',
      },
      {
        id: 5,
        to: `/${CART}`,
        exact: false,
        icon: 'shopping_cart',
        label: 'カート',
        withCounter: true,
        expandedLabel: 'カート',
      },
      {
        id: 6,
        to: `/${CASH}`,
        exact: false,
        icon: 'import_export',
        label: '入出金',
        expandedLabel: '入出金',
      },
      {
        id: 7,
        to: `/${MEDIA}`,
        exact: false,
        icon: 'subscriptions',
        label: '動画',
        expandedLabel: '動画',
      },
      {
        id: 8,
        to: `/${MESSAGE}`,
        exact: false,
        icon: 'notifications',
        isIconOutlined: true,
        label: 'メッセージ',
        expandedLabel: 'メッセージ',
        mark: (unreadCount ?? 0) > 0 ? <UnreadMark top={0} right={isExpanded ? 20 : 6} /> : undefined,
      },
    ],
    [isAutoSelectMaintenance, isExpanded, unreadCount],
  );

  const clickHandler = useCallback(
    (e) => {
      onClick(e);
      e.currentTarget.blur();
    },
    [onClick],
  );

  const wrapperStyle = useMemo(() => {
    const size = NavArray.length;
    if (size > 1 && !isExpanded) {
      return {
        gridTemplateRows: `repeat(${size - 1}, max-content) auto max-content`,
      };
    }
    return {
      gridTemplateRows: `repeat(${size}, max-content)`,
    };
  }, [NavArray, isExpanded]);

  return (
    <div style={wrapperStyle} className={classNames(styles.wrapper, { [styles.isExpanded]: isExpanded })}>
      {NavArray.map((item, i) => {
        const lintContent = (
          <>
            {item.new && <div className={styles.badge}>NEW</div>}
            <i className={`material-icons${item.isIconOutlined ? '-outlined' : ''}`}>{item.icon}</i>
            <span className={classNames(styles.text, { [styles.isExpanded]: isExpanded })}>
              {isExpanded ? item.expandedLabel : item.label}
            </span>
            {item.withCounter && Boolean(cartItemsCount) && (
              <div className={classNames(styles.counter, { [styles.isExpanded]: isExpanded })}>{cartItemsCount}</div>
            )}
            {item.mark}
          </>
        );

        const filler = NavArray.length === i + 1 && !isExpanded ? <div /> : null;
        return (
          <React.Fragment key={item.id}>
            {filler}
            {item.isDisabled || item.isMaintenance ? (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                onClick={item.isMaintenance ? openMaintenanceModal : () => {}}
                role="button"
                tabIndex={i}
                className={classNames(styles.item, styles.isDisabled, { [styles.isExpanded]: isExpanded })}
                data-for={item?.tooltipId?.current}
                data-tip={item?.tooltipMessage}
              >
                {lintContent}
                <ReactTooltip id={item?.tooltipId?.current} />
              </div>
            ) : (
              <NavLink
                to={item.to}
                exact={item.exact}
                className={classNames(item.className, styles.item, { [styles.isExpanded]: isExpanded })}
                activeClassName={styles.isActive}
                onClick={clickHandler}
              >
                {lintContent}
              </NavLink>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

NavBar.propTypes = {
  cartItemsCount: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  isExpanded: PropTypes.bool,
};

NavBar.defaultProps = {
  onClick: () => {},
  isExpanded: false,
};

export default memo(NavBar);
