import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { ReloadingAppProvider } from 'shared-modules/contexts';
import { systemErrorHandler } from 'shared-modules/services';
import { loginFlowRequest } from 'shared-modules/redux/actions';
import { history, store } from './redux/store';
import { sessionExpiryErrorHandler } from './redux/sagas/commonHandlers';
import RoutingComponent from './appWrappers/RoutingComponent';
import NotificationContainer from './appWrappers/NotificationContainer/NotificationContainer';
import ModalContainer from './appWrappers/ModalContainer';
import { getAsyncStorage, reloadApp } from './services';
import { SubWindowManagementProvider } from './contexts';
import 'react-toastify/dist/ReactToastify.css';
import 'react-grid-layout/css/styles.css';

store.dispatch(loginFlowRequest({ startup: true, sessionExpiryErrorHandler }));

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ReloadingAppProvider storage={getAsyncStorage()} reload={reloadApp}>
          <SubWindowManagementProvider>
            {process.env.NODE_ENV !== 'development' ? (
              <ErrorBoundary onError={() => systemErrorHandler(true)} FallbackComponent={() => <></>}>
                <RoutingComponent />
                <ModalContainer />
                <NotificationContainer />
              </ErrorBoundary>
            ) : (
              <>
                <RoutingComponent />
                <ModalContainer />
                <NotificationContainer />
              </>
            )}
          </SubWindowManagementProvider>
        </ReloadingAppProvider>
      </Router>
    </Provider>
  );
};

export default App;
