import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMessage } from 'shared-modules/hooks/message';
import { CHANNEL_BROWSER } from 'shared-modules/constants';
import { UnreadMark } from '../../../components';
import styles from './card.module.scss';

const browse = ({ linkUrl, messageId }) => window.open(linkUrl, messageId);

export const Card = memo(({ message }) => {
  const { showMessage, messageDate } = useMessage({ message, browse, channel: CHANNEL_BROWSER });
  const { messageId, action, linkUrl, thumbnailUrl, title, status } = message;
  return (
    <div key={messageId} role="button" className={styles.container} onClick={showMessage} aria-hidden>
      {!status?.readFlg && <UnreadMark top={6} right={10} />}
      <div className={styles.thumbnailContainer}>
        <img src={thumbnailUrl} className={styles.thumbnail} alt="" />
      </div>
      <div className={styles.info}>
        <div className={styles.titleArea}>
          <div className={styles.title}>{title}</div>
          <div className={styles.date}>{messageDate}</div>
        </div>
        <div className={styles.iconArea}>
          {!action && linkUrl && <i className={classNames('material-icons', styles.icon)}>open_in_new</i>}
        </div>
      </div>
    </div>
  );
});

Card.propTypes = {
  message: PropTypes.shape({
    messageId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    action: PropTypes.string,
    linkUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string.isRequired,
    startTime: PropTypes.string,
    status: PropTypes.shape({
      startTime: PropTypes.string,
      readFlg: PropTypes.bool,
    }),
  }).isRequired,
};
