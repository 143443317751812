/* eslint-disable import/no-unresolved,import/no-extraneous-dependencies */
import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Decimal from 'decimal.js';
import {
  AP_GROUP_ORDER,
  AP_GROUP_RULE_67_NOT_YEN_PAIR_MULTIPLIER,
  AP_GROUP_RULE_67_YEN_PAIR_MULTIPLIER,
  AP_GROUP_SOURCES,
  BUY_SELL_MAIN,
  BUY_SELL_VALUE,
  CFD_AP_GROUP_CHANGE_LOGIC_STEP,
  COUNT_INPUT_NAME,
  COUNTER_BUY_MAX,
  COUNTER_BUY_MIN,
  COUNTER_NAME,
  COUNTER_PRECISION,
  COUNTER_PRICE_NAME,
  COUNTER_PRICE_PRECISION,
  COUNTER_SELL_MAX,
  COUNTER_SELL_MIN,
  COUNTER_STEP,
  COUNTRY_TYPE,
  ETF,
  FOLLOW_BUY_MAX,
  FOLLOW_BUY_MIN,
  FOLLOW_NAME,
  FOLLOW_PRECISION,
  FOLLOW_SELL_MAX,
  FOLLOW_SELL_MIN,
  FOLLOW_STEP,
  FX,
  LOSS_CUT_WIDTH_MAX,
  LOSS_CUT_WIDTH_MIN,
  LOSS_CUT_WIDTH_NAME,
  LOSS_CUT_WIDTH_PRECISION,
  LOSS_CUT_WIDTH_STEP,
  MAX_QUANTITY_AP_ORDER,
  OPTIONS_COUNTER_TYPE,
  OPTIONS_ORDER_TYPE,
  PRICE_1_NAME,
  PRICE_2_NAME,
  PRICE_MAX_MULTIPLIER,
  PRICE_MAX_NOT_PAIRED_YEN,
  PRICE_MAX_PAIRED_YEN,
  PRICE_MIN_MULTIPLIER,
  PROFIT_MARGIN_MAX,
  PROFIT_MARGIN_MIN,
  PROFIT_MARGIN_NAME,
  PROFIT_MARGIN_PRECISION,
  PROFIT_MARGIN_STEP,
  TRADE_METHODS,
  VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_BUY,
  VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_SELL,
  VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_BUY,
  VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_SELL,
  VALIDATION_ERROR_AP_ORDER_LOST_CUT_PIPS,
  VALIDATION_ERROR_AP_ORDER_OCO2_LESS_THAN_OCO1_BUY,
  VALIDATION_ERROR_AP_ORDER_OCO2_MORE_THAN_OCO1_SELL,
  VALIDATION_ERROR_AP_ORDER_PROFIT_MARGIN_PIPS,
  VALIDATION_ERROR_AP_RULE_66,
} from '../../constants';
import { changeApGroupItemRequest } from '../../redux/actions/portfolioActions';
import {
  createValidationErrorMessageAPOrderCounterPriceBuy,
  createValidationErrorMessageAPOrderCounterPriceSell,
  createValidationErrorMessageAPOrderEntryPrice1Buy,
  createValidationErrorMessageAPOrderEntryPrice1Sell,
  createValidationErrorMessageAPOrderEntryPrice2Buy,
  createValidationErrorMessageAPOrderEntryPrice2Sell,
  createValidationErrorMessageAPR67ProfitMarginBuy,
  createValidationErrorMessageAPR67ProfitMarginSell,
  createValidationErrorMessageAPR67LossCutWidthBuy,
  createValidationErrorMessageAPR67LossCutWidthSell,
  getAPQuantityMin,
  getAPQuantityStep,
  getAPValidateQuantityErrorMessage,
  getPriceStep,
  ORDER_DETAILS_EDIT_HELPERS as editHelpers,
  getServiceQuantityUnit,
  checkIsWebApp,
  roundExactlyOnPrecisionMatching,
} from '../index';
import { useInstrumentSettings, usePricesForBuySell } from './index';
import { getPipsLabelWithParentheses, getValidationCurrencyUnitByServiceId } from '../../utils';
import { useInstrumentShortName } from '../../hooks';

const rule66Types = {
  follow: 0,
  counter: 1,
};

const nanToBlank = (value) => (Number.isNaN(Number(value)) ? '' : value);

const useDynamicAPGroupChangeInfo = (data, isOpen = false) => {
  const {
    name,
    side,
    quantity,
    entryPrice1,
    entryPrice2,
    tp,
    sl,
    follow,
    counter,
    counterPrice,
    tradePrice,
    status,
    positionId,
    latestNewOrderPrice1,
    latestNewOrderPrice2,
    id,
    orderStatus,
  } = data;

  const sourceTypeRaw = useSelector((state) => state.portfolio.selectedApGroupData.sourceType);
  const sourceType = useMemo(
    () => (sourceTypeRaw === AP_GROUP_SOURCES.MONEY_HATCH.KEY ? TRADE_METHODS.MH_AP.ID : TRADE_METHODS.AP.ID),
    [sourceTypeRaw],
  );

  const dispatch = useDispatch();
  const {
    instrumentId,
    id: groupId,
    activeApCount,
    serviceId,
  } = useSelector((state) => state.portfolio.selectedApGroupData);

  const isWeb = checkIsWebApp();

  // local state
  const [amountValue, setAmountValue] = useState('');
  const [entryPriceValue, setEntryPriceValue] = useState('');
  const [entryPriceValue2, setEntryPriceValue2] = useState('');
  const [profitMarginValue, setProfitMarginValue] = useState('');
  const [lossCutWidthValue, setLossCutWidthValue] = useState('');
  const [followValue, setFollowValue] = useState('');
  const [counterType, changeCounterType] = useState(OPTIONS_COUNTER_TYPE[0].id);
  const [counterValue, setCounterValue] = useState('');
  const [selectedOrderType, setOrderType] = useState(OPTIONS_ORDER_TYPE[0].value);
  const [selectedOrderType2, setOrderType2] = useState(OPTIONS_ORDER_TYPE[0].value);
  const [selectedTradePrice, setTradePrice] = useState('');
  const [errorsArray, changeErrorsArray] = useState([]);

  // selectors
  const isFX = serviceId === FX;
  const isETF = serviceId === ETF;

  const requestIsLoading = useSelector((state) => state.portfolio.changingApGroupItemIsLoading);
  const {
    quantityPrecision,
    pricePrecision,
    buyQuantityMin,
    buyQuantityMax,
    sellQuantityMin,
    sellQuantityMax,
    buyPriceMin,
    buyPriceMax,
    sellPriceMin,
    sellPriceMax,
  } = useInstrumentSettings(instrumentId, sourceType);

  const isMoneyHatch = sourceTypeRaw === AP_GROUP_SOURCES.MONEY_HATCH.KEY;

  const { sellPrice, buyPrice } = usePricesForBuySell({ currencyPair: instrumentId });
  const priceRef = useRef({});
  useEffect(() => {
    priceRef.current = { sellPrice, buyPrice };
  }, [sellPrice, buyPrice]);

  // calculated memo variables
  const shortName = useInstrumentShortName(instrumentId);
  const isActive = useMemo(() => status === AP_GROUP_ORDER.ACTIVITY.ACTIVE.ID, [status]);
  const hasOpenPositions = useMemo(() => positionId !== null, [positionId]);
  const isSingle = useMemo(
    () => (isActive ? !latestNewOrderPrice2 : !entryPrice2),
    [isActive, latestNewOrderPrice2, entryPrice2],
  );
  const firstOrderIsActive = useMemo(() => orderStatus === AP_GROUP_ORDER.STATUS.FIRST_ORDER.ID, [orderStatus]);

  const priceIsDisabled = (isActive && !firstOrderIsActive) || isMoneyHatch;

  const counterPipsIsSelected = useMemo(() => counterType === OPTIONS_COUNTER_TYPE[0].id, [counterType]);

  const price1 = useMemo(
    () => (isActive ? latestNewOrderPrice1 : entryPrice1),
    [isActive, latestNewOrderPrice1, entryPrice1],
  );
  const price2 = useMemo(
    () => (isActive ? latestNewOrderPrice2 : entryPrice2),
    [isActive, latestNewOrderPrice2, entryPrice2],
  );

  const orderType1 = useMemo(() => {
    if (!price1 && !price2) return 0;
    return 1;
  }, [price1, price2]);
  const orderType2 = useMemo(() => {
    if (!price2) return 0;
    return 1;
  }, [price2]);

  // effects
  useEffect(() => {
    if (isOpen) {
      setAmountValue(editHelpers.getNumberValue(quantity));
      setEntryPriceValue(editHelpers.getNumberValue(price1));
      setEntryPriceValue2(editHelpers.getNumberValue(price2));
      setProfitMarginValue(editHelpers.getNumberValue(tp));
      setLossCutWidthValue(editHelpers.getNumberValue(sl));
      setFollowValue(editHelpers.getNumberValue(follow));
      changeCounterType(counter ? OPTIONS_COUNTER_TYPE[0].id : OPTIONS_COUNTER_TYPE[1].id);
      setCounterValue(editHelpers.getNumberValue(counter ?? counterPrice));
      setOrderType(OPTIONS_ORDER_TYPE[orderType1].value);
      setOrderType2(OPTIONS_ORDER_TYPE[orderType2].value);
      setTradePrice(tradePrice);
      changeErrorsArray([]);
    }
  }, [isOpen, quantity, tp, sl, follow, price1, price2, counter, counterPrice, tradePrice, orderType1, orderType2]);

  useEffect(() => {
    setCounterValue(editHelpers.getNumberValue(counterType === OPTIONS_COUNTER_TYPE[0].id ? counter : counterPrice));
    changeErrorsArray((prevVal) => prevVal.filter((item) => item.inputName !== COUNTER_NAME));
  }, [counterType, counter, counterPrice]);

  const isSellSide = useMemo(() => Number(side) === BUY_SELL_MAIN.SELL.ID, [side]);
  const currencyUnit = getValidationCurrencyUnitByServiceId(instrumentId, serviceId);
  const quantityUnit = getServiceQuantityUnit(serviceId);

  // validation handlers
  const validateQuantity = useCallback(
    (newValue) => {
      let errorMessage;
      let minValue;
      let maxValue;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        errorMessage = getAPValidateQuantityErrorMessage(instrumentId);
        minValue = getAPQuantityMin(instrumentId);
        maxValue = MAX_QUANTITY_AP_ORDER;
      } else {
        minValue = isSellSide ? sellQuantityMin : buyQuantityMin;
        maxValue = isSellSide ? sellQuantityMax : buyQuantityMax;

        if (maxValue === 0) {
          errorMessage = '現在、この銘柄の新規売り注文は受付できません。';
        } else if (minValue === maxValue) {
          errorMessage = `${quantityPrecision}${quantityUnit}でご設定ください。`;
        } else {
          // eslint-disable-next-line max-len
          errorMessage = `${minValue}${quantityUnit}以上${maxValue}${quantityUnit}以下、${quantityPrecision}${quantityUnit}単位でご設定ください。`;
        }
      }

      return editHelpers.createValidateFunction({
        inputName: COUNT_INPUT_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision: quantityPrecision,
        changeErrorFunction: changeErrorsArray,
      })(newValue);
    },
    [
      isFX,
      quantityUnit,
      quantityPrecision,
      instrumentId,
      isSellSide,
      sellQuantityMin,
      buyQuantityMin,
      sellQuantityMax,
      buyQuantityMax,
    ],
  );

  const validatePrice1 = useCallback(
    (newValue) => {
      const currentPrice = priceRef.current[isSellSide ? 'sellPrice' : 'buyPrice'];

      let minValue;
      let maxValue;
      let errorMessage;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        const hasYen = instrumentId.endsWith(COUNTRY_TYPE.JPY);

        minValue = Decimal.mul(currentPrice, PRICE_MIN_MULTIPLIER).toNumber();
        maxValue = Math.min(
          Decimal.mul(currentPrice, PRICE_MAX_MULTIPLIER).toNumber(),
          hasYen ? PRICE_MAX_PAIRED_YEN : PRICE_MAX_NOT_PAIRED_YEN,
        );

        errorMessage = isSellSide
          ? createValidationErrorMessageAPOrderEntryPrice1Sell(currentPrice, pricePrecision)
          : createValidationErrorMessageAPOrderEntryPrice1Buy(currentPrice, pricePrecision);
      } else {
        minValue = isSellSide ? sellPriceMin : buyPriceMin;
        maxValue = isSellSide ? sellPriceMax : buyPriceMax;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      return editHelpers.createValidateFunction({
        inputName: PRICE_1_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision: pricePrecision,
        changeErrorFunction: changeErrorsArray,
        exclusiveMin: isFX,
      })(newValue);
    },
    [
      isSellSide,
      isFX,
      pricePrecision,
      instrumentId,
      sellPriceMin,
      buyPriceMin,
      sellPriceMax,
      buyPriceMax,
      currencyUnit,
    ],
  );

  const validatePrice2 = useCallback(
    (newValue) => {
      const currentPrice = priceRef.current[isSellSide ? 'sellPrice' : 'buyPrice'];

      const OCOValidation = isSellSide ? newValue < entryPriceValue : newValue > entryPriceValue;
      const OCOErrorMessage = isSellSide
        ? VALIDATION_ERROR_AP_ORDER_OCO2_MORE_THAN_OCO1_SELL
        : VALIDATION_ERROR_AP_ORDER_OCO2_LESS_THAN_OCO1_BUY;

      let minValue;
      let maxValue;
      let errorMessage;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        const hasYen = instrumentId.endsWith(COUNTRY_TYPE.JPY);

        minValue = Decimal.mul(currentPrice, PRICE_MIN_MULTIPLIER).toNumber();
        maxValue = Math.min(
          Decimal.mul(currentPrice, PRICE_MAX_MULTIPLIER).toNumber(),
          hasYen ? PRICE_MAX_PAIRED_YEN : PRICE_MAX_NOT_PAIRED_YEN,
        );
        errorMessage = isSellSide
          ? createValidationErrorMessageAPOrderEntryPrice2Sell(currentPrice, pricePrecision)
          : createValidationErrorMessageAPOrderEntryPrice2Buy(currentPrice, pricePrecision);
      } else {
        minValue = isSellSide ? sellPriceMin : buyPriceMin;
        maxValue = isSellSide ? sellPriceMax : buyPriceMax;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      return editHelpers.createValidateFunction({
        inputName: PRICE_2_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision: pricePrecision,
        changeErrorFunction: changeErrorsArray,
        additionalCheck: OCOValidation,
        additionalCheckErrorMessage: OCOErrorMessage,
        exclusiveMin: isFX,
      })(newValue);
    },
    [
      isSellSide,
      entryPriceValue,
      isFX,
      pricePrecision,
      instrumentId,
      sellPriceMin,
      buyPriceMin,
      sellPriceMax,
      buyPriceMax,
      currencyUnit,
    ],
  );

  const validateProfitMargin = useCallback(
    (rawValue) => {
      const currentPrice = priceRef.current[isSellSide ? 'buyPrice' : 'sellPrice'];
      let isValid = true;
      let additionalCheckErrorMessage;

      // User is allowed to insert "-" since it is valid character for numbers
      // this might mess up with validation, so it is treated by validation as a blank value
      const newValue = nanToBlank(rawValue);

      if (hasOpenPositions) {
        let term;
        // TODO CFD FXかそうでないかの判定で問題ないか要確認
        if (isFX) {
          const divider = instrumentId.endsWith(COUNTRY_TYPE.JPY)
            ? AP_GROUP_RULE_67_YEN_PAIR_MULTIPLIER
            : AP_GROUP_RULE_67_NOT_YEN_PAIR_MULTIPLIER;
          term = Decimal.div(newValue || 0, divider);
        } else {
          term = newValue || 0;
        }

        if (isSellSide) {
          const min = Decimal.sub(tradePrice, term).toNumber();
          isValid = min <= currentPrice;
          additionalCheckErrorMessage = createValidationErrorMessageAPR67ProfitMarginSell(currentPrice, pricePrecision);
        } else {
          const max = Decimal.add(tradePrice, term).toNumber();
          isValid = currentPrice <= max;
          additionalCheckErrorMessage = createValidationErrorMessageAPR67ProfitMarginBuy(currentPrice, pricePrecision);
        }
      }

      let minValue;
      let maxValue;
      let valuePrecision;

      let errorMessage;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        minValue = PROFIT_MARGIN_MIN;
        maxValue = PROFIT_MARGIN_MAX;
        errorMessage = VALIDATION_ERROR_AP_ORDER_PROFIT_MARGIN_PIPS;
        valuePrecision = PROFIT_MARGIN_PRECISION;
      } else {
        minValue = isSellSide ? sellPriceMin : buyPriceMin;
        maxValue = isSellSide ? sellPriceMax : buyPriceMax;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
        valuePrecision = pricePrecision;
      }

      return editHelpers.createValidateFunction({
        inputName: PROFIT_MARGIN_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        additionalCheckErrorMessage,
        additionalCheck: isValid,
      })(newValue);
    },
    [
      isSellSide,
      pricePrecision,
      hasOpenPositions,
      isFX,
      tradePrice,
      instrumentId,
      sellPriceMin,
      buyPriceMin,
      sellPriceMax,
      buyPriceMax,
      currencyUnit,
    ],
  );

  const validateLossCutWidth = useCallback(
    (rawValue) => {
      let minValue;
      let maxValue;
      let errorMessage;
      let valuePrecision;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        minValue = LOSS_CUT_WIDTH_MIN;
        maxValue = LOSS_CUT_WIDTH_MAX;
        errorMessage = VALIDATION_ERROR_AP_ORDER_LOST_CUT_PIPS;
        valuePrecision = LOSS_CUT_WIDTH_PRECISION;
      } else {
        minValue = -(isSellSide ? sellPriceMax : buyPriceMax);
        maxValue = -(isSellSide ? sellPriceMin : buyPriceMin);
        valuePrecision = pricePrecision;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      const currentPrice = priceRef.current[isSellSide ? 'buyPrice' : 'sellPrice'];

      let additionalCheck = true;
      const additionalCheckErrorMessage = isSellSide
        ? createValidationErrorMessageAPR67LossCutWidthSell(currentPrice, pricePrecision)
        : createValidationErrorMessageAPR67LossCutWidthBuy(currentPrice, pricePrecision);

      // User is allowed to insert "-" since it is valid character for numbers
      // this might mess up with validation, so it is treated by validation as a blank value
      const newValue = nanToBlank(rawValue);

      if (hasOpenPositions && newValue) {
        let term;
        // TODO CFD FXかそうでないかの判定で問題ないか要確認
        if (isFX) {
          const divider = instrumentId.endsWith(COUNTRY_TYPE.JPY)
            ? AP_GROUP_RULE_67_YEN_PAIR_MULTIPLIER
            : AP_GROUP_RULE_67_NOT_YEN_PAIR_MULTIPLIER;
          term = Decimal.div(newValue, divider);
        } else {
          term = newValue;
        }

        const priceDifference = Decimal.sub(priceRef.current.buyPrice, priceRef.current.sellPrice);
        const termWithDifference = Decimal.sub(term, priceDifference);
        if (isSellSide) {
          const max = Decimal.sub(tradePrice, termWithDifference).toNumber();
          additionalCheck = currentPrice <= max;
        } else {
          const min = Decimal.add(tradePrice, termWithDifference).toNumber();
          additionalCheck = min <= currentPrice;
        }
      }

      return editHelpers.createValidateFunction({
        inputName: LOSS_CUT_WIDTH_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        skippIfEmptyString: true,
        additionalCheck,
        additionalCheckErrorMessage,
      })(newValue);
    },
    [
      isFX,
      isSellSide,
      pricePrecision,
      hasOpenPositions,
      sellPriceMax,
      buyPriceMax,
      sellPriceMin,
      buyPriceMin,
      currencyUnit,
      tradePrice,
      instrumentId,
    ],
  );

  const rule66Validation = useCallback(
    ({ value, type }) => {
      if (type === rule66Types.follow) {
        return value !== '' || counterValue !== '';
      }
      if (type === rule66Types.counter) {
        return value !== '' || followValue !== '';
      }
      return false;
    },
    [followValue, counterValue],
  );

  const validateFollow = useCallback(
    (newValue) => {
      let minValue;
      let maxValue;
      let errorMessage;
      let valuePrecision;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        minValue = isSellSide ? FOLLOW_SELL_MIN : FOLLOW_BUY_MIN;
        maxValue = isSellSide ? FOLLOW_SELL_MAX : FOLLOW_BUY_MAX;
        errorMessage = isSellSide
          ? VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_SELL
          : VALIDATION_ERROR_AP_ORDER_FOLLOW_PIPS_BUY;
        valuePrecision = FOLLOW_PRECISION;
      } else {
        minValue = isSellSide ? -sellPriceMax : buyPriceMin;
        maxValue = isSellSide ? -sellPriceMin : buyPriceMax;
        valuePrecision = pricePrecision;
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      const additionalCheck = rule66Validation({ value: newValue, type: rule66Types.follow });

      return editHelpers.createValidateFunction({
        inputName: FOLLOW_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        skippIfEmptyString: true,
        additionalCheck,
        additionalCheckErrorMessage: VALIDATION_ERROR_AP_RULE_66,
        additionalCheckFieldName: COUNTER_NAME,
      })(newValue);
    },
    [
      isFX,
      rule66Validation,
      isSellSide,
      sellPriceMax,
      buyPriceMin,
      sellPriceMin,
      buyPriceMax,
      pricePrecision,
      currencyUnit,
    ],
  );

  const validateCounter = useCallback(
    (newValue) => {
      const isCounter = counterPipsIsSelected;
      let minValue;
      let maxValue;
      let valuePrecision;
      let errorMessage;
      let exclusiveMin = false;
      let exclusiveMax = false;

      // TODO CFD FXかそうでないかの判定で問題ないか要確認
      if (isFX) {
        if (isCounter) {
          minValue = isSellSide ? COUNTER_SELL_MIN : COUNTER_BUY_MIN;
          maxValue = isSellSide ? COUNTER_SELL_MAX : COUNTER_BUY_MAX;
          errorMessage = isSellSide
            ? VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_SELL
            : VALIDATION_ERROR_AP_ORDER_COUNTER_PIPS_BUY;
          valuePrecision = COUNTER_PRECISION;
        } else {
          const currentPrice = priceRef.current[isSellSide ? 'sellPrice' : 'buyPrice'];
          minValue = PRICE_MIN_MULTIPLIER * currentPrice;
          maxValue = PRICE_MAX_MULTIPLIER * currentPrice;
          errorMessage = isSellSide
            ? createValidationErrorMessageAPOrderCounterPriceSell(currentPrice, pricePrecision)
            : createValidationErrorMessageAPOrderCounterPriceBuy(currentPrice, pricePrecision);
          valuePrecision = pricePrecision;
          exclusiveMin = true;
          exclusiveMax = true;
        }
      } else {
        valuePrecision = pricePrecision;
        if (isCounter) {
          minValue = isSellSide ? sellPriceMin : -buyPriceMax;
          maxValue = isSellSide ? sellPriceMax : -buyPriceMin;
        } else {
          minValue = isSellSide ? sellPriceMin : buyPriceMin;
          maxValue = isSellSide ? sellPriceMax : buyPriceMax;
        }
        errorMessage = `${minValue}${currencyUnit}以上、${maxValue}${currencyUnit}以下、${pricePrecision}${currencyUnit}単位でご設定ください。`; // eslint-disable-line
      }

      const additionalCheck = rule66Validation({ value: newValue, type: rule66Types.counter });

      return editHelpers.createValidateFunction({
        inputName: COUNTER_NAME,
        errorMessage,
        minValue,
        maxValue,
        valuePrecision,
        changeErrorFunction: changeErrorsArray,
        exclusiveMin,
        exclusiveMax,
        skippIfEmptyString: true,
        additionalCheck,
        additionalCheckErrorMessage: VALIDATION_ERROR_AP_RULE_66,
        additionalCheckFieldName: FOLLOW_NAME,
      })(newValue);
    },
    [
      counterPipsIsSelected,
      isFX,
      rule66Validation,
      isSellSide,
      pricePrecision,
      currencyUnit,
      sellPriceMin,
      buyPriceMax,
      sellPriceMax,
      buyPriceMin,
    ],
  );

  const validateChangedData = useCallback(
    (changedData) => {
      const errorObject = [];

      if (Object.prototype.hasOwnProperty.call(changedData, COUNT_INPUT_NAME)) {
        errorObject.push(validateQuantity(changedData.quantity));
      }
      if (!priceIsDisabled && Object.prototype.hasOwnProperty.call(changedData, PRICE_1_NAME)) {
        errorObject.push(validatePrice1(changedData.entryPrice1));
      }
      if (!priceIsDisabled && Object.prototype.hasOwnProperty.call(changedData, PRICE_2_NAME)) {
        errorObject.push(validatePrice2(changedData.entryPrice2));
      }
      errorObject.push(validateProfitMargin(changedData.tp));
      errorObject.push(validateLossCutWidth(changedData.sl));
      errorObject.push(validateFollow(changedData.follow));

      errorObject.push(
        validateCounter(
          changedData[
            Object.prototype.hasOwnProperty.call(changedData, COUNTER_NAME) ? COUNTER_NAME : COUNTER_PRICE_NAME
          ],
        ),
      );

      return errorObject;
    },
    [
      validateQuantity,
      validatePrice1,
      validatePrice2,
      validateProfitMargin,
      validateLossCutWidth,
      validateFollow,
      validateCounter,
      priceIsDisabled,
    ],
  );

  const handleQuantityValue = useCallback(
    (val) => {
      const newValue = isFX ? val : roundExactlyOnPrecisionMatching(val, Math.round(1 / quantityPrecision));
      setAmountValue(newValue);
    },
    [quantityPrecision, isFX],
  );

  const submitHandler = useCallback(
    (callback) => () => {
      let changedData;
      const dataTemplate = {
        tp: editHelpers.convertToNumberOrEmptyString(profitMarginValue),
        sl: editHelpers.convertToNumberOrEmptyString(lossCutWidthValue),
        follow: editHelpers.convertToNumberOrEmptyString(followValue),
        [counterPipsIsSelected ? COUNTER_NAME : COUNTER_PRICE_NAME]:
          editHelpers.convertToNumberOrEmptyString(counterValue),
      };

      // when priceIsDisabled=true, use the original price,
      //   - entryPriceX, not latestNewOrderPriceX
      const targetPrice1 = priceIsDisabled ? entryPrice1 : editHelpers.convertToNumberOrEmptyString(entryPriceValue);

      if (isSingle && !priceIsDisabled) {
        changedData = {
          quantity: editHelpers.convertToNumberOrEmptyString(amountValue),
          entryPrice1: targetPrice1,
          instrumentId,
          ...dataTemplate,
        };
      } else {
        const targetPrice2 = priceIsDisabled ? entryPrice2 : editHelpers.convertToNumberOrEmptyString(entryPriceValue2);
        changedData = {
          quantity: editHelpers.convertToNumberOrEmptyString(amountValue),
          entryPrice1: targetPrice1,
          entryPrice2: targetPrice2,
          instrumentId,
          ...dataTemplate,
        };
      }
      const validationErrors = validateChangedData(changedData);

      if (!validationErrors.includes(true)) {
        dispatch(
          changeApGroupItemRequest({
            groupId,
            serviceId,
            apId: id,
            data: changedData,
            callback,
            status: activeApCount === 0 ? 0 : 1,
          }),
        );
      }
    },
    [
      profitMarginValue,
      lossCutWidthValue,
      followValue,
      counterPipsIsSelected,
      counterValue,
      priceIsDisabled,
      entryPrice1,
      entryPriceValue,
      isSingle,
      validateChangedData,
      amountValue,
      entryPrice2,
      entryPriceValue2,
      dispatch,
      groupId,
      serviceId,
      id,
      activeApCount,
      instrumentId,
    ],
  );

  const counterMinValue = useMemo(() => {
    if (!counterPipsIsSelected) {
      return 0;
    }

    return isSellSide ? 0 : null;
  }, [isSellSide, counterPipsIsSelected]);

  const priceStep = useMemo(() => {
    if (isFX) {
      return getPriceStep(instrumentId);
    }
    if (isETF) {
      return pricePrecision;
    }
    return CFD_AP_GROUP_CHANGE_LOGIC_STEP;
  }, [instrumentId, isFX, pricePrecision, isETF]);

  const apQuantityStep = useMemo(() => {
    if (isFX) return getAPQuantityStep(instrumentId);
    return quantityPrecision;
  }, [instrumentId, isFX, quantityPrecision]);

  const profitMarginStep = useMemo(() => {
    if (isFX) {
      return PROFIT_MARGIN_STEP;
    }
    if (isETF) {
      return pricePrecision;
    }
    return CFD_AP_GROUP_CHANGE_LOGIC_STEP;
  }, [isFX, isETF, pricePrecision]);

  const lossCutWidthStep = useMemo(() => {
    if (isFX) {
      return LOSS_CUT_WIDTH_STEP;
    }
    if (isETF) {
      return pricePrecision;
    }
    return CFD_AP_GROUP_CHANGE_LOGIC_STEP;
  }, [isFX, isETF, pricePrecision]);

  const followStep = useMemo(() => {
    if (isFX) {
      return FOLLOW_STEP;
    }
    if (isETF) {
      return pricePrecision;
    }
    return CFD_AP_GROUP_CHANGE_LOGIC_STEP;
  }, [isFX, isETF, pricePrecision]);

  const counterStep = useMemo(() => {
    if (isFX) {
      return counterPipsIsSelected ? COUNTER_STEP : COUNTER_PRICE_PRECISION;
    }
    if (isETF) {
      return pricePrecision;
    }
    return CFD_AP_GROUP_CHANGE_LOGIC_STEP;
  }, [isFX, isETF, pricePrecision, counterPipsIsSelected]);

  const counterTypeCurrencyUnit = !isFX && isWeb && currencyUnit ? `(${currencyUnit})` : '';

  const pipsLabelWithParentheses = getPipsLabelWithParentheses(serviceId, instrumentId);
  return {
    name: {
      label: '注文名',
      value: name,
    },
    instrumentId: {
      label: '銘柄',
      value: shortName,
    },
    side: {
      label: '売買',
      value: BUY_SELL_VALUE[side],
    },
    quantity: {
      get: amountValue,
      set: handleQuantityValue,
      label: `数量(${quantityUnit})`,
      name: COUNT_INPUT_NAME,
      validate: validateQuantity,
      isDisabled: hasOpenPositions,
      step: apQuantityStep,
    },
    tradePrice: {
      get: selectedTradePrice,
      set: setTradePrice,
      label: '取引価格',
      condition: hasOpenPositions,
    },
    orderType: {
      get: selectedOrderType,
      set: setOrderType,
      label: isSingle ? '注文タイプ' : '注文タイプ1',
      condition: !hasOpenPositions,
    },
    entryPrice: {
      get: priceIsDisabled && !entryPriceValue ? '-' : entryPriceValue,
      set: setEntryPriceValue,
      isDisabled: priceIsDisabled,
      label: isSingle ? 'エントリー価格' : 'エントリー価格1',
      name: PRICE_1_NAME,
      validate: validatePrice1,
    },
    orderType2: {
      get: selectedOrderType2,
      set: setOrderType2,
      label: '注文タイプ2',
      condition: !isSingle && !hasOpenPositions,
    },
    entryPrice2: {
      get: entryPriceValue2,
      set: setEntryPriceValue2,
      label: 'エントリー価格2',
      name: PRICE_2_NAME,
      validate: validatePrice2,
      isDisabled: priceIsDisabled,
    },
    profitMargin: {
      get: profitMarginValue,
      set: setProfitMarginValue,
      label: `利確幅${pipsLabelWithParentheses}`,
      step: profitMarginStep,
      name: PROFIT_MARGIN_NAME,
      validate: validateProfitMargin,
    },
    lossCutWidth: {
      get: lossCutWidthValue,
      set: setLossCutWidthValue,
      label: `損切幅${pipsLabelWithParentheses}`,
      step: lossCutWidthStep,
      name: LOSS_CUT_WIDTH_NAME,
      validate: validateLossCutWidth,
      min: null,
    },
    follow: {
      get: followValue,
      set: setFollowValue,
      label: `フォロー値${pipsLabelWithParentheses}`,
      step: followStep,
      name: FOLLOW_NAME,
      validate: validateFollow,
      min: Number(side) === BUY_SELL_MAIN.BUY.ID ? 0 : null,
    },
    counterType: {
      get: counterType,
      set: changeCounterType,
      label: `カウンター値${counterTypeCurrencyUnit}`,
      options: OPTIONS_COUNTER_TYPE,
    },
    counterValue: {
      get: counterValue,
      set: setCounterValue,
      label: counterPipsIsSelected ? '' : '@',
      withPips: counterPipsIsSelected,
      step: counterStep,
      name: COUNTER_NAME,
      validate: validateCounter,
      min: counterMinValue,
    },
    submit: {
      label: '完了',
      handler: submitHandler,
      isLoading: requestIsLoading,
      isDisabled: Boolean(errorsArray.length),
    },
    priceStep,
    errorsArray,
  };
};

export default useDynamicAPGroupChangeInfo;
