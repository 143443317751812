import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { FX } from 'shared-modules/constants';
import CustomCurrencyIcon from '../../../../../../components/CustomCurrencyIcon';
import CustomCheckbox from '../../../../../../components/CustomCheckbox';
import styles from './builderItem.module.scss';

export const BuilderItem = memo(({ apGroup, serviceId, disabled, onCheck }) => {
  const instrumentMap = useSelector((state) => state.settings.instrumentList);
  const isFX = serviceId === FX;

  const handleChange = useCallback(
    (_isCheck) => {
      onCheck(apGroup);
    },
    [apGroup, onCheck],
  );

  return (
    <div
      onClick={handleChange}
      onKeyDown={(e) => {
        if (e.key === 'Enter') handleChange();
      }}
      role="button"
      tabIndex="0"
      className={classNames(styles.container, { [styles.disabled]: !apGroup.checked && disabled })}
    >
      <div>
        <CustomCurrencyIcon isSmall currencyPair={apGroup.instrumentId} />
      </div>
      <div className={styles.nameArea}>
        <div className={styles.name}>{apGroup?.name}</div>
        <div className={styles.symbol}>
          {isFX ? apGroup.instrumentId : _.get(instrumentMap, [apGroup.instrumentId, 'shortName'], '-')}
        </div>
      </div>
      <CustomCheckbox
        isChecked={apGroup.checked ?? false}
        // onClickが重複して実行されるためcheckbox側のイベントを外す。requiredのためダミーで関数を渡す
        onChange={() => {
          return null;
        }}
        isDisabled={disabled}
      />
    </div>
  );
});

BuilderItem.propTypes = {
  apGroup: PropTypes.shape({
    instrumentId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
  }).isRequired,
  serviceId: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

BuilderItem.defaultProps = {
  disabled: false,
};
