import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  RECOMMENDED_MARGIN_LABEL,
  REQUIRED_MARGIN_LABEL,
  REQUIRED_MARGIN_TOOLTIP,
  RISK_ASSESSMENT_LABEL,
} from 'shared-modules/constants';
import { BUILDER_ORDER_TYPES } from 'shared-modules/constants/builder';
import { getSlLabel, roundRoi, roundUpBy1000 } from 'shared-modules/utils';
import { recommendedMarginTooltips } from 'shared-modules/services/common';
import { Evaluation } from '../Evaluation';
import { ColoredPriceText, ColoredRateText, MarginText, NumberText } from '../Texts';
import { BuySellLabel } from '../BuySellLabel';
import { InfoTooltip } from '../InfoTooltip';
import styles from './simulationInfo.module.scss';

export const SimulationInfo = memo(
  ({
    serviceId,
    totalPl,
    marginRecommended,
    marginRequired,
    count,
    slSetting,
    newCount,
    closeCount,
    sides,
    riskAssessment,
    marginRecommendedLabel,
  }) => {
    const stopLossOrder = useMemo(() => getSlLabel(slSetting), [slSetting]);

    const roundedMarginRecommended = roundUpBy1000(marginRecommended);
    const calculatedRoi = useMemo(
      () => roundRoi((totalPl * 100) / roundedMarginRecommended),
      [totalPl, roundedMarginRecommended],
    );

    const orderType = useSelector((state) => state.builder.orderType);

    const buySell = useMemo(() => {
      if (sides == null || sides.length === 0) {
        return <div className={styles.value}>-</div>;
      }
      const copiedSides = [...sides];
      copiedSides.sort();
      return (
        <div className={styles.buySell}>
          {copiedSides
            .reverse()
            .map((side) => <BuySellLabel key={side} className={styles.side} type={Number(side)} />)
            .reduce((prev, curr, index) => {
              return [
                ...prev,
                curr,
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={styles.join}>
                  と
                </div>,
              ];
            }, [])
            .slice(0, -1)}
        </div>
      );
    }, [sides]);

    return (
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.label}>期間収益率</div>
          <ColoredRateText value={calculatedRoi} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>期間損益</div>
          <ColoredPriceText value={totalPl} />
        </div>
        {orderType !== BUILDER_ORDER_TYPES.TECH.ID && (
          <div className={styles.row}>
            <div className={styles.label}>{RISK_ASSESSMENT_LABEL}</div>
            <Evaluation
              scoreClassName={styles.evaluationScore}
              iconClassName={styles.evaluationIcon}
              value={riskAssessment}
            />
          </div>
        )}
        <div className={styles.row}>
          <div className={styles.label}>
            {marginRecommendedLabel || RECOMMENDED_MARGIN_LABEL}
            <InfoTooltip message={recommendedMarginTooltips(serviceId)} />
          </div>
          <MarginText value={roundedMarginRecommended} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>
            {REQUIRED_MARGIN_LABEL}
            <InfoTooltip message={REQUIRED_MARGIN_TOOLTIP} />
          </div>
          <MarginText value={marginRequired} />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>本数</div>
          <NumberText value={count} />
        </div>
        {newCount != null && (
          <div className={styles.row}>
            <div className={styles.label}>新規回数</div>
            <NumberText value={newCount} symbol="回" />
          </div>
        )}
        {closeCount != null && (
          <div className={styles.row}>
            <div className={styles.label}>決済回数</div>
            <NumberText value={closeCount} symbol="回" />
          </div>
        )}
        {slSetting != null && (
          <div className={styles.row}>
            <div className={styles.label}>損切注文</div>
            <div className={styles.value}>{stopLossOrder}</div>
          </div>
        )}
        {sides != null && (
          <div className={styles.row}>
            <div className={styles.label}>売買</div>
            <div className={styles.value}>{buySell}</div>
          </div>
        )}
      </div>
    );
  },
);

SimulationInfo.propTypes = {
  serviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  totalPl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginRecommended: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginRequired: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  count: PropTypes.number,
  slSetting: PropTypes.number,
  newCount: PropTypes.number,
  closeCount: PropTypes.number,
  sides: PropTypes.arrayOf(PropTypes.string),
  riskAssessment: PropTypes.number,
  marginRecommendedLabel: PropTypes.string,
};

SimulationInfo.defaultProps = {
  serviceId: undefined,
  totalPl: undefined,
  marginRecommended: undefined,
  marginRequired: undefined,
  count: undefined,
  slSetting: undefined,
  newCount: undefined,
  closeCount: undefined,
  sides: undefined,
  riskAssessment: undefined,
  marginRecommendedLabel: undefined,
};
