/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useEffect, useMemo, useState } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useDispatch, useSelector } from 'react-redux';
import {
  AP_GROUP_STATUSES,
  CARD_STATUS_IDS,
  CFD,
  DEFAULT_LAB_SELECTED_TERM_ID,
  ETF,
  FX,
  LAB_EXTERNAL_LINK_NAME,
  LAB_EXTERNAL_LINK_URL,
} from '../constants';
import { useSelectedShareItem, useSelectedStrategyGroup } from '.';
import { useCalculatingChartDataWithLabels } from '../services/hooks';
import { useLabTableData, useSelectionsTableData } from '../services/hooks/autoSelectDetails';
import { publishedLabSelector, publishedLabSimulationSelector, publishedLabsSelector } from '../redux/labs';
import {
  addToCartRequest,
  createSelectionApGroupRequest,
  saveAutoSelectFilterCondition,
} from '../redux/actions/autoSelectActions';
import { getStockLeft, normalizeLabStrategy } from '../services/select';
import { useSimulationSummary } from './useSimulationSummary';
import { getApGroupRequest } from '../redux/actions/portfolioActions';
import { getTechnicalBuilderDataRequest } from '../redux/tech';
import { TAG_ID_ALL } from '../constants/select';

export const useLoadingSelections = () => {
  const { areActiveSelectionsLoading, areSelectionTagsLoading } = useSelector((state) => state.autoSelect);
  const { loading } = useSelector(publishedLabsSelector);
  return useMemo(() => {
    return loading || areActiveSelectionsLoading || areSelectionTagsLoading;
  }, [areActiveSelectionsLoading, areSelectionTagsLoading, loading]);
};

export const useTableLoading = () => {
  const isLoadingModal = useSelector((state) => state.autoSelect.selectionModalInfoIsLoading);
  const isLoaderTermId = useSelector((state) => state.autoSelect.selectionModalTermInfoIsLoading);
  return useMemo(() => isLoadingModal || isLoaderTermId, [isLoadingModal, isLoaderTermId]);
};

const useSelectedDataSelection = () => {
  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const selectedTermId = useSelector((state) => state.autoSelect.selectedTermId);
  const { [defaultSelectionTermId]: defaultTermIdInfo, [selectedTermId]: selectTermIdInfo } = useSelector(
    (state) => state.autoSelect.selectionModalInfo,
  );

  const info = selectTermIdInfo || defaultTermIdInfo || {};
  const { selectionId, selectionVersion, defaultSets, simulationStats, strategyList, serviceIds } = info;

  const loadingModal = useSelector((state) => state.autoSelect.selectionModalInfoIsLoading);
  const loadingTermId = useSelector((state) => state.autoSelect.selectionModalTermInfoIsLoading);

  return {
    selectionId,
    selectionVersion,
    defaultSets,
    simulationStats,
    strategyList,
    serviceIds,
    loading: loadingModal || loadingTermId,
  };
};

const useSelectedDataLab = () => {
  const publishedLab = useSelector(publishedLabSelector);
  const { labId, defaultSets = 1, serviceId, serviceIds } = publishedLab || {};
  const { result: data, loading } = useSelector(publishedLabSimulationSelector);
  const { simulationStats, strategyList } = data ?? {};

  return {
    labId,
    defaultSets,
    data,
    simulationStats,
    strategyList,
    serviceId,
    serviceIds,
    loading,
  };
};

export const useOrderSettingTableDataLab = () => {
  const { serviceId, defaultSets = 1, strategyList, loading } = useSelectedDataLab();
  const { autoTradeSettingsData, helpers } = useLabTableData(strategyList ?? [], defaultSets, serviceId);
  return { autoTradeSettingsData, helpers, loading };
};

const useExecutionTableDataLabInternal = ({ serviceId, defaultSets, strategyList }) => {
  const { simulationTradesData, autoTradeSettingsData, helpers } = useLabTableData(
    strategyList ?? [],
    defaultSets,
    serviceId,
  );
  return { simulationTradesData, autoTradeSettingsData, helpers };
};

export const useExecutionTableDataLab = () => {
  const selectedData = useSelectedDataLab();
  const tableData = useExecutionTableDataLabInternal(selectedData);
  return { ...tableData, loading: selectedData.loading };
};

export const useSimulationChartDataSelection = (countValue) => {
  const { selectionId, selectionVersion, strategyList, serviceIds, loading, simulationStats, defaultSets } =
    useSelectedDataSelection();
  const {
    chartData,
    chartRoi,
    totalPl,
    chartRiskReturn,
    roundedMarginRecommended,
    roundedMarginRecommendedFX,
    roundedMarginRecommendedETF,
    roundedMarginRecommendedCFD,
    marginRequired,
  } = useCalculatingChartDataWithLabels({
    strategyList,
    defaultSets: countValue ?? defaultSets,
  });
  const loadingChart = useMemo(() => ((chartData?.length ?? 0) > 0 ? false : loading), [loading, chartData]);
  const simulationData = useMemo(() => {
    return {
      roi: chartRoi?.value,
      totalPl: totalPl?.value,
      riskReturn: chartRiskReturn?.value,
      riskAssessment: simulationStats?.riskAssessment,
      marginRecommended: roundedMarginRecommended?.value,
      marginRecommendedFX: roundedMarginRecommendedFX?.value,
      marginRecommendedETF: roundedMarginRecommendedETF?.value,
      marginRecommendedCFD: roundedMarginRecommendedCFD?.value,
      marginRequired: marginRequired?.value,
      count:
        strategyList?.reduce((total, strategy) => {
          return total + (strategy.itemList?.length ?? 0);
        }, 0) ?? 0,
      tradeCount: simulationStats?.tradeCount,
      defaultSets,
    };
  }, [
    chartRoi,
    totalPl,
    chartRiskReturn,
    roundedMarginRecommended,
    roundedMarginRecommendedFX,
    roundedMarginRecommendedETF,
    roundedMarginRecommendedCFD,
    marginRequired,
    strategyList,
    simulationStats,
    defaultSets,
  ]);
  return {
    chartData,
    simulationData,
    serviceId: serviceIds,
    selectionId,
    selectionVersion,
    loading: loadingChart,
    strategyList,
    defaultSets,
  };
};

const useSimulationChartDataLabInternal = ({ simulationStats, strategyList, defaultSets, serviceId }) => {
  const labStrategyList = (strategyList ?? []).map((strategy) => ({
    strategyDetail: { ...strategy, serviceId },
    strategySets: strategy.strategySets,
  }));

  const { chartData, chartRoi, marginRequired, roundedMarginRecommended } = useCalculatingChartDataWithLabels({
    strategyList: labStrategyList,
    defaultSets,
  });

  const simulationData = useMemo(() => {
    const { realizedPl, unrealizedPl, riskReturn, recentRoi, riskAssessment, tradeCount } = simulationStats ?? {};
    const totalPl = realizedPl == null || unrealizedPl == null ? null : (realizedPl + unrealizedPl) * defaultSets;
    return {
      roi: chartRoi?.value,
      totalPl,
      riskReturn,
      marginRecommended: roundedMarginRecommended?.value == null ? null : roundedMarginRecommended?.value,
      marginRequired: marginRequired?.value == null ? null : marginRequired?.value,
      count: strategyList?.length ?? 0,
      recentRoi,
      riskAssessment,
      tradeCount,
    };
  }, [simulationStats, chartRoi, strategyList, marginRequired, roundedMarginRecommended, defaultSets]);
  return {
    chartData,
    simulationData,
    serviceId,
  };
};

export const useSimulationChartDataLab = (countValue) => {
  const selectedData = useSelectedDataLab();
  const chartData = useSimulationChartDataLabInternal({
    ...selectedData,
    defaultSets: countValue ?? selectedData.defaultSets,
  });
  return {
    ...chartData,
    loading: selectedData.loading,
    labId: selectedData.labId,
    strategyList: selectedData.strategyList,
    defaultSets: selectedData.defaultSets,
  };
};

export const useSimulationDataLab = () => {
  const selectedData = useSelectedDataLab();
  const tableData = useExecutionTableDataLabInternal(selectedData);
  const chartData = useSimulationChartDataLabInternal(selectedData);
  const { loading, strategyList, labId } = selectedData;
  const strategies = useMemo(() => {
    return (strategyList ?? []).map((strategy) => normalizeLabStrategy(strategy));
  }, [strategyList]);
  return {
    ...tableData,
    ...chartData,
    strategies,
    loading,
    labId,
  };
};

export const useAddToCartPayload = () => {
  const dispatch = useDispatch();
  const isLab = useSelectedShareItem();
  const selectionData = useSelectedDataSelection();
  const labData = useSelectedDataLab();
  const data = isLab ? labData : selectionData;
  const { labId, selectionId, selectionVersion, defaultSets, simulationStats } = data;
  const { marginRecommended, marginRequired, marginRequiredFX, marginRequiredETF, marginRequiredCFD } =
    simulationStats ?? {};
  const addToCart = useCallback(
    (sets) => {
      const payload = isLab ? { labId, labSets: sets } : { selectionId, selectionVersion, selectionSets: sets };
      dispatch(addToCartRequest(payload));
    },
    [dispatch, isLab, labId, selectionId, selectionVersion],
  );
  return useMemo(
    () => ({
      payload: {
        margin: marginRecommended,
        marginRequired,
        marginRequiredFX, // mobile only
        marginRequiredETF, // mobile only
        marginRequiredCFD, // mobile only
        sets: defaultSets,
        isLaunchInsideDetailModal: true,
        callback: addToCart,
        isLab,
      },
    }),
    [
      marginRecommended,
      marginRequired,
      marginRequiredFX,
      marginRequiredETF,
      marginRequiredCFD,
      defaultSets,
      addToCart,
      isLab,
    ],
  );
};

export const useRunNowPayload = ({ openStrategiesSuccessModal }) => {
  const dispatch = useDispatch();
  const isLab = useSelectedShareItem();
  const selectionData = useSelectedDataSelection();
  const labData = useSelectedDataLab();
  const data = isLab ? labData : selectionData;
  const { selectionId, selectionVersion, labId, serviceIds, simulationStats, defaultSets } = data;

  const {
    marginRecommended,
    marginRecommendedFX,
    marginRecommendedETF,
    marginRecommendedCFD,
    marginRequired,
    marginRequiredFX,
    marginRequiredETF,
    marginRequiredCFD,
  } = simulationStats ?? {};

  const callback = useCallback(
    (resultArray) => {
      dispatch(openStrategiesSuccessModal({ resultArray }));
      serviceIds.forEach((sid) => {
        dispatch(getApGroupRequest({ serviceId: sid }));
      });
      dispatch(getTechnicalBuilderDataRequest({ status: CARD_STATUS_IDS[AP_GROUP_STATUSES.ACTIVE] }));
    },
    [dispatch, openStrategiesSuccessModal, serviceIds],
  );

  const runNow = useCallback(
    (sets) => {
      const payload = { sets, callback };
      if (isLab) {
        payload.labId = labId;
      } else {
        payload.selectionId = selectionId;
        payload.selectionVersion = selectionVersion;
      }
      dispatch(createSelectionApGroupRequest(payload));
    },
    [dispatch, isLab, selectionId, selectionVersion, labId, callback],
  );

  // lab の場合、service 固定なので marginRecommendedXXX は undefined
  return useMemo(
    () => ({
      payload: {
        marginRecommended,
        marginRecommendedFX: isLab && serviceIds?.includes?.(FX) ? marginRecommended : marginRecommendedFX,
        marginRecommendedETF: isLab && serviceIds?.includes?.(ETF) ? marginRecommended : marginRecommendedETF,
        marginRecommendedCFD: isLab && serviceIds?.includes?.(CFD) ? marginRecommended : marginRecommendedCFD,
        marginRequired,
        marginRequiredFX,
        marginRequiredETF,
        marginRequiredCFD,
        sets: defaultSets,
        isLab,
        callback: runNow,
      },
      serviceIds,
    }),
    [
      isLab,
      marginRecommended,
      marginRecommendedFX,
      marginRecommendedETF,
      marginRecommendedCFD,
      marginRequired,
      marginRequiredFX,
      marginRequiredETF,
      marginRequiredCFD,
      defaultSets,
      runNow,
      serviceIds,
    ],
  );
};

export const useLogicInfo = () => {
  const termId = useSelector((state) => state.autoSelect.selectedTermId);
  const selectedShareItem = useSelectedShareItem();
  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const { [termId ?? defaultSelectionTermId]: { strategyList } = {} } = useSelector(
    (state) => state.autoSelect.selectionModalInfo,
  );
  const { comment } = useSelector(publishedLabSelector) || {};
  return useMemo(() => {
    if (selectedShareItem) {
      return { title: 'ビルダーの概要', description: comment };
    }
    if (strategyList?.length !== 1) {
      return null;
    }
    const origin = strategyList[0]?.strategyDetail?.logic;
    if (origin == null) {
      return null;
    }
    return { ...origin, title: `「${origin.name ?? ''}」とは？` };
  }, [selectedShareItem, strategyList, comment]);
};

export const useSelectionDetail = () => {
  const termId = useSelector((state) => state.autoSelect.selectedTermId);
  const selectedShareItem = useSelectedShareItem();
  const [strategyGroup] = useSelectedStrategyGroup();
  const logic = useLogicInfo();
  const loadingSelections = useLoadingSelections();
  const loadingDetail = useSelector((state) => state.autoSelect.selectionModalInfoIsLoading);
  const loadingDetailTerm = useSelector((state) => state.autoSelect.selectionModalTermInfoIsLoading);
  const loadingSimulationLab = useSelector((state) => state.labs.publishedLabs.simulation.loading);
  const changingSimulationTermLab = useSelector((state) => state.labs.publishedLabs.changingSimulationTerm);
  const { id, loading } = useSelector((state) => state.autoSelect.addToCartRequestInfo);
  const defaultSelectionTermId = useSelector((state) => state.constants.defaultSelectionTermId);
  const { [termId ?? defaultSelectionTermId]: { externalLinkName, externalLinkUrl } = {} } = useSelector(
    (state) => state.autoSelect.selectionModalInfo,
  );
  const loadingRunNow = useSelector((state) => state.autoSelect.createSelectionApGroupRequestIsLoading);
  const loadingAddToCart = useMemo(() => {
    if (strategyGroup == null) {
      return false;
    }
    const targetId = strategyGroup.labId ?? strategyGroup.selectionId;
    return id === targetId && loading;
  }, [id, loading, strategyGroup]);
  const isSoldOut = useMemo(() => getStockLeft(strategyGroup) <= 0, [strategyGroup]);
  const [name, url] = useMemo(() => {
    if (selectedShareItem) {
      return [LAB_EXTERNAL_LINK_NAME, LAB_EXTERNAL_LINK_URL];
    }
    return [externalLinkName, externalLinkUrl];
  }, [selectedShareItem, externalLinkName, externalLinkUrl]);
  return useMemo(
    () => ({
      strategyGroup,
      logic,
      externalLinkName: name,
      externalLinkUrl: url,
      loadingSelections,
      loadingAddToCart,
      loadingRunNow,
      loadingDetail,
      loadingDetailTerm,
      loadingDetailLab: loadingSimulationLab && !changingSimulationTermLab,
      isSoldOut,
    }),
    [
      strategyGroup,
      logic,
      name,
      url,
      loadingSelections,
      loadingAddToCart,
      loadingRunNow,
      loadingDetail,
      loadingDetailTerm,
      isSoldOut,
      loadingSimulationLab,
      changingSimulationTermLab,
    ],
  );
};

export const useLabTermOptions = () => {
  const termOptions = useSelector((state) => state.constants.selectionTermOptions);
  return useMemo(() => [{ label: '稼働日', value: DEFAULT_LAB_SELECTED_TERM_ID }].concat(termOptions), [termOptions]);
};

export const useSimulationSummarySelection = () => {
  const loading = useTableLoading();
  const termId = useSelector((state) => state.autoSelect.selectedTermId);
  const { simulationTradesData, autoTradeSettingsData } = useSelectionsTableData(termId);
  return useSimulationSummary({ loading, simulationTradesData, autoTradeSettingsData });
};

export const useSimulationSummaryLab = () => {
  const { simulationTradesData, autoTradeSettingsData, loading } = useExecutionTableDataLab();
  return useSimulationSummary({ loading, simulationTradesData, autoTradeSettingsData });
};

const isEmptyCondition = (state, ignoreKey) => {
  if (state == null) {
    return true;
  }
  const keys = Object.keys(state);
  if (keys.length === 0) {
    return true;
  }
  return keys.every((key) => {
    if (key === ignoreKey) {
      return true;
    }
    const child = state[key];
    if (child == null) {
      return true;
    }
    const childKeys = Object.keys(child);
    if (childKeys.length === 0) {
      return true;
    }
    return childKeys.every((childKey) => (child[childKey] ?? false) === false);
  });
};

export const useFilterCondition = (tabs) => {
  const dispatch = useDispatch();
  const { filterCondition } = useSelector((state) => state.autoSelect);
  const [newFilterCondition, setNewFilterCondition] = useState({ ...filterCondition });
  const [visible, setVisible] = useState(false);

  const { selectedAll, assets } = useMemo(() => {
    const assetsWithoutAll = (tabs.options || []).filter(({ id }) => id !== TAG_ID_ALL);
    const all = assetsWithoutAll.find(({ id }) => id === tabs.get) == null;
    return { selectedAll: all, assets: assetsWithoutAll.map(({ value }) => ({ id: value.toLowerCase(), value })) };
  }, [tabs.options, tabs.get]);

  const nothing = useMemo(
    () => isEmptyCondition(filterCondition, selectedAll ? null : 'assets'),
    [filterCondition, selectedAll],
  );

  const handleOpen = useCallback(() => {
    setNewFilterCondition({ ...filterCondition });
    setVisible(true);
  }, [filterCondition]);

  const handleClose = useCallback(() => {
    setVisible(false);
    setNewFilterCondition({});
  }, []);

  const handleOk = useCallback(() => {
    dispatch(saveAutoSelectFilterCondition({ filterCondition: newFilterCondition }));
    handleClose();
  }, [dispatch, newFilterCondition, handleClose]);

  const handleChange = useCallback(
    (checked, key, item) => {
      setNewFilterCondition({
        ...newFilterCondition,
        [key]: {
          ...(newFilterCondition[key] || {}),
          [item.id]: checked,
        },
      });
    },
    [newFilterCondition],
  );

  useEffect(() => {
    setNewFilterCondition({ ...filterCondition });
  }, [filterCondition]);

  return useMemo(() => {
    return {
      visible,
      nothing,
      filterCondition: newFilterCondition,
      selectedAll,
      assets,
      handleOpen,
      handleClose,
      handleOk,
      handleChange,
    };
  }, [visible, nothing, newFilterCondition, selectedAll, assets, handleOpen, handleClose, handleOk, handleChange]);
};
