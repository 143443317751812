// eslint-disable-next-line import/no-unresolved
import { useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';

export const useStrategyListForRiskAssessment = ({ data, itemList, strategySets }) => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  return useMemo(() => {
    if (data == null) {
      return [];
    }
    const {
      instrumentId,
      name,
      serviceId,
      itemList: dataItemList,
      simulationStats,
      simulationTradeList,
      simulationChartList,
      ...rest
    } = data;
    const detailServiceId = serviceId == null ? instrumentList?.[instrumentId]?.serviceId : serviceId;
    const strategyDetail = {
      instrumentId,
      name,
      serviceId: detailServiceId,
      itemList: dataItemList ?? itemList,
      simulationStats,
      simulationTradeList,
      simulationChartList,
    };
    return [{ strategySets, strategyDetail, ...rest }];
  }, [strategySets, data, itemList, instrumentList]);
};
