import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  techEndLoading,
  techStartLoading,
  getTechnicalBuilderDataRequest,
  updateTechnicalBuilderDataRequest,
  updateTechnicalBuilderNameRequest,
  deleteTechnicalBuilderDataRequest,
  updateTechnicalBuilderStatusRequest,
  getTechnicalBuilderDataSuccess,
} from '../tech';
import {
  getTechnicalBuilderData,
  updateTechnicalBuilderData,
  deleteTechnicalBuilderData,
  updateTechnicalBuilderStatus,
} from '../../api/techApi';
import { errorHandler } from './errorSaga';
import { BUILDER_ORDER_CONFIGURATION_OPTIONS as TECH_STORE_KEYS } from '../../constants/builder';
import { sendNotificationError, sendNotificationSuccess } from '../actions/notificationActions';
import { DEFAULT_ERROR_CODE } from '../../constants/apiConstant';

export function* getTechnicalBuilderDataHandler(action) {
  const tutorialMode = yield select((state) => state.tutorial.tutorialMode);
  if (tutorialMode) {
    return;
  }

  try {
    const status = action?.payload?.status;

    yield put(techStartLoading());
    const { data } = yield call(getTechnicalBuilderData, { status });

    yield put(getTechnicalBuilderDataSuccess({ data }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(techEndLoading());
  }
}

const AUTO_SETTLE_REQUEST_KEY = 'isAutoSettle';
const PRICE_RANGE_REQUEST_KEY = 'priceRange';
const ORDER_NUMS_REQUEST_KEY = 'orderNums';
const QUANTITY_REQUEST_KEY = 'quantity';
const TP_REQUEST_KEY = 'tp';
const SL_REQUEST_KEY = 'sl';
const FOLLOW_REQUEST_KEY = 'follow';
const COUNTER_REQUEST_KEY = 'counter';
const COUNTER_FIX_REQUEST_KEY = 'counterPrice';

function* updateTechnicalBuilderDataRequestHandler(action) {
  try {
    yield put(techStartLoading());

    const {
      technicalBuilderId,
      serviceId,
      instrumentId,
      counterPrice,
      entryPrice1,
      defaultParams: def,
      callback,
    } = action?.payload;

    const checks = yield select((state) => state.tech.config);
    const {
      [TECH_STORE_KEYS.TECH.IS_ALL_SETTLEMENT]: isAllSettlement,
      [TECH_STORE_KEYS.TECH.IS_ALL_SETTLEMENT_IS_CHECKED]: isAllSettlementIsChecked,
      [TECH_STORE_KEYS.TECH.RANGE_SPREAD_IS_CHECKED]: priceRangeIsChecked,
      [TECH_STORE_KEYS.TECH.ITEMS_COUNT_IS_CHECKED]: orderNumsIsChecked,
      [TECH_STORE_KEYS.TECH.AMOUNT_IS_CHECKED]: quantityIsChecked,
      [TECH_STORE_KEYS.TECH.PROFIT_MARGIN_IS_CHECKED]: tpIsChecked,
      [TECH_STORE_KEYS.TECH.STOP_LOSS_SPREAD_IS_CHECKED]: slIsChecked,
      [TECH_STORE_KEYS.TECH.FOLLOW_VALUE_IS_CHECKED]: followIsChecked,
      [TECH_STORE_KEYS.TECH.COUNTER_VALUE_IS_CHECKED]: counterIsChecked,
      [TECH_STORE_KEYS.TECH.COUNTER_IS_FIXED_CHECKED]: isCounterFixChecked,
    } = checks;

    const values = yield select((state) => state.builder.multiOrder);
    const {
      [TECH_STORE_KEYS.TECH.RANGE_SPREAD]: priceRange,
      [TECH_STORE_KEYS.TECH.ITEMS_COUNT]: orderNums,
      [TECH_STORE_KEYS.TECH.AMOUNT]: quantity,
      [TECH_STORE_KEYS.TECH.PROFIT_MARGIN]: tp,
      [TECH_STORE_KEYS.TECH.STOP_LOSS_SPREAD]: sl,
      [TECH_STORE_KEYS.TECH.FOLLOW_VALUE]: follow,
      [TECH_STORE_KEYS.TECH.COUNTER_VALUE]: _counter,
      [TECH_STORE_KEYS.TECH.COUNTER_IS_FIXED]: _isCounterFixed,
    } = values;

    const keyPear = {
      [AUTO_SETTLE_REQUEST_KEY]: isAllSettlementIsChecked ? isAllSettlement : null,
      [PRICE_RANGE_REQUEST_KEY]: priceRangeIsChecked ? priceRange : null,
      [ORDER_NUMS_REQUEST_KEY]: orderNumsIsChecked ? orderNums : null,
    };
    const itemListKeyPear = {
      [QUANTITY_REQUEST_KEY]: quantityIsChecked ? quantity : null,
      [TP_REQUEST_KEY]: tpIsChecked ? tp : null,
      [SL_REQUEST_KEY]: slIsChecked ? sl : null,
      [FOLLOW_REQUEST_KEY]: followIsChecked ? follow : null,
    };
    // 変更後パラメータを画面に反映させるためにここで用意する
    let afterParams = {};

    // API用に整形する 各項目のチェックがfalseの場合はフィールドを空にする
    const requestBody = {
      itemList: [],
      instrumentId,
      serviceId,
    };

    let itemList = {
      entryPrice1,
    };

    // 変更ありのフィールドのみ代入
    Object.keys(keyPear).forEach((key) => {
      if (keyPear[key] !== null) {
        const value = keyPear[key];
        if (typeof value === 'string' || typeof value === 'number') {
          requestBody[key] = Number(value) ? value : null;
          afterParams[key] = value;
        } else {
          requestBody[key] = value;
          afterParams[key] = value;
        }
      }
    });

    Object.keys(itemListKeyPear).forEach((key) => {
      if (itemListKeyPear[key] !== null) {
        const value = itemListKeyPear[key];
        itemList[key] = value;
        afterParams[key] = value;
      }
    });

    if (counterIsChecked || isCounterFixChecked) {
      const counter = counterIsChecked ? _counter : def?.counter;
      const isCounterFixed = isCounterFixChecked ? _isCounterFixed : def?.fixedCounterPrice;

      const counterObj = {
        [COUNTER_REQUEST_KEY]: counter,
        [COUNTER_FIX_REQUEST_KEY]: isCounterFixed ? counterPrice : null,
      };

      itemList = { ...itemList, ...counterObj };

      afterParams = {
        ...afterParams,
        counter,
        isCounterFixed,
      };
    }
    requestBody.itemList.push(itemList);

    yield call(updateTechnicalBuilderData, { technicalBuilderId, requestBody });

    if (callback) callback(afterParams);

    yield put(sendNotificationSuccess({ message: '設定変更が完了しました' }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(techEndLoading());
  }
}

function* updateTechnicalBuilderNameRequestHandler(action) {
  try {
    yield put(techStartLoading());

    const { technicalBuilderId, serviceId, name, callback } = action?.payload;

    // API用に整形する
    const requestBody = { serviceId, name };

    yield call(updateTechnicalBuilderData, { technicalBuilderId, requestBody });

    if (callback) callback();

    yield put(sendNotificationSuccess({ message: '設定変更が完了しました' }));
  } catch (e) {
    if (e.response.status === DEFAULT_ERROR_CODE) {
      yield put(
        sendNotificationError({
          message: 'すでに同じ名前のテクニカルロジックがあるため、ロジック名は変更できません。',
        }),
      );
    }
    yield call(errorHandler, { error: e });
  } finally {
    yield put(techEndLoading());
  }
}

function* deleteTechnicalBuilderDataRequestHandler(action) {
  try {
    const { technicalBuilderId, successMessage, callback } = action?.payload;

    yield put(techStartLoading());
    yield call(deleteTechnicalBuilderData, { technicalBuilderId });

    if (callback) callback();

    yield put(sendNotificationSuccess({ message: successMessage }));
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(techEndLoading());
  }
}

function* updateTechnicalBuilderStatusRequestHandler(action) {
  try {
    const { technicalBuilderId, status, serviceId, callback } = action?.payload;

    yield put(techStartLoading());

    yield call(updateTechnicalBuilderStatus, { technicalBuilderId, status, serviceId });

    if (callback) callback(status);
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(techEndLoading());
  }
}

export default function* techSaga() {
  yield takeLatest(getTechnicalBuilderDataRequest.type, getTechnicalBuilderDataHandler);
  yield takeLatest(updateTechnicalBuilderNameRequest.type, updateTechnicalBuilderNameRequestHandler);
  yield takeLatest(updateTechnicalBuilderDataRequest.type, updateTechnicalBuilderDataRequestHandler);
  yield takeLatest(deleteTechnicalBuilderDataRequest.type, deleteTechnicalBuilderDataRequestHandler);
  yield takeLatest(updateTechnicalBuilderStatusRequest.type, updateTechnicalBuilderStatusRequestHandler);
}
