import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CFD, ETF, FX } from 'shared-modules/constants';
import { useInstrumentOptions } from 'shared-modules/hooks';
import { useGetBuilderServiceId } from 'shared-modules/services/hooks/builder';
import { CustomFlag, CfdInstrumentIcon, EtfInstrumentIcon } from '../../../../../../components';
import styles from './assetLabel.module.scss';

const INSTRUMENT_ICONS = {
  [ETF]: EtfInstrumentIcon,
  [CFD]: CfdInstrumentIcon,
};

export const AssetLabel = memo(() => {
  const serviceId = useGetBuilderServiceId();
  const activeCurrency = useSelector((state) => state.builder.activeCurrency);
  const options = useInstrumentOptions()[serviceId];

  const symbol = useMemo(() => {
    return options.find((option) => option.value === activeCurrency);
  }, [options, activeCurrency]);

  const label = useMemo(() => {
    if (serviceId === FX) {
      const [ccy1, ccy2] = symbol.value.split('/') || [];
      return (
        <>
          <CustomFlag className={styles.icon} country={ccy1} />
          <CustomFlag className={styles.icon} country={ccy2} />
          <span className={styles.symbol}>
            {ccy1}
            <span className={styles.sep}>/</span>
            {ccy2}
          </span>
        </>
      );
    }
    const InstrumentIcon = INSTRUMENT_ICONS[serviceId];
    return (
      <>
        {InstrumentIcon && <InstrumentIcon className={styles.icon} instrumentId={symbol.value} size="small" />}
        <span className={styles.symbol}>{symbol.label}</span>
      </>
    );
  }, [serviceId, symbol]);

  return <div className={styles.container}>{label}</div>;
});
