import React, { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MARKET_STATUSES, TRADE_METHODS } from 'shared-modules/constants';
import { calculateSpread } from 'shared-modules/services';
import { useInstrumentSettings } from 'shared-modules/services/hooks';
import CustomFlag from '../../../../components/CustomFlag';
import ValueCompared from './components/ValueCompared';
import styles from './currencyPairItemFX.module.scss';

const CurrencyPairItemFX = ({ instrumentId, innerRef, draggableProps, disabled, changeActiveCurrency }) => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const { bid, previousBid, ask, previousAsk } = useSelector((state) => state.currencies.rates[instrumentId]) || {};

  const realInstrumentId = useMemo(() => instrumentId.split('.').pop(), [instrumentId]);

  const [firstFlagIcon, secondFlagIcon] = useMemo(() => {
    return realInstrumentId.split('/');
  }, [realInstrumentId]);

  const { marketStatus } = useInstrumentSettings(realInstrumentId, TRADE_METHODS.MANUAL.ID);
  const isClosed = useMemo(() => Number(marketStatus) === MARKET_STATUSES.CLOSED.ID, [marketStatus]);

  const handleSelectItem = useCallback(() => {
    if (disabled) return false;
    return changeActiveCurrency(instrumentId);
  }, [instrumentId, disabled, changeActiveCurrency]);

  const spread = useMemo(
    () => calculateSpread({ buyPrice: ask, sellPrice: bid, instrumentId, instrumentList }),
    [instrumentId, instrumentList, ask, bid],
  );

  return (
    <div
      role="button"
      aria-hidden
      className={classNames(styles.wrapper, { [styles.isDisabled]: disabled })}
      ref={innerRef}
      {...draggableProps.draggableProps} // eslint-disable-line
      onClick={handleSelectItem}
    >
      <i
        className={classNames('material-icons', styles.draggableIcon)}
        {...draggableProps.dragHandleProps} // eslint-disable-line
      >
        drag_indicator
      </i>

      <div className={styles.currencyBlock}>
        <div className={styles.flagContainer}>
          <CustomFlag country={firstFlagIcon} className={styles.flag} />
          <CustomFlag country={secondFlagIcon} className={styles.flag} />
        </div>

        <div className={classNames(styles.currencyPairRow, { [styles.isClosed]: isClosed || disabled })}>
          {realInstrumentId}
        </div>
      </div>

      <ValueCompared instrumentId={instrumentId} value={bid} prevValue={previousBid} isClosed={isClosed || disabled} />

      <div className={classNames(styles.spread, { [styles.isClosed]: isClosed || disabled })}>{spread}</div>

      <ValueCompared instrumentId={instrumentId} value={ask} prevValue={previousAsk} isClosed={isClosed || disabled} />
    </div>
  );
};

CurrencyPairItemFX.propTypes = {
  instrumentId: PropTypes.string.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.shape({}) })]).isRequired,
  draggableProps: PropTypes.shape({}).isRequired,
  changeActiveCurrency: PropTypes.func,
  disabled: PropTypes.bool,
};

CurrencyPairItemFX.defaultProps = {
  changeActiveCurrency: () => {},
  disabled: false,
};

export default memo(CurrencyPairItemFX);
