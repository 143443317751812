// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { useMemo } from 'react';
import { AP_SUFFIX, M_SUFFIX, TRADE_METHODS, rateSeparationedAssets } from '../constants';
import { useInstrumentOptions } from './useInstrumentOptions';
import { getInstrumentShortName, getServiceId, removeInstrumentIdSuffix as removeSuffix } from '../utils';

export { removeSuffix };

export const useSymbol = (isManual) => {
  const instrumentList = useSelector((state) => state.settings.instrumentList);
  const SelectedSymbolList = useMemo(() => {
    if (isManual) {
      return Object.entries(instrumentList ?? {})
        .filter((e) => e[1].instrumentId.includes(M_SUFFIX))
        .map((el) => el[1].symbol);
    }
    return Object.entries(instrumentList ?? {})
      .filter((e) => e[1].instrumentId.includes(AP_SUFFIX))
      .map((el) => el[1].symbol);
  }, [instrumentList, isManual]);
  return SelectedSymbolList;
};

export const useRemovedDuplicateInstrumentOptions = (serviceId) => {
  const instrumentOptions = useInstrumentOptions()[serviceId];
  if (!rateSeparationedAssets.includes(serviceId)) {
    return instrumentOptions;
  }

  const removedDuplicateArray = instrumentOptions.reduce((accumulator, item) => {
    const isLabelDuplicate = accumulator.some((existingItem) => existingItem.label === item.label);
    const sanitizedValue = item.value.includes(M_SUFFIX)
      ? item.value.replace(M_SUFFIX, '')
      : item.value.replace(AP_SUFFIX, '');
    if (!isLabelDuplicate) {
      accumulator.push({ ...item, value: sanitizedValue });
    }
    return accumulator;
  }, []);
  return removedDuplicateArray;
};

export const useApOrManualInstrumentOptions = (isManual, serviceId) => {
  const instrumentOptions = useInstrumentOptions();

  const memoizedInstrumentOptions = useMemo(() => {
    if (serviceId) {
      const apOrManualInstrumentoptions = Object.fromEntries(
        Object.entries(instrumentOptions)
          .filter(([asset]) => asset === serviceId)
          .map(([asset, instruments]) => [
            asset,
            rateSeparationedAssets.includes(asset)
              ? instruments.filter(({ value }) => (isManual ? value.includes(M_SUFFIX) : value.includes(AP_SUFFIX)))
              : instruments,
          ]),
      );
      return apOrManualInstrumentoptions;
    }

    const apOrManualInstrumentOptions = Object.fromEntries(
      Object.entries(instrumentOptions).map(([asset, instruments]) => [
        asset,
        rateSeparationedAssets.includes(asset)
          ? instruments.filter(({ value }) => (isManual ? value.includes(M_SUFFIX) : value.includes(AP_SUFFIX)))
          : instruments,
      ]),
    );
    return apOrManualInstrumentOptions;
  }, [isManual, serviceId, instrumentOptions]);

  return memoizedInstrumentOptions;
};

export const transformInstrumentId = (id, instrument, serviceId) => {
  if (id === TRADE_METHODS.MANUAL.ID && rateSeparationedAssets.includes(serviceId)) {
    const transformedInstrumentId = instrument.replace(AP_SUFFIX, M_SUFFIX);
    return transformedInstrumentId;
  }
  if (id === TRADE_METHODS.AP.ID && rateSeparationedAssets.includes(serviceId)) {
    const transformedInstrumentId = instrument.replace(M_SUFFIX, AP_SUFFIX);
    return transformedInstrumentId;
  }
  return instrument;
};

export const transformInstrumentList = (tradeMethod, instrument, serviceId) => {
  if (rateSeparationedAssets.includes(serviceId)) {
    return instrument.map((item) => {
      const { displayed, id, instrumentId } = item;
      if (tradeMethod === TRADE_METHODS.MANUAL.ID) {
        const modifiedInstrumentId = instrumentId.replace(AP_SUFFIX, M_SUFFIX);

        return {
          displayed,
          id,
          instrumentId: modifiedInstrumentId,
        };
      }
      if (tradeMethod === TRADE_METHODS.AP.ID) {
        const modifiedInstrumentId = instrumentId.replace(M_SUFFIX, AP_SUFFIX);

        return {
          displayed,
          id,
          instrumentId: modifiedInstrumentId,
        };
      }
      return {
        displayed,
        id,
        instrumentId,
      };
    });
  }
  return instrument;
};

export const getShortName = (instrumentId, instrumentList) => {
  const instrument = instrumentList?.[instrumentId];
  if (!instrument) {
    const instrumentWithMatchingSymbol = Object.values(instrumentList).find(
      (instruments) => instruments.symbol === instrumentId,
    );

    if (instrumentWithMatchingSymbol) {
      return instrumentWithMatchingSymbol.shortName ?? '-';
    }
  }
  return getInstrumentShortName(instrumentId, instrumentList);
};

export const getInstrumentServiceId = (instrumentId, instrumentList) => {
  const serviceIdFromInstrumentList = instrumentList?.[instrumentId]?.serviceId;
  if (!serviceIdFromInstrumentList) {
    const instrumentWithMatchingSymbol = Object.values(instrumentList).find(
      (instrument) => instrument.symbol === instrumentId,
    );

    if (instrumentWithMatchingSymbol) {
      return instrumentWithMatchingSymbol.serviceId;
    }
  }
  return getServiceId(instrumentId, instrumentList);
};
export const useReplaceInstrumentIdToSymbol = (serviceId) => {
  const instrumentOptions = useInstrumentOptions()[serviceId];

  if (!rateSeparationedAssets.includes(serviceId)) {
    return instrumentOptions;
  }
  return instrumentOptions
    .filter((e) => e && e.value && e?.value?.includes(AP_SUFFIX))
    .map((el) => {
      const { value, label } = el;
      const symbol = removeSuffix(value);
      return { value: symbol, label };
    });
};

export const useInstrumentGroups = (instrumentGroup, serviceId) => {
  if (!rateSeparationedAssets.includes(serviceId)) {
    return instrumentGroup;
  }
  const mergedData = {};

  instrumentGroup.forEach((item) => {
    const instrumentId = removeSuffix(item.instrumentId);

    if (instrumentId in mergedData) {
      mergedData[instrumentId].orderingRequiredMargin += item.orderingRequiredMargin;
      mergedData[instrumentId].positionRequiredMargin += item.positionRequiredMargin;
    } else {
      mergedData[instrumentId] = {
        instrumentId,
        orderingRequiredMargin: item.orderingRequiredMargin,
        positionRequiredMargin: item.positionRequiredMargin,
      };
    }
  });

  return Object.values(mergedData);
};

export const removedDuplicateInstrumentList = (instrumentList, serviceId) => {
  if (!rateSeparationedAssets.includes(serviceId)) {
    return instrumentList;
  }
  const uniqueData = [];

  Object.keys(instrumentList).forEach((key) => {
    const { instrumentId, ...restContent } = instrumentList[key];
    const fixedInstrumentId = removeSuffix(instrumentId);

    if (!(fixedInstrumentId in uniqueData)) {
      uniqueData[fixedInstrumentId] = { instrumentId: fixedInstrumentId, ...restContent };
    }
  });

  return uniqueData;
};
