import {
  CHANGE_ACTIVE_CURRENCY,
  CHANGE_EXCHANGE_TYPE,
  CHANGE_ORDER_TYPE,
  CHANGE_SINGLE_ORDER_OPTION,
  CHANGE_MULTI_ORDER_OPTION,
  CHANGE_ORDER_EDIT_STATUS,
  CHANGE_SELECTED_TERM_ID,
  RESET_ORDER_TYPE,
  RESET_ACTIVE_CURRENCY,
  RESET_EXCHANGE_TYPE,
  RESET_OPTIONS,
  RESET_SINGLE_ORDER_OPTIONS,
  RESET_MULTI_ORDER_OPTIONS,
  RESET_ORDERS_TABLES,
  RESET_CHART_DATA,
  ADD_ORDER_SETTINGS_LIST,
  ADD_ORDER_SETTINGS,
  ADD_LOGIC_GROUP,
  DELETE_ORDER_SETTINGS_BY_ID,
  DELETE_LOGIC_GROUP_BY_ID,
  GET_CHART_DATA_REQUEST,
  GET_CHART_DATA_REQUEST_START_LOADING,
  GET_CHART_DATA_REQUEST_END_LOADING,
  GET_CHART_DATA_SUCCESS,
  GET_SIMULATION_DATA_REQUEST,
  GET_SIMULATION_DATA_REQUEST_START_LOADING,
  GET_SIMULATION_DATA_REQUEST_END_LOADING,
  GET_SIMULATION_DATA_SUCCESS,
  RESET_SIMULATION_DATA,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_REQUEST_START_LOADING,
  ADD_TO_CART_REQUEST_END_LOADING,
  ADD_TO_PORTFOLIO_REQUEST,
  ADD_TO_PORTFOLIO_REQUEST_START_LOADING,
  ADD_TO_PORTFOLIO_REQUEST_END_LOADING,
  CHANGE_SINGLE_ORDER_VALIDATION_ERRORS,
  CHANGE_MULTI_ORDER_VALIDATION_ERRORS,
  RESET_SINGLE_ORDER_VALIDATION_ERRORS,
  RESET_MULTI_ORDER_VALIDATION_ERRORS,
  HIDE_PROFITLOSS_CHART,
  SHOW_PROFITLOSS_CHART,
  CHANGE_TECH_ORDER_OPTION,
  GET_CLOSE_RATE_DATA_SUCCESS,
  ADD_TECH_LOGIC_GROUP,
  ADD_TO_PORTFOLIO_TECH_REQUEST,
  ADD_TO_CART_TECH_REQUEST,
  GET_TECH_SIMULATION_DATA_REQUEST,
  RESET_TECH_ORDER_OPTIONS,
  DELETE_CHART_MAKE_ORDER_SETTINGS_BY_ID,
} from '../actionConstants/builderConstants';

export const changeBuilderActiveCurrency = ({ activeCurrency }) => ({
  type: CHANGE_ACTIVE_CURRENCY,
  payload: { activeCurrency },
});

export const changeBuilderExchangeType = ({ exchangeType }) => ({
  type: CHANGE_EXCHANGE_TYPE,
  payload: { exchangeType },
});
export const changeBuilderOrderType = ({ orderType }) => ({ type: CHANGE_ORDER_TYPE, payload: { orderType } });
export const changeBuilderSingleOrderOption = ({ fieldName, value }) => ({
  type: CHANGE_SINGLE_ORDER_OPTION,
  payload: { fieldName, value },
});
export const changeBuilderMultiOrderOption = ({ fieldName, value }) => ({
  type: CHANGE_MULTI_ORDER_OPTION,
  payload: { fieldName, value },
});
export const changeSelectedTermIdBuilder = ({ termId }) => ({ type: CHANGE_SELECTED_TERM_ID, payload: { termId } });
export const changeTechBuilderMultiOrderOption = ({ fieldName, value }) => ({
  type: CHANGE_TECH_ORDER_OPTION,
  payload: { fieldName, value },
});
export const changeBuilderOrderEditStatus = ({ allowOrderEdit }) => ({
  type: CHANGE_ORDER_EDIT_STATUS,
  payload: { allowOrderEdit },
});
export const changeBuilderSingleOrderValidationErrors = ({ fieldName, errorMessage, hasValidationError }) => ({
  type: CHANGE_SINGLE_ORDER_VALIDATION_ERRORS,
  payload: { fieldName, errorMessage, hasValidationError },
});
export const changeBuilderMultiOrderValidationErrors = ({ fieldName, errorMessage, hasValidationError }) => ({
  type: CHANGE_MULTI_ORDER_VALIDATION_ERRORS,
  payload: { fieldName, errorMessage, hasValidationError },
});

export const resetBuilderOrderType = () => ({ type: RESET_ORDER_TYPE });
export const resetBuilderActiveCurrency = () => ({ type: RESET_ACTIVE_CURRENCY });
export const resetBuilderExchangeType = () => ({ type: RESET_EXCHANGE_TYPE });
export const resetBuilderOptions = () => ({ type: RESET_OPTIONS });
export const resetBuilderSingleOrderOptions = () => ({ type: RESET_SINGLE_ORDER_OPTIONS });
export const resetBuilderMultiOrderOptions = () => ({ type: RESET_MULTI_ORDER_OPTIONS });
export const resetBuilderTechOrderOptions = () => ({ type: RESET_TECH_ORDER_OPTIONS });
export const resetBuilderOrdersTables = () => ({ type: RESET_ORDERS_TABLES });
export const resetBuilderChartData = () => ({ type: RESET_CHART_DATA });
export const resetBuilderSingleOrderValidationErrors = () => ({ type: RESET_SINGLE_ORDER_VALIDATION_ERRORS });
export const resetBuilderMultiOrderValidationErrors = () => ({ type: RESET_MULTI_ORDER_VALIDATION_ERRORS });

export const addBuilderOrderSettingsList = ({ orderSettings }) => ({
  type: ADD_ORDER_SETTINGS_LIST,
  payload: { orderSettings },
});
export const addBuilderOrderSettings = ({
  instrumentId,
  realInstrumentId,
  buySell,
  amount,
  entryPriceTypeId,
  entryPricePipsValue,
  entryPrice1,
  entryPrice2,
  profitMargin,
  stopLossRange,
  follow,
  counter,
  counterPrice,
  id,
}) => ({
  type: ADD_ORDER_SETTINGS,
  payload: {
    instrumentId,
    realInstrumentId,
    buySell,
    amount,
    entryPriceTypeId,
    entryPricePipsValue,
    entryPrice1,
    entryPrice2,
    profitMargin,
    stopLossRange,
    follow,
    counter,
    counterPrice,
    id,
  },
});
export const addBuilderLogicGroup = ({ orderSettings, logicGroup }) => ({
  type: ADD_LOGIC_GROUP,
  payload: { orderSettings, logicGroup },
});
export const addTechBuilderLogicGroup = ({ orderSettings, logicGroup }) => ({
  type: ADD_TECH_LOGIC_GROUP,
  payload: { orderSettings, logicGroup },
});

export const deleteBuilderOrderSettingsById = ({ id }) => ({ type: DELETE_ORDER_SETTINGS_BY_ID, payload: { id } });
export const deleteChartMakeOrderSettingsById = ({ id }) => ({
  type: DELETE_CHART_MAKE_ORDER_SETTINGS_BY_ID,
  payload: { id },
});
export const deleteBuilderLogicGroupById = ({ id }) => ({ type: DELETE_LOGIC_GROUP_BY_ID, payload: { id } });

export const getBuilderChartDataRequest = () => ({ type: GET_CHART_DATA_REQUEST });
export const getBuilderChartDataRequestStartLoading = () => ({ type: GET_CHART_DATA_REQUEST_START_LOADING });
export const getBuilderChartDataRequestEndLoading = () => ({ type: GET_CHART_DATA_REQUEST_END_LOADING });
export const getBuilderChartDataSuccess = ({ chartData }) => ({ type: GET_CHART_DATA_SUCCESS, payload: { chartData } });
export const getCloseRateDataSuccess = ({ askCloseRate, bidCloseRate }) => ({
  type: GET_CLOSE_RATE_DATA_SUCCESS,
  payload: { askCloseRate, bidCloseRate },
});

export const getBuilderSimulationDataRequest = ({ orderRequestData, callback = null, termId }) => ({
  type: GET_SIMULATION_DATA_REQUEST,
  payload: { orderRequestData, callback, termId },
});
export const getTechBuilderSimulationDataRequest = ({ orderRequestData, callback = null, termId }) => ({
  type: GET_TECH_SIMULATION_DATA_REQUEST,
  payload: { orderRequestData, callback, termId },
});
export const getBuilderSimulationDataRequestStartLoading = () => ({ type: GET_SIMULATION_DATA_REQUEST_START_LOADING });
export const getBuilderSimulationDataRequestEndLoading = () => ({ type: GET_SIMULATION_DATA_REQUEST_END_LOADING });
export const getBuilderSimulationDataSuccess = ({ simulationData, termId }) => ({
  type: GET_SIMULATION_DATA_SUCCESS,
  payload: { simulationData, termId },
});
export const resetBuilderSimulationData = () => ({ type: RESET_SIMULATION_DATA });

export const addToCartBuilderRequest = ({ strategySets, orderRequestData, callback = null, isChartMake = false }) => ({
  type: ADD_TO_CART_REQUEST,
  payload: { strategySets, orderRequestData, callback, isChartMake },
});
export const addToCartTechBuilderRequest = ({ strategySets, orderRequestData, callback = null }) => ({
  type: ADD_TO_CART_TECH_REQUEST,
  payload: { strategySets, orderRequestData, callback },
});
export const addToCartBuilderRequestStartLoading = () => ({ type: ADD_TO_CART_REQUEST_START_LOADING });
export const addToCartBuilderRequestEndLoading = () => ({ type: ADD_TO_CART_REQUEST_END_LOADING });

export const addToPortfolioBuilderRequest = ({
  strategySets,
  orderRequestData,
  successCallback = null,
  failCallback = null,
}) => ({
  type: ADD_TO_PORTFOLIO_REQUEST,
  payload: { strategySets, orderRequestData, successCallback, failCallback },
});
export const addToPortfolioTechBuilderRequest = ({
  strategySets,
  orderRequestData,
  successCallback = null,
  failCallback = null,
}) => ({
  type: ADD_TO_PORTFOLIO_TECH_REQUEST,
  payload: { strategySets, orderRequestData, successCallback, failCallback },
});
export const addToPortfolioBuilderRequestStartLoading = () => ({ type: ADD_TO_PORTFOLIO_REQUEST_START_LOADING });
export const addToPortfolioBuilderRequestEndLoading = () => ({ type: ADD_TO_PORTFOLIO_REQUEST_END_LOADING });

export const hideProfitlossChart = () => ({ type: HIDE_PROFITLOSS_CHART });
export const showProfitlossChart = () => ({ type: SHOW_PROFITLOSS_CHART });
