import {
  GET_NOTIFICATION_LIST_REQUEST,
  GET_NOTIFICATION_LIST_SUCCESS,
  OPEN_ADVERTISEMENT_REQUEST,
  OPEN_ADVERTISEMENT_SUCCESS,
  CLOSE_ADVERTISEMENT,
} from '../actionConstants/advertisementConstants';

export const getNotificationListRequest = () => ({ type: GET_NOTIFICATION_LIST_REQUEST });
export const getNotificationListSuccess = ({ advertisementsList }) => ({
  type: GET_NOTIFICATION_LIST_SUCCESS,
  payload: { advertisementsList },
});

export const openAdvertisementRequest = () => ({ type: OPEN_ADVERTISEMENT_REQUEST });
export const openAdvertisementSuccess = ({ body, popupUrl }) => ({
  type: OPEN_ADVERTISEMENT_SUCCESS,
  payload: { body, popupUrl },
});
export const closeAdvertisement = () => ({ type: CLOSE_ADVERTISEMENT });
