import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Pane } from './Pane';
import styles from './splitPane.module.scss';

export const SPLITTER_SIZE = 8;

const unFocus = (document, window) => {
  if (document.selection) {
    document.selection.empty();
  } else {
    try {
      window.getSelection().removeAllRanges();
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
};

const getDefaultSize = (defaultSize, minSize, maxSize, draggedSize) => {
  if (typeof draggedSize === 'number') {
    const min = typeof minSize === 'number' ? minSize : 0;
    const max = typeof maxSize === 'number' && maxSize >= 0 ? maxSize : Infinity;
    return Math.max(min, Math.min(max, draggedSize));
  }
  if (defaultSize != null) {
    return defaultSize;
  }
  return minSize;
};

const getActualSize = (vertical, size, target) => {
  if (typeof size === 'string') {
    const { width, height } = target.getBoundingClientRect();
    return vertical ? width : height;
  }
  return size;
};

export const SplitPane = ({
  split,
  size,
  defaultSize,
  minSize,
  maxSize,
  className,
  splitterClassName,
  pane1ClassName,
  pane2ClassName,
  children,
  onMove,
}) => {
  const splitPaneRef = useRef(null);
  const splitterRef = useRef(null);
  const pane1Ref = useRef(null);
  const prevSizeRef = useRef(size);
  const defaultSizeRef = useRef(getDefaultSize(defaultSize, minSize, maxSize, null));
  const [active, setActive] = useState(false);
  const positionRef = useRef(null);
  const [draggedSize, setDraggedSize] = useState(null);
  const initialSize = size ?? defaultSizeRef.current;
  const pane1SizeRef = useRef(initialSize);
  const [pane1Size, setPane1Size] = useState(pane1SizeRef.current);
  const maxHeightRef = useRef(null);
  const maxWidthRef = useRef(null);
  const vertical = split === 'vertical';

  const style = useMemo(() => {
    const innerStyle = {
      display: 'flex',
      flex: 1,
      height: '100%',
      position: 'absolute',
      outline: 'none',
      overflow: 'hidden',
      userSelect: 'text',
    };
    if (vertical) {
      return {
        ...innerStyle,
        flexDirection: 'row',
        left: 0,
        right: 0,
      };
    }
    return {
      ...innerStyle,
      bottom: 0,
      flexDirection: 'column',
      minHeight: '100%',
      top: 0,
      width: '100%',
    };
  }, [vertical]);

  const [child1, child2] = useMemo(() => {
    return React.Children.toArray(children).filter((c) => c);
  }, [children]);

  const handleMouseUp = useCallback(() => {
    if (active) {
      setActive(false);
    }
  }, [active]);

  const handleMouseDown = useCallback(
    (event) => {
      unFocus(document, window);
      setActive(true);
      positionRef.current = vertical ? event.clientX : event.clientY;
    },
    [vertical],
  );

  const handleMouseMove = useCallback(
    (event) => {
      if (!active) {
        return;
      }
      unFocus(document, window);
      const splitter = splitterRef.current;
      if (!splitter) {
        return;
      }

      const actualSize = getActualSize(vertical, pane1SizeRef.current, pane1Ref.current);
      const current = vertical ? event.clientX : event.clientY;
      const position = positionRef.current ?? 0;
      const positionDelta = position - current;
      const sizeDelta = positionDelta;

      // TODO flex order に対応する場合はここで sizeDelta に補正を入れる必要がある

      const fullSize = (vertical ? maxWidthRef.current : maxHeightRef.current) - SPLITTER_SIZE;
      const newMaxSize = maxSize != null && maxSize <= 0 ? fullSize + maxSize : Math.min(maxSize ?? Infinity, fullSize);

      let newSize = actualSize - sizeDelta;
      if (newSize < Math.min(minSize, fullSize)) {
        newSize = minSize;
        const { x, y } = splitter.getBoundingClientRect();
        positionRef.current = vertical ? x : y;
      } else if (newSize > newMaxSize) {
        newSize = newMaxSize;
        const { x, y } = splitter.getBoundingClientRect();
        positionRef.current = vertical ? x : y;
      } else {
        positionRef.current = current;
      }

      setDraggedSize(newSize);
      setPane1Size(newSize);
      pane1SizeRef.current = newSize;
      onMove?.(newSize);
    },
    [maxSize, minSize, active, vertical, onMove],
  );

  useEffect(() => {
    defaultSizeRef.current = getDefaultSize(defaultSize, minSize, maxSize, draggedSize);
  }, [defaultSize, minSize, maxSize, draggedSize]);

  useEffect(() => {
    if (size == null || (size != null && size === prevSizeRef.current)) {
      return;
    }
    const newSize = size ?? defaultSizeRef.current;
    if (size != null) {
      setDraggedSize(newSize);
    }
    setPane1Size(newSize);
    pane1SizeRef.current = size;
    prevSizeRef.current = size;
  }, [size]);

  // split pane の resize 監視
  useEffect(() => {
    if (!window.ResizeObserver) {
      return () => {};
    }
    const splitPane = splitPaneRef.current;
    const rect = splitPane.getBoundingClientRect();
    maxHeightRef.current = rect.height;
    maxWidthRef.current = rect.width;
    const observer = new window.ResizeObserver((entries) => {
      if (!pane1Ref.current) {
        return;
      }
      const [target] = entries;
      const { width = 0, height = 0 } = target?.contentRect ?? {};
      maxHeightRef.current = height;
      maxWidthRef.current = width;
      const limitSize = (vertical ? maxWidthRef.current : maxHeightRef.current) - SPLITTER_SIZE;
      const pane1Rect = pane1Ref.current.getBoundingClientRect();
      const currentSize = vertical ? pane1Rect.width : pane1Rect.height;
      if (currentSize > limitSize && limitSize >= minSize) {
        setPane1Size(limitSize);
        pane1SizeRef.current = limitSize;
      }
    });
    observer.observe(splitPane);
    return () => {
      observer.unobserve(splitPane);
    };
  }, [vertical, minSize, maxSize]);

  useEffect(() => {
    const splitPane = splitPaneRef.current;
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mouseleave', handleMouseUp);
    splitPane.addEventListener('mousemove', handleMouseMove);
    const removes = [];
    Array.from(document.getElementsByTagName('iframe'))
      .filter((iframe) => iframe.contentDocument)
      .forEach((iframe) => {
        const handleMouseMoveIframe = (event) => {
          const customEvent = new CustomEvent('mousemove', { bubbles: true, cancelable: false });
          const { left, top } = iframe.getBoundingClientRect();
          customEvent.clientX = event.clientX + left;
          customEvent.clientY = event.clientY + top;
          iframe.dispatchEvent(customEvent);
        };
        const iframeWindow = iframe.contentWindow;
        iframeWindow.addEventListener('mouseup', handleMouseUp);
        iframeWindow.addEventListener('mousemove', handleMouseMoveIframe);
        removes.push(() => {
          if (iframeWindow.closed) {
            return;
          }
          iframeWindow.removeEventListener('mouseup', handleMouseUp);
          iframeWindow.removeEventListener('mousemove', handleMouseMoveIframe);
        });
      });
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mouseleave', handleMouseUp);
      splitPane.removeEventListener('mousemove', handleMouseMove);
      removes.forEach((remove) => remove());
    };
  }, [handleMouseUp, handleMouseMove]);

  const splitterStyle = useMemo(() => {
    if (vertical) {
      return {
        width: SPLITTER_SIZE,
        minWidth: SPLITTER_SIZE,
      };
    }
    return {
      height: SPLITTER_SIZE,
      minHeight: SPLITTER_SIZE,
    };
  }, [vertical]);

  return (
    <div ref={splitPaneRef} style={style} className={className}>
      <Pane ref={pane1Ref} className={pane1ClassName} split={split} size={pane1Size}>
        {child1}
      </Pane>
      <span
        ref={splitterRef}
        role="presentation"
        style={splitterStyle}
        className={classNames(styles.splitter, styles[split], splitterClassName)}
        onMouseDown={handleMouseDown}
      />
      <Pane className={pane2ClassName} split={split}>
        {child2}
      </Pane>
    </div>
  );
};

SplitPane.propTypes = {
  split: PropTypes.oneOf(['vertical', 'horizontal']),
  minSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  splitterClassName: PropTypes.string,
  pane1ClassName: PropTypes.string,
  pane2ClassName: PropTypes.string,
  onMove: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

SplitPane.defaultProps = {
  split: 'vertical',
  minSize: 50,
  maxSize: undefined,
  defaultSize: undefined,
  size: undefined,
  className: undefined,
  splitterClassName: undefined,
  pane1ClassName: undefined,
  pane2ClassName: undefined,
  onMove: undefined,
};
