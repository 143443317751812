import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'shared-modules/redux/actions/routerActions';
import {
  updateUserLearnTriautoStatus,
  updateUserLearnTriautoStatusRequest,
} from 'shared-modules/redux/actions/settingsActions';
import { MODAL_SIZES, LEARN_TRIAUTO_MENU, LEARN_TRIAUTO_STATUS } from 'shared-modules/constants';
import { Button, Modal } from '../../../../components';
import { CASH } from '../../../../constants';
import styles from './BeginDeposit.module.scss';
import beginDeposit from '../../../../assets/learnTriauto/beginDeposit.png';

const BeginDeposit = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();
  const { beginnerReadStatusList } = useSelector((state) => state.settings.learnTriautoStatus);

  const openCash = useCallback(() => {
    if (beginnerReadStatusList[LEARN_TRIAUTO_MENU.BEGINNER.STEPS[1].key] === LEARN_TRIAUTO_STATUS.READ_STATUS.UNREAD) {
      dispatch(updateUserLearnTriautoStatus({ key: LEARN_TRIAUTO_MENU.BEGINNER.STEPS[1].key }));
      dispatch(updateUserLearnTriautoStatusRequest());
    }
    closeModal();
    dispatch(push(`/${CASH}`));
  }, [dispatch, beginnerReadStatusList, closeModal]);

  const closePanel = useCallback(() => {
    if (beginnerReadStatusList[LEARN_TRIAUTO_MENU.BEGINNER.STEPS[1].key] === LEARN_TRIAUTO_STATUS.READ_STATUS.UNREAD) {
      dispatch(updateUserLearnTriautoStatus({ key: LEARN_TRIAUTO_MENU.BEGINNER.STEPS[1].key }));
      dispatch(updateUserLearnTriautoStatusRequest());
    }
    closeModal();
  }, [dispatch, beginnerReadStatusList, closeModal]);

  const leftContents = () => {
    return (
      <div>
        <img src={beginDeposit} className={styles.img} alt="口座に入金する" />
      </div>
    );
  };

  const rightContents = () => {
    return (
      <div>
        <div className={styles.topTitle}>
          <p>口座に入金する</p>
        </div>
        <div className={styles.sentenceBlock}>
          <span>運用するルールによって必要な資金は変わりますが、</span>
          <span className={styles.bold}>
            トライ
            <br />
            オートのお客様は平均50万以上
          </span>
          <span>入金して始めています。</span>
        </div>
        <div className={styles.sentenceBlock}>
          <span>
            セレクトでは各ルールの推奨証拠金、発注証拠金を詳細画面で確認できます。余裕を持ってお取引いただくことをおすすめしています。
          </span>
        </div>
        <div className={styles.footer}>
          <Button className={styles.button} onClick={openCash} primary>
            入金へすすむ
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {isOpen && (
        <Modal
          closeModal={closePanel}
          isOpen={isOpen}
          centered
          size={MODAL_SIZES.WIDTH_1024}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className={styles.modalComponent}>
            <div className={styles.leftComponent}>{leftContents()}</div>
            <div className={styles.rightComponent}>{rightContents()}</div>
          </div>
        </Modal>
      )}
    </>
  );
};

BeginDeposit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(BeginDeposit);
