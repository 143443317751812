import React, { memo, useMemo, useEffect, useRef, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Bowser from 'bowser';
import classNames from 'classnames';
import {
  DEFAULT_SERVICE_ID,
  KEY_FOR_DEFAULT_SERVICE_ID,
  SSO_PAYMENT_DEPOSIT_REDIRECT_URL,
  SSO_PAYMENT_TRANSFER_REDIRECT_URL,
  SSO_PAYMENT_WITHDRAWAL_REDIRECT_URL,
  SSO_PAYMENT_DETAIL_REDIRECT_URL,
  SSO_MONEY_HATCH_URL,
  KEY_FOR_SYSTEM_ERROR,
  LOGIN,
} from 'shared-modules/constants';
import { useRedirectAllMaintenance } from 'shared-modules/hooks/service';
import { getDefaultValuesFromLocalStorage, systemErrorHandler } from 'shared-modules/services';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import {
  HOME,
  AUTO_SELECT,
  BUILDER,
  MANUAL_TRADE,
  CART,
  CASH,
  CASH_DEPOSIT_SUCCESS,
  CASH_DEPOSIT_FAILED,
  SSO_QUICK_DEPOSIT_URL,
  MANUAL_TRADE_CHART,
  OTHER_WINDOW_AUTO_SELECT_DETAIL_CHART,
  CURRENCY_PAIRS,
  SSO_AUTH_URL,
  MOBILE_CHART,
  MOBILE_AUTO_SELECT_PRICE_CHART,
  MOBILE_DRAW_CHART,
  MOBILE_MARKET_ORDER_CHART,
  MOBILE_LAB_PRICE_CHART,
  SSO_MY_PAGE_URL,
  SSO_PAYMENT_URL,
  UNSUPPORTED_BROWSERS,
  BUILDER_CHART,
  MOBILE_TECH_BUILDER_CHART,
  TRADE_INFO,
  MEDIA,
  MESSAGE,
} from '../../constants';
import {
  changeServiceIdSuccess,
  openAgreementInfo,
  sendNotificationError,
  openAdvertisementRequest,
  openTutorialModal,
} from '../../redux/actions';
import PreventActionsScreen from './components/PreventActionsScreen';
import Header from './components/Header';
import PortfolioPage from '../../screens/PortfolioPage';
import AutoSelect from '../../screens/AutoSelect';
import { Builder } from '../../screens/Builder';
import Cash from '../../screens/Cash';
import QuickDepositSuccess from '../../screens/Cash/components/QuickDeposit/QuickDepositSuccess';
import QuickDepositFailed from '../../screens/Cash/components/QuickDeposit/QuickDepositFailed';
import ManualTrade from '../../screens/ManualTrade';
import NotFound from '../../screens/NotFound';
import LoginPage from '../../screens/LoginPage';
import SSOAuthPage from '../../screens/SSOAuthPage';
import SSOMyPage from '../../screens/SSOMyPage';
import SSOPayment from '../../screens/SSOPayment';
import { Cart } from '../../screens/Cart';
import SideBar from './components/SideBar';
import ManualTradeChart from '../../screens/ManualTradeChart';
import OtherWindowAutoSelectDetailChart from '../../screens/OtherWindowAutoSelectDetailChart';
import CurrencyPairPage from '../../screens/CurrencyPairPage';
import MobileChart from '../../screens/MobileChart';
import MobileAutoSelectPriceChart from '../../screens/MobileAutoSelectPriceChart';
import MobileMarketOrderChart from '../../screens/mobileMarketOrderChart';
import MobileLabPriceChart from '../../screens/MobileLabPriceChart';
import BuilderChart from '../../screens/BuilderChart';
import TechBuilderChart from '../../screens/TechBuilderChart/TechBuilderChart';
import UnsupportedBrowserPage from '../../screens/UnsupportedBrowserPage';
import Loading from '../../screens/Loading/Loading';
import SSOMoneyHatch from '../../screens/SSOMoneyHatch/SSOMoneyHatch';
import SSOQuickDeposit from '../../screens/SSOQuickDeposit';
import SSOPaymentDeposit from '../../screens/SSOPaymentDeposit';
import SSOPaymentTransfer from '../../screens/SSOPaymentTransfer';
import SSOPaymentWithdrawal from '../../screens/SSOPaymentWithdrawal';
import SSOPaymentDetail from '../../screens/SSOPaymentDetail';
import styles from './routingComponent.module.scss';
import MobileDrawOrder from '../../screens/DrawOrder/MobileDrawOrder';
import TradeInfo from '../../screens/TradeInfo/TradeInfo';
import { AppHeaderHeightProvider, TableRefProvider } from '../../contexts';
import Media from '../../screens/Media/Media';
import { Message } from '../../screens/Message';
import { useCloseAllSubWindows } from '../../hooks';

const ROUTES_WITH_SIDEBAR = [
  '',
  HOME,
  AUTO_SELECT,
  BUILDER,
  MANUAL_TRADE,
  CART,
  CASH,
  TRADE_INFO,
  MEDIA,
  MESSAGE,
  CASH_DEPOSIT_SUCCESS,
  CASH_DEPOSIT_FAILED,
];

const ROUTES_WITH_HEADER_INFO = [
  AUTO_SELECT,
  MANUAL_TRADE,
  BUILDER,
  CART,
  TRADE_INFO,
  CASH,
  MEDIA,
  MESSAGE,
  CASH_DEPOSIT_SUCCESS,
  CASH_DEPOSIT_FAILED,
];

const SERVICE_ROUTES = [`/${SSO_AUTH_URL}`, `/${SSO_MY_PAGE_URL}`, `/${SSO_PAYMENT_URL}`, `/${UNSUPPORTED_BROWSERS}`];

const noNeedToReadDisclaimer = (account) => !account || account.disclaimerReadFlg;

function getSystemErrorStatusFromLocalStorage(key, defaultValue) {
  const rawValue = getDefaultValuesFromLocalStorage({ key });
  return rawValue ? JSON.parse(rawValue) : defaultValue;
}

const RoutingComponent = () => {
  const dispatch = useDispatch();
  useRedirectAllMaintenance();

  const [innerLoading, changeInnerLoading] = useState(true);
  useEffect(() => {
    const serviceId = getDefaultValuesFromLocalStorage({
      key: KEY_FOR_DEFAULT_SERVICE_ID,
      defaultValue: DEFAULT_SERVICE_ID,
    });
    dispatch(changeServiceIdSuccess({ serviceId }));
    changeInnerLoading(false);
  }, [dispatch]);

  const routePathname = useSelector((state) => state.router.location.pathname);
  const isMobile = useSelector((state) => Boolean(state.router.location.query.isMobile));
  const activeRoute = useMemo(() => routePathname.split('/')[1], [routePathname]);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isSystemError = getSystemErrorStatusFromLocalStorage(KEY_FOR_SYSTEM_ERROR, false);
  // const loginPageRedirectFlag = !isAuthenticated && loginedService.includes(activeRoute);

  const instrumentListIsLoading = useSelector((state) => state.settings.instrumentListIsLoading);
  const settingsIsLoading = useSelector((state) => state.settings.settingsIsLoading);
  const accountInfoIsLoading = useSelector((state) => state.settings.accountInfoIsLoading);
  const initialRequestsIsLoading = useMemo(
    () => instrumentListIsLoading || settingsIsLoading || accountInfoIsLoading,
    [instrumentListIsLoading, settingsIsLoading, accountInfoIsLoading],
  );

  const browserIsNotSupported = useMemo(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return UNSUPPORTED_BROWSERS.includes(browser.getBrowserName());
  }, []);

  // without sidebar page is all except login and 404
  const withoutSideBar = useMemo(() => {
    return !(isAuthenticated && ROUTES_WITH_SIDEBAR.includes(activeRoute)) || initialRequestsIsLoading;
  }, [isAuthenticated, activeRoute, initialRequestsIsLoading]);

  const withHeaderInfo = useMemo(() => {
    return ROUTES_WITH_HEADER_INFO.includes(activeRoute) && isAuthenticated && !initialRequestsIsLoading;
  }, [activeRoute, isAuthenticated, initialRequestsIsLoading]);

  const isAgreementAccept = useSelector((state) => {
    return Object.values(state.settings.accountInfo).every((s) => noNeedToReadDisclaimer(s));
  });
  const isLoadingAds = useSelector((state) => state.advertisement.isLoading);

  const isServiceRoute = useMemo(() => SERVICE_ROUTES.includes(routePathname), [routePathname]);

  const isFirstLogin = useSelector((state) => state.settings.isFirstLogin);

  const canShowModal = isAuthenticated && !initialRequestsIsLoading && !isServiceRoute && !isMobile;
  useEffect(() => {
    if (canShowModal) {
      if (!isAgreementAccept) {
        dispatch(openAgreementInfo());
      } else if (isFirstLogin) {
        dispatch(openTutorialModal());
      } else if (!isLoadingAds) {
        dispatch(openAdvertisementRequest());
      } else if (isSystemError) {
        dispatch(
          sendNotificationError({
            title: 'エラー',
            message: '予期せぬエラーが発生しました。お時間をおいて再度お試しください。',
            buttonText: '閉じる',
          }),
        );
        systemErrorHandler(false);
      }
    }
  }, [canShowModal, isAgreementAccept, isLoadingAds, dispatch, isSystemError, isFirstLogin]);

  const closeAllSubWindows = useCloseAllSubWindows();
  const closeAllSubWindowsRef = useRef(closeAllSubWindows);

  useEffect(() => {
    const handlePageHide = () => {
      closeAllSubWindowsRef.current();
    };
    window.addEventListener('pagehide', handlePageHide);
    return () => {
      window.removeEventListener('pagehide', handlePageHide);
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      closeAllSubWindowsRef.current();
    }
  }, [isAuthenticated]);

  if (innerLoading) {
    return <div className={styles.wrapper} />;
  }

  return (
    <AppHeaderHeightProvider>
      <TableRefProvider>
        <Switch>
          {isAuthenticated == null && <Route component={Loading} />}
          <Route path={`/${MANUAL_TRADE_CHART}`} exact component={ManualTradeChart} />
          <Route
            path={`/${OTHER_WINDOW_AUTO_SELECT_DETAIL_CHART}`}
            exact
            component={OtherWindowAutoSelectDetailChart}
          />
          <Route path={`/${CURRENCY_PAIRS}`} exact component={CurrencyPairPage} />
          <Route path={`/${MOBILE_CHART}`} component={MobileChart} />
          <Route path={`/${MOBILE_DRAW_CHART}`} component={MobileDrawOrder} />
          <Route path={`/${BUILDER_CHART}`} exact component={BuilderChart} />
          <Route path={`/${MOBILE_TECH_BUILDER_CHART}`} exact component={TechBuilderChart} />
          <Route path={`/${MOBILE_AUTO_SELECT_PRICE_CHART}`} exact component={MobileAutoSelectPriceChart} />
          <Route path={`/${MOBILE_MARKET_ORDER_CHART}`} exact component={MobileMarketOrderChart} />
          <Route path={`/${MOBILE_LAB_PRICE_CHART}`} exact component={MobileLabPriceChart} />
          <Route>
            <div
              className={
                isAuthenticated
                  ? classNames(styles.wrapper)
                  : classNames(styles.loginWapper, { [styles.withoutSidebar]: withoutSideBar })
              }
            >
              <PreventActionsScreen />
              {isAuthenticated && (
                <>
                  <Header withHeaderInfo={withHeaderInfo} containerClass={styles.header} />
                  <SideBar containerClass={styles.sidebar} />
                </>
              )}
              <div className={classNames(styles.content, { [styles.withoutSidebar]: withoutSideBar })}>
                <Switch>
                  {browserIsNotSupported && <Route component={UnsupportedBrowserPage} />}
                  {isMobile && (
                    <Switch>
                      <Route path={`/${SSO_MY_PAGE_URL}`} exact component={SSOMyPage} />
                      <Route path={`/${SSO_PAYMENT_URL}`} exact component={SSOPayment} />
                      <Route path={`/${SSO_QUICK_DEPOSIT_URL}`} exact component={SSOQuickDeposit} />
                      <Route path={`/${SSO_PAYMENT_DEPOSIT_REDIRECT_URL}`} exact component={SSOPaymentDeposit} />
                      <Route path={`/${SSO_PAYMENT_TRANSFER_REDIRECT_URL}`} exact component={SSOPaymentTransfer} />
                      <Route path={`/${SSO_PAYMENT_WITHDRAWAL_REDIRECT_URL}`} exact component={SSOPaymentWithdrawal} />
                      <Route path={`/${SSO_PAYMENT_DETAIL_REDIRECT_URL}`} exact component={SSOPaymentDetail} />
                      <Route path={`/${SSO_MONEY_HATCH_URL}`} exact component={SSOMoneyHatch} />
                    </Switch>
                  )}
                  <Route path={`/${SSO_AUTH_URL}`} exact component={SSOAuthPage} />
                  {isAuthenticated && (
                    <Switch>
                      {initialRequestsIsLoading && <Route component={Loading} />}
                      <Route path={`/${HOME}`} exact component={PortfolioPage} />
                      <Route path={`/${AUTO_SELECT}`} component={AutoSelect} />
                      <Route path={`/${BUILDER}`} exact component={Builder} />
                      <Route path={`/${MANUAL_TRADE}`} exact component={ManualTrade} />
                      <Route path={`/${TRADE_INFO}`} exact component={TradeInfo} />
                      <Route path={`/${CART}`} exact component={Cart} />
                      <Route path={`/${CASH}`} exact component={Cash} />
                      <Route path={`/${CASH_DEPOSIT_SUCCESS}`} exact component={QuickDepositSuccess} />
                      <Route path={`/${CASH_DEPOSIT_FAILED}`} exact component={QuickDepositFailed} />
                      <Route path={`/${MEDIA}`} component={Media} />
                      <Route path={`/${MESSAGE}`} exact component={Message} />
                      <Route path={`/${SSO_MY_PAGE_URL}`} exact component={SSOMyPage} />
                      <Route path={`/${SSO_PAYMENT_URL}`} exact component={SSOPayment} />
                      <Route path={`/${SSO_QUICK_DEPOSIT_URL}`} exact component={SSOQuickDeposit} />
                      <Route path={`/${SSO_PAYMENT_DEPOSIT_REDIRECT_URL}`} exact component={SSOPaymentDeposit} />
                      <Route path={`/${SSO_PAYMENT_TRANSFER_REDIRECT_URL}`} exact component={SSOPaymentTransfer} />
                      <Route path={`/${SSO_PAYMENT_WITHDRAWAL_REDIRECT_URL}`} exact component={SSOPaymentWithdrawal} />
                      <Route path={`/${SSO_PAYMENT_DETAIL_REDIRECT_URL}`} exact component={SSOPaymentDetail} />
                      <Route path={`/${SSO_MONEY_HATCH_URL}`} exact component={SSOMoneyHatch} />
                      {activeRoute === LOGIN && <Redirect to={`/${HOME}`} />}
                      <Route component={NotFound} />
                    </Switch>
                  )}
                  {!isAuthenticated && (
                    <Switch>
                      <Route path={`/${LOGIN}`} exact component={LoginPage} />
                      <Redirect to={`/${LOGIN}`} />
                    </Switch>
                  )}
                </Switch>
              </div>
            </div>
          </Route>
        </Switch>
      </TableRefProvider>
    </AppHeaderHeightProvider>
  );
};

export default memo(RoutingComponent);
