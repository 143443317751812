import {
  GET_USER_SSO_MY_PAGE_REQUEST,
  GET_USER_SSO_MY_PAGE_SUCCESS,
  GET_USER_SSO_PAYMENT_REQUEST,
  GET_USER_SSO_PAYMENT_SUCCESS,
  GET_USER_SSO_MONEY_HATCH_REQUEST,
  GET_USER_SSO_MONEY_HATCH_SUCCESS,
  GET_USER_SSO_CASH_FLOW_REQUEST,
  GET_USER_SSO_CASH_FLOW_SUCCESS,
  RESET_SSO_DATA,
} from '../actionConstants/userSSOConstants';

export const getUserSSOMyPageRequest = () => ({ type: GET_USER_SSO_MY_PAGE_REQUEST });
export const getUserSSOMyPageSuccess = ({ url, token, hash }) => ({
  type: GET_USER_SSO_MY_PAGE_SUCCESS,
  payload: { url, token, hash },
});

export const getUserSSOPaymentRequest = ({ serviceId = '', key = '' }) => ({
  type: GET_USER_SSO_PAYMENT_REQUEST,
  payload: { serviceId, key },
});
export const getUserSSOPaymentSuccess = ({ url, token, hash }) => ({
  type: GET_USER_SSO_PAYMENT_SUCCESS,
  payload: { url, token, hash },
});

export const getUserSSOMoneyHatchRequest = () => ({ type: GET_USER_SSO_MONEY_HATCH_REQUEST });
export const getUserSSOMoneyHatchSuccess = ({ url, token, hash }) => ({
  type: GET_USER_SSO_MONEY_HATCH_SUCCESS,
  payload: { url, token, hash },
});

export const getUserSSOCashflowRequest = ({ serviceId, bankCode = '', amount = '', redirectKey }) => ({
  type: GET_USER_SSO_CASH_FLOW_REQUEST,
  payload: { serviceId, bankCode, amount, redirectKey },
});

export const getUserSSOCashflowSuccess = ({ url, token, hash }) => ({
  type: GET_USER_SSO_CASH_FLOW_SUCCESS,
  payload: { url, token, hash },
});

export const resetSSOData = () => ({ type: RESET_SSO_DATA });
