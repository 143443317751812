import React, { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useKeyDownHandler } from '../../services/hooks';
import styles from './tabItem.module.scss';

export const TabItem = memo(
  ({
    isLine,
    tabMinWidth,
    label,
    className,
    value,
    badge,
    id,
    activeKey,
    disabled,
    isVisuallyDisabled,
    onChange,
    onActive,
  }) => {
    const tabRef = useRef(null);

    const active = activeKey === id;

    const handleClick = useCallback(() => {
      if (!disabled && !active) {
        if (onChange) {
          onChange(id);
        }
      }
    }, [active, id, disabled, onChange]);

    const handleKeyDown = useKeyDownHandler(handleClick);

    // onActive と言いつつ tab サイズ変更時にも呼び出しているのは下線もリサイズさせるため
    // 本当なら分けるべき
    useEffect(() => {
      if (active) {
        const { offsetLeft, clientLeft, clientWidth } = tabRef.current;
        onActive?.({ offsetLeft, clientLeft, clientWidth });
      }
    }, [tabMinWidth, active, onActive]);

    return (
      <div
        ref={tabRef}
        role="button"
        tabIndex={disabled ? -1 : 0}
        style={tabMinWidth == null ? {} : { minWidth: tabMinWidth }}
        className={classNames(
          { [styles.card]: !isLine },
          { [styles.line]: isLine },
          { [styles.active]: active },
          { [styles.disabled]: disabled || isVisuallyDisabled },
          { [styles.disabledVisually]: isVisuallyDisabled },
          className,
        )}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <div className={styles.label}>{label ?? value ?? ''}</div>
        {badge && (
          <div className={styles.badgeContainer}>
            <div className={styles.badge}>{badge}</div>
          </div>
        )}
      </div>
    );
  },
);

TabItem.propTypes = {
  isLine: PropTypes.bool.isRequired,
  tabMinWidth: PropTypes.number,
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  badge: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  isVisuallyDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onActive: PropTypes.func,
};

TabItem.defaultProps = {
  className: '',
  tabMinWidth: undefined,
  disabled: undefined,
  isVisuallyDisabled: undefined,
  label: undefined,
  value: undefined,
  badge: undefined,
  onActive: undefined,
};
