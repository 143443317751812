import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCalculatingMarginByStrategyList } from 'shared-modules/hooks';
import { ProfitLossChart, SimulationInfo, Spin, TermSelect } from '../../../../../components';
import { tutorialSimulationChart, tutorialSimulationInfo } from '../../../../../constants/tutorial/classNames';
import styles from './commonChart.module.scss';

export const CHART_MARGIN = {
  top: 10,
  left: 14,
  right: 10,
  bottom: 0,
};

export const CommonChart = memo(
  ({
    chartData,
    simulationData,
    simulationSummary,
    strategyList,
    defaultSets,
    serviceId,
    loading,
    scale,
    termId,
    termOptions,
    onChangeTermId,
  }) => {
    if (chartData == null || simulationData == null) {
      return null;
    }
    return (
      <div className={classNames(styles.container, tutorialSimulationChart)}>
        <div className={styles.header}>
          <TermSelect
            titleClassName={styles.termSelectTitle}
            title="シミュレーション期間"
            options={termOptions}
            termId={termId}
            onChange={onChangeTermId}
          />
        </div>
        <div className={styles.chartArea}>
          {loading ? (
            <Spin className={styles.loader} />
          ) : (
            <CommonChartInner
              chartData={chartData}
              simulationData={simulationData}
              simulationSummary={simulationSummary}
              strategyList={strategyList}
              defaultSets={defaultSets}
              serviceId={serviceId}
              scale={scale}
            />
          )}
        </div>
      </div>
    );
  },
);

CommonChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      realizedPl: PropTypes.number,
      upl: PropTypes.number,
      pl: PropTypes.number,
      sum: PropTypes.number,
    }),
  ),
  simulationData: PropTypes.shape({
    roi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalPl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    riskReturn: PropTypes.number,
    marginRecommended: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginRequired: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    count: PropTypes.number,
  }),
  simulationSummary: PropTypes.shape({
    count: PropTypes.number,
    slSetting: PropTypes.number,
    newCount: PropTypes.number,
    closeCount: PropTypes.number,
    sides: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  strategyList: PropTypes.arrayOf(PropTypes.shape({})),
  defaultSets: PropTypes.number,
  serviceId: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  loading: PropTypes.bool,
  scale: PropTypes.string,
  termId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  termOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  onChangeTermId: PropTypes.func,
};

CommonChart.defaultProps = {
  chartData: undefined,
  simulationData: undefined,
  strategyList: undefined,
  defaultSets: undefined,
  serviceId: undefined,
  loading: false,
  scale: undefined,
  termId: undefined,
  termOptions: undefined,
  onChangeTermId: undefined,
};

const CommonChartInner = memo(
  ({ chartData, simulationData, simulationSummary, strategyList, defaultSets, serviceId, scale }) => {
    const marginRequired = useCalculatingMarginByStrategyList(strategyList, defaultSets);
    const { totalPl, marginRecommended, riskReturn, riskAssessment, tradeCount } = simulationData ?? {};
    const { count, slSetting, closeCount, sides } = simulationSummary;
    return (
      <>
        <div className={styles.chart}>
          <ProfitLossChart chartMargin={CHART_MARGIN} data={chartData} useDefaultYAxisWidth scale={scale} />
        </div>
        <div className={classNames(styles.info, tutorialSimulationInfo)}>
          <SimulationInfo
            serviceId={serviceId}
            totalPl={totalPl}
            riskReturn={riskReturn}
            marginRecommended={marginRecommended}
            marginRequired={marginRequired}
            count={count}
            slSetting={slSetting}
            newCount={tradeCount}
            closeCount={closeCount}
            sides={sides}
            riskAssessment={riskAssessment}
            formattedMarginValue
          />
        </div>
      </>
    );
  },
);

CommonChartInner.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      realizedPl: PropTypes.number,
      upl: PropTypes.number,
      pl: PropTypes.number,
      sum: PropTypes.number,
    }),
  ),
  simulationData: PropTypes.shape({
    roi: PropTypes.number,
    totalPl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    riskReturn: PropTypes.number,
    marginRecommended: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginRequired: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    count: PropTypes.number,
    riskAssessment: PropTypes.number,
  }),
  simulationSummary: PropTypes.shape({
    count: PropTypes.number,
    slSetting: PropTypes.number,
    newCount: PropTypes.number,
    closeCount: PropTypes.number,
    sides: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  strategyList: PropTypes.arrayOf(PropTypes.shape({})),
  defaultSets: PropTypes.number,
  serviceId: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  scale: PropTypes.string,
};

CommonChartInner.defaultProps = {
  chartData: undefined,
  simulationData: undefined,
  strategyList: undefined,
  defaultSets: undefined,
  serviceId: undefined,
  scale: undefined,
};
