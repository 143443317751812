import React, { memo, useState, useCallback, useMemo } from 'react';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useKeyPressHandler } from '../../services/hooks';
import styles from './customAccordion.module.scss';

const CardHeader = ({ children, onClick, eventKey, activeKeys }) => {
  const isActive = useMemo(() => activeKeys.includes(eventKey), [eventKey, activeKeys]);
  const handleClick = useCallback(
    (e) => {
      e.currentTarget.blur();
      onClick(eventKey);
    },
    [onClick, eventKey],
  );
  const handleKeyPress = useKeyPressHandler(handleClick);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      className={classNames(styles.cardHeader, { [styles.isActive]: isActive })}
    >
      {children}
      <i className={classNames('material-icons', styles.arrowIcon, { [styles.isActive]: isActive })}>
        keyboard_arrow_down
      </i>
    </div>
  );
};

CardHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClick: PropTypes.func.isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  activeKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

CardHeader.defaultProps = {
  activeKeys: [],
};

const CustomAccordion = ({ options, className, defaultActiveKeys, onChangeActiveKeys }) => {
  const [activeKeys, setActiveKeys] = useState(defaultActiveKeys);
  const onHeaderClick = useCallback(
    (activeKey) => {
      if (activeKeys.includes(activeKey)) {
        const filteredKeys = activeKeys.filter((key) => key !== activeKey);
        onChangeActiveKeys(filteredKeys);
        return setActiveKeys(filteredKeys);
      }
      const newKeys = [...activeKeys, activeKey];
      onChangeActiveKeys(newKeys);
      return setActiveKeys(newKeys);
    },
    [activeKeys, onChangeActiveKeys],
  );

  return (
    <div className={className}>
      {options.map(({ key, title, body }) => (
        <Accordion key={key} activeKey={activeKeys.includes(key) ? key : null}>
          <CardHeader eventKey={key} onClick={onHeaderClick} activeKeys={activeKeys}>
            {title}
          </CardHeader>
          <Accordion.Collapse eventKey={key}>
            <div className={styles.cardBody}>{body}</div>
          </Accordion.Collapse>
        </Accordion>
      ))}
    </div>
  );
};

CustomAccordion.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    }).isRequired,
  ).isRequired,
  className: PropTypes.string,
  defaultActiveKeys: PropTypes.arrayOf(PropTypes.number),
  onChangeActiveKeys: PropTypes.func,
};

CustomAccordion.defaultProps = {
  className: '',
  defaultActiveKeys: [],
  onChangeActiveKeys: () => {},
};

export default memo(CustomAccordion);
