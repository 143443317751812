export const OPEN_CONFIRMATION_MODAL = 'MODALS/OPEN_CONFIRMATION_MODAL';
export const CLOSE_CONFIRMATION_MODAL = 'MODALS/CLOSE_CONFIRMATION_MODAL';

export const OPEN_INPUT_CONFIRMATION_MODAL = 'MODALS/OPEN_INPUT_CONFIRMATION_MODAL';
export const CLOSE_INPUT_CONFIRMATION_MODAL = 'MODALS/CLOSE_INPUT_CONFIRMATION_MODAL';

export const OPEN_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL = 'MODALS/OPEN_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL';
export const CLOSE_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL = 'MODALS/CLOSE_PORTFOLIO_MANUAL_TRADE_DETAIL_MODAL';

export const OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL = 'MODALS/OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL';
export const CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL = 'MODALS/CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_MODAL';

export const OPEN_BUILDER_CONFIGURATION_FLOW_MODAL = 'MODALS/OPEN_BUILDER_CONFIGURATION_FLOW_MODAL';
export const CLOSE_BUILDER_CONFIGURATION_FLOW_MODAL = 'MODALS/CLOSE_BUILDER_CONFIGURATION_FLOW_MODAL';

export const OPEN_TECH_BUILDER_CONFIGURATION_FLOW_MODAL = 'MODALS/OPEN_TECH_BUILDER_CONFIGURATION_FLOW_MODAL';
export const CLOSE_TECH_BUILDER_CONFIGURATION_FLOW_MODAL = 'MODALS/CLOSE_TECH_BUILDER_CONFIGURATION_FLOW_MODAL';

export const OPEN_BUILDER_ADD_TO_CART_MODAL = 'MODALS/OPEN_BUILDER_ADD_TO_CART_MODAL';
export const CLOSE_BUILDER_ADD_TO_CART_MODAL = 'MODALS/CLOSE_BUILDER_ADD_TO_CART_MODAL';

export const OPEN_BUILDER_ADD_TO_PORTFOLIO_MODAL = 'MODALS/OPEN_BUILDER_ADD_TO_PORTFOLIO_MODAL';
export const CLOSE_BUILDER_ADD_TO_PORTFOLIO_MODAL = 'MODALS/CLOSE_BUILDER_ADD_TO_PORTFOLIO_MODAL';

export const OPEN_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL = 'MODALS/OPEN_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL';
export const CLOSE_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL = 'MODALS/CLOSE_BUILDER_ADD_TO_PORTFOLIO_SUCCESS_MODAL';

export const OPEN_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL = 'MODALS/OPEN_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL';
export const CLOSE_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL = 'MODALS/CLOSE_BUILDER_ADD_TO_PORTFOLIO_FAIL_MODAL';

export const OPEN_CHART_MAKE_ADD_TO_CART_MODAL = 'MODALS/OPEN_CHART_MAKE_ADD_TO_CART_MODAL';
export const CLOSE_CHART_MAKE_ADD_TO_CART_MODAL = 'MODALS/CLOSE_CHART_MAKE_ADD_TO_CART_MODAL';

export const OPEN_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL = 'MODALS/OPEN_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL';
export const CLOSE_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL = 'MODALS/CLOSE_CHART_MAKE_RUN_NOW_CONFIRMATION_MODAL';

export const OPEN_TECH_ADD_TO_CART_MODAL = 'MODALS/OPEN_TECH_ADD_TO_CART_MODAL';
export const CLOSE_TECH_ADD_TO_CART_MODAL = 'MODALS/CLOSE_TECH_ADD_TO_CART_MODAL';

export const OPEN_TECH_ADD_TO_PORTFOLIO_MODAL = 'MODALS/OPEN_TECH_ADD_TO_PORTFOLIO_MODAL';
export const CLOSE_TECH_ADD_TO_PORTFOLIO_MODAL = 'MODALS/CLOSE_TECH_ADD_TO_PORTFOLIO_MODAL';

export const OPEN_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL = 'MODALS/OPEN_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL';
export const CLOSE_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL = 'MODALS/CLOSE_TECH_ADD_TO_PORTFOLIO_SUCCESS_MODAL';

export const OPEN_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL = 'MODALS/OPEN_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL';
export const CLOSE_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL = 'MODALS/CLOSE_TECH_ADD_TO_PORTFOLIO_FAIL_MODAL';

export const OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER = 'MODALS/OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER';
export const CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER = 'MODALS/CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER';

export const OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION =
  'MODALS/OPEN_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION';
export const CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION =
  'MODALS/CLOSE_MANUAL_TRADE_TABLE_CLOSE_ORDER_CONFIRMATION';

export const OPEN_MANUAL_TRADE_CURRENCY_SETTINGS = 'MODALS/OPEN_MANUAL_TRADE_CURRENCY_SETTINGS';
export const CLOSE_MANUAL_TRADE_CURRENCY_SETTINGS = 'MODALS/CLOSE_MANUAL_TRADE_CURRENCY_SETTINGS';

export const OPEN_MANUAL_TRADE_CONFIG = 'MODALS/OPEN_MANUAL_TRADE_CONFIG';
export const CLOSE_MANUAL_TRADE_CONFIG = 'MODALS/CLOSE_MANUAL_TRADE_CONFIG';

export const OPEN_MANUAL_TRADE_NEWS = 'MODALS/OPEN_MANUAL_TRADE_NEWS';
export const CLOSE_MANUAL_TRADE_NEWS = 'MODALS/CLOSE_MANUAL_TRADE_NEWS';

export const OPEN_MANUAL_TRADE_CONFIRMATION = 'MODALS/OPEN_MANUAL_TRADE_CONFIRMATION';
export const CLOSE_MANUAL_TRADE_CONFIRMATION = 'MODALS/CLOSE_MANUAL_TRADE_CONFIRMATION';

export const OPEN_MANUAL_TRADE_TABLE_CHANGE_ORDER = 'MODALS/OPEN_MANUAL_TRADE_TABLE_CHANGE_ORDER';
export const CLOSE_MANUAL_TRADE_TABLE_CHANGE_ORDER = 'MODALS/CLOSE_MANUAL_TRADE_TABLE_CHANGE_ORDER';

export const OPEN_BULK_CHANGE = 'MODALS/OPEN_BULK_CHANGE';
export const CLOSE_BULK_CHANGE = 'MODALS/CLOSE_BULK_CHANGE';

export const OPEN_BULK_CHANGE_CONFIRM_CHECK = 'MODALS/OPEN_BULK_CHANGE_CONFIRM_CHECK';
export const CLOSE_BULK_CHANGE_CONFIRM_CHECK = 'MODALS/CLOSE_BULK_CHANGE_CONFIRM_CHECK';

export const OPEN_BULK_CHANGE_SELECT = 'MODALS/OPEN_BULK_CHANGE_SELECT';
export const CLOSE_BULK_CHANGE_SELECT = 'MODALS/CLOSE_BULK_CHANGE_SELECT';

export const OPEN_BULK_CHANGE_FAIL = 'MODALS/OPEN_BULK_CHANGE_FAIL';
export const CLOSE_BULK_CHANGE_FAIL = 'MODALS/CLOSE_BULK_CHANGE_FAIL';

export const OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE = 'MODALS/OPEN_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE';
export const CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE = 'MODALS/CLOSE_PORTFOLIO_AUTO_TRADE_DETAIL_ORDER_TYPE';

export const OPEN_AUTO_SELECT_RUN_NOW_CONFIRMATION = 'MODALS/OPEN_AUTO_SELECT_RUN_NOW_CONFIRMATION';
export const CLOSE_AUTO_SELECT_RUN_NOW_CONFIRMATION = 'MODALS/CLOSE_AUTO_SELECT_RUN_NOW_CONFIRMATION';

export const OPEN_AUTO_SELECT_ADD_TO_CART_CONFIRMATION = 'MODALS/OPEN_AUTO_SELECT_ADD_TO_CART_CONFIRMATION';
export const CLOSE_AUTO_SELECT_ADD_TO_CART_CONFIRMATION = 'MODALS/CLOSE_AUTO_SELECT_ADD_TO_CART_CONFIRMATION';

export const OPEN_HELP_INFO = 'MODALS/OPEN_HELP_INFO';
export const CLOSE_HELP_INFO = 'MODALS/CLOSE_HELP_INFO';

export const OPEN_AGREEMENT_INFO = 'MODALS/OPEN_AGREEMENT_INFO';
export const CLOSE_AGREEMENT_INFO = 'MODALS/CLOSE_AGREEMENT_INFO';

export const OPEN_COPYRIGHT_INFO = 'MODALS/OPEN_COPYRIGHT_INFO';
export const CLOSE_COPYRIGHT_INFO = 'MODALS/CLOSE_COPYRIGHT_INFO';

export const OPEN_NOTIFICATION_SETTINGS = 'MODALS/OPEN_NOTIFICATION_SETTINGS';
export const CLOSE_NOTIFICATION_SETTINGS = 'MODALS/CLOSE_NOTIFICATION_SETTINGS';

export const OPEN_REPORTS_DETAILS = 'MODALS/OPEN_REPORTS_DETAILS';
export const CLOSE_REPORTS_DETAILS = 'MODALS/CLOSE_REPORTS_DETAILS';

export const OPEN_BUILDER_WARNING_INFO = 'MODALS/OPEN_BUILDER_WARNING_INFO';
export const CLOSE_BUILDER_WARNING_INFO = 'MODALS/CLOSE_BUILDER_WARNING_INFO';

export const OPEN_TECH_BUILDER_WARNING_INFO = 'MODALS/OPEN_TECH_BUILDER_WARNING_INFO';
export const CLOSE_TECH_BUILDER_WARNING_INFO = 'MODALS/CLOSE_TECH_BUILDER_WARNING_INFO';

export const OPEN_AUTO_SELECT_WARNING_INFO = 'MODALS/OPEN_AUTO_SELECT_WARNING_INFO';
export const CLOSE_AUTO_SELECT_WARNING_INFO = 'MODALS/CLOSE_AUTO_SELECT_WARNING_INFO';

export const OPEN_STRATEGIES_SUCCESS = 'MODALS/OPEN_STRATEGIES_SUCCESS';
export const CLOSE_STRATEGIES_SUCCESS = 'MODALS/CLOSE_STRATEGIES_SUCCESS';

export const OPEN_YOUTUBE_MODAL = 'MODALS/OPEN_YOUTUBE_MODAL';
export const CLOSE_YOUTUBE_MODAL = 'MODALS/CLOSE_YOUTUBE_MODAL';

export const OPEN_LAB_TAG_INFO_MODAL = 'MODALS/OPEN_LAB_TAG_INFO_MODAL';
export const CLOSE_LAB_TAG_INFO_MODAL = 'MODALS/CLOSE_LAB_TAG_INFO_MODAL';

export const OPEN_TECH_LOGIC_INFO = 'MODALS/OPEN_TECH_LOGIC_INFO';
export const CLOSE_TECH_LOGIC_INFO = 'MODALS/CLOSE_TECH_LOGIC_INFO';

export const OPEN_RANGE_OUT_CONFIRMATION = 'MESSAGES/OPEN_RANGE_OUT_CONFIRMATION';
export const CLOSE_RANGE_OUT_CONFIRMATION = 'MESSAGES/CLOSE_RANGE_OUT_CONFIRMATION';

export const OPEN_TUTORIAL_MODAL = 'MODALS/OPEN_TUTORIAL_MODAL';
export const CLOSE_TUTORIAL_MODAL = 'MODALS/CLOSE_TUTORIAL_MODAL';

export const OPEN_TUTORIAL_PORTFOLIO_DETAIL_MODAL = 'MODALS/OPEN_TUTORIAL_PORTFOLIO_DETAIL_MODAL';
export const CLOSE_TUTORIAL_PORTFOLIO_DETAIL_MODAL = 'MODALS/CLOSE_TUTORIAL_PORTFOLIO_DETAIL_MODAL';

export const OPEN_BEGIN_DEPOSIT_MODAL = 'MODALS/OPEN_BEGIN_DEPOSIT_MODAL';
export const CLOSE_BEGIN_DEPOSIT_MODAL = 'MODALS/CLOSE_BEGIN_DEPOSIT_MODAL';

export const OPEN_BEGIN_RUN_AUTO_MODAL = 'MODALS/OPEN_BEGIN_RUN_AUTO_MODAL';
export const CLOSE_BEGIN_RUN_AUTO_MODAL = 'MODALS/CLOSE_BEGIN_RUN_AUTO_MODAL';

export const OPEN_SEARCH_AUTO_SELECT_RULE_NAME_MODAL = 'OPEN_SEARCH_AUTO_SELECT_RULE_NAME_MODAL';
export const CLOSE_SEARCH_AUTO_SELECT_RULE_NAME_MODAL = 'CLOSE_SEARCH_AUTO_SELECT_RULE_NAME_MODAL';
