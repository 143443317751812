/* eslint-disable-next-line import/no-unresolved */
import { useCallback, useMemo } from 'react';
/* eslint-disable-next-line import/no-unresolved */
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { TAB_INDIVIDUAL_NOTICE } from '../constants/message';
import { markAsReadRequest } from '../redux/actions/messageActions';
import { ALL_SERVICES, SERVICE_ID_TO_VALUE } from '../constants/core';

const PREFIX_ACTION_TYPE = 'action:';
const isActionType = (action) => action != null && action.startsWith(PREFIX_ACTION_TYPE);
const getActionType = (action) => action.slice(PREFIX_ACTION_TYPE.length);

export const useMessage = ({ message, browse, channel }) => {
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.message.currentTab);
  const { action, linkUrl, messageId, status, startTime, targetServices } = message;
  const serviceValues = targetServices.map(({ serviceId: serviceValue }) => serviceValue);
  const showMessage = useCallback(() => {
    if (isActionType(action)) {
      const type = getActionType(action);
      const { parameters = null } = status || {};
      dispatch({ type, payload: { linkUrl, messageId, parameters, serviceValues } });
    } else {
      browse({ linkUrl, messageId });
      const isIndividual = currentTab === TAB_INDIVIDUAL_NOTICE;
      dispatch(markAsReadRequest({ messageId, isIndividual, channel }));
    }
  }, [dispatch, browse, channel, action, linkUrl, messageId, status, serviceValues, currentTab]);
  const messageDate = useMemo(() => {
    const sTime = status?.startTime ?? startTime;
    if (!sTime) {
      return null;
    }
    const startTimeObj = moment(sTime);
    const days = moment().diff(startTimeObj, 'days');
    if (days === 0) {
      return '今日';
    }
    if (days === 1) {
      return '昨日';
    }
    return startTimeObj.format('YYYY-MM-DD');
  }, [status, startTime]);

  return useMemo(() => {
    return {
      messageDate,
      showMessage,
    };
  }, [messageDate, showMessage]);
};

export const useRangeOutList = ({ parameters, serviceValues }) => {
  return useMemo(() => {
    const serviceValueSet = new Set(serviceValues);
    const rangeOutMap = JSON.parse(parameters ?? null) || {};
    return ALL_SERVICES.map((serviceId) => SERVICE_ID_TO_VALUE[serviceId])
      .filter((serviceValue) => serviceValueSet.has(serviceValue))
      .flatMap((serviceValue) => rangeOutMap[serviceValue] ?? []);
  }, [parameters, serviceValues]);
};
