import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ENTRY_PRICE_NAME_BY_ID } from 'shared-modules/constants/builder';
import { INDICATORS } from 'shared-modules/constants';
import { getServiceQuantityUnit, toCommaSeparatedNumber } from 'shared-modules/services';
import { useGetBuilderServiceId } from 'shared-modules/services/hooks/builder';
import { getPipsLabelWithParentheses } from 'shared-modules/utils';
import { deleteBuilderLogicGroupById } from '../../../../../redux/actions';
import { Table } from '../../../../../components';
import { Empty } from './Empty';
import { SeqCell, BuySellCell, NumberCell as Cell } from './Cell';
import { DeleteIcon } from './DeleteIcon';

const builderResized = {
  key: 'builderLogicGroupTable',
  default: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
};

const techResized = {
  key: 'techLogicGroupTable',
  default: [75, 60, 37, 120, 120, 30, 30, 60, 60, 60, 60, 75, 30],
};

export const LogicGroupTable = memo(({ tableType }) => {
  const dispatch = useDispatch();
  const serviceId = useGetBuilderServiceId();
  const quantityUnit = getServiceQuantityUnit(serviceId);
  const instrumentId = useSelector((state) => state.builder.activeCurrency);
  const pipsLabelWithParentheses = getPipsLabelWithParentheses(serviceId, instrumentId);
  const tableData = useSelector((state) => state.builder.logicGroupsList);

  const handleDelete = useCallback(
    (id) => {
      dispatch(deleteBuilderLogicGroupById({ id }));
    },
    [dispatch],
  );

  const builderColumns = useMemo(
    () => [
      {
        Header: '番号',
        accessor: 'number',
        disableSortBy: true,
        Cell: SeqCell,
      },
      { Header: '銘柄', accessor: 'instrumentId', disableSortBy: true },
      {
        Header: '売買',
        accessor: 'buySell',
        Cell: BuySellCell,
        disableSortBy: true,
      },
      { Header: `数量(${quantityUnit})`, accessor: 'amount', isNumber: true, disableSortBy: true },
      { Header: '本数', accessor: 'itemsCount', isNumber: true, disableSortBy: true },
      { Header: 'レンジ幅', accessor: 'rangeSpread', Cell, isNumber: true, disableSortBy: true },
      {
        Header: '価格指定/前日終値',
        accessor: 'entryPriceType',
        isNumber: true,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => ENTRY_PRICE_NAME_BY_ID[value],
      },
      {
        Header: 'OCO',
        accessor: 'ocoIsChecked',
        Cell: ({ cell: { value } }) => (value ? 'ON' : 'OFF'),
        disableSortBy: true,
      },
      {
        Header: `利確幅${pipsLabelWithParentheses}`,
        accessor: 'profitMargin',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: `損切幅${pipsLabelWithParentheses}`,
        accessor: 'stopLossRange',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: 'フォロー値',
        accessor: 'follow',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: 'カウンター',
        accessor: 'counter',
        Cell: ({
          row: {
            original: { counter, counterIsFixed },
          },
        }) => {
          const separatedValue = toCommaSeparatedNumber(counter);
          const counterValue = counterIsFixed ? `${separatedValue}固定` : separatedValue;
          return counter ? counterValue : '-';
        },
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'options',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { id },
          },
        }) => <DeleteIcon id={id} onClick={handleDelete} />,
        disableSortBy: true,
        sticky: 'right',
      },
    ],
    [handleDelete, pipsLabelWithParentheses, quantityUnit],
  );

  const techColumns = useMemo(
    () => [
      { Header: '銘柄', accessor: 'instrumentId', disableSortBy: true },
      {
        Header: '売買シグナル',
        accessor: 'buySell',
        Cell: BuySellCell,
        disableSortBy: true,
      },
      {
        Header: '全決済',
        accessor: 'isAllSettlement',
        Cell: ({
          row: {
            original: { isAllSettlement },
          },
        }) => {
          return isAllSettlement ? 'ON' : 'OFF';
        },
      },
      {
        Header: 'インジケーター1',
        accessor: 'indicator1',
        Cell: ({
          row: {
            original: { indicator1 },
          },
        }) => {
          return INDICATORS.find((i1) => i1.value === indicator1)?.label;
        },
      },
      {
        Header: 'インジケーター2',
        accessor: 'indicator2',
        Cell: ({
          row: {
            original: { indicator2 },
          },
        }) => {
          return INDICATORS.find((i2) => i2.value === indicator2)?.label ?? '-';
        },
      },
      { Header: `数量(${quantityUnit})`, accessor: 'amount', isNumber: true, disableSortBy: true },
      { Header: '本数', accessor: 'itemsCount', isNumber: true, disableSortBy: true },
      { Header: 'レンジ幅', accessor: 'rangeSpread', Cell, isNumber: true, disableSortBy: true },
      {
        Header: `利確幅${pipsLabelWithParentheses}`,
        accessor: 'profitMargin',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: `損切幅${pipsLabelWithParentheses}`,
        accessor: 'stopLossRange',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: 'フォロー値',
        accessor: 'follow',
        Cell,
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: 'カウンター値',
        accessor: 'counter',
        Cell: ({
          row: {
            original: { counter, counterIsFixed },
          },
        }) => {
          const separatedValue = toCommaSeparatedNumber(counter);
          const counterValue = counterIsFixed ? `${separatedValue}固定` : separatedValue;
          return counter ? counterValue : '-';
        },
        isNumber: true,
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'options',
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { id },
          },
        }) => <DeleteIcon id={id} onClick={handleDelete} />,
        disableSortBy: true,
        sticky: 'right',
      },
    ],
    [handleDelete, pipsLabelWithParentheses, quantityUnit],
  );

  const columns = useMemo(() => {
    switch (tableType) {
      case 'builder':
        return builderColumns;
      case 'tech':
        return techColumns;
      default:
        return [];
    }
  }, [builderColumns, techColumns, tableType]);

  const resized = useMemo(() => {
    switch (tableType) {
      case 'builder':
        return builderResized;
      case 'tech':
        return techResized;
      default:
        return [];
    }
  }, [tableType]);

  if (!tableData.length) {
    return (
      <Empty
        title="追加されたロジックはありません。"
        desc={`${tableType === 'tech' ? '' : '複数の'}ロジックを追加することができます。`}
      />
    );
  }
  return <Table tableData={tableData} columns={columns} resized={resized} key={1} />;
});

LogicGroupTable.propTypes = {
  tableType: PropTypes.string,
};

LogicGroupTable.defaultProps = {
  tableType: 'builder',
};
