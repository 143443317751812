import { put, call, takeEvery, takeLatest, delay, fork, join, select } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import {
  LOGIN,
  LOGIN_CHECKBOX,
  PASSWORD,
  PASSWORD_CHECKBOX,
  SSO_MY_PAGE_URL,
  SSO_PAYMENT_URL,
  SSO_MONEY_HATCH_URL,
  ETF_ACCOUNT,
  KEY_FOR_DEFAULT_SERVICE_ID,
  KEY_FOR_JWT_ETF,
  CFD_ACCOUNT,
  KEY_FOR_JWT_CFD,
  KEY_FOR_JWT_PORTAL_ID,
} from '../../constants';
import {
  DISPLAYING_ERROR_ID_TOO_SHORT,
  DISPLAYING_ERROR_PASSWORD_TOO_SHORT,
  DISPLAYING_ERROR_PASSWORD_TOO_SHORT_MOBILE,
  IMS_200B_ERROR_MESSAGE,
  SESSION_EXPIRED_ERROR_MESSAGE,
} from '../../constants/errorMesages';
import {
  IMS_LOGIN_ACCOUNT_TYPE,
  NEW_SESSION_WITH_THIS_CREDENTIAL_CODE,
  SESSION_EXPIRED_CODE,
  SSO_LOGIN_ACCOUNT_TYPE,
} from '../../constants/apiConstant';

import {
  LOGIN_USER_REQUEST,
  LOGOUT_USER,
  GET_INITIAL_REQUESTS,
  GET_PUBLIC_INITIAL_REQUESTS,
  LOGIN_FLOW_REQUEST,
  CHANGE_SERVICE_ID_REQUEST,
  GET_REFETCH_INITIAL_REQUESTS,
} from '../actionConstants/authConstants';
import {
  loginUserSuccess,
  loginUserFailed,
  authStartLoading,
  authEndLoading,
  clearReduxStore,
  changeLoginValues,
  loginFlowStartLoading,
  loginFlowStopLoading,
  getInitialRequests,
  changeServiceIdSuccess,
  getPublicInitialRequests,
  updateUsername,
  clearStorage,
  openLoginAlert,
  changePortalId,
  getRefetchInitialRequests,
} from '../actions/authActions';
import { getNewsPeriodically } from '../actions/manualTradeActions';
import {
  getInstrumentListRequest,
  getAccountInfoRequest,
  getSettingsRequest,
  getUserMailSettingsRequest,
  getLearnTriautoConfirmationRequest,
} from '../actions/settingsActions';
import { socketDisconnectRequest, socketConnectionRequest } from '../actions/socketActions';
import { getNotificationListRequest } from '../actions/advertisementActions';
import { getCurrentCartItemsCountRequest } from '../actions/cartActions';
import { getMarginPeriodically } from '../actions/portfolioActions';
import {
  calcInstrumentMarginPeriodically,
  calcPublicInstrumentMarginPeriodically,
  getRatesRequest,
} from '../actions/currenciesActions';
import { startMessageListener } from '../actions/messageActions';
import {
  signInUser,
  checkAuthenticatedUser,
  checkSession,
  logoutUser,
  getIdToken,
  refreshUserSession,
} from '../../api';
import { parseLoginError, parsedIMSError, saveDefaultValuesFromLocalStorage, checkIsWebApp } from '../../services';
import { getValidationResult, validateLength, validateName } from '../../services/validators';
import { accountInfoRequestHandler, getLoginMethodHandler, getSettingsHandler } from './settingsSaga';
import { sendNotificationError } from '../actions/notificationActions';
import {
  clearAutoSelectFilterCondition,
  clearAutoSelectSortOrder,
  removeFilterInitKeyword,
} from '../actions/autoSelectActions';
import { getMarginRequestHandler } from './portfolioSaga';
import { getPositionsRequestHandler } from './currenciesSaga';
import Logger from '../../services/Logger';
import { openErrorInfoModal } from '../actions/errorActions';
import { completeInitialization, startInitialization } from '../actions/eventActions';
import { ALL_SERVICES } from '../../constants/core';
import { getAccountInfo } from './common';
import { makeNotificationErrorParams } from '../../utils/service';
import { errorHandler } from './errorSaga';

// ユーザアクションによる呼び出しを想定
function* checkServiceAvailabilityAndSwitch({ serviceId, localMode, successActionCreator, withoutNotification }) {
  try {
    const accountInfo = yield* getAccountInfo();
    const errorParams = makeNotificationErrorParams({ accountInfo, serviceId });
    if (errorParams) {
      const currentServiceId = yield select((state) => state.auth.serviceId);
      if (accountInfo[currentServiceId].isNotAvailable) {
        // 変更対象と現在のサービスが共に無効の場合
        const otherServices = ALL_SERVICES.filter((service) => service !== serviceId && service !== currentServiceId);
        const otherAvailableService = otherServices.find((service) => !accountInfo[service].isNotAvailable);
        if (otherAvailableService) {
          // 他に有効なサービスがある場合はそちらを選択
          yield put(changeServiceIdSuccess({ serviceId: otherAvailableService, localMode }));
          saveDefaultValuesFromLocalStorage({ key: KEY_FOR_DEFAULT_SERVICE_ID, value: otherAvailableService });
          if (successActionCreator) {
            yield put(successActionCreator(otherAvailableService));
          }
        }
      }
      if (!withoutNotification) {
        const isAuth = yield select((state) => state.auth.isAuthenticated);
        if (isAuth) {
          yield put(sendNotificationError(errorParams));
        } else {
          // 未ログインの場合はログインを促すアラートを表示
          yield put(openLoginAlert());
        }
      }
      return true;
    }

    saveDefaultValuesFromLocalStorage({ key: KEY_FOR_DEFAULT_SERVICE_ID, value: serviceId });
    yield put(changeServiceIdSuccess({ serviceId, localMode }));
    if (successActionCreator) {
      yield put(successActionCreator(serviceId));
    }

    yield delay(0);
  } catch (error) {
    // empty
  }

  return false;
}

function* changeServiceIdHandler(action) {
  try {
    const {
      payload: { localMode, serviceId, reload = true, successActionCreator },
    } = action;

    yield put(authStartLoading());

    const isAuth = yield select((state) => state.auth.isAuthenticated);
    const shouldBreak = yield* checkServiceAvailabilityAndSwitch({ serviceId, localMode, successActionCreator });
    if (shouldBreak) {
      return;
    }

    if (reload) {
      if (checkIsWebApp() || isAuth) {
        yield put(getRefetchInitialRequests());
      }
      if (!checkIsWebApp() && !isAuth) {
        yield put(getPublicInitialRequests());
      }
    }
  } catch (e) {
    // empty
  } finally {
    yield put(authEndLoading());
  }
}

// extract account info from id token returned by Amazon Cognito service
export function* getAccountFromIdToken() {
  const idToken = yield call(getIdToken);
  let etfAccount = null;
  let cfdAccount = null;

  try {
    const user = jwtDecode(idToken);

    if (user) {
      if (Object.prototype.hasOwnProperty.call(user, KEY_FOR_JWT_ETF)) {
        etfAccount = user[KEY_FOR_JWT_ETF];
      }
      if (Object.prototype.hasOwnProperty.call(user, KEY_FOR_JWT_CFD)) {
        cfdAccount = user[KEY_FOR_JWT_CFD];
      }
      if (Object.prototype.hasOwnProperty.call(user, KEY_FOR_JWT_PORTAL_ID)) {
        yield put(changePortalId({ portalId: user[KEY_FOR_JWT_PORTAL_ID] }));
      }
    }
  } catch (error) {
    Logger.error({ name: 'Id token decode error', message: `Id token ${idToken}` });
  }
  if (etfAccount === '') {
    etfAccount = null;
  }
  if (cfdAccount === '') {
    cfdAccount = null;
  }

  yield put(changeLoginValues({ inputName: ETF_ACCOUNT, value: etfAccount }));
  yield put(changeLoginValues({ inputName: CFD_ACCOUNT, value: cfdAccount }));
}

// ほとんどの場合、state.auth.isAuthenticated を true に設定する前に実行されるため、
// 中で state.auth.isAuthenticated を参照するロジックを書く際は注意が必要
function* getMasterData() {
  try {
    const serviceId = yield select((state) => state.auth.serviceId);
    yield* getAccountFromIdToken();
    // そもそも socket 接続結果を待ち合わせしないと取りこぼしが発生すると思う。
    // socket 接続中 -> fetch で最新ステータス取得 -> ステータス変更 -> socket 通知 -> socket 接続確立のパターン
    yield put(socketConnectionRequest()); // メンテナンスの通知で state.auth.isAuthenticated を見ているためかなり灰色
    yield put(getInstrumentListRequest({ isRefetch: false }));

    yield* getSettingsHandler(getSettingsRequest({ isRefetch: false }));
    yield* accountInfoRequestHandler(getAccountInfoRequest({ isRefetch: false }));
    yield put(getRatesRequest());

    yield* checkServiceAvailabilityAndSwitch({ serviceId, withoutNotification: true });
  } catch (e) {
    Logger.error(e);
    throw e;
  }
}

function* initialize(action) {
  try {
    const {
      payload: { isRefetch },
    } = action;
    const serviceId = yield select((state) => state.auth.serviceId);
    yield* getPositionsRequestHandler({ payload: { serviceId } });
    yield* getMarginRequestHandler({ payload: { serviceId } });
    const settings = yield select((state) => state.settings);
    const otherServiceIds = ALL_SERVICES.filter((service) => service !== serviceId);
    // ↓繰り返しの書き方の統一を促しているだけで、実質無意味なルール
    // eslint-disable-next-line no-restricted-syntax
    for (const otherServiceId of otherServiceIds) {
      const isOtherServiceMaintenance = settings[otherServiceId]?.isMaintenance;
      if (!isOtherServiceMaintenance) {
        yield* getPositionsRequestHandler({ payload: { serviceId: otherServiceId } });
        yield* getMarginRequestHandler({ payload: { serviceId: otherServiceId } });
      }
    }

    // send two requests to make sure we get both initial arrays of settings
    yield put(getUserMailSettingsRequest({ serviceId }));
    // ↓繰り返しの書き方の統一を促しているだけで、実質無意味なルール
    // eslint-disable-next-line no-restricted-syntax
    for (const otherServiceId of otherServiceIds) {
      const isOtherServiceMaintenance = settings[otherServiceId]?.isMaintenance;
      if (!isOtherServiceMaintenance) {
        yield put(getUserMailSettingsRequest({ serviceId: otherServiceId }));
      }
    }

    if (!isRefetch) {
      if (checkIsWebApp()) yield put(getNewsPeriodically());
      yield put(startMessageListener());
      yield put(getCurrentCartItemsCountRequest());
      yield put(getNotificationListRequest());
      yield put(getLearnTriautoConfirmationRequest());
      yield put(getMarginPeriodically());
    }
    yield put(calcInstrumentMarginPeriodically());
  } catch (e) {
    Logger.error(e);
  }
}

function* doInitialRequests() {
  try {
    yield put(startInitialization());
    yield call(getMasterData);
    yield put(getInitialRequests({ withoutMasterData: true }));
  } catch {
    // getMasterData でエラーが発生した場合
    yield put(completeInitialization());
  }
}

function* initialRequests(action) {
  try {
    yield put(startInitialization());
    if (action.payload.withoutMasterData !== true) {
      yield call(getMasterData);
    }
    yield* initialize(action);
  } finally {
    yield put(completeInitialization());
  }
}

function* refetchInitialRequests(action) {
  try {
    while (true) {
      const isInitializing = yield select((state) => state.event.isInitializing);
      if (!isInitializing) {
        break;
      }
      // TODO 600ms は感覚
      yield delay(600);
    }
  } catch (error) {
    Logger.error(error);
    return;
  }
  // initialize は独自でエラー処理しているため try 〜 catch から外す
  yield* initialize(action);
}

function* publicInitialRequest() {
  try {
    yield put(getInstrumentListRequest({ isPublic: true }));
    yield* getSettingsHandler(getSettingsRequest({ isPublic: true }));
    yield put(getRatesRequest({ isPublic: true }));
    yield put(socketConnectionRequest({ isPublic: true }));
    yield put(calcPublicInstrumentMarginPeriodically());
  } catch (e) {
    Logger.error(e);
  }
}

function* loginRequestHandler(action) {
  try {
    const {
      payload: { login, password, loginCheckbox, passwordCheckbox, accountType, successCallback, isEmergency },
    } = action;

    yield put(authStartLoading());

    if (!isEmergency) {
      if (accountType === SSO_LOGIN_ACCOUNT_TYPE) {
        getValidationResult([validateName({ login }, DISPLAYING_ERROR_ID_TOO_SHORT)]);
      } else {
        getValidationResult([
          validateName({ login }, DISPLAYING_ERROR_ID_TOO_SHORT),
          validateLength({ password }, DISPLAYING_ERROR_PASSWORD_TOO_SHORT_MOBILE),
        ]);
      }
    } else {
      getValidationResult([
        validateName({ login }, DISPLAYING_ERROR_ID_TOO_SHORT),
        validateName({ password }, DISPLAYING_ERROR_PASSWORD_TOO_SHORT),
      ]);
    }
    yield signInUser({ login, password, accountType });
    yield call(refreshUserSession);

    if (loginCheckbox) {
      yield put(changeLoginValues({ inputName: LOGIN, value: login }));
      yield put(changeLoginValues({ inputName: LOGIN_CHECKBOX, value: loginCheckbox }));
    }
    if (passwordCheckbox) {
      yield put(changeLoginValues({ inputName: PASSWORD, value: password }));
      yield put(changeLoginValues({ inputName: PASSWORD_CHECKBOX, value: passwordCheckbox }));
    }

    yield put(removeFilterInitKeyword());
    yield put(clearAutoSelectFilterCondition());
    yield put(clearAutoSelectSortOrder());
    yield put(clearStorage());
    yield put(clearReduxStore());
    yield call(doInitialRequests);
    yield delay(0);

    yield put(loginUserSuccess());
    yield put(updateUsername({ username: login }));

    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    const errorMessages = parseLoginError(e);
    const {
      payload: { accountType },
    } = action;
    const isEmergency = accountType !== IMS_LOGIN_ACCOUNT_TYPE;

    let defaultErrorMessage = IMS_200B_ERROR_MESSAGE;
    if (isEmergency) {
      defaultErrorMessage = parsedIMSError(e);
    }
    // Only display final loginFailed message when there's no validation error
    const loginErrorMessage = errorMessages.length > 0 ? '' : defaultErrorMessage;
    yield put(loginUserFailed({ errorMessages, loginErrorMessage }));

    const {
      payload: { errorCallback },
    } = action;

    if (errorCallback) {
      errorCallback();
    }
  } finally {
    yield put(authEndLoading());
  }
}

function* checkUserWithoutAdditionalRequests() {
  try {
    const username = yield call(checkAuthenticatedUser);
    yield put(updateUsername({ username }));
    return true;
  } catch (e) {
    yield put(updateUsername({ username: null }));
    return false;
  }
}

function* checkSessionHandler(action) {
  try {
    yield call(checkSession);
  } catch (error) {
    // このブロックでエラーが発生した場合は呼び出し元にエラー処理を委ねる
    const { callbackAction, startup, sessionExpiryErrorHandler } = action.payload;
    if (startup === true) {
      const { status } = error.response ?? {};
      if (status === NEW_SESSION_WITH_THIS_CREDENTIAL_CODE || status === SESSION_EXPIRED_CODE) {
        if (sessionExpiryErrorHandler) {
          yield call(sessionExpiryErrorHandler, callbackAction);
        }
        return false; // NG
      }
    }
    yield call(errorHandler, { error });
    return false; // NG
  }
  return true; // OK
}

const ssoPages = [`/${SSO_MY_PAGE_URL}`, `/${SSO_PAYMENT_URL}`, `/${SSO_MONEY_HATCH_URL}`];

function* loginFlowRequestHandlerWeb(action) {
  try {
    yield put(loginFlowStartLoading());

    const checkUserTask = yield fork(checkUserWithoutAdditionalRequests);

    const currentPage = yield select((state) => state.router.location.pathname);
    if (ssoPages.includes(currentPage)) {
      const userIsAuthenticatedForSSO = yield join(checkUserTask);

      if (userIsAuthenticatedForSSO) {
        yield put(loginUserSuccess());

        yield put(loginFlowStopLoading());
        return;
      }
    }

    const getLoginMethodTask = yield fork(getLoginMethodHandler);

    const userIsAuthenticated = yield join(checkUserTask);
    const getLoginMethodSuccess = yield join(getLoginMethodTask);
    if (!userIsAuthenticated) {
      yield put(loginUserFailed({ errorMessages: [], errorMessage: [] }));
    }

    if (!getLoginMethodSuccess) {
      return;
    }

    if (userIsAuthenticated) {
      const checkSessionResult = yield call(checkSessionHandler, action);
      if (checkSessionResult) {
        yield call(doInitialRequests);
        yield delay(0);

        yield put(loginUserSuccess());
        yield put(loginFlowStopLoading());
      } else {
        yield put(loginUserFailed({ errorMessages: [], errorMessage: [] }));
        yield put(loginFlowStopLoading());
      }
      return;
    }
    yield put(loginFlowStopLoading());
  } catch (e) {
    yield put(loginUserFailed({ errorMessages: [], errorMessage: [] }));
    Logger.error(e);
  }
}

function* loginFlowRequestHandlerMobile({ payload: { callbackAction, startup, sessionExpiryErrorHandler } = {} }) {
  try {
    yield put(loginFlowStartLoading());

    // 既にセッションが切れているかどうかを判断する為、チェック前に値を保存しておく
    const username = yield select((state) => state.auth.username);

    const userIsAuthenticated = yield* checkUserWithoutAdditionalRequests();

    if (userIsAuthenticated) {
      const checkSessionResult = yield call(checkSessionHandler, {
        payload: { callbackAction, startup, sessionExpiryErrorHandler },
      });
      if (checkSessionResult) {
        yield call(doInitialRequests);
        yield delay(0);

        yield put(loginUserSuccess());
      } else {
        yield put(loginUserFailed({ errorMessages: [], errorMessage: [] }));
        yield put(getPublicInitialRequests());
      }
      if (callbackAction) {
        yield put(callbackAction);
      }
      return;
    }

    if (username) {
      // cognito session just ended
      if (startup === true) {
        if (sessionExpiryErrorHandler) {
          yield call(sessionExpiryErrorHandler, callbackAction);
        }
      } else {
        yield put(openErrorInfoModal({ message: SESSION_EXPIRED_ERROR_MESSAGE, title: 'エラー', forceLogout: true }));
      }
      yield put(loginUserFailed({ errorMessages: [], errorMessage: [] }));
      yield put(getPublicInitialRequests());
      if (callbackAction) {
        yield put(callbackAction);
      }
      return;
    }
    yield put(loginUserFailed({ errorMessages: [], errorMessage: [] }));
    yield put(getPublicInitialRequests());
    if (callbackAction) {
      yield put(callbackAction);
    }
  } catch (e) {
    Logger.error(e);
  } finally {
    yield put(loginFlowStopLoading());
  }
}

function* logoutUserHandler(action) {
  try {
    const {
      payload: { successCallback, successAction },
    } = action;

    yield put(socketDisconnectRequest());
    yield call(logoutUser);
    yield put(clearAutoSelectFilterCondition());
    yield put(clearAutoSelectSortOrder());
    yield put(clearStorage());
    yield put(clearReduxStore(true));
    yield delay(0);

    const isMobile = !checkIsWebApp();
    if (isMobile) yield put(getPublicInitialRequests());

    // should run callback here, after clearing everything before
    if (successCallback) yield call(successCallback);
    if (successAction) yield put(successAction);
  } catch (e) {
    Logger.error(e);
  }
}

export default function* authSagaHandler() {
  yield takeLatest(LOGIN_USER_REQUEST, loginRequestHandler);
  yield takeEvery(LOGOUT_USER, logoutUserHandler);
  yield takeLatest(GET_INITIAL_REQUESTS, initialRequests);
  yield takeLatest(GET_REFETCH_INITIAL_REQUESTS, refetchInitialRequests);
  yield takeLatest(GET_PUBLIC_INITIAL_REQUESTS, publicInitialRequest);
  yield takeEvery(CHANGE_SERVICE_ID_REQUEST, changeServiceIdHandler);
  yield takeLatest(LOGIN_FLOW_REQUEST, checkIsWebApp() ? loginFlowRequestHandlerWeb : loginFlowRequestHandlerMobile);
}
