import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FX } from 'shared-modules/constants';
import { getServiceQuantityUnit } from 'shared-modules/services';
import { tutorialSwitch } from '../../../../../../../../constants/tutorial/classNames';
import { Table } from '../../../../../../../../components';
import BuySellItem from '../../../../../../../../components/BuySellItem';
import { ShortName } from '../../../../../../../../components/ShortName';
import InstrumentPrecisionTableCell from '../../../../../../../../components/InstrumentPrecisionTableCell';
import DummySwitchComponent from '../DummySwitchComponent';
import styles from '../../../../../PortfolioAutoTradeDetail/components/DetailTable/detailTable.module.scss';
import { orderNameWithToolTips, quantityFooter, sideHeader } from '../../../../../../../../services/tableTemplate';

const TutorialTableOrders = ({ serviceId }) => {
  const selectedApGroupMetaInfo = useSelector((state) => state.portfolio.selectedApGroupMetaInfo);
  const tableMetaInfo = useMemo(
    () => ({ loading: selectedApGroupMetaInfo.loading, ...selectedApGroupMetaInfo[serviceId] }),
    [selectedApGroupMetaInfo, serviceId],
  );

  const { id: selectedApGroupId } = useSelector((state) => state.portfolio.selectedApGroupData);

  const tableData = useSelector((state) => state.webTutorial.orderData);

  const resized = useMemo(
    () => ({
      key: 'portfolioFXAutoTradeOrdersTable',
      default: [88, 99, 44, 100, 99, 106, 84, 84, 82, 90, 80],
    }),
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: '銘柄',
        accessor: 'instrumentId',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (serviceId === FX ? value : <ShortName instrumentId={value} />),
      },
      {
        Header: '注文名',
        accessor: 'name',
        Cell: ({ cell: { value } }) => orderNameWithToolTips(value),
      },
      {
        Header: sideHeader,
        accessor: 'side',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => <BuySellItem type={Number(value)} />,
      },
      {
        Header: `数量(${getServiceQuantityUnit(serviceId)})`,
        accessor: 'quantity',
        isNumber: true,
        Footer: quantityFooter,
      },
      {
        id: 'entryPrice1',
        Header: 'エントリー 価格1',
        accessor: 'entryPrice1',
        isNumber: true,
        Cell: InstrumentPrecisionTableCell,
      },
      {
        id: 'entryPrice2',
        Header: 'エントリー 価格2',
        accessor: 'entryPrice2',
        isNumber: true,
        Cell: InstrumentPrecisionTableCell,
      },
      {
        Header: `利確幅${serviceId === FX ? '(pips)' : ''}`,
        accessor: 'tp',
        isNumber: true,
        // eslint-disable-next-line react/prop-types
        Cell: serviceId === FX ? ({ cell: { value } }) => value || '-' : InstrumentPrecisionTableCell,
      },
      {
        Header: `損切幅${serviceId === FX ? '(pips)' : ''}`,
        accessor: 'sl',
        isNumber: true,
        // eslint-disable-next-line react/prop-types
        Cell: serviceId === FX ? ({ cell: { value } }) => value || '-' : InstrumentPrecisionTableCell,
      },
      {
        Header: 'フォロー値',
        accessor: 'follow',
        // eslint-disable-next-line react/prop-types
        Cell: serviceId === FX ? ({ cell: { value } }) => value || '-' : InstrumentPrecisionTableCell,
        isNumber: true,
      },
      {
        Header: 'カウンター値(価格指定)',
        // can't pass in multi accessor values, so use function -> we can customize what to display simply
        accessor: ({ counterPrice, counter }) => (counterPrice ? `(${counterPrice})` : counter),
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => value || '-',
        disableSortBy: true,
        minWidth: 95,
      },
      {
        Header: '稼働',
        accessor: 'operation',
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => (
          <div className={tutorialSwitch}>
            <DummySwitchComponent rowData={original} groupId={selectedApGroupId} />
          </div>
        ),
        sticky: 'right',
      },
    ],
    [selectedApGroupId, serviceId],
  );

  return (
    <Table
      key={serviceId}
      tableData={tableData}
      columns={columns}
      resized={resized}
      className={styles.table}
      tableMetaInfo={tableMetaInfo}
      useServerSorting
    />
  );
};

TutorialTableOrders.propTypes = {
  serviceId: PropTypes.string.isRequired,
};

export default memo(TutorialTableOrders);
