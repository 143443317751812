import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Decimal from 'decimal.js';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ALL_SERVICES } from 'shared-modules/constants/core';
import {
  DEFAULT_QUANTITY_UNIT_CONV_FACTOR,
  FX,
  MIXED,
  RECOMMENDED_MARGIN_LABEL,
  RISK_ASSESSMENT_LABEL,
} from 'shared-modules/constants';
import { RECOMMENDATION } from 'shared-modules/constants/select';
import { numberExists, roundRoi, roundUpBy1000 } from 'shared-modules/utils';
import { useSelectedStrategyGroup } from 'shared-modules/hooks';
import SelectionImage from '../../../../components/SelectionImage';
import { Evaluation, FeatureTags, Tag } from '../../../../components';
import { ColoredRateText, MarginText } from '../../../../components/Texts';
import styles from './cardItem.module.scss';

export const CardItem = memo(({ data }) => {
  const {
    image,
    name,
    selectionId,
    labId,
    serviceId,
    description,
    style: styleValue,
    period,
    comment,
    simulationStats: {
      recentRoi,
      riskReturn,
      riskAssessment,
      selectionStrategyDetailList,
      realizedPl,
      unrealizedPl,
      marginRecommended,
    },
    strategyList: labStrategyList,
    defaultSets,
    technicalTerm,
    recommendation,
  } = data;

  const [strategyGroup, setStrategyGroup] = useSelectedStrategyGroup();
  const { labId: selectedLabId, selectionId: selectedSelectionId } = strategyGroup ?? {};
  const selected = selectedLabId ? selectedLabId === labId : selectedSelectionId === selectionId;
  const isShare = labId != null;
  const instrumentList = useSelector((state) => state.settings.instrumentList);

  const strategyList = isShare
    ? (labStrategyList ?? []).map((strategy) => ({
        ...strategy,
        strategyDetail: {
          ...strategy?.strategyDetail,
          itemList: strategy?.strategyDetail?.itemList.map((item) => ({
            ...item,
            quantity: new Decimal(item.quantity)
              .div(
                instrumentList[strategy?.strategyDetail?.instrumentId]?.quantityUnitConvFactor ??
                  DEFAULT_QUANTITY_UNIT_CONV_FACTOR,
              )
              .toNumber(),
          })),
        },
      }))
    : (selectionStrategyDetailList ?? []).map((strategy) => ({
        strategySets: strategy.strategySets,
        strategyDetail: {
          instrumentId: strategy.instrumentId,
          itemList: strategy?.itemList?.map((item) => ({
            ...item,
            quantity: new Decimal(item.quantity)
              .div(instrumentList[strategy?.instrumentId]?.quantityUnitConvFactor ?? DEFAULT_QUANTITY_UNIT_CONV_FACTOR)
              .toNumber(),
          })),
        },
      }));

  const totalPl = useMemo(
    () =>
      numberExists(realizedPl) && numberExists(unrealizedPl) ? new Decimal(realizedPl).add(unrealizedPl).toNumber() : 0,
    [realizedPl, unrealizedPl],
  );

  const calculatedMarginRecommended = useMemo(() => {
    if (!marginRecommended || !defaultSets) {
      return 0;
    }
    return roundUpBy1000(marginRecommended * defaultSets);
  }, [marginRecommended, defaultSets]);

  const roi = useMemo(
    () => new Decimal(totalPl).div(calculatedMarginRecommended).mul(100).mul(defaultSets).toNumber(),
    [totalPl, calculatedMarginRecommended, defaultSets],
  );

  const calculatedRoi = useMemo(() => roundRoi(roi), [roi]);

  const serviceIdSet = useMemo(
    () =>
      new Set(
        strategyList
          .map((strategy) => instrumentList?.[strategy.strategyDetail.instrumentId]?.serviceId)
          .filter((sid) => sid),
      ),
    [strategyList, instrumentList],
  );

  const tags = useMemo(() => {
    if (serviceId === MIXED) {
      return (
        <div className={styles.tags}>
          {ALL_SERVICES.filter((service) => serviceIdSet.has(service)).map((service) => {
            return <Tag key={service} className={styles.serviceTag} text={service} />;
          })}
        </div>
      );
    }
    return <Tag className={styles.serviceTag} text={serviceId} />;
  }, [serviceId, serviceIdSet]);

  const handleClickItem = useCallback(() => {
    if (!selected) {
      setStrategyGroup(data);
    }
  }, [selected, data, setStrategyGroup]);

  return (
    <div
      className={classNames(styles.container, {
        [styles.recommendation]: recommendation,
      })}
      onClick={handleClickItem}
      role="button"
      aria-hidden
    >
      {selected && (
        <div className={classNames(styles.lineContainer, { [styles.lineRecommendation]: recommendation })}>
          <div className={styles.line} />
          <div className={styles.mask} />
        </div>
      )}
      {recommendation && (
        <div className={classNames(styles.recommendedTagContainer, { [styles.selected]: selected })}>
          <div className={styles.recommendedTag}>{RECOMMENDATION}</div>
        </div>
      )}
      <div className={styles.content}>
        <SelectionImage
          className={styles.image}
          width=""
          serviceId={serviceId}
          image={image}
          hasFrame={serviceId !== FX}
          isShare={isShare}
        />
        <div className={styles.details}>
          <div className={styles.headline}>
            <div className={styles.upperRow}>
              <div className={styles.nameContainer}>
                <div className={styles.name}>{name}</div>
              </div>
              {tags}
            </div>
            <div className={styles.lowerRow}>
              <div className={styles.description}>{isShare ? comment : description}</div>
            </div>
          </div>
          <FeatureTags
            styleValue={styleValue}
            periodValue={isShare ? String(technicalTerm) : period}
            riskReturn={riskReturn}
            recentRoi={recentRoi}
          />
          <div className={styles.stats}>
            <div className={styles.item}>
              <div className={styles.label}>{RISK_ASSESSMENT_LABEL}</div>
              <Evaluation value={riskAssessment} />
            </div>
            <div className={styles.item}>
              <div className={styles.label}>収益率</div>
              <ColoredRateText className={styles.value} value={calculatedRoi} symbolClassName={styles.percent} />
            </div>
            <div className={styles.item}>
              <div className={styles.label}>{RECOMMENDED_MARGIN_LABEL}</div>
              <MarginText
                value={calculatedMarginRecommended}
                className={styles.value}
                symbolClassName={styles.symbol}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

CardItem.propTypes = {
  data: PropTypes.shape({
    selectionId: PropTypes.number,
    labId: PropTypes.number,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
    serviceId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    style: PropTypes.string,
    period: PropTypes.string,
    technicalTerm: PropTypes.string,
    comment: PropTypes.string,
    selectionVersion: PropTypes.number,
    simulationStats: PropTypes.shape({
      roi: PropTypes.number,
      recentRoi: PropTypes.number,
      riskReturn: PropTypes.number,
      marginRecommended: PropTypes.number,
      marginRequired: PropTypes.number,
      riskAssessment: PropTypes.number,
      selectionStrategyDetailList: PropTypes.arrayOf(PropTypes.shape({})),
      maxDd: PropTypes.number,
      realizedPl: PropTypes.number,
      unrealizedPl: PropTypes.number,
    }).isRequired,
    defaultSets: PropTypes.number,
    strategyList: PropTypes.arrayOf(PropTypes.shape({})),
    recommendation: PropTypes.bool,
  }).isRequired,
};
