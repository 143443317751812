import { put, call, all, takeEvery, select } from 'redux-saga/effects';
import { changeGroupItem } from '../../api/portfolioApi';
import {
  multiEditApGroupItemRequest,
  multiEditApGroupItemFail,
  removeFailData,
  startLoading,
  stopLoading,
} from '../multiEdit';
import { errorHandler } from './errorSaga';
import { getApGroupByIdRequest } from '../actions/portfolioActions';

const allSettled = (effects) => {
  return all(
    effects.map((effect, index) =>
      call(function* settled() {
        try {
          return { error: false, result: yield effect };
        } catch (e) {
          return {
            error: true,
            errorMessage: e.response.data.message,
            result: e.response,
            index,
            statusCode: e.response.status,
          };
        }
      }),
    ),
  );
};

function* multiEditApGroupItemRequestHandler(action) {
  const {
    payload: { groupId, dataList, successCallback, failCallback },
  } = action;
  try {
    const selectedApGroupData = yield select((state) => state.portfolio.selectedApGroupData);
    const apList = selectedApGroupData?.apList || [];
    const instrumentList = yield select((state) => state.settings.instrumentList);
    const serviceId = instrumentList?.[selectedApGroupData?.instrumentId]?.serviceId;
    yield put(startLoading());
    yield put(removeFailData());

    const results = yield allSettled(
      dataList.map((ap) =>
        call(changeGroupItem, {
          groupId,
          serviceId,
          apId: ap.apId,
          requestBody: ap.data,
        }),
      ),
    );

    const errorResults = results.filter((result) => result.error === true);

    if (errorResults.length > 0) {
      const failData = [];

      errorResults.forEach((result) => {
        const apId = dataList[result.index]?.apId;

        const { name: apName } = apList.find((ap) => ap.id === apId);

        failData.push({
          apId: dataList[result.index]?.apId,
          apName,
          failedMessage: result.errorMessage,
          statusCode: result.statusCode,
        });
      });

      yield put(multiEditApGroupItemFail({ failData }));

      if (failCallback) failCallback();

      return;
    }

    if (successCallback) {
      yield put(getApGroupByIdRequest({ id: groupId, serviceId }));
      successCallback();
    }
  } catch (e) {
    yield call(errorHandler, { error: e });
  } finally {
    yield put(stopLoading());
  }
}

export default function* multiEditSaga() {
  yield takeEvery(multiEditApGroupItemRequest.type, multiEditApGroupItemRequestHandler);
}
