import { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { CFD, ETF, FX } from 'shared-modules/constants';
import { getShortName } from 'shared-modules/hooks/symbol';
import useProfitLossFilter from 'shared-modules/services/hooks/useProfitLossFilter';
import CustomCurrencyIcon from '../../../../components/CustomCurrencyIcon';
import { Button, Table, InfoTooltip } from '../../../../components';
import PLCalendar from '../../../../components/PLCalendar/PLCalendar';
import { ColoredPriceText, NumberText } from '../../../../components/Texts';
import MonthItemButton from './MonthItemButton';

import styles from './plTrendTab.module.scss';

const RESIZED = {
  [FX]: {
    KEY: `portfolioFXPlTotal`,
    COLUMNS: [120, 124, 88, 74, 74],
  },
  [ETF]: {
    KEY: `portfolioETFPlTotal`,
    COLUMNS: [120, 124, 88, 74, 74],
  },
  [CFD]: {
    KEY: `portfolioCFDPlTotal`,
    COLUMNS: [120, 124, 88, 74, 74],
  },
};

export const PlTrendTab = memo(({ serviceId }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const {
    instrumentList,
    plByInstrument,
    chartData,
    totalPl,
    totalSwapPl,
    numberOfEntries,
    numberOfExits,
    monthOptions,
    handleMonthSelect,
    selectedMonth,
    resetSelectedMonth,
    textContents,
    currentFrontDate,
  } = useProfitLossFilter(serviceId);

  const selectedMonthIndex = monthOptions.findIndex((item) => item.value === selectedMonth.value);
  const prevMonth = monthOptions[Math.max(selectedMonthIndex - 1, 0)];
  const nextMonth = monthOptions[Math.min(selectedMonthIndex + 1, monthOptions.length - 1)];
  const isFirst = selectedMonthIndex === 0;
  const isLast = selectedMonthIndex === monthOptions.length - 1;

  const handleSelectMonthModal = useCallback(
    (item) => {
      handleMonthSelect(item);
      setModalVisible(false);
    },
    [handleMonthSelect, setModalVisible],
  );

  const columns = useMemo(
    () => [
      {
        Header: '銘柄',
        accessor: 'instrumentId',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (
          <div className="d-flex flex-row">
            <div className="me-2">
              <CustomCurrencyIcon isMini currencyPair={value} className={classNames(styles.iconBorder)} />
            </div>
            <div className="d-flex align-items-center text-start">{getShortName(value, instrumentList)}</div>
          </div>
        ),
        isOrderName: true,
        className: classNames(styles.tableHeaderCol, styles.name),
        disableResizing: true,
      },
      {
        Header: '実現損益',
        accessor: 'pl',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (
          <ColoredPriceText className={styles.cellValue} symbolClassName={styles.cellValue} value={value} allowZero />
        ),
        isNumber: true,
        className: classNames(styles.tableHeaderCol, styles.number),
        disableResizing: true,
      },
      {
        Header: textContents.titlePlSwap,
        accessor: 'swapPl',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (
          <ColoredPriceText className={styles.cellValue} symbolClassName={styles.cellValue} value={value} allowZero />
        ),
        isNumber: true,
        className: classNames(styles.tableHeaderCol, styles.number),
        disableResizing: true,
      },
      {
        Header: '新規',
        accessor: 'openExecutionCount',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (
          <NumberText className={styles.cellValue} symbolClassName={styles.cellValue} value={value} symbol="回" />
        ),
        isNumber: true,
        className: classNames(styles.tableHeaderCol, styles.number),
        disableResizing: true,
      },
      {
        Header: '決済',
        accessor: 'closeExecutionCount',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => (
          <NumberText className={styles.cellValue} symbolClassName={styles.cellValue} value={value} symbol="回" />
        ),
        isNumber: true,
        className: classNames(styles.tableHeaderCol, styles.number),
        disableResizing: true,
      },
    ],
    [instrumentList, textContents.titlePlSwap],
  );

  const resized = useMemo(() => {
    const { KEY, COLUMNS } = RESIZED[serviceId];
    return {
      key: KEY,
      default: [...COLUMNS],
    };
  }, [serviceId]);

  return (
    <div className={classNames('d-flex flex-column pt-3', styles.tabContent, styles.gap24)}>
      <div className="d-flex justify-content-between">
        <div className={styles.titleSide} />
        <div className={classNames('d-flex flex-row', styles.gap24)}>
          <Button
            className={styles.changeMonthBtn}
            secondary
            onClick={() => handleMonthSelect(prevMonth)}
            disabled={isFirst}
          >
            <i className="material-icons-outlined">keyboard_arrow_left</i>
          </Button>
          <div className={styles.selectedMonth}>{selectedMonth.label}</div>
          <Button
            className={styles.changeMonthBtn}
            secondary
            onClick={() => handleMonthSelect(nextMonth)}
            disabled={isLast}
          >
            <i className="material-icons-outlined">keyboard_arrow_right</i>
          </Button>
        </div>
        <div className={classNames('d-flex flex-row', styles.titleSide, styles.gap10)}>
          <Button className={styles.thisMonthBtn} secondary onClick={resetSelectedMonth} disabled={false}>
            今月
          </Button>
          <button
            type="button"
            className={classNames(styles.calendarBtn, 'btn btn-outline-secondary')}
            onClick={() => setModalVisible(true)}
          >
            <i className="material-icons-outlined">calendar_today</i>
          </button>
        </div>
      </div>
      <div className={classNames('d-flex flex-row', styles.gap24)}>
        <div className={classNames('d-flex flex-column', styles.leftContainer, styles.gap24)}>
          <div className={classNames('card d-flex flex-column', styles.cardLayout, styles.gap8)}>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <div className={styles.cardLabel}>損益合計</div>
                <InfoTooltip message={textContents.toolTipPlTotal} />
              </div>

              <ColoredPriceText
                className={styles.plTotal}
                symbolClassName={styles.symbol}
                value={totalPl + totalSwapPl}
                allowZero
              />
            </div>
            <div className="d-flex justify-content-between">
              <div className={classNames('d-flex justify-content-between', styles.smallItem)}>
                <div className="d-flex flex-row">
                  <div className={styles.cardLabel}>実現損益</div>
                  <InfoTooltip message={textContents.toolTipPl} />
                </div>
                <ColoredPriceText
                  className={styles.cardValue}
                  symbolClassName={styles.symbol}
                  value={totalPl}
                  allowZero
                />
              </div>
              <div className={classNames('d-flex justify-content-between', styles.smallItem)}>
                <div className="d-flex flex-row">
                  <div className={styles.cardLabel}>{textContents.titlePlSwap}</div>
                  <InfoTooltip message={textContents.toolTipPlSwap} />
                </div>
                <ColoredPriceText
                  className={styles.cardValue}
                  symbolClassName={styles.symbol}
                  value={totalSwapPl}
                  allowZero
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className={classNames('d-flex justify-content-between', styles.smallItem)}>
                <div className={styles.cardLabel}> 新規 </div>
                <NumberText
                  className={styles.cardValue}
                  symbolClassName={styles.symbol}
                  value={numberOfEntries}
                  symbol="回"
                />
              </div>
              <div className={classNames('d-flex justify-content-between', styles.smallItem)}>
                <div className={styles.cardLabel}>決済</div>
                <NumberText
                  className={styles.cardValue}
                  symbolClassName={styles.symbol}
                  value={numberOfExits}
                  symbol="回"
                />
              </div>
            </div>
          </div>
          <div>
            <h2 className={styles.tableTitle}>銘柄ごとの損益合計</h2>
            <div className={styles.tableContainer}>
              <Table
                tableData={plByInstrument}
                columns={columns}
                resized={resized}
                emptyText="選択中の期間に損益は発生していません。"
                rowHight={64}
              />
            </div>
          </div>
        </div>
        <div className={styles.calendarContainer}>
          <PLCalendar month={selectedMonth} data={chartData} currentFrontDate={currentFrontDate} />
        </div>
      </div>
      <Modal
        contentClassName="popup popup--fit-content"
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        className={styles.modal}
        backdropClassName={styles.backdrop}
        centered
      >
        <Modal.Header className="popup__header" closeButton closeVariant="white">
          <Modal.Title className={styles.modalTitle}>期間指定</Modal.Title>
        </Modal.Header>
        <Modal.Body className="popup__body">
          <div className="container pt-2">
            <div className="row row-cols-3">
              {monthOptions.map((item) => (
                <div className="col" style={{ padding: 5 }} key={item.value}>
                  <MonthItemButton
                    isActive={item.value === selectedMonth.value}
                    onClick={() => handleSelectMonthModal(item)}
                  >
                    {item.label}
                  </MonthItemButton>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});

PlTrendTab.propTypes = {
  serviceId: PropTypes.string.isRequired,
};
