import { CFD, ETF, FX } from '.';

export const ADD_TO_CART = 'カートに追加';
export const RUN_NOW = '今すぐ稼働';
export const RECOMMENDATION = 'おすすめ';
export const CONFIRM_MESSAGES = Object.freeze([
  'こちらは一般の投資家が作成したロジックです',
  '注文設定をよく確認いただいた上で運用をご検討ください。',
]);
export const MESSAGE_NO_RESULT = '条件に一致する自動売買ルールが見つかりませんでした。';
export const HOW_TO_READ_CARD = [
  '絞込機能',
  '　運用スタイルや運用の目安となる期間や資金を指定してルールを検索できます。',
  '運用スタイル',
  '　上昇狙い / 下落狙い / レンジ狙い',
  '想定運用期間',
  '　短期（3か月未満） / 中期（3か月以上～6か月未満） / 長期（6か月以上）',
  '効率良',
  '　過去1年間の最大ドローダウンに対して最大総合損益の割合で評価し、資金効率が良いルールに付与されます。',
  '好調',
  '　直近30日間のシミュレーションにおいてパフォーマンスが良いルールに付与されます。',
  'リスク評価',
  '　1～5',
  '　評価の数字が大きいほどリスクを抑えて運用できていると考えられます。',
  '　前日終値から過去1年間の相場変動を対象に標準偏差を計算し、推奨証拠金に対してどの程度マイナスになるかを',
  '　５段階評価で表しています。評価の数字が大きいほどリスクを抑えて運用できていると考えられます。',
  '　シミュレーション上マイナスが出なかった、あるいは注文が1回も約定していない場合にはリスク評価はハイフンとなります。',
];
export const HOW_TO_READ_CARD_MOBILE = [
  '絞込機能',
  '運用スタイルや運用の目安となる期間や資金を指定してルールを検索できます。',
  '',
  '運用スタイル',
  '上昇狙い / 下落狙い / レンジ狙い',
  '',
  '運用期間',
  '短期（3か月未満） / ',
  '中期（3か月以上～6か月未満） / ',
  '長期（6か月以上）',
  '',
  '効率良',
  '過去1年間の最大ドローダウンに対して最大総合損益の割合で評価し、資金効率が良いルールに付与されます。',
  '',
  '好 調',
  '直近30日間のシミュレーションにおいてパフォーマンスが良いルールに付与されます。',
  '',
  'リスク評価',
  '1～5',
  '評価の数字が大きいほどリスクを抑えて運用できていると考えられます。',
  '前日終値から過去1年間の相場変動を対象に標準偏差を計算し、推奨証拠金に対してどの程度マイナスになるかを５段階評価で表しています。',
  '評価の数字が大きいほどリスクを抑えて運用できていると考えられます。',
  'シミュレーション上マイナスが出なかった、あるいは注文が1回も約定していない場合にはリスク評価はハイフンとなります。',
];

export const HOW_TO_READ_CARD_BOLD_LINES = ['絞込機能', '運用スタイル', '想定運用期間', '効率良', '好調', 'リスク評価'];
export const HOW_TO_READ_CARD_BOLD_LINES_MOBILE = [
  '絞込機能',
  '運用スタイル',
  '運用期間',
  '効率良',
  '好 調',
  'リスク評価',
];

export const MARGINS = Object.freeze([
  Object.freeze({ id: '0', value: '50万円未満' }),
  Object.freeze({ id: '1', value: '50〜100万円未満' }),
  Object.freeze({ id: '2', value: '100〜200万円未満' }),
  Object.freeze({ id: '3', value: '200万円以上' }),
]);

export const PERIODS = Object.freeze([
  Object.freeze({ id: '0', value: '短期' }),
  Object.freeze({ id: '1', value: '中期' }),
  Object.freeze({ id: '2', value: '長期' }),
]);

export const EFFICIENT = '0';
export const DOING_WELL = '1';

export const TITLES = Object.freeze([
  Object.freeze({ id: EFFICIENT, value: '効率良' }),
  Object.freeze({ id: DOING_WELL, value: '好調' }),
]);

export const RISK_ASSESSMENTS = Object.freeze([
  Object.freeze({ id: '1', value: '1' }),
  Object.freeze({ id: '2', value: '2' }),
  Object.freeze({ id: '3', value: '3' }),
  Object.freeze({ id: '4', value: '4' }),
  Object.freeze({ id: '5', value: '5' }),
]);

export const MULTI_STRATEGY = 'マルチ戦略';

const SHARE_RETURN_TAG_ID_FX = 1000;
const SHARE_RETURN_TAG_ID_ETF = 2000;
const SHARE_RETURN_TAG_ID_CFD = 3000;
const SHARE_RETURN_TAG_ID_ALL = Object.freeze([
  SHARE_RETURN_TAG_ID_FX,
  SHARE_RETURN_TAG_ID_ETF,
  SHARE_RETURN_TAG_ID_CFD,
]);

export const TAG_ID_FX = 1;
export const TAG_ID_ETF = 2;
export const TAG_ID_CFD = 3;
export const TAG_ID_ALL = 9;

export const SHARE_RETURN_TAG_ID_MAPPING = Object.freeze({
  [TAG_ID_FX]: SHARE_RETURN_TAG_ID_FX,
  [TAG_ID_ETF]: SHARE_RETURN_TAG_ID_ETF,
  [TAG_ID_CFD]: SHARE_RETURN_TAG_ID_CFD,
  [TAG_ID_ALL]: SHARE_RETURN_TAG_ID_ALL,
});

export const SERVICE_TAG_MAPPING = Object.freeze({
  [FX]: TAG_ID_FX,
  [ETF]: TAG_ID_ETF,
  [CFD]: TAG_ID_CFD,
});

export const TAG_SERVICE_MAPPING = Object.freeze({
  [TAG_ID_FX]: FX,
  [TAG_ID_ETF]: ETF,
  [TAG_ID_CFD]: CFD,
});

export const ORDER_RETURN = 0;
export const ORDER_POPULAR = 3;

export const SELECTION_OPTIONS = Object.freeze([
  Object.freeze({ label: 'リターン順', value: ORDER_RETURN }),
  Object.freeze({ label: '人気順', value: ORDER_POPULAR }),
]);

export const DEFAULT_TERM_ID = '3';
